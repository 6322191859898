import { Header } from "@ramble/ramble-ui";
import styled, { theme } from "../../theme";

export const AuthHeader = styled(Header)`
    flex: 0 0 auto;
    font-size: 1.25em;
    margin: 0.7em 0 1em;
    justify-content: space-between;
    font-weight: 600;
    border-bottom: 1px solid ${theme.colors.divider};
    padding-bottom: 10px;
`;

export const AuthDescription = styled.div`
    text-align: left;
`;
