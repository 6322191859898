import { flagRoot } from "../../utils";

export type NotificationType = "error" | "warning" | "info";

/**
 * Notification
 */
export interface Notification {
    /**
     * Unique notification id
     */
    id: number;
    /**
     * Notification message
     */
    message: string;
    /**
     * Notification message
     */
    subMessage?: string | undefined;
    /**
     * Notification type
     */
    type: NotificationType;
}

export const notificationDefaults: Notification = {
    id: 0,
    message: "",
    subMessage: "",
    type: "info",
};

/**
 * Notification array
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State extends Array<Notification> {}
export function notificationsStateDefaults(): State {
    return flagRoot([]);
}

/**
 * Max notifications
 */
export const MAX_NOTIFICATIONS = 5;
