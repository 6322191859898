import { Modal } from "@ramble/ramble-ui";
import React from "react";
import styled from "../theme";

const TermsModalContainer = styled(Modal)`
    max-width: 450px;
    border: 1px solid #d0d0ce;
    border-radius: 0.5em;
    padding: 25px 25px;
    background-color: #f8feff;
`;

const TermsModalHeader = styled.div`
    display: flex;
    width: 100%;
`;

const TermsModalTitle = styled(TermsModalHeader)`
    border-bottom: 1px solid black;

    h3 {
        font-weight: 500;
        font-size: 18px;
        margin-top: 0;
    }
`;

const TermsContentBlock = styled(TermsModalHeader)`
    flex-wrap: wrap;
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    h4 {
        font-weight: 500;
        color: #388196;
    }

    p {
        color: #687c7c;
        font-size: 12px;
    }
`;
const CloseButton = styled.button`
    width: 30px;
    height: 30px;
    border-radius: 0.5em;
    background-color: rgb(59, 151, 177);
    color: #fff;
    margin-left: auto;
    border: none;
    font-weight: bold;
    font-size: 18px;

    &:hover {
        cursor: pointer;
    }
`;

interface TermsModalProp {
    active: boolean;
    onRequestClose(): void;
}

export const TermsModal: React.SFC<TermsModalProp> = (props) => {
    return (
        <TermsModalContainer
            uiActive={props.active}
            uiOnRequestClose={props.onRequestClose}
            uiDefaultSizes={false}
        >
            <TermsModalHeader>
                <CloseButton onClick={props.onRequestClose}>X</CloseButton>
            </TermsModalHeader>
            <TermsModalTitle>
                <h3>IMPORTANT PLAN TERMS</h3>
            </TermsModalTitle>
            <TermsContentBlock>
                <h4>PLAN TERM</h4>
                <p>
                    The plan term begins on the date of sign-up and is set to
                    automatically renew at the end of the applicable membership
                    plan term. All plans are pre-paid and by purchasing a
                    membership, you agree to the initial and recurring
                    membership fees at the then-current membership rate for the
                    selected plan until you cancel your membership. We will send
                    you a reminder prior to the renewal with the then-current
                    renewal rate. We may change our membership prices from time
                    to time with notice; pricing changes will be applied at the
                    next billing cycle.
                </p>
            </TermsContentBlock>
            <TermsContentBlock>
                <h4>PROMOTIONAL RATES</h4>
                <p>
                    When applied, a promotional rate applies for duration of the
                    plan term unless otherwise specified. After the promotional
                    period, you will be automatically charged at the
                    then-current rate for the service(s) you’ve selected. If you
                    add or remove services, your service rates will be adjusted
                    accordingly.
                </p>
            </TermsContentBlock>
            <TermsContentBlock>
                <h4>CANCELLATION</h4>
                <p>
                    You may cancel at any time which will become effective at
                    the end of your current billing cycle. When you cancel, all
                    future membership charges will be discontinued. You will not
                    receive a pro-rated refund; your access and membership
                    benefits will continue for the remainder of the current
                    billing period.
                </p>
            </TermsContentBlock>
            <TermsContentBlock>
                <h4>MONEY-BACK GUARANTEE</h4>
                <p>
                    We are confident that you will love QUIPA and the value it
                    brings to your business. However, if you decide QUIPA
                    membership is not right for you, we offer a 30-day
                    money-back guarantee. Email us at support@quipa.com to
                    request a refund within 30 days of sign-up. We will process
                    a full refund for the membership price paid to the original
                    payment method. One-time fees for set-up, training, etc. are
                    excluded and non-refundable.
                </p>
            </TermsContentBlock>
        </TermsModalContainer>
    );
};
