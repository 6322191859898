import { Api } from "@quipa/api";
import { flagRoot } from "../../utils";

export type BillingInfo = Api.BillingInfo;
export type AccountInfo = Api.AccountInfo;

export interface State {
    /**
     * Account id
     */
    id: number;
    /**
     * Account email
     */
    email: string;
    /**
     * Account type
     */
    type: "subscribed" | "unsubscribed";
    /**
     * Is Beta
     */
    isBeta: boolean;
    /**
     * Assigned account card
     */
    // cardDigits: string | null;
    /**
     * Calculator defaults
     */
    calculatorDefaults: Api.AccountCalculatorDefaultValues;
    /**
     * Quickbook connected
     */
    quickbookConnected: boolean;
    /**
     * Avatar id
     */
    avatarId?: string | null;
}

export function defaults(): State {
    const st: State = {
        id: 0,
        email: "",
        type: "unsubscribed",
        isBeta: false,
        // cardDigits: null,
        quickbookConnected: false,
        calculatorDefaults: {
            workroomDefaultHeadingVal: 0,
            workroomDefaultHeadingDoubleTurned: true,
            workroomDefaultHeadingAllowance: 0,
            workroomDefaultBottomHemVal: 0,
            workroomDefaultBottomDoubleTurned: true,
            workroomDefaultBottomAllowance: 0,
            workroomDefaultSideHemVal: 0,
            workroomDefaultSideDoubleTurned: true,
            workroomDefaultSideAllowance: 0,
            workroomDefaultHorizontalTapeTrimTrim: 0,
            workroomDefaultVerticalTapeTrimTrim: 0,
        },
    };
    return flagRoot(st);
}

export type SubscriptionInfo = Api.SubscriptionInfo;

export type PlanInfo = Api.PlanInfo;
