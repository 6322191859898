import styled from "../../theme";

export const PageContent = styled.div`
    margin: 20px;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    @media screen and (max-width: 992px) {
        min-width: 700px !important;
        max-width: 880px !important;
    }

    @media screen and (max-width: 806px) {
        min-width: 600px !important;
        max-width: 780px !important;
    }
`;
