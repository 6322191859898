import { ValidationPopupInput } from "@ramble/ramble-ui";
import { detect } from "detect-browser";
import React, { Component } from "react";
import styled, { theme } from "../../theme";

interface DisplayValueProps {
    uiOpenList: boolean;
    uiOptionsExist: boolean;
}

const Label = styled.label`
    color: ${theme.colors.grayRGBA};

    .red-asterisk {
        color: #ff6084;
    }
`;

const DisplayNameWrapper = styled.div`
    display: flex;
    flex-direction: column;

    .select-wrapper {
        position: relative;

        > label {
            width: 100%;
        }
    }

    .select-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
        position: absolute;
        left: 0;
        right: 0;
        border-left: 1px solid hsla(0, 0%, 13.9%, 0.15);
        border-right: 1px solid hsla(0, 0%, 13.9%, 0.15);
        border-bottom: 1px solid hsla(0, 0%, 13.9%, 0.15);
        border-radius: 0 0 0.5em 0.5em;
        margin-top: -4px;
        background-color: #fff;
    }

    .select-item {
        color: rgba(27, 28, 29, 0.87);
        padding: 0.25em 1em;
        border-bottom: 1px solid ${theme.colors.grayRGBA};
        display: flex;
        justify-content: space-between;

        > span {
            color: ${theme.colors.grayRGBA};
            font-style: italic;
        }

        &:hover {
            cursor: pointer;
            background-color: #f8f5f8;
        }

        &:first-child {
            padding-top: 0.5em;
        }

        &:last-child {
            border-radius: 0 0 0.5em 0.5em;
            border-bottom: none;
        }
    }
`;

const DisplayNameInput = styled(ValidationPopupInput)<DisplayValueProps>`
    position: relative;
    border-radius: 0.5em;
    border: 1px solid hsla(0, 0%, 13.9%, 0.15);
    z-index: 10;
    padding: 0.35em 0.5em;

    &::after,
    &::before {
        content: "";
        position: absolute;
        border-left: ${(props) => props.uiOptionsExist && "4px solid #a3a3a3"};
        top: 50%;
        height: 10px;
        pointer-events: none;
    }

    &::after {
        transform: translateY(-50%)
            ${(props) =>
                props.uiOpenList
                    ? "rotate(-90deg) skew(-32deg)"
                    : "rotate(90deg) skew(32deg)"};
        right: 15px;
    }

    &::before {
        transform: translateY(-50%)
            ${(props) =>
                props.uiOpenList
                    ? "rotate(90deg) skew(32deg)"
                    : "rotate(-90deg) skew(-32deg)"};
        right: 25px;
    }
`;

interface Props {
    value: string;
    companyName?: string;
    onInputChange(value: string): void;
}

interface State {
    openSelectList: boolean;
}

export default class DisplayName extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            openSelectList: false,
        };
    }

    public handleFocus = () => {
        this.setState({ openSelectList: true });
    };

    public handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
        const value = e.currentTarget.value;
        const { companyName, onInputChange } = this.props;
        if (value === "" && companyName) {
            onInputChange(`${companyName}`);
        }

        this.hideSelectList();
    };

    public hideSelectList = () => {
        this.setState({ openSelectList: false });
    };

    public onClickItem = (e: any) => {
        const value = e.currentTarget.getAttribute("data-value");

        this.props.onInputChange(value);
    };

    public handleFieldChange = (e: any) => {
        const value = e.currentTarget.value;
        this.props.onInputChange(value);
    };

    public render(): JSX.Element {
        const browser = detect();
        const { companyName, value } = this.props;
        const { openSelectList } = this.state;
        const optionsExist = !!companyName;
        return (
            <>
                <Label htmlFor="displayNameOwner">
                    Display Name<span className="red-asterisk">*</span>
                </Label>
                <DisplayNameWrapper>
                    <div className="select-wrapper">
                        <DisplayNameInput
                            id="displayNameOwner"
                            name="displayName"
                            type="text"
                            required={!companyName}
                            value={value || ""}
                            onChange={this.handleFieldChange}
                            uiReportOnBlur={false}
                            onFocus={this.handleFocus}
                            onBlur={(e) => this.handleBlur(e)}
                            uiOpenList={openSelectList}
                            uiOptionsExist={optionsExist}
                            data-index={0}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                        {openSelectList && companyName && (
                            <ul className="select-list">
                                <li
                                    className="select-item"
                                    data-value={`${companyName}`}
                                    onMouseDown={(e) => this.onClickItem(e)}
                                >
                                    {companyName}
                                    <span>default</span>
                                </li>
                            </ul>
                        )}
                    </div>
                </DisplayNameWrapper>
            </>
        );
    }
}
