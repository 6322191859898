import React, { forwardRef, SVGAttributes } from "react";

/**
 * Close icon
 */
const CloseIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>((props, ref) => {
    const { style, ...other } = props;
    return (
        <svg
            fill="currentColor"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 40 40"
            {...other}
            style={{
                verticalAlign: "middle",
                ...style,
            }}
            ref={ref}
        >
            {/* tslint:disable-next-line:max-line-length */}
            <g><path d="m33.5 29.5q0 0.9-0.7 1.5l-3 3.1q-0.6 0.6-1.5 0.6t-1.5-0.6l-6.6-6.6-6.5 6.6q-0.7 0.6-1.6 0.6t-1.5-0.6l-3-3.1q-0.6-0.6-0.6-1.5t0.6-1.5l6.5-6.6-6.5-6.5q-0.6-0.7-0.6-1.6t0.6-1.5l3-3q0.6-0.6 1.5-0.6t1.6 0.6l6.5 6.6 6.6-6.6q0.6-0.6 1.5-0.6t1.5 0.6l3.1 3q0.6 0.7 0.6 1.5t-0.6 1.6l-6.6 6.5 6.6 6.6q0.6 0.6 0.6 1.5z" /></g>
        </svg>
    );
});
CloseIcon.defaultProps = {
    height: "1em",
    width: "1em",
};

export default CloseIcon;
