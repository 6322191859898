import { GetAllQueryParams, Supplier, SupplierGet, SupplierSearch } from "../interfaces/supplier";

import axiosInstance from "./axios-instance";

const getAll = async (queryParams?: GetAllQueryParams) => {
    try {
        const response = await axiosInstance.get<Supplier[]>("/supplier", {
            params: queryParams,
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching suppliers:", error);
        throw error;
    }
};

const getSuppliers = async (queryParams?: GetAllQueryParams) => {
    try {
        const response = await axiosInstance.get<Supplier[]>("/supplier/get-all", {
            params: queryParams,
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching suppliers:", error);
        throw error;
    }
};

const getSupplier = async (queryParams: SupplierGet) => {
    try {
        const response: any = await axiosInstance.get<SupplierGet>(
            `/supplier/get?id=${queryParams.supplierId}`
        );

        return response.data;
    } catch (error) {
        console.error("Error with get supplier:", error);
        throw error;
    }
};

const addSupplier = async (queryParams?: any) => {
    try {
        const response = await axiosInstance.post(`/supplier/add`, queryParams);

        return response.data;
    } catch (error) {
        console.error("Error adding supplier:", error);
        throw error;
    }
};

const editSupplier = async (queryParams: any) => {
    try {
        const response = await axiosInstance.post('/supplier/edit', queryParams)

        return response.data;
    } catch (error) {
        console.error("Error editing supplier:", error);
        throw error;
    }
};

const searchSuppliers = async (queryParams: SupplierSearch) => {
    try {
        const response: any = await axiosInstance.get<SupplierSearch>(
            `/supplier/search_suppliers?text=${queryParams.text}&accountId=${queryParams.accountId}&excludeId=${queryParams.excludeId}&includeInactive=${queryParams.includeInactive}&nameSort=${queryParams.nameSort}&typeSort=${queryParams.typeSort}`
        );

        return response.data;
    } catch (error) {
        console.error("Error with search suppliers:", error);
        throw error;
    }
};

export { getAll, getSuppliers, getSupplier, addSupplier, editSupplier, searchSuppliers };
