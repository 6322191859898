import React, { FC, memo, ReactElement } from "react";
import styled, { css } from "../../theme";

export interface SidebarItemProps {
    /**
     * Active item
     */
    active?: boolean;
    /**
     * Expanded mode
     */
    // expanded: boolean;
    /**
     * Item label
     */
    label: string;
    /**
     * Item icon
     */
    icon: ReactElement<any>;
    /**
     * Item value
     */
    value: string;
    /**
     * Item click callback
     */
    onItemClick(val: string): void;
}

interface ContainerUIProps {
    uiActive?: boolean;
    uiFullMode?: boolean;
}

const Container = styled.div<ContainerUIProps>`
    display: flex;
    flex-flow: row nowrap;
    flex-direction: column;
    /* justify-content: ${props => props.uiFullMode ? "flex-start" : "center"}; */
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    height: 64px;
    gap:5px;

    ${props => props.uiActive && css`
        background: rgba(255, 255, 255,0.20);
    `};

    > svg {
        font-size: 1.6em;
        color: #fff;
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    }

    &:hover {
        background: #377a8d;
    }
`;

const Label = styled.p`
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
`;

export const SidebarItem: FC<SidebarItemProps> = memo(props => {
    return (
        <Container
            // uiFullMode={props.expanded}
            uiActive={props.active}
            onClick={() => props.onItemClick(props.value)}
        >
            {props.icon}
           <Label>{props.label}</Label>
        </Container>
    );
});
