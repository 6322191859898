import React, { FC, memo } from "react";
import styled from "../../theme";

interface ActionMenuItemProps {
    label: string;
    value: string;
    alignment?: "left" | "right";
    topMargin?: string;
    disabled?: boolean;
    onClick(val: string): void;
    setClientProjectModal?(val: boolean): void;
}

interface ItemContainerProps {
    uiAlignment?: ActionMenuItemProps["alignment"];
    uiTopMargin?: ActionMenuItemProps["topMargin"];
}

const ItemContainer = styled.div<ItemContainerProps>`
    margin: 0 20px;
    border-bottom: 1px solid #969696;
    display: flex;
    flex-flow: row nowrap;
    justify-content: ${(props) =>
        props.uiAlignment === "left" ? "space-between" : "flex-end"};
    align-items: center;
    padding: 3px;
    transition: background 0.1s ease;
    font-weight: 500;
    cursor: pointer;
    text-align: ${(props) =>
        props.uiAlignment === "right" ? "right" : "left"};
    margin-top: ${(props) => (props.uiTopMargin === "8px" ? "8px" : "0")};
    color: #3b97b1;

    /* &:hover {
        background: #377a8d;
    } */

    > svg {
        height: 100%;
        width: auto;
        margin-left: 1.5em;
    }
`;

export const ActionMenuItem: FC<ActionMenuItemProps> = memo((props) => {
    const handleClick = () => props.onClick(props.value);

    return (
        <ItemContainer
            onClick={props.disabled ? undefined : handleClick}
            uiAlignment={props.alignment}
            uiTopMargin={props.topMargin}
            className={`${props.disabled ? "cursor-default" : ""}`}
            style={{
                color: props.disabled ? "#c2bcbc" : undefined,
                fontWeight: props.disabled ? "200" : undefined,
                fontStyle: props.disabled ? "italic" : "initial",
            }}
        >
            {props.label}
        </ItemContainer>
    );
});

ActionMenuItem.defaultProps = {
    alignment: "left",
    topMargin: "0px",
};
