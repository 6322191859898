import { Api } from "@quipa/api";
import { actionReducer } from "../../utils";
import { defaults, State } from "./model";

// NOT NEEDED

export default actionReducer<Api.RealtimeActionsMap, State>(defaults(), {
    // [Api.GET_CLIENT]: {
    //     success: (draft, { payload }) => {
    //         draft.id = payload.id;
    //         draft.firstName = payload.firstName;
    //         draft.lastName = payload.lastName;
    //         draft.displayName = payload.displayName;
    //         draft.email = payload.email;
    //         draft.parent = payload.parent;
    //         draft.level = payload.level;
    //         draft.shippingStreetAddress = payload.shippingStreetAddress;
    //         draft.shippingUnitSuite = payload.shippingUnitSuite;
    //         draft.shippingCityTown = payload.shippingCityTown;
    //         draft.shippingCountry = payload.shippingCountry;
    //         draft.shippingStateProvince = payload.shippingStateProvince;
    //         draft.shippingZIPCode = payload.shippingZIPCode;
    //         draft.cellPhone = payload.cellPhone;
    //         draft.homePhone = payload.homePhone;
    //         draft.fax = payload.fax;
    //         draft.company = payload.company;
    //         draft.officePhone = payload.officePhone;
    //         draft.website = payload.website;
    //         draft.projectManager = payload.projectManager;
    //         draft.subClientOfClientList = payload.subClientOfClientList;
    //         draft.notes = payload.notes;
    //         // todo remove @ts-ignore
    //         // @ts-ignore
    //         draft.clientBillingContactInformation.id = payload.clientBillingContactInformation.id;
    //         draft.clientBillingContactInformation.billToClient = payload.clientBillingContactInformation.billToClient;
    //         draft.clientBillingContactInformation.billingFirstName = payload.clientBillingContactInformation.billingFirstName;
    //         draft.clientBillingContactInformation.billingLastName = payload.clientBillingContactInformation.billingLastName;
    //         draft.clientBillingContactInformation.billingStreetAddress = payload.clientBillingContactInformation.billingStreetAddress;
    //         draft.clientBillingContactInformation.billingUnitSuite = payload.clientBillingContactInformation.billingUnitSuite;
    //         draft.clientBillingContactInformation.billingCityTown = payload.clientBillingContactInformation.billingCityTown;
    //         draft.clientBillingContactInformation.billingCountry = payload.clientBillingContactInformation.billingCountry;
    //         draft.clientBillingContactInformation.billingStateProvince = payload.clientBillingContactInformation.billingStateProvince;
    //         draft.clientBillingContactInformation.billingZIPCode = payload.clientBillingContactInformation.billingZIPCode;
    //         draft.clientBillingContactInformation.billingCellPhone = payload.clientBillingContactInformation.billingCellPhone;
    //         draft.clientBillingContactInformation.billingHomePhone = payload.clientBillingContactInformation.billingHomePhone;
    //         draft.clientBillingContactInformation.billingFax = payload.clientBillingContactInformation.billingFax;
    //         draft.clientBillingContactInformation.billingCompany = payload.clientBillingContactInformation.billingCompany;
    //         draft.clientBillingContactInformation.billingOfficePhone = payload.clientBillingContactInformation.billingOfficePhone;
    //         draft.clientBillingContactInformation.billingEmail = payload.clientBillingContactInformation.billingEmail;
    //         draft.clientBillingContactInformation.billingWebsite = payload.clientBillingContactInformation.billingWebsite;
    //         draft.clientBillingContactInformation.billingSalesTax = payload.clientBillingContactInformation.billingSalesTax;
    //         // todo remove @ts-ignore
    //         // @ts-ignore
    //         draft.clientAdditionalContact.id = payload.clientAdditionalContact.id;
    //         draft.clientAdditionalContact.additionalContactRelationToClient = payload.clientAdditionalContact.additionalContactRelationToClient;
    //         draft.clientAdditionalContact.additionalContactFirstName = payload.clientAdditionalContact.additionalContactFirstName;
    //         draft.clientAdditionalContact.additionalContactLastName = payload.clientAdditionalContact.additionalContactLastName;
    //         draft.clientAdditionalContact.additionalContactBillingStreetAddress = payload.clientAdditionalContact.additionalContactBillingStreetAddress;
    //         draft.clientAdditionalContact.additionalContactBillingUnitSuite = payload.clientAdditionalContact.additionalContactBillingUnitSuite;
    //         draft.clientAdditionalContact.additionalContactBillingCityTown = payload.clientAdditionalContact.additionalContactBillingCityTown;
    //         draft.clientAdditionalContact.additionalContactBillingCountry = payload.clientAdditionalContact.additionalContactBillingCountry;
    //         draft.clientAdditionalContact.additionalContactBillingStateProvince = payload.clientAdditionalContact.additionalContactBillingStateProvince;
    //         draft.clientAdditionalContact.additionalContactBillingZIPCode = payload.clientAdditionalContact.additionalContactBillingZIPCode;
    //         draft.clientAdditionalContact.additionalContactCellPhone = payload.clientAdditionalContact.additionalContactCellPhone;
    //         draft.clientAdditionalContact.additionalContactHomePhone = payload.clientAdditionalContact.additionalContactHomePhone;
    //         draft.clientAdditionalContact.additionalContactFax = payload.clientAdditionalContact.additionalContactFax;
    //         draft.clientAdditionalContact.additionalContactCompany = payload.clientAdditionalContact.additionalContactCompany;
    //         draft.clientAdditionalContact.additionalContactOfficePhone = payload.clientAdditionalContact.additionalContactOfficePhone;
    //         draft.clientAdditionalContact.additionalContactEmail = payload.clientAdditionalContact.additionalContactEmail;
    //         draft.clientAdditionalContact.additionalContactWebsite = payload.clientAdditionalContact.additionalContactWebsite;
    //     },
    // },
    // [Api.GET_CLIENTS]: {
    //     success: (draft: any, { payload }) => {
    //         draft.list = payload;
    //     },
    // },
    // [Api.ADD_CLIENT]: {
    //     request: (draft, { payload }) => {

    //     },
    // },
    // [Api.EDIT_CLIENT]: {
    //     success: (draft, { payload }) => {
    //         draft.id = payload.id;
    //         draft.firstName = payload.firstName;
    //         draft.lastName = payload.lastName;
    //         draft.displayName = payload.displayName;
    //         draft.email = payload.email;
    //         draft.parent = payload.parent;
    //         draft.level = payload.level;
    //         draft.shippingStreetAddress = payload.shippingStreetAddress;
    //         draft.shippingUnitSuite = payload.shippingUnitSuite;
    //         draft.shippingCityTown = payload.shippingCityTown;
    //         draft.shippingCountry = payload.shippingCountry;
    //         draft.shippingStateProvince = payload.shippingStateProvince;
    //         draft.shippingZIPCode = payload.shippingZIPCode;
    //         draft.cellPhone = payload.cellPhone;
    //         draft.homePhone = payload.homePhone;
    //         draft.fax = payload.fax;
    //         draft.company = payload.company;
    //         draft.officePhone = payload.officePhone;
    //         draft.website = payload.website;
    //         draft.projectManager = payload.projectManager;
    //         draft.subClientOfClientList = payload.subClientOfClientList;
    //         draft.notes = payload.notes;
    //         draft.clientBillingContactInformation.billToClient = payload.clientBillingContactInformation.billToClient;
    //         draft.clientBillingContactInformation.billingFirstName = payload.clientBillingContactInformation.billingFirstName;
    //         draft.clientBillingContactInformation.billingLastName = payload.clientBillingContactInformation.billingLastName;
    //         draft.clientBillingContactInformation.billingStreetAddress = payload.clientBillingContactInformation.billingStreetAddress;
    //         draft.clientBillingContactInformation.billingUnitSuite = payload.clientBillingContactInformation.billingUnitSuite;
    //         draft.clientBillingContactInformation.billingCityTown = payload.clientBillingContactInformation.billingCityTown;
    //         draft.clientBillingContactInformation.billingCountry = payload.clientBillingContactInformation.billingCountry;
    //         draft.clientBillingContactInformation.billingStateProvince = payload.clientBillingContactInformation.billingStateProvince;
    //         draft.clientBillingContactInformation.billingZIPCode = payload.clientBillingContactInformation.billingZIPCode;
    //         draft.clientBillingContactInformation.billingCellPhone = payload.clientBillingContactInformation.billingCellPhone;
    //         draft.clientBillingContactInformation.billingHomePhone = payload.clientBillingContactInformation.billingHomePhone;
    //         draft.clientBillingContactInformation.billingFax = payload.clientBillingContactInformation.billingFax;
    //         draft.clientBillingContactInformation.billingCompany = payload.clientBillingContactInformation.billingCompany;
    //         draft.clientBillingContactInformation.billingOfficePhone = payload.clientBillingContactInformation.billingOfficePhone;
    //         draft.clientBillingContactInformation.billingEmail = payload.clientBillingContactInformation.billingEmail;
    //         draft.clientBillingContactInformation.billingWebsite = payload.clientBillingContactInformation.billingWebsite;
    //         draft.clientBillingContactInformation.billingSalesTax = payload.clientBillingContactInformation.billingSalesTax;
    //         draft.clientAdditionalContact.additionalContactRelationToClient = payload.clientAdditionalContact.additionalContactRelationToClient;
    //         draft.clientAdditionalContact.additionalContactFirstName = payload.clientAdditionalContact.additionalContactFirstName;
    //         draft.clientAdditionalContact.additionalContactLastName = payload.clientAdditionalContact.additionalContactLastName;
    //         draft.clientAdditionalContact.additionalContactBillingStreetAddress = payload.clientAdditionalContact.additionalContactBillingStreetAddress;
    //         draft.clientAdditionalContact.additionalContactBillingUnitSuite = payload.clientAdditionalContact.additionalContactBillingUnitSuite;
    //         draft.clientAdditionalContact.additionalContactBillingCityTown = payload.clientAdditionalContact.additionalContactBillingCityTown;
    //         draft.clientAdditionalContact.additionalContactBillingCountry = payload.clientAdditionalContact.additionalContactBillingCountry;
    //         draft.clientAdditionalContact.additionalContactBillingStateProvince = payload.clientAdditionalContact.additionalContactBillingStateProvince;
    //         draft.clientAdditionalContact.additionalContactBillingZIPCode = payload.clientAdditionalContact.additionalContactBillingZIPCode;
    //         draft.clientAdditionalContact.additionalContactCellPhone = payload.clientAdditionalContact.additionalContactCellPhone;
    //         draft.clientAdditionalContact.additionalContactHomePhone = payload.clientAdditionalContact.additionalContactHomePhone;
    //         draft.clientAdditionalContact.additionalContactFax = payload.clientAdditionalContact.additionalContactFax;
    //         draft.clientAdditionalContact.additionalContactCompany = payload.clientAdditionalContact.additionalContactCompany;
    //         draft.clientAdditionalContact.additionalContactOfficePhone = payload.clientAdditionalContact.additionalContactOfficePhone;
    //         draft.clientAdditionalContact.additionalContactEmail = payload.clientAdditionalContact.additionalContactEmail;
    //         draft.clientAdditionalContact.additionalContactWebsite = payload.clientAdditionalContact.additionalContactWebsite;
    //     },
    // },
});
