import { ClassAttributes, HTMLAttributes } from "react";
import styled from "../theme/styled";

// tslint:disable-next-line:no-empty-interface
export interface ModalContentUIProps { }

export type ModalContentProps = HTMLAttributes<HTMLElement> & ModalContentUIProps;

const ModalContent = styled.section<ModalContentProps & ClassAttributes<HTMLElement>>`
    width: 100%;
    flex: 1 1 auto;
    font-size: 1em;
    line-height: 1.4;
    padding: 1.5rem;
`;

/**
 * Modal content
 */
export default ModalContent;
