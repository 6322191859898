import { Modal, PageDimmer } from "@ramble/ramble-ui";
import React, { PureComponent } from "react";

import CopyToClipboard from "../components/UI/copy-to-clipboard";
import styled from "../theme";

const noop = () => { return; };

const ModalContainer = styled(Modal)`
    flex-direction: column;
    width: 50%;
    min-width: 400px;
    max-width: 600px;
    height: 200px;
    border: solid 1px #e69fa0;
    background-color: #fbf5f7;
    padding: 15px;
`;

const SubContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
`;

const BackButton = styled.button`
    background-color: #3b87a0;
    border: 0;
    color: #fff;
    cursor: pointer;
    outline-color: #fff0;
    font-size: 25px;
    padding: 2px 10px 0 10px;
    border-radius: 8px;
    position: absolute;
    right: 0;
`;

const Title = styled.div`
    font-size: 30px;
    flex: 5;
    padding: 5px;
    display: flex;
    color: #307083;

    > span {
        margin-top: auto;
        margin-bottom: 8px;
    }
`;

const Message = styled.div`
    font-size: 16px;
    flex: 4;
    padding: 5px;
    color: #9d9a9a;
    margin: 20px 0 40px 0;

    span {
        color: #3b97b1;
    }
`;

const Divider = styled.div`
    width: 100%;
    height: 5px;
    background: #3b97b1;
`;

interface Props {
    isModalOpen: boolean;
    onModalClose(): void;
}

class SupportModal extends PureComponent<Props> {

    public render() {
        return (
            <>
                <PageDimmer uiActive={this.props.isModalOpen} />
                <ModalContainer uiActive={this.props.isModalOpen} uiOnRequestClose={noop} uiDefaultSizes={false}>
                    <SubContainer>
                        <BackButton onClick={this.props.onModalClose}>&times;</BackButton>
                        <Title><span>Support</span></Title>
                        <Divider/>
                        <Message>Please contact us with any questions or issues at <CopyToClipboard title="support@quipa.com" copy="support@quipa.com" tooltipMessage="Email Copied" ><span>support@quipa.com</span></CopyToClipboard> and we will get back to you as soon as possible.</Message>
                    </SubContainer>
                </ModalContainer>
            </>
        );
    }
}

export default SupportModal;
