import {
    GridTable as GridTableE,
    GridTableBody as GridTableBodyE,
    GridTableCell,
    GridTableRow,
    Modal,
    ModalContent,
    ModalHeader,
    PageDimmer,
} from "@ramble/ramble-ui";
import * as _ from "lodash";
import moment from "moment";
import React, { PureComponent } from "react";
import { UserSubscriptionInfo } from "../redux/modules/admin";
import styled, {css} from "../theme";

interface CellProps {
    uiCenter?: boolean;
}

const ModalContainer = styled(Modal)`
    width: 90%;
    max-height: 500px;
    min-height: 300px;
`;

const ModalHeaderPlan = styled(ModalHeader)`
    color: #306f82;
    border-bottom: none;
    padding-bottom: 0;

    div {
        width: 100%;
        border-bottom: 10px solid #e6f1f4;
        padding-bottom: 0.5rem;
        font-family: "Playfair Display", serif;
        font-size: 26px;
    }
`;

const GridTable = styled(GridTableE)`
    border: 0;
`;

const Row = styled(GridTableRow)`
    grid-template-columns: 80px 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "planId planName monthlyPrice startDate renewelDate workOrder";
    font-weight: 600;
    color: #4a4a4a;
    text-align: left;
    background-color: #ecf6f9;

    // &:hover {
    //     background-color: #c1dcec;
    // }
`;

const RowItem = styled(GridTableRow)`
    grid-template-columns: 80px 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    border-bottom: solid #ddd 1px;

    &:last-child {
        border: 0;
    }

    &:hover {
        background-color: #f8f5f8;
    }
`;

const Cell = styled(GridTableCell)<CellProps>`
    text-align: left;
    display: flex;
    align-items: center;
    ${props => props.uiCenter && css`
        justify-content: center;
    `};
`;

const GridTableBody = styled(GridTableBodyE)`
    overflow: auto;
    max-height: 300px;
`;

const ModalContentPlan = styled(ModalContent)`
    padding-bottom: 30px;
    overflow: hidden;
`;

interface PlanInfoModalProps {
    active: boolean;
    planHistory: UserSubscriptionInfo[];
    onRequestClose(): void;
}

// tslint:disable-next-line:no-empty-interface
interface State { }

export class PlanInfoModal extends PureComponent<PlanInfoModalProps, State> {
    public state: State = {
    };

    public render(): JSX.Element {
        const { active, onRequestClose } = this.props;
        return (
            <>
                <PageDimmer uiActive={active} />
                <ModalContainer
                    uiActive={active}
                    uiOnRequestClose={onRequestClose}
                    uiDefaultSizes={false}
                >
                    <ModalHeaderPlan>
                        <div>Plan History</div>
                    </ModalHeaderPlan>
                    <ModalContentPlan>
                        <GridTable>
                            <Row>
                                <GridTableCell uiGridArea="planId">PLAN ID</GridTableCell>
                                <GridTableCell uiGridArea="planName">PLAN NAME</GridTableCell>
                                <GridTableCell uiGridArea="monthlyPrice">MONTHLY PRICE</GridTableCell>
                                <GridTableCell uiGridArea="startDate">START DATE</GridTableCell>
                                <GridTableCell uiGridArea="renewelDate">RENEWAL DATE</GridTableCell>
                                <GridTableCell uiGridArea="workOrder">WORK ORDERS</GridTableCell>
                            </Row>
                            <GridTableBody>
                                    {this.sortByDate(this.props.planHistory).map(el => this.renderPlanHistoryRow(el))}
                            </GridTableBody>
                        </GridTable>
                    </ModalContentPlan>
                </ModalContainer>
            </>
        );
    }

    public renderPlanHistoryRow = (plan: UserSubscriptionInfo): JSX.Element => {
        const monthlyPrice = plan.monthlyPrice ? plan.monthlyPrice : 0;
        const discountAmount = plan.discountAmount ? plan.discountAmount : 0;
        const realAmount = monthlyPrice - discountAmount;
        return (
            <RowItem>
                <Cell>{plan.planId}</Cell>
                <Cell>{plan.planName}</Cell>
                <Cell>
                    {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(realAmount)}
                </Cell>
                <Cell>{plan.startDate ? moment(plan.startDate).format("YYYY-MM-DD hh:mm:ss") : ""}</Cell>
                <Cell>{plan.renewalDate ? moment(plan.renewalDate).format("YYYY-MM-DD hh:mm:ss") : ""}</Cell>
                {plan.workOrdersLimit !== -1 ? <Cell>plan.workOrdersLimit</Cell> : <Cell>&infin;</Cell>}
            </RowItem>
        );
    }

    private sortByDate = (planHistory: UserSubscriptionInfo[]): UserSubscriptionInfo[] => {
        const sortedArray = _.orderBy(planHistory, (el: UserSubscriptionInfo) => {
            return moment(el.startDate);
          }, ["desc"]);
        return sortedArray;
    }
}
