import { isRealtimeAction, REALTIME_ACTION_FAILED, REALTIME_ACTION_REQUEST, REALTIME_ACTION_SUCCESS, RealtimeAction } from "@quipa/api";
import { Middleware } from "redux";
import { BEGIN, COMMIT, OptimisticAction, REVERT } from "redux-optimist";
import { RootState } from "../modules";

function isOptimisticAction(action: RealtimeAction<any, any>): action is RealtimeAction<any, any> & OptimisticAction {
    return !!action.meta.isOptimistic;
}

let nextTransactionId = 1;

/**
 * Optimistic middleware
 * This should be performed BEFORE realtime middleware otherwise optimistic.id will be lost
 */
const middleware: Middleware<{}, RootState> = store => next => action => {
    if (typeof action === "function") {
        return next(action);
    }

    // pass non realtime actions and non optimistic actions
    if (!isRealtimeAction(action) || !isOptimisticAction(action)) {
        return next(action);
    }

    switch (action.flowType) {
        case REALTIME_ACTION_REQUEST: {
            action.optimist = {
                id: nextTransactionId++,
                type: BEGIN,
            };
            break;
        }
        case REALTIME_ACTION_SUCCESS: {
            // tslint:disable-next-line:no-null-keyword
            if (action.optimist && action.optimist.id != null) {
                action.optimist.type = COMMIT;
            }
            break;
        }
        case REALTIME_ACTION_FAILED: {
            // tslint:disable-next-line:no-null-keyword
            if (action.optimist && action.optimist.id !== null) {
                action.optimist.type = REVERT;
            }
            break;
        }
    }
    return next(action);
};

export default middleware;
