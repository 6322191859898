import React, { SelectHTMLAttributes, forwardRef } from "react";
import styled, { css } from "../theme/styled";

import Color from "color";
import { ColorValue } from "../theme";
import { cleanUiProps } from "../utils/withStyledProps";

export interface SelectUIProps {
    /**
     * Color for chevron icon
     */
    uiColor?: ColorValue;
    /**
     * Disabled select
     */
    uiDisabled?: boolean;
}

export type SelectProps = SelectHTMLAttributes<HTMLSelectElement> &
    SelectUIProps;

const SelectComponent = forwardRef<HTMLSelectElement, SelectProps>(
    (props, ref) => {
        const { uiColor, uiDisabled, className, ...other } = props;
        cleanUiProps(other);
        return (
            <div className={className}>
                <select {...other} className="select" ref={ref} />
                <svg
                    className="chevron"
                    focusable={false}
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 40 40"
                    width="1em"
                    height="1em"
                >
                    {/* tslint:disable-next-line:max-line-length */}
                    <g>
                        <path d="m37.6 18l-16.6 16.6q-0.4 0.4-1 0.4t-1-0.4l-16.6-16.6q-0.4-0.4-0.4-1t0.4-1l3.7-3.7q0.5-0.4 1-0.4t1 0.4l11.9 11.9 11.9-11.9q0.4-0.4 1-0.4t1 0.4l3.7 3.7q0.4 0.4 0.4 1t-0.4 1z" />
                    </g>
                </svg>
            </div>
        );
    }
);

/**
 * Simple select by using native select. Doesn't support multiple select
 */
const  Select = styled(SelectComponent)`
    position: relative;
    font-weight: 400;
    cursor: pointer;
    font-style: normal;
    font-size: 1em;
    /* flex: 1 1 auto; */
    /* width: 100%; */
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    outline: none;
    text-align: left;
    box-sizing: content-box;
    background: ${({ theme }) => theme.colors.background};
    ${(props) =>
        props.uiDisabled &&
        css`
            opacity: 0.45;
            pointer-events: none;
        `};

    > .select {
        width: 100%;
        height: 100%;
        border: 0;
        outline: none;
        color: inherit;
        font-size: 1em;
        background-color: inherit;
        -webkit-appearance: none; /* stylelint-disable-line */
        -moz-appearance: none; /* stylelint-disable-line */
        cursor: pointer;
        padding: 0.5em 3em 0.5em 1em;
        /* stylelint-disable */
        border: 1px solid
            ${({ theme }) => Color(theme.colors.divider).alpha(0.15).string()};
        border-radius: 0;
        /* stylelint-enable */
    }

    > .chevron {
        display: inline-block;
        color: ${({ theme, uiColor }) =>
            Color(theme.getColor(uiColor || theme.colors.primary))
                .alpha(0.54)
                .string()};
        pointer-events: none;
        fill: currentColor;
        flex-shrink: 0;
        user-select: none;
        align-self: center;
        vertical-align: middle;
        padding-right: 0.7em;
        position: absolute;
        right: 0;
    }
`;

export default Select;
