import { BotInfo, BrowserInfo, NodeInfo } from "detect-browser";
import {
    FormFields,
    FormFieldsAction,
    FormFieldsPartial,
} from "../../../redux/modules/my-shop/form-fields-reducer";
import {
    FormInput,
    FormLabel,
    RadioInput,
    RadioLabel,
    TextAreaContainer,
} from "../styles";
import React, { ChangeEvent, Dispatch, FC, useEffect, useState } from "react";
import SearchSelect, {
    SearchSelectProps,
} from "../../UI/search_select/search_select";

import { Category } from "../../../interfaces/category";
import { FabricsOrLiningsItemDetailsFields } from "../../../redux/modules/my-shop/fabrics-or-linings-item-details-fields-reducer";
import ItemAvatar from "../item-avatar";
import { Supplier } from "../../../interfaces/supplier";
import { TextArea } from "@ramble/ramble-ui";

interface DetailsProps {
    categories: Category[];
    suppliers: Supplier[];
    browser: BrowserInfo | BotInfo | NodeInfo | null;
    formFields: FormFields;
    fabricsOrLiningsItemDetailsFields: FabricsOrLiningsItemDetailsFields;
    dispatch: Dispatch<FormFieldsAction>;
}

const Details: FC<DetailsProps> = ({
    categories,
    suppliers,
    browser,
    formFields,
    fabricsOrLiningsItemDetailsFields,
    dispatch,
}) => {
    const [isSalesEdited, setIsSalesEdited] = useState<boolean>(false);
    const [isOrderingEdited, setIsOrderingEdited] = useState<boolean>(false);

    const getDimensions = () => {
        const {
            fabricWidthInches,
            verticalRepeatInches,
            horizontalRepeatInches,
        } = fabricsOrLiningsItemDetailsFields;
        if (
            !fabricWidthInches &&
            !verticalRepeatInches &&
            !horizontalRepeatInches
        ) {
            return "";
        }
    };

    const setOrderingDescription = () => {
        const textArea = document.getElementById(
            "orderingDescription"
        ) as HTMLInputElement;

        if (!textArea) {
            return;
        }
        let value = "";
        const { name, itemNumber, orderingDescription } = formFields;
        if (orderingDescription) {
            value = orderingDescription;
        } else {
            const dimensions = getDimensions();
            const data = [name, itemNumber, dimensions];
            let dataValue = data.filter((str) => str).join("\n");

            value = dataValue && dataValue.length ? `${dataValue}\n` : ""; //NOSONAR
            //optional chaining not supported by typescript version, so NOSONAR
        }
        textArea.value = value;
    };

    const setSalesDescription = () => {
        const textArea = document.getElementById(
            "salesDescription"
        ) as HTMLInputElement;

        if (!textArea) {
            return;
        }

        let value = "";
        const { supplierName, name, itemNumber, salesDescription } = formFields;

        if (salesDescription) {
            value = salesDescription;
        } else {
            const dimensions = getDimensions();
            const data = [supplierName, name, itemNumber, dimensions];
            const dataValue = data.filter((str) => str).join("\n");
            value = dataValue && dataValue.length ? `${dataValue}\n` : ""; //NOSONAR
            //optional chaining not supported by typescript version, so NOSONAR
        }
        textArea.value = value;
    };

    const handleItemNameNumberChange = (
        e: ChangeEvent<HTMLInputElement>
    ): void => {
        const key = e.currentTarget.id;
        const value = e.currentTarget.value;

        let updatedForm = {
            [key]: value,
        };
        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updatedForm,
        });
    };

    const handleSearchCategory: SearchSelectProps["onSearch"] = async (
        term: string
      ) => {
        try {
          const mainCategories: Category[] = categories.filter(
            (c) => c.isActive && (c.parentCategoryId === undefined || c.parentCategoryId === null)
          );
      
          const results: { id: number; displayName: string }[] = [];
      
          const findSubcategories = (parentId: number) => {
            const subcategories = categories.filter(
              (c) => c.isActive && c.parentCategoryId === parentId
            );
            subcategories.forEach((subcategory) => {
              results.push({
                id: subcategory.id,
                displayName: `  • ${subcategory.name}`,
              });
              findSubcategories(subcategory.id);
            });
          };
      
          mainCategories.forEach((mainCategory) => {
            results.push({
              id: mainCategory.id,
              displayName: mainCategory.name,
            });
            findSubcategories(mainCategory.id);
          });
      
          return results;
        } catch (error) {
          console.error("Error searching categories:", error);
          return [
            {
              id: 0,
              displayName: "",
            },
          ];
        }
    };

    const handleSelectCategory = (id: number, name: string) => {
        const formFieldUpdates: FormFieldsPartial = {
            categoryId: id,
            categoryName: name,
        };

        dispatch({
            type: "updateFormFields",
            formFieldUpdates,
        });
    };

    const handleSearchSupplier: SearchSelectProps["onSearch"] = async (
        term: string
    ) => {
        try {
            let results = [...suppliers];
            results = results
                .filter((r) => r.active)
                .filter((r) => {
                    if (term && term.length) {
                        return r.displayName
                            .toLowerCase()
                            .includes(term.toLowerCase());
                    }
                    return r;
                })
                .sort((a, b) => a.displayName.localeCompare(b.displayName));
                
            return results.map((r) => ({
                id: r.id,
                displayName: r.displayName,
            }));
        } catch (error) {
            console.error("Error searching suppliers:", error);
            return [
                {
                    id: 0,
                    displayName: "",
                },
            ];
        }
    };

    const handleSelectSupplier = (id: number, name: string) => {
        const formFieldUpdates: FormFieldsPartial = {
            supplierId: id,
            supplierName: name,
        };

        dispatch({
            type: "updateFormFields",
            formFieldUpdates,
        });
    };

    const handlePrefillTextAreaChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        const key = e.currentTarget.id;
        const value = e.currentTarget.value;
        const hasValue = value.length;

        if (hasValue) {
            key === "salesDescription"
                ? setIsSalesEdited(true)
                : setIsOrderingEdited(true);
        }
    };

    const handleFieldChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        let key = e.currentTarget.id;
        let value: string | number = e.currentTarget.value;
        let moreUpdates = {};
        if (["itemTypeProduct", "itemTypeService"].includes(key)) {
            key = "itemTypeId";
            value = +value;
            moreUpdates = {
                retailPricingMethod: "",
                retailPricingValue: "",
                tradePricingMethod: "",
                tradePricingValue: "",
            };
        }

        const updatedForm = {
            [key]: value,
            ...moreUpdates,
        };

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updatedForm,
        });
    };

    //whenever there is an update to the these 3, supplierName, item name and itemNumber
    //salesDescription text area is to be prefilled.
    //but after salesDescription is edited, a change to the 3, should not prefill anymore
    useEffect(() => {
        if (!isSalesEdited) {
            setSalesDescription();
        }
    }, [
        formFields.supplierName,
        formFields.name,
        formFields.itemNumber,
        fabricsOrLiningsItemDetailsFields.fabricWidthInches,
        fabricsOrLiningsItemDetailsFields.verticalRepeatInches,
        fabricsOrLiningsItemDetailsFields.horizontalRepeatInches,
        isSalesEdited,
    ]);

    //whenever there is an update to the these 2, item name and itemNumber
    //orderingDescription text area is to be prefilled.
    //but after orderingDescription is edited, a change to the 2, should not prefill anymore
    useEffect(() => {
        if (!isOrderingEdited) {
            setOrderingDescription();
        }
    }, [
        formFields.name,
        formFields.itemNumber,
        fabricsOrLiningsItemDetailsFields.fabricWidthInches,
        fabricsOrLiningsItemDetailsFields.verticalRepeatInches,
        fabricsOrLiningsItemDetailsFields.horizontalRepeatInches,
        isOrderingEdited,
    ]);

    return (
        <div className="flex flex-wrap w-full items-start">
            <div className="w-2/4 mb-6 pr-6">
                <div className="flex items-center font-bold tracking-wider place-content-between mt-4 mb-4 text-twBlue1">
                    ITEM INFORMATION
                </div>
                <div className="mb-2">
                    <FormLabel htmlFor="Itemcategory">
                        Category<span className="text-red-600">*</span>
                    </FormLabel>
                    <SearchSelect
                        id="itemCategory"
                        searchType="modal"
                        onItemClick={(id, name) =>
                            handleSelectCategory(id, name)
                        }
                        onSearch={handleSearchCategory}
                        initialValue={formFields.categoryName}
                        placeholder="Please select"
                        keepKeyword
                        onKeywordCleared={() => handleSelectCategory(0, "")}
                        required={true}
                    />
                </div>
                <div className="flex flex-col mb-2">
                    <FormLabel htmlFor="itemName">
                        Name<span className="text-red-600">*</span>
                    </FormLabel>
                    <FormInput
                        id="name"
                        name="Item Name"
                        className="px-1.5 py-2"
                        type="text"
                        required
                        value={formFields.name}
                        onChange={handleItemNameNumberChange}
                        uiReportOnBlur={false}
                        disabled={false}
                        uiDisabled={false}
                        uiValidityMessages={{
                            valueMissing:
                                "Item Name must be at least 1 character.",
                        }}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
                <div className="flex flex-col mb-2">
                    <FormLabel htmlFor="itemNumber">Item #</FormLabel>
                    <FormInput
                        id="itemNumber"
                        name="Item Number"
                        className="px-1.5 py-2"
                        type="text"
                        value={formFields.itemNumber}
                        onChange={handleItemNameNumberChange}
                        uiReportOnBlur={false}
                        disabled={false}
                        uiDisabled={false}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
                <div className="mb-2">
                    <FormLabel htmlFor="Itemcategory">
                        Preferred Supplier
                    </FormLabel>
                    <SearchSelect
                        onItemClick={(id, name) =>
                            handleSelectSupplier(id, name)
                        }
                        onSearch={handleSearchSupplier}
                        initialValue={formFields.supplierName}
                        placeholder="Please select"
                        keepKeyword
                        onKeywordCleared={() => handleSelectSupplier(0, "")}
                        searchType="modal"
                        required={false}
                    />
                </div>
                <div className="mt-4">
                    <p className="m-0">
                        Type<span className="text-red-600">*</span>
                    </p>
                    <RadioLabel className="mb-2">
                        <RadioInput
                            id="itemTypeProduct"
                            name="itemType"
                            type="radio"
                            checked={formFields.itemTypeId === 1}
                            value={1}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            disabled={false}
                            required
                        />
                        Product
                    </RadioLabel>
                    <RadioLabel className="mb-2">
                        <RadioInput
                            id="itemTypeService"
                            name="itemType"
                            type="radio"
                            checked={formFields.itemTypeId === 2}
                            value={2}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            disabled={false}
                            required
                        />
                        Service
                    </RadioLabel>
                </div>
                <TextAreaContainer>
                    <span>Sales Description</span>
                    <p className="m-0 text-sm text-twBlue1">
                        Client-facing description visible on estimates and
                        invoices
                    </p>
                    <TextArea
                        id="salesDescription"
                        name="salesDescription"
                        onChange={handlePrefillTextAreaChange}
                    />
                </TextAreaContainer>
                <TextAreaContainer>
                    <span>Ordering Description</span>
                    <p className="m-0 text-sm text-twBlue1">
                        Supplier-facing description visible on purchase orders
                    </p>
                    <TextArea
                        id="orderingDescription"
                        name="orderingDescription"
                        onChange={handlePrefillTextAreaChange}
                    />
                </TextAreaContainer>
                <TextAreaContainer>
                    <span>Internal Notes</span>
                    <p className="m-0 text-sm text-twBlue1">
                        Notes for you and your team only; not visible on any
                        forms
                    </p>
                    <TextArea
                        id="notes"
                        name="notes"
                        value={formFields.notes}
                        onChange={handleFieldChange}
                    />
                </TextAreaContainer>
            </div>

            <ItemAvatar dispatch={dispatch} formFields={formFields} />
        </div>
    );
};

export default Details;
