import { Omit } from "../../../../types/index";
import { ActionDescriptor } from "../../common";
import { DraperyOrderSummaryComponent } from "./interfaces";

export const ADD_DRAPERY_ORDER_SUMMARY_COMPONENT =
    "draperyOrderSummaryComponent/ADD";
export type AddDraperyOrderSummaryComponentAction = ActionDescriptor<
    typeof ADD_DRAPERY_ORDER_SUMMARY_COMPONENT,
    Omit<DraperyOrderSummaryComponent, "id"> & { accountId: number },
    DraperyOrderSummaryComponent
>;

export const EDIT_DRAPERY_ORDER_SUMMARY_COMPONENT =
    "draperyOrderSummaryComponent/EDIT";
export type EditDraperyOrderSummaryComponentAction = ActionDescriptor<
    typeof EDIT_DRAPERY_ORDER_SUMMARY_COMPONENT,
    DraperyOrderSummaryComponent,
    {}
>;

export const GET_DRAPERY_ORDER_SUMMARY_COMPONENT =
    "draperyOrderSummaryComponent/GET";
export type GetDraperyOrderSummaryComponentAction = ActionDescriptor<
    typeof GET_DRAPERY_ORDER_SUMMARY_COMPONENT,
    { id: number },
    DraperyOrderSummaryComponent
>;

export const DELETE_DRAPERY_ORDER_SUMMARY_COMPONENT =
    "draperyOrderSummaryComponent/DELETE";
export type DeleteDraperyOrderSummaryComponentAction = ActionDescriptor<
    typeof DELETE_DRAPERY_ORDER_SUMMARY_COMPONENT,
    { id: number },
    {}
>;

export const SEARCH_DRAPERY_ORDER_SUMMARY_COMPONENTS =
    "draperyOrderSummaryComponent/Search";
export type SearchDraperyOrderSummaryComponentsAction = ActionDescriptor<
    typeof SEARCH_DRAPERY_ORDER_SUMMARY_COMPONENTS,
    {
        draperyOrderSummaryId: number;
    },
    DraperyOrderSummaryComponent[]
>;

export interface DraperyOrderSummaryComponentActionsMap {
    [ADD_DRAPERY_ORDER_SUMMARY_COMPONENT]: AddDraperyOrderSummaryComponentAction;
    [EDIT_DRAPERY_ORDER_SUMMARY_COMPONENT]: EditDraperyOrderSummaryComponentAction;
    [GET_DRAPERY_ORDER_SUMMARY_COMPONENT]: GetDraperyOrderSummaryComponentAction;
    [DELETE_DRAPERY_ORDER_SUMMARY_COMPONENT]: DeleteDraperyOrderSummaryComponentAction;
    [SEARCH_DRAPERY_ORDER_SUMMARY_COMPONENTS]: SearchDraperyOrderSummaryComponentsAction;
}
