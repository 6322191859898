import * as ClientModule from "../../../redux/modules/client";

import {
    ClientBlock,
    ClientFormInput,
    ClientFormLabel,
    ClientFormSubtitle,
    ThreeItemsWrapper,
    TwoItemsWrapper,
} from "../../../containers/client/client_modal";
import { FaCheck, FaTimes } from "react-icons/fa";
import { FiPlusCircle } from "react-icons/fi";
import React, { ChangeEvent, Component } from "react";

import ClientHoverBox from "../../clients/client_hover_box";
import IconButton from "../buttons/icon-button";
import SelectList from "../select-list";
import { ValidationForm } from "@ramble/ramble-ui";
import { confirmation } from "../../../confirmation";
import { detect } from "detect-browser";
import { formatPhoneNumber } from "../../../utils/phone";
import styled from "../../../theme";

const additionalInfoDefault = {
    relation: "",
    firstName: "",
    lastName: "",
    streetAddress: "",
    unitSuite: "",
    cityTown: "",
    country: "",
    stateProvince: "",
    zipCode: "",
    cellPhone: "",
    homePhone: "",
    fax: "",
    company: "",
    officePhone: "",
    email: "",
    website: "",
};

const AddButton = styled(IconButton)`
    margin-left: auto;
    align-items: center;
`;

const SaveButton = styled(IconButton)`
    position: absolute;
    top: 20px;
    right: 50px;
`;

const CloseButton = styled(IconButton)`
    position: absolute;
    top: 20px;
    right: 20px;
`;

const AdditionalForm = styled(ValidationForm)`
    display: flex;
    flex-wrap: wrap;
    padding-left: 2rem;
    margin-top: 20px;
    flex-direction: row;
    position: unset;
`;

const AdditionalWrap = styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-top: 0 !important;
    align-items: flex-start;
    height: calc(100% - 11px);
    position: relative;
`;

const AdditionalInfoWrap = styled.div`
    width: 70%;
    overflow: auto;
    height: 100%;
`;

const AdditionalInfo = styled.div`
    width: 30%;
    height: 100%;
    flex-shrink: 0;
    padding: 2.5rem 1rem;
    box-shadow: 4px -4px 4px 1px #c1c2c3;
    display: flex;
    flex-direction: column;
`;

const AdditionalInfoList = styled.ul`
    padding: 0;
    margin: 15px 0 0 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const AdditionalInfoItem = styled.li`
    position: relative;
    display: flex;
    align-items: center;
`;

const AdditionalItemWrap = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 15px;

    .delete-contact {
        font-size: 1.3em;
        fill: #438cab;
        margin-left: 0.5em;
        transition: transform 0.1s ease-in-out;

        &:hover {
            cursor: pointer;
            transform: scale(1.1);
        }
    }
`;

const AdditionalInfoTitle = styled.span`
    color: #44546a;
    margin-right: 5px;
    font-weight: 100;
`;

const AdditionalContactsInfo = styled.div`
    flex-grow: 0;
    width: 70%;
    margin-bottom: 25px;

    > div:nth-child(2) {
        width: 80%;
    }
`;

export const DisableAutocompleteInput = styled.input`
    display: none;
`;

export interface AdditionalContatctsProps {
    contacts: ClientModule.Client["additionalContactDetails"];
    stateList: string[];
    isOfClient?: boolean;
    additionalFormOpen: boolean;
    isOpen?: boolean;
    updateParentState?(): void;
    onAdditionalContactsChanged(
        additionalContacts: ClientModule.Client["additionalContactDetails"]
    ): void;
    deleteContact(index: number): void;
    setAdditionalFormStatus(status: "open" | "closed"): void;
}

interface State {
    currentIndex: number;
    currentAdditionalInfo: ClientModule.ClientAddressDetails;
}

export default class AdditionalContacts extends Component<
    AdditionalContatctsProps,
    State
> {
    public state: State = {
        currentIndex: 0,
        currentAdditionalInfo: { ...additionalInfoDefault },
    };

    public render(): JSX.Element {
        return (
            <ClientBlock>
                <AdditionalWrap>
                    <AdditionalInfo>
                        <ClientFormSubtitle>
                            ADDITIONAL CONTACTS
                            {!this.props.additionalFormOpen && (
                                <AddButton
                                    uiSize={20}
                                    onClick={this.openAdditionalForm}
                                >
                                    <FiPlusCircle />
                                </AddButton>
                            )}
                        </ClientFormSubtitle>
                        <AdditionalInfoList>
                            {this.renderAdditionalRelationship()}
                        </AdditionalInfoList>
                    </AdditionalInfo>
                    <AdditionalInfoWrap>
                        {this.props.additionalFormOpen &&
                            this.renderAdditionalForm(
                                this.state.currentAdditionalInfo
                            )}
                    </AdditionalInfoWrap>
                </AdditionalWrap>
            </ClientBlock>
        );
    }

    public renderAdditionalRelationship(): JSX.Element[] {
        return this.props.contacts.map((item, i) => (
            <AdditionalItemWrap key={item.id}>
                <AdditionalInfoItem key={`${item.id}-li`}>
                    <AdditionalInfoTitle>{item.relation}:</AdditionalInfoTitle>
                    <ClientHoverBox
                        additionalContact={item}
                        type="contact"
                        onClick={() => this.editAdditionalInfo(item, i)}
                    />
                </AdditionalInfoItem>
                <FaTimes
                    className="delete-contact"
                    onClick={() => this.deleteContact(i)}
                />
            </AdditionalItemWrap>
        ));
    }

    private renderAdditionalContactsInformation(
        relationshipInfo: ClientModule.ClientAddressDetails
    ): JSX.Element {
        const browser = detect();
        const autoCompleteVal =
            browser && browser.name === "chrome" ? "new-password" : "off";
        return (
            <>
                <AdditionalContactsInfo>
                    <ClientFormSubtitle>
                        ADDITIONAL CONTACT’S INFORMATION
                    </ClientFormSubtitle>
                    <div className="mb-2">
                        <ClientFormLabel htmlFor="additionalContactRelationshipName">
                            {this.props.isOfClient
                                ? "Relationship to the Client"
                                : "Relationship to the Supplier"}
                            <span className="red-asterisk">*</span>
                        </ClientFormLabel>
                        <ClientFormInput
                            id="additionalContactRelationshipName"
                            name="relation"
                            className="label"
                            type="text"
                            value={relationshipInfo.relation}
                            onChange={this.handleInputChange}
                            uiReportOnBlur={false}
                            required
                            onBlur={this.handleInputBlur}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                    <TwoItemsWrapper>
                        <div>
                            <ClientFormLabel htmlFor="additionalContactFirstName">
                                First Name
                                <span className="red-asterisk">*</span>
                            </ClientFormLabel>
                            <ClientFormInput
                                id="additionalContactFirstName"
                                name="firstName"
                                className="label"
                                type="text"
                                value={relationshipInfo.firstName || ""}
                                onChange={this.handleInputChange}
                                uiReportOnBlur={false}
                                required
                                onBlur={this.handleInputBlur}
                                autoComplete={
                                    browser && browser.name === "chrome"
                                        ? "new-password"
                                        : "off"
                                }
                                list="autocompleteOff"
                            />
                        </div>
                        <div>
                            <ClientFormLabel htmlFor="additionalContactLastName">
                                Last Name
                            </ClientFormLabel>
                            <ClientFormInput
                                id="additionalContactLastName"
                                name="lastName"
                                className="label"
                                type="text"
                                value={relationshipInfo.lastName || ""}
                                onChange={this.handleInputChange}
                                uiReportOnBlur={false}
                                onBlur={this.handleInputBlur}
                                autoComplete={
                                    browser && browser.name === "chrome"
                                        ? "new-password"
                                        : "off"
                                }
                                list="autocompleteOff"
                            />
                        </div>
                    </TwoItemsWrapper>
                    <div className="mb-2">
                        <ClientFormLabel htmlFor="additionalContactCompany">
                            Company Name
                        </ClientFormLabel>
                        <ClientFormInput
                            id="additionalContactCompany"
                            name="company"
                            className="label"
                            type="text"
                            value={relationshipInfo.company || ""}
                            onChange={this.handleInputChange}
                            uiReportOnBlur={false}
                            onBlur={this.handleInputBlur}
                            autoComplete={autoCompleteVal}
                            list="autocompleteOff"
                        />
                    </div>
                    <div className="mb-2">
                        <ClientFormLabel htmlFor="additionalContactEmail">
                            Email
                        </ClientFormLabel>
                        <ClientFormInput
                            id="additionalContactEmail"
                            name="email"
                            className="label"
                            type="email"
                            value={relationshipInfo.email || ""}
                            onChange={this.handleInputChange}
                            uiReportOnBlur={false}
                            onBlur={this.handleInputBlur}
                            autoComplete={autoCompleteVal}
                            list="autocompleteOff"
                        />
                    </div>
                    <TwoItemsWrapper>
                        <div>
                            <ClientFormLabel htmlFor="additionalContactCellPhone">
                                Cell Phone
                            </ClientFormLabel>
                            <ClientFormInput
                                id="additionalContactCellPhone"
                                name="cellPhone"
                                className="label"
                                type="text"
                                value={relationshipInfo.cellPhone || ""}
                                onChange={this.handleInputChange}
                                uiReportOnBlur={false}
                                onBlur={this.handleInputBlur}
                                autoComplete={
                                    browser && browser.name === "chrome"
                                        ? "new-password"
                                        : "off"
                                }
                                list="autocompleteOff"
                            />
                        </div>
                        <div>
                            <ClientFormLabel htmlFor="additionalContactHomePhone">
                                {this.props.isOfClient
                                    ? "Home Phone"
                                    : "Company Phone"}
                            </ClientFormLabel>
                            <ClientFormInput
                                id="additionalContactHomePhone"
                                name="homePhone"
                                className="label"
                                type="text"
                                value={relationshipInfo.homePhone || ""}
                                onChange={this.handleInputChange}
                                uiReportOnBlur={false}
                                onBlur={this.handleInputBlur}
                                autoComplete={
                                    browser && browser.name === "chrome"
                                        ? "new-password"
                                        : "off"
                                }
                                list="autocompleteOff"
                            />
                        </div>
                    </TwoItemsWrapper>
                </AdditionalContactsInfo>
            </>
        );
    }

    private renderAdditionalContactsAddress(
        relationshipInfo: ClientModule.ClientAddressDetails
    ): JSX.Element {
        const browser = detect();
        const { stateList } = this.props;

        return (
            <AdditionalContactsInfo>
                <ClientFormSubtitle>Address</ClientFormSubtitle>
                <div className="mb-2">
                    <ClientFormLabel htmlFor="additionalContactBillingStreetAddress">
                        Street Address
                    </ClientFormLabel>
                    <ClientFormInput
                        id="additionalContactBillingStreetAddress"
                        name="streetAddress"
                        className="label"
                        type="text"
                        value={relationshipInfo.streetAddress || ""}
                        onChange={this.handleInputChange}
                        uiReportOnBlur={false}
                        onBlur={this.handleInputBlur}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
                <div className="mb-2">
                    <ClientFormLabel htmlFor="additionalContactBillingUnitSuite">
                        Suite / Unit
                    </ClientFormLabel>
                    <ClientFormInput
                        id="additionalContactBillingUnitSuite"
                        name="unitSuite"
                        className="label"
                        type="text"
                        value={relationshipInfo.unitSuite || ""}
                        onChange={this.handleInputChange}
                        uiReportOnBlur={false}
                        onBlur={this.handleInputBlur}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
                <ThreeItemsWrapper>
                    <div>
                        <ClientFormLabel htmlFor="additionalContactBillingCityTown">
                            City
                        </ClientFormLabel>
                        <ClientFormInput
                            id="additionalContactBillingCityTown"
                            name="cityTown"
                            className="label"
                            type="text"
                            value={relationshipInfo.cityTown || ""}
                            onChange={this.handleInputChange}
                            uiReportOnBlur={false}
                            onBlur={this.handleInputBlur}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                    <div>
                        <SelectList
                            label="State"
                            selectList={stateList}
                            handleClickSelect={this.handleSelectChange}
                            valueSelect={relationshipInfo.stateProvince}
                            flexDirection="column"
                            name="stateProvince"
                            smallPadding
                            customValue
                            noAddButton
                            noClearBox
                            uiDropArrow
                        />
                    </div>
                    <div>
                        <ClientFormLabel htmlFor="additionalContactBillingZIPCode">
                            Zip
                        </ClientFormLabel>
                        <ClientFormInput
                            id="additionalContactBillingZIPCode"
                            name="zipCode"
                            className="label"
                            type="number"
                            value={relationshipInfo.zipCode || ""}
                            onChange={this.handleInputChange}
                            uiReportOnBlur={false}
                            onBlur={this.handleInputBlur}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                </ThreeItemsWrapper>
                <div>
                    <ClientFormLabel htmlFor="additionalContactBillingCountry">
                        Country
                    </ClientFormLabel>
                    <ClientFormInput
                        id="additionalContactBillingCountry"
                        name="country"
                        className="label"
                        type="text"
                        value={relationshipInfo.country || ""}
                        onChange={this.handleInputChange}
                        uiReportOnBlur={false}
                        onBlur={this.handleInputBlur}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
            </AdditionalContactsInfo>
        );
    }

    public renderAdditionalForm(
        relationshipInfo: ClientModule.ClientAddressDetails
    ): JSX.Element {
        return (
            <AdditionalForm
                uiPadding={false}
                uiUseDimmer={false}
                uiReportValidityMode="firstInvalid"
                uiOnSubmit={this.submitForm}
                uiOnInvalidElem={this.handleInvalidElem}
                id="additionalFormId"
            >
                {" "}
                <SaveButton type="submit" uiSize={20}>
                    <FaCheck />
                </SaveButton>
                <CloseButton type="button" uiSize={20} onClick={this.resetForm}>
                    <FaTimes />
                </CloseButton>
                <DisableAutocompleteInput name="firstName" />
                {this.renderAdditionalContactsInformation(relationshipInfo)}
                {this.renderAdditionalContactsAddress(relationshipInfo)}
            </AdditionalForm>
        );
    }

    private editAdditionalInfo(
        relationshipInfo: ClientModule.ClientAddressDetails,
        index: number
    ) {
        this.setState({
            currentIndex: index,
            currentAdditionalInfo: { ...relationshipInfo },
        });
        this.props.setAdditionalFormStatus("open");
    }

    private resetForm = (): void => {
        if (this.props.updateParentState) {
            this.props.updateParentState();
        }
        this.setState({
            currentAdditionalInfo: { ...additionalInfoDefault },
            currentIndex: 0,
        });
        this.props.setAdditionalFormStatus("closed");
    };

    private submitForm = async () => {
        const { onAdditionalContactsChanged, contacts } = this.props;
        const { currentAdditionalInfo, currentIndex } = this.state;

        if (this.props.updateParentState) {
            this.props.updateParentState();
        }
        this.setState(
            { currentAdditionalInfo: { ...additionalInfoDefault } },
            () => {
                onAdditionalContactsChanged([
                    ...contacts.slice(0, currentIndex),
                    currentAdditionalInfo,
                    ...contacts.slice(currentIndex + 1),
                ]);
            }
        );
    };

    private handleInvalidElem = (e: HTMLElement): void => {
        e.scrollIntoView({ behavior: "smooth", block: "center" });
    };

    private openAdditionalForm = (e: React.MouseEvent) => {
        e.preventDefault();

        this.setState({
            currentIndex: this.props.contacts.length,
            currentAdditionalInfo: { ...additionalInfoDefault },
        });
        this.props.setAdditionalFormStatus("open");
    };

    private handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name } = e.currentTarget;
        let { value } = e.currentTarget;

        if (name.endsWith("Phone")) {
            value = formatPhoneNumber(value);
        }

        this.setState({
            currentAdditionalInfo: {
                ...this.state.currentAdditionalInfo,
                [name]: value,
            },
        });
    };

    private handleSelectChange = (value: string, name: string) => {
        this.setState({
            currentAdditionalInfo: {
                ...this.state.currentAdditionalInfo,
                [name]: value,
            },
        });
    };

    private deleteContact = async (index: number) => {
        const allowed = await confirmation(
            <div>Are you sure you want to delete this Additional Contact?</div>,
            {
                uiHeader: "Delete",
                uiAcceptLabel: "Yes",
                uiDeclineLabel: "No",
            }
        );
        if (allowed) {
            if (this.state.currentIndex === index) {
                this.props.setAdditionalFormStatus("closed");
            }
            this.props.deleteContact(index);
        }
    };

    // A temporary solution is likely to require validation on the server side

    private handleInputBlur = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.currentTarget;

        this.setState({
            currentAdditionalInfo: {
                ...this.state.currentAdditionalInfo,
                [name]: value.trim(),
            },
        });
    };
}
