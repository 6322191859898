import React, { FC } from "react";
import SearchSelect, { SearchSelectProps } from "../Input/search_input";

import styled from "../../../theme";

export interface HeaderProps {
    headingText: string;
    onSearch: SearchSelectProps["onSearch"];
    onKeywordCleared: SearchSelectProps["onKeywordCleared"];
}

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    // margin-top: -15px;
`;

const Heading = styled.h1`
    font-family: "Playfair Display", serif;
    font-size: 24px;
    letter-spacing: 1.06px;
    margin-right: 15px;
    color: #306f82;
`;

const Header: FC<HeaderProps> = (props) => (
    <HeaderContainer>
        <Heading>{props.headingText}</Heading>

        <SearchSelect
            onSearch={props.onSearch}
            onKeywordCleared={props.onKeywordCleared}
            placeholder="Search..."
        />
    </HeaderContainer>
);

export default Header;
