import React, { forwardRef, HTMLAttributes } from "react";
import { createAnimatedIcon } from "../icons/animated_icon";
import styled from "../theme/styled";
import { cleanUiProps } from "../utils/withStyledProps";
import LoaderIcon from "./loader_icon";

export interface LoaderUIProps {
    /**
     * Loader size(font-size) in px/em/rem/etc...
     * @default 2rem
     */
    uiSize?: string;
}

export type LoaderProps = HTMLAttributes<HTMLDivElement> & LoaderUIProps;

const SpinIcon = createAnimatedIcon(LoaderIcon);

const LoaderComponent = forwardRef<HTMLDivElement, LoaderProps>((props, ref) => {
    const { children, uiSize, ...other } = props;
    cleanUiProps(other);
    return (
        <div {...other} ref={ref}>
            <SpinIcon className="loader-icon" />
            {children}
        </div>
    );
});

/**
 * Simple loader
 */
const Loader = styled(LoaderComponent)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: ${({ uiSize }) => uiSize!};
`;

Loader.defaultProps = {
    uiSize: "2rem",
};

export default Loader;
