import { Loader, LoaderText, PageDimmer } from "@ramble/ramble-ui";
import React, { memo } from "react";
import styled from "../../theme";

const ApplicationLoaderText = styled(LoaderText) `
    font-size: 0.4em;
    margin-top: 0.5em;
`;

/**
 * Application loader props
 */
interface ApplicationLoaderProps {
    /**
     * Message to display in loader
     */
    message: string;
}

/**
 * Application loader displays loading spinner
 */
const ApplicationLoaderE: React.SFC<ApplicationLoaderProps> = props => {
    const { message } = props;
    return (
        <PageDimmer uiActive>
            <Loader uiSize="4em">
                <ApplicationLoaderText>{message}</ApplicationLoaderText>
            </Loader>
        </PageDimmer>
    );
};

export const ApplicationLoader = memo(ApplicationLoaderE);
