import Bind from "lodash-decorators/bind";
import React, { createRef, PureComponent, RefObject } from "react";
import TimePicker from "react-time-picker";
import styled from "../theme/styled";
import { forwardRef, WithForwardedRef } from "../utils/ref";
import Input, { InputProps } from "./input";

const StyledContainer = styled(Input)`
    * {
        outline: none;
    }

    .react-time-picker__inputGroup {
        padding-left: 0;

        > :first-child {
            visibility: visible !important;
            position: absolute !important;
            top: 0 !important;
            left: 0 !important;
            bottom: 0 !important;
            right: 0 !important;
            padding: 0 !important;
            z-index: -1 !important;
            width: 0 !important;
        }
    }

    .react-time-picker__inputGroup__input:invalid {
        background: initial;
    }

    .react-time-picker__wrapper {
        border: none;
        padding: 0;
        margin: 0;
    }

    .react-time-picker__clear-button {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
    }
`;

interface State {
    currentValue?: string;
}

const noop = () => { /* ignore */ };

class NonNativeTimeInputComponent extends PureComponent<InputProps, State> {
    private inputElem?: HTMLInputElement;
    private containerRef: RefObject<HTMLElement> = createRef();

    public constructor(props: InputProps) {
        super(props);
        this.state = {
            currentValue: props.value != null ? props.value as string : props.defaultValue as string || "",
        };
    }

    public componentDidMount(): void {
        if (!this.containerRef.current) {
            return;
        }
        const { forwardedRef } = this.props as WithForwardedRef<InputProps>;
        const inputGroup = this.containerRef.current.getElementsByClassName("react-time-picker__inputGroup")[0];
        if (inputGroup && inputGroup.children[0]) {
            this.inputElem = inputGroup.children[0] as HTMLInputElement;
            this.inputElem.oninvalid = this.handleInvalid;
            if (forwardedRef) {
                if (typeof forwardedRef === "function") {
                    forwardedRef(this.inputElem);
                } else if (typeof forwardedRef === "object") {
                    (forwardedRef as any).current = this.inputElem;
                }
            }
        }
    }

    public componentWillUnmount(): void {
        const { forwardedRef } = this.props as WithForwardedRef<InputProps>;
        if (forwardedRef) {
            if (typeof forwardedRef === "function") {
                forwardedRef(null);
            } else if (typeof forwardedRef === "object") {
                (forwardedRef as any).current = null;
            }
        }
    }

    public render(): JSX.Element {
        const { uiDisabled, uiError, id, name, className, min, max, required, value } = this.props;
        return (
            <StyledContainer
                id={id}
                as="div"
                className={className}
                uiDisabled={uiDisabled}
                uiError={uiError}
                ref={this.containerRef as any}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
            >
                <TimePicker
                    disableClock
                    value={value != null ? value as string : this.state.currentValue}
                    onChange={this.handleChange}
                    name={name}
                    required={required}
                    minTime={min as string}
                    maxTime={max as string}
                    maxDetail="minute"
                    clockIcon={null}
                />
            </StyledContainer>
        );
    }

    /**
     * Handle invalid event on hidden input with date value
     * @param e Native event
     */
    @Bind()
    private handleInvalid(e: Event): void {
        if (!this.inputElem) {
            return;
        }
        const { onInvalid } = this.props;
        if (onInvalid) {
            // create react-compatible event
            onInvalid({
                bubbles: e.bubbles,
                cancelable: e.cancelable,
                currentTarget: this.inputElem,
                defaultPrevented: e.defaultPrevented,
                eventPhase: e.eventPhase,
                isTrusted: e.isTrusted,
                nativeEvent: e,
                target: this.inputElem,
                timeStamp: e.timeStamp,
                type: e.type,
                preventDefault: e.preventDefault,
                persist: () => { /* ignore */},
                stopPropagation: e.stopImmediatePropagation,
                isDefaultPrevented: () => e.defaultPrevented,
                isPropagationStopped: () => e.bubbles,
            });
        }
    }

    @Bind()
    private handleFocus(e: React.FocusEvent<HTMLDivElement>): void {
        if (!this.inputElem) {
            return;
        }
        const { onFocus } = this.props;
        if (onFocus) {
            onFocus({
                ...e,
                // persist not copied
                persist: noop,
                target: this.inputElem,
                currentTarget: this.inputElem,
            });
        }
    }

    @Bind()
    private handleBlur(e: React.FocusEvent<HTMLDivElement>): void {
        if (!this.inputElem) {
            return;
        }
        const { onBlur } = this.props;
        if (onBlur) {
            onBlur({
                ...e,
                // persist not copied
                persist: noop,
                target: this.inputElem,
                currentTarget: this.inputElem,
            });
        }
    }

    /**
     * Handle input change
     * @param newVal New value
     */
    @Bind()
    private handleChange(newVal: string): void {
        const { value, onChange } = this.props;
        // nonctrolled input
        if (value == null) {
            this.setState({ currentValue: newVal });
        }
        if (onChange && this.inputElem) {
            onChange({
                bubbles: false,
                cancelable: false,
                currentTarget: this.inputElem,
                defaultPrevented: true,
                eventPhase: 3,
                type: "change",
                timeStamp: new Date().getTime(),
                target: this.inputElem,
                isTrusted: true,
                nativeEvent: null as any,
                persist: noop,
                isDefaultPrevented: () => true,
                isPropagationStopped: () => true,
                preventDefault: noop,
                stopPropagation: noop,
            });
        }
    }
}

export default forwardRef<HTMLInputElement, InputProps>(NonNativeTimeInputComponent);
