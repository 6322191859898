import { Api, Req } from "@quipa/api";

export const addDraperyOrder = (draperyOrdering: Api.AddDraperyOrderAction[Req]) => Api.createAction(Api.ADD_DRAPERY_ORDER, draperyOrdering, { authenticated: true });
export const addDraperyOrderItem = (draperyItemOrdering: Api.AddDraperyOrderItemAction[Req]) => Api.createAction(Api.ADD_DRAPERY_ORDER_ITEM, draperyItemOrdering, { authenticated: true });
export const editDraperyOrder = (draperyOrdering: Api.EditDraperyOrderAction[Req]) => Api.createAction(Api.EDIT_DRAPERY_ORDER, draperyOrdering, { authenticated: true });
export const getDraperyOrder = (id: number) => Api.createAction(Api.GET_DRAPERY_ORDER, { id }, { authenticated: true });
export const getDraperyOrderAllInfo = (id: number) => Api.createAction(Api.GET_DRAPERY_ORDER_ALL_INFO, { id }, { authenticated: true });
export const deleteDraperyOrder = (id: number) => Api.createAction(Api.DELETE_DRAPERY_ORDER, { id }, { authenticated: true });

export const addDraperyOrderDesign = (draperyDesign: Api.AddDraperyOrderDesignAction[Req]) => Api.createAction(Api.ADD_DRAPERY_ORDER_DESIGN, draperyDesign, { authenticated: true });
export const editDraperyOrderDesign = (draperyDesign: Api.EditDraperyOrderDesignAction[Req]) => Api.createAction(Api.EDIT_DRAPERY_ORDER_DESIGN, draperyDesign, { authenticated: true });
export const getDraperyOrderDesign = (id: number) => Api.createAction(Api.GET_DRAPERY_ORDER_DESIGN, { id }, { authenticated: true });
export const deleteDraperyOrderDesign = (id: number) => Api.createAction(Api.DELETE_DRAPERY_ORDER_DESIGN, { id }, { authenticated: true });

export const addDraperyOrderDesingEmbellishment = (draperyEmbellishment: Api.AddDraperyOrderDesignEmbellishmentAction[Req]) =>
    Api.createAction(Api.ADD_DRAPERY_ORDER_DESIGN_EMBELLISHMENT, draperyEmbellishment, { authenticated: true });
export const editDraperyOrderDesignEmbellishment = (draperyEmbellishment: Api.EditDraperyOrderDesignEmbellishmentAction[Req]) =>
    Api.createAction(Api.EDIT_DRAPERY_ORDER_DESIGN_EMBELLISHMENT, draperyEmbellishment, { authenticated: true });
export const getDraperyOrderDesignEmbellishment = (id: number) => Api.createAction(Api.GET_DRAPERY_ORDER_DESIGN_EMBELLISHMENT, { id }, { authenticated: true });
export const deleteDraperyOrderDesignEmbellishment = (id: number) => Api.createAction(Api.DELETE_DRAPERY_ORDER_DESIGN_EMBELLISHMENT, { id }, { authenticated: true });
export const searchDraperyOrderDesignEmbellishments = (draperyOrderDesignId: number) => Api.createAction(Api.SEARCH_DRAPERY_ORDER_DESIGN_EMBELLISHMENTS, { draperyOrderDesignId }, { authenticated: true });

export const addDraperyOrderDesignLining = (draperyLining: Api.AddDraperyOrderDesignLiningAction[Req]) => Api.createAction(Api.ADD_DRAPERY_ORDER_DESIGN_LINING, draperyLining, { authenticated: true });
export const editDraperyOrderDesignLining = (draperyLining: Api.EditDraperyOrderDesignLiningAction[Req]) => Api.createAction(Api.EDIT_DRAPERY_ORDER_DESIGN_LINING, draperyLining, { authenticated: true });
export const getDraperyOrderDesignLining = (id: number) => Api.createAction(Api.GET_DRAPERY_ORDER_DESIGN_LINING, { id }, { authenticated: true });
export const deleteDraperyOrderDesignLining = (id: number) => Api.createAction(Api.DELETE_DRAPERY_ORDER_DESIGN_LINING, { id }, { authenticated: true });
export const searchDraperyOrderDesignLinings = (draperyOrderDesignId: number) => Api.createAction(Api.SEARCH_DRAPERY_ORDER_DESIGN_LININGS, { draperyOrderDesignId }, { authenticated: true });

export const addDraperyOrderFabric = (draperyFabric: Api.AddDraperyOrderFabricAction[Req]) => Api.createAction(Api.ADD_DRAPERY_ORDER_FABRIC, draperyFabric, { authenticated: true });
export const editDraperyOrderFabric = (draperyFabric: Api.EditDraperyOrderFabricAction[Req]) => Api.createAction(Api.EDIT_DRAPERY_ORDER_FABRIC, draperyFabric, { authenticated: true });
export const getDraperyOrderFabric = (id: number) => Api.createAction(Api.GET_DRAPERY_ORDER_FABRIC, { id }, { authenticated: true });
export const deleteDraperyOrderFabric = (id: number) => Api.createAction(Api.DELETE_DRAPERY_ORDER_FABRIC, { id }, { authenticated: true });

export const addDraperyOrderSummary = (draperySummary: Api.AddDraperyOrderSummaryAction[Req]) => Api.createAction(Api.ADD_DRAPERY_ORDER_SUMMARY, draperySummary, { authenticated: true });
export const editDraperyOrderSummary = (draperySummary: Api.EditDraperyOrderSummaryAction[Req]) => Api.createAction(Api.EDIT_DRAPERY_ORDER_SUMMARY, draperySummary, { authenticated: true });
export const getDraperyOrderSummary = (id: number) => Api.createAction(Api.GET_DRAPERY_ORDER_SUMMARY, { id }, { authenticated: true });
export const deleteDraperyOrderSummary = (id: number) => Api.createAction(Api.DELETE_DRAPERY_ORDER_SUMMARY, { id }, { authenticated: true });

export const addDraperyOrderSummaryComponent = (draperySummaryComp: Api.AddDraperyOrderSummaryComponentAction[Req]) => Api.createAction(Api.ADD_DRAPERY_ORDER_SUMMARY_COMPONENT, draperySummaryComp, { authenticated: true });
export const editDraperyOrderSummaryComponent = (draperySummaryComp: Api.EditDraperyOrderSummaryComponentAction[Req]) => Api.createAction(Api.EDIT_DRAPERY_ORDER_SUMMARY_COMPONENT, draperySummaryComp, { authenticated: true });
export const getDraperyOrderSummaryComponent = (id: number) => Api.createAction(Api.GET_DRAPERY_ORDER_SUMMARY_COMPONENT, { id }, { authenticated: true });
export const deleteDraperyOrderSummaryComponent = (id: number) => Api.createAction(Api.DELETE_DRAPERY_ORDER_SUMMARY_COMPONENT, { id }, { authenticated: true });
export const searchDraperyOrderSummaryComponents = (draperyOrderSummaryId: number) => Api.createAction(Api.SEARCH_DRAPERY_ORDER_SUMMARY_COMPONENTS, { draperyOrderSummaryId }, { authenticated: true });