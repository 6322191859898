import { Api } from "@quipa/api";

export const logError = (error: Error) => {
    const { message, stack } = error;
    const logLevelError: Api.UILogLevel = Api.UILogLevel.ERROR;

    return Api.createAction(
        Api.UI_LOGGER,
        { message, stack, level: logLevelError },
        {}
    );
};
