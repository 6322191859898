// tslint:disable:max-classes-per-file

export interface SerializedError {
    /**
     * Error name
     */
    name: string;
    /**
     * Error code
     */
    code: number;
    /**
     * Error message
     */
    message: string;
    /**
     * Stack trace (when stack reporting is enabled)
     */
    stack?: string;
}

export class SerializableError extends Error {
    /**
     * Error code. Usually HTTP status code
     */
    public readonly errorCode: number;

    public constructor(message?: string, code: number = 500, stack?: string) {
        super(message);
        this.name = "SerializableError";
        this.errorCode = code;
        if (stack) {
            this.stack = stack;
        }
    }

    /**
     * Serialize error
     * @returns Serialized object
     */
    public serialize(stack: boolean = false): SerializedError {
        return {
            name: this.name,
            code: this.errorCode,
            message: this.message,
            stack: stack ? this.stack : undefined,
        };
    }
}

/**
 * User is not authenticated error
 */
export class UnauthenticatedError extends SerializableError {
    public constructor(stack?: string) {
        super("Authentication is required to perform this action", 401, stack);
        this.name = "UnauthenticatedError";
    }
}

/**
 * Authentication failed error
 */
export class AuthenticationFailedError extends SerializableError {
    public constructor(stack?: string) {
        super("Authentication failed", 401, stack);
        this.name = "AuthenticationFailedError";
    }
}
export class AuthenticationEmailFailedError extends SerializableError {
    public constructor(stack?: string) {
        super("Email not recognized", 401, stack);
        this.name = "AuthenticationFailedError";
    }
}

export class AuthenticationPasswordFailedError extends SerializableError {
    public constructor(stack?: string) {
        super("Password incorrect", 401, stack);
        this.name = "AuthenticationFailedError";
    }
}

/**
 * Authorization failed error
 */
export class AuthorizationFailedError extends SerializableError {
    public constructor(stack?: string) {
        super("You don't have the rights to perform this action", 403, stack);
        this.name = "AuthorizationFailedError";
    }
}

export interface BadRequestedSerializedError extends SerializedError {
    /**
     * Error type
     */
    errorType: string;
}

/**
 * Generic bad request error
 */
export class BadRequestError extends SerializableError {
    /**
     * Error type constant
     */
    public readonly errorType: string;

    /**
     * @constructor
     * @param message
     * @param errorType
     */
    public constructor(
        message: string = "Bad request",
        errorType: string = "generic",
        stack?: string
    ) {
        super(message, 400, stack);
        this.name = "BadRequestError";
        this.errorType = errorType;
    }

    public serialize(): BadRequestedSerializedError {
        return {
            ...super.serialize(),
            errorType: this.errorType,
        };
    }
}

/**
 * Any other errors indicating error in server code
 */
export class ServerError extends SerializableError {
    public constructor(message?: string, stack?: string) {
        message = message || "Something went wrong";
        super(`Server processing error: ${message}`, 500, stack);
        this.name = "ServerError";
        this.stack = stack;
    }
}

/**
 * Api limited error
 */
export class ApiLimitedError extends SerializableError {
    public constructor(stack?: string) {
        super("Operation is limited", 429, stack);
        this.name = "ApiLimitedError";
    }
}

/**
 * Simple error string map
 */
export interface ValidationErrorMap {
    [key: string]: string;
}

export interface ValidationSerializedError extends SerializedError {
    /**
     * Error messages
     */
    errors: ValidationErrorMap;
    /**
     * Error types
     */
    errorTypes: ValidationErrorMap;
}

/**
 * Validation error
 */
export class ValidationError extends SerializableError {
    /**
     * Create new ValidationError instance for some failed unique items check
     * @param keyOrKeys Single key string or key map to embed into error
     *  When given single string will uppercase first letter of the key for key name in the error message
     *  When given key map will look to key value as key name in the error message
     * @returns Validation error instance
     */
    public static makeUniqueAvailabilityError(
        keyOrKeys: string | { [key: string]: string }
    ): ValidationError {
        const errors: ValidationErrorMap = {};
        const errorTypes: ValidationErrorMap = {};

        // If simple string was given then make a simple object with uppercased first letter as readable name
        keyOrKeys =
            typeof keyOrKeys === "string"
                ? {
                      [keyOrKeys]:
                          keyOrKeys.charAt(0).toUpperCase() +
                          keyOrKeys.slice(1),
                  }
                : keyOrKeys;

        for (const key of Object.keys(keyOrKeys)) {
            errors[key] = `${keyOrKeys[key]} already in use`;
            errorTypes[key] = "any.unique";
        }
        return new ValidationError(errors, errorTypes);
    }

    /**
     * Error mapping. Key is the failed validation path
     * and value is the error message
     */
    public readonly errors: ValidationErrorMap;

    /**
     * Error types mapping. Key is the failed validation path
     * and value is the validation error type (for ex. number.min)
     */
    public readonly errorTypes: ValidationErrorMap;

    /**
     * @constructor
     * @param errors
     * @param errorTypes
     */
    public constructor(
        errors: ValidationErrorMap,
        errorTypes: ValidationErrorMap,
        stack?: string
    ) {
        super("Validation failed", 422, stack);
        this.errors = errors;
        this.name = "ValidationError";
        this.errorTypes = errorTypes;
        this.message =
            "Validation failed:\n" +
            Object.keys(this.errors).reduce(
                (str, key) => (str += `\t${key}:  ${this.errors[key]}\n`),
                ""
            );
    }

    public serialize(): ValidationSerializedError {
        return {
            ...super.serialize(),
            errors: this.errors,
            errorTypes: this.errorTypes,
        };
    }
}
