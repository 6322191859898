import { ActionDescriptor } from "../../common";
import { Promotion, PromotionValidationResult } from "./interfaces";

export const ADD_PROMOTION = "promotion/ADD";
export type AddPromotionAction = ActionDescriptor<typeof ADD_PROMOTION, Promotion, Promotion>;

export const EDIT_PROMOTION = "promotion/EDIT";
export type EditPromotionAction = ActionDescriptor<typeof EDIT_PROMOTION, Promotion, {}>;

export const GET_PROMOTION = "promotion/GET";
export type GetPromotionAction = ActionDescriptor<typeof GET_PROMOTION, { code: string }, Promotion>;

export const GET_PROMOTIONS = "promotion/GET_ALL";
export type GetPromotionsAction = ActionDescriptor<typeof GET_PROMOTIONS, {
    idSort?: "asc" | "desc";
}, Promotion[]>;

export const VALIDATE_PROMOTION = "promotion/VALIDATE";
export type ValidatePromotionAction = ActionDescriptor<typeof VALIDATE_PROMOTION, { code: string } & { accountId: number }, PromotionValidationResult>;

export interface PromotionActionsMap {
    [ADD_PROMOTION]: AddPromotionAction;
    [EDIT_PROMOTION]: EditPromotionAction;
    [GET_PROMOTION]: GetPromotionAction;
    [GET_PROMOTIONS]: GetPromotionsAction;
    [VALIDATE_PROMOTION]: ValidatePromotionAction;
}
