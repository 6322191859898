import { ClassAttributes, HTMLAttributes } from "react";
import styled, { css } from "../theme/styled";

export interface GridTableUIProps {
    /**
     * Bordered table
     * @default true
     */
    uiBorder?: boolean;
}

export type GridTableProps = HTMLAttributes<HTMLDivElement> & GridTableUIProps;

const GridTable = styled.div<GridTableProps & ClassAttributes<HTMLDivElement>>`
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    ${({ uiBorder, theme }) => uiBorder && css`
        border: 1px solid #e2e2e2 !important;
        border-radius: 0.28rem;
    `};
`;
GridTable.defaultProps = {
    uiBorder: true,
};

/** @component */
export default GridTable;
