import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {AccountModule, RootState, UserModule} from "../../redux";
import styled from "../../theme";
import { AuthHeader } from "../auth/auth_header";
import { Logo } from "../auth/logo";
import { ActionLink } from "../navigation/route_link";
import { TermsModal } from "../../modals/terms_modal";
import { createCheckout } from "../../api/stripe";
import { createCrispProfile } from "../../api/crisp";
import {getEmailVerificationList} from "../../api/email-verification-list";
import { sendVerifyEmail } from "../../api/signup";
// interface Props {
//     clientSecret: string;
// }

const LogoutLink = styled(ActionLink)`
    font-size: 0.8em;
    margin-left: 50px;
`;

const PlanTermsWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: center;
    font-size: 12px;

    > div:last-child {
        margin-left: 8px;
        cursor: pointer;
        color: #3b97b1;
    }
`;

export const ProceedToCheckout = () => {
    const dispatch = useDispatch();
    const accountId = useSelector((state: RootState) => state.account.id);
    const email = useSelector((state: RootState) => state.user.email);
    // const userName = useSelector((state: RootState) => state);
    // const [couponId, setCouponId] = useState("");
    const [isModalTermsOpen, setIsModalTermsOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>("");
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const logout = () => {
        dispatch(UserModule.logout());
        window.location.replace("/login");
    };

    const openTermsModal = () => {
        setIsModalTermsOpen(true);
    };

    const closeTermsModal = () => {
        setIsModalTermsOpen(false);
    };

    const sendVerificationEmail = async (email: string) => {
        try {
            const res = await sendVerifyEmail(email);
            if(!res.isVerified) {
                setErrorMessage("");
                setSuccessMessage("A verification link has been sent to your email. Please open the email and click the link to proceed.")
            }
        } catch (error) {
            console.log("error send verify email", error);
        }

    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setSuccessMessage("");
        await createCrispProfile(accountId);
        try {
            let quipaStore: any = localStorage.getItem("quipa-store");
            if(quipaStore) quipaStore = JSON.parse(quipaStore);
            const email = quipaStore.user.email;
            const emailVerification = await getEmailVerificationList(email);
            const account = await dispatch(AccountModule.getAccount(accountId));

            if(emailVerification && emailVerification.isVerified && !account.error) {
                const data = await createCheckout(accountId, account.payload.type === 'subscribed');
                setErrorMessage("");
                window.location.href = data.url;
            } else {
                setErrorMessage("Please verify your email");
            }


            // If you need to perform additional actions after setting clientSecret, you can uncomment the following block
            // if(data) {
            //     setFetched(true);
            //     setOptions({clientSecret: data.client_secret})
            // }
    
        } catch (err) {
            console.log("err: ", err);
        }
    };

    return (
        <>
            <Logo />
            <AuthHeader>
                Your membership is not active.
                <LogoutLink onClick={logout}>Logout</LogoutLink>
            </AuthHeader>

            <button onClick={handleSubmit} style={{ display: 'inline-block', minHeight: '1em', marginTop: '50px', color: 'rgb(255, 255, 255)',
                background:'rgb(59, 151, 177)',
                boxShadow: 'rgba(59, 151, 177, 0.2) 0px 1px 5px 0px, rgba(59, 151, 177, 0.14) 0px 2px 2px 0px, rgba(59, 151, 177, 0.12) 0px 3px 1px -2px',
                padding: '0.78em 1.5em', minWidth: '250px', maxWidth: '350px',
                margin: '0 auto', fontWeight: '600'
                }}>
                Become a Member
            </button>
             {errorMessage && <div>
                 <div style={{
                 color: "rgba(255, 0, 0, 0.8)",
                 fontSize: "1em",
                 marginTop:  "7px",
             }}>{errorMessage}</div>
                <div style={{ cursor: "pointer", marginTop: "7px", fontSize: "14px", color: "rgba(27, 28, 29, 0.6)" }} onClick={() => sendVerificationEmail(email)}>Resend the verification email</div>
             </div> }
            {successMessage && <div style={{
                     color: "rgba(255, 0, 0, 0.8)",
                     fontSize: "1em",
                     marginTop:  "7px"}}>{successMessage}</div>}
            <PlanTermsWrapper>
                <div>Important</div>
                <div onClick={openTermsModal}>Plan Terms*</div>
            </PlanTermsWrapper>
            <TermsModal
                active={isModalTermsOpen}
                onRequestClose={closeTermsModal}
            />
        </>
    );
};
