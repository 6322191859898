import { ActionDescriptor } from "../../common";
import { UILogInterface } from "./interfaces";

export const UI_LOGGER = "UI/LOGGER";

export enum UILogLevel {
    ERROR = "uiError",
    WARNING = "uiWarning",
    INFO = "uiInfo",
}

export type UILogAction = ActionDescriptor<
    typeof UI_LOGGER,
    UILogInterface,
    {}
>;

export interface UILogActionsMap {
    [UI_LOGGER]: UILogAction;
}
