import { Modal, ModalCloseIcon, ModalContent, ModalHeader, PageDimmer } from "@ramble/ramble-ui";
import Bind from "lodash-decorators/bind";
import React, { PureComponent } from "react";
import { BillingItem } from "../components/account_settings/billing_grid_row";
import styled from "../theme";
import moment from "moment";


const Title = styled.h3`
    font-size: 20px;
    font-weight: 600;
`;
const ScrollableDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    max-height: 200px;
`;

export interface BillingHistoryModalProps {
    /**
     * Modal is active
     */
    active: boolean;
    items: BillingItem[];
    /**
     * Request close callback
     */
    onRequestClose(): void;
}

interface State {
    updating: boolean;
}

export class BillingHistoryModal extends PureComponent<BillingHistoryModalProps, State> {
    public state: State = {
        updating: false,
    };

    public render(): JSX.Element {
        const { active } = this.props;
        return (
            <>
                <PageDimmer uiActive={active}/>
                <Modal
                    uiActive={active}
                    uiOnRequestClose={this.handleClose}
                    style={{maxWidth:'400px'}}
                >
                    <ModalHeader>
                        <Title>Billing History</Title>
                        <ModalCloseIcon onClick={this.handleClose} />
                    </ModalHeader>
                    <ModalContent style={{ paddingTop:'0' }}>
                        <ScrollableDiv>
                            {this.props.items.map((item, id) => (
                                <div key={id}>
                                    <a href={item.receiptUrl} target="_blank">
                                        {moment(item.date).format("DD MMMM YYYY")}
                                    </a>
                                </div>
                            ))}
                        </ScrollableDiv>
                    </ModalContent>
                </Modal>
            </>
        );
    }

    @Bind()
    private handleClose(): void {
        if (this.state.updating) {
            return;
        }
        this.props.onRequestClose();
    }
}
