import React, { forwardRef } from "react";
import styled from "../theme/styled";
import Input, { InputProps } from "./input";
import NonNativeTimeInput from "./non_native_time_input";
import { hasNativeTimePicker } from "./utils";

const nativeAvailable = hasNativeTimePicker();

export type TimeInputProps = InputProps;

const TimeInput = forwardRef<HTMLInputElement, TimeInputProps>((props, ref) => {
    return (
        nativeAvailable ? <Input {...props} ref={ref} type="time" /> : <NonNativeTimeInput {...props} ref={ref} />
    );
});

export default styled(TimeInput)`
    /* ignore */
`;
