import Color from "color";
import Bind from "lodash-decorators/bind";
import React, { PureComponent } from "react";
import { CardElement, ReactStripeElements } from "react-stripe-elements";
import styled, { css, theme } from "../../theme";

interface StyledCardProps {
    uiFocused?: boolean;
}

const StyledCard = styled(CardElement)<StyledCardProps>`
    font-size: 1rem;
    border-radius: 0.28rem;
    padding: 0.67em 1em;
    border: 1px solid ${Color(theme.colors.divider).alpha(0.15).string()};
    color: ${Color(theme.colors.primary).alpha(0.87).string()};
    background: ${theme.colors.background};
    transition: 0.1s ease;
    transition-property: border-color, background-color, color;
    ${props => props.uiFocused && css`
        border-color: ${theme.colors.primary};
        color: ${Color(theme.colors.primaryText).alpha(0.8).string()};
    `};
    font-family: 'Poppins', Arial, Helvetica, sans-serif !important;
`;

interface State {
    focused: boolean;
}

export class CreditCardInput extends PureComponent<ReactStripeElements.ElementProps, State> {
    public state: State = {
        focused: false,
    };

    public render(): JSX.Element {
        return (
                <StyledCard
                    {...this.props}
                    uiFocused={this.state.focused}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                    style={{
                        base: {
                            fontFamily: '"Poppins", Arial, Helvetica, sans-serif',
                            fontSize: "15px",
                        },
                    }}
                />
        );
    }

    @Bind()
    private onBlur(): void {
        this.setState({
            focused: false,
        });
    }

    @Bind()
    private onFocus(): void {
        this.setState({
            focused: true,
        });
    }
}
