import { GridTableCell, GridTableRow } from "@ramble/ramble-ui";
import React, { FC, useState } from "react";
import styled, { css } from "../../../theme";

import { AvatarImage } from "../avatar";
import CopyToClipboard from "../copy-to-clipboard";
import { SortTableRowInfo } from "./sort_table";
import ArrowDown from '../../../assets/icons/chevron-right.svg'

const RowE: FC<RowProps> = (props) => (
    <GridTableRow className={props.className} onClick={props.onClick}>
        {props.children}
    </GridTableRow>
);

const Row = styled(RowE)`
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 0;
    border-bottom: solid #e2e2e2 1px;
    padding: 0;
    color: #000;
    font-size: 14px;
    font-weight: 400;

    &:last-child {
        border: 0;
    }

    &:hover {
        background-color: #eee;
    }

    ${(props) =>
        props.italic &&
        css`
            color: #aaa;
            font-style: italic;
        `};
`;

const BasicGridTableCell = styled(GridTableCell)`
    text-align: left;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    font-weight: 400;
    border-left: 1px solid #e2e2e2;

    &:first-child {
        border-left: none;
    }
`;

const DisplayNameE: FC<LevelProps> = (props) => (
    <BasicGridTableCell className={props.className}>
        {props.children}
    </BasicGridTableCell>
);

const DisplayName = styled(DisplayNameE) <LevelProps>`
    text-align: left;
    padding-left: ${(props) => props.level * 34 + 10}px;

    & .dropdown_arrow{
        width: 24px;
        height: 100%;
        
        & .dropdown_arrow_position{
            width: 12px;
            transform: rotate(0deg);
            transition: all .15s ease;

            &.active{
                transform: rotate(90deg);
            }
        }
    }
`;

const Email = styled(BasicGridTableCell)`
    overflow: unset;
    white-space: normal;
`;

const Avatar = styled(AvatarImage)`
    margin-right: 8px;
    margin-left: 8px;
`;

export interface RowProps {
    className?: string;
    italic?: boolean;
    children?: React.ReactNode;
    onClick(): void;
}

export interface LevelProps {
    level: number;
    className?: string;
    children?: React.ReactNode;
}

export interface SortTableRowProps extends LevelProps {
    data: SortTableRowInfo;
    onChoosed(id: number): void;
    handleToggle?(item: any): void;
    isActiveIndex?: boolean;
}

const SortTableRow: FC<SortTableRowProps> = (props) => {
    const [disabled, setDisabled] = useState<boolean>(false)

    return (
        <Row
            italic={!props.data.active}
            className={`grid-table-row`}
            onClick={() => {
                if (!disabled) {
                    props.onChoosed(props.data.id)
                }
            }}
        >
            <DisplayName className={props.className} level={props.level}>
                <div className="flex items-center justify-center dropdown_arrow" onMouseOut={() => setDisabled(false)} onMouseOver={() => setDisabled(true)} onClick={() => {
                    if (props.handleToggle) props.handleToggle(props.data)
                }}>
                    {props.data.subList && props.data.subList.length > 0 && (
                        <ArrowDown className={`dropdown_arrow_position ${props.isActiveIndex ? 'active' : ''}`} />
                    )}
                </div>
                <Avatar
                    avatarId={props.data.avatarId}
                    firstName={props.data.firstName}
                    lastName={props.data.lastName}
                    companyName={props.data.companyName}
                    width={28}
                    height={28}
                    fontSize={15}
                />
                {props.data.displayName}
                {!props.data.active && " (Inactive)"}
            </DisplayName>

            <Email>
                {props.data.email && props.data.email && (
                    <CopyToClipboard
                        title={props.data.email}
                        copy={props.data.email}
                        tooltipMessage="Email Copied"
                    />
                )}
            </Email>

            <BasicGridTableCell>
                {props.data.cellPhone && props.data.cellPhone}
                {props.data.homePhone &&
                    !props.data.cellPhone &&
                    props.data.homePhone &&
                    props.data.homePhone}
                {props.data.officePhone &&
                    !props.data.cellPhone &&
                    !props.data.homePhone &&
                    props.data.officePhone &&
                    props.data.officePhone}
            </BasicGridTableCell>
        </Row>
    );
}

export default SortTableRow;
