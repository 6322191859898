import {
    Container,
    HContainer,
    Separator,
    VContainer,
} from "../UI/more_detail/container";
import React, { FC } from "react";

import ClientHoverBox from "../clients/client_hover_box";
import CopyToClipboard from "../UI/copy-to-clipboard";
import DetailValue from "../UI/more_detail/detail_value";
import Section from "../UI/more_detail/section";
import { SuppliersModule } from "../../redux";
import styled from "../../theme";

const RelationName = styled.span`
    color: #41a1dc;
    margin-right: 3px;
    align-self: center;
`;

interface SupplierMoreDetailsProps {
    className?: string;
    /**
     * Supplier info to display
     */
    supplier: SuppliersModule.Supplier;
    /**
     * Supplier click callback
     * @param id
     */
    onEditClick(id: number): void;
}

const SupplierMoreDetails: FC<SupplierMoreDetailsProps> = (props) => {
    const { supplier } = props;

    const renderTagList = (tags: string[]) => {
        return tags.map((tag, idx) => {
            return <DetailValue key={idx} value={tag} />;
        });
    };

    const renderFirstVContainer = (rightBorder: boolean) => {
        return (
            <>
                <HContainer>
                    <Section title="ORDERING INFORMATION" rightBorder>
                        {supplier.companyName && (
                            <DetailValue
                                label="Company"
                                value={supplier.companyName}
                            />
                        )}
                        {supplier.mainPhone && (
                            <DetailValue
                                label="Main Phone"
                                value={supplier.mainPhone}
                            />
                        )}
                        {supplier.fax && (
                            <DetailValue label="Fax" value={supplier.fax} />
                        )}
                        {supplier.shippingAddress.accountNumber && (
                            <DetailValue
                                label="Account #"
                                value={supplier.shippingAddress.accountNumber}
                            />
                        )}
                        <Separator />
                    </Section>
                    <Section title="BILLING INFORMATION" rightBorder>
                        {supplier.billingAddress &&
                            supplier.billingAddress.company && (
                                <DetailValue
                                    label="Company"
                                    value={supplier.billingAddress.company}
                                />
                            )}
                        {supplier.billingAddress &&
                            supplier.billingAddress.mainPhone && (
                                <DetailValue
                                    label="Main Phone"
                                    value={supplier.billingAddress.mainPhone}
                                />
                            )}
                        {supplier.billingAddress &&
                            supplier.billingAddress.fax && (
                                <DetailValue
                                    label="Fax"
                                    value={supplier.billingAddress.fax}
                                />
                            )}
                        {supplier.billingAddress &&
                            supplier.billingAddress.paymentTerms && (
                                <DetailValue
                                    label="Payment Terms"
                                    value={supplier.billingAddress.paymentTerms}
                                />
                            )}

                        {supplier.billingAddress.accountNumber && (
                            <DetailValue
                                label="Account #"
                                value={supplier.billingAddress.accountNumber}
                            />
                        )}
                        <Separator />
                    </Section>
                    <Section
                        title="ADDITIONAL CONTACTS"
                        rightBorder={rightBorder}
                    >
                        {supplier.additionalContacts &&
                            supplier.additionalContacts.map(
                                (
                                    sp: SuppliersModule.SupplierAdditionalContact,
                                ) => {
                                    return (
                                        <>
                                            {sp &&
                                                sp.relation &&
                                                (sp.firstName ||
                                                    sp.lastName) && (
                                                    <HContainer>
                                                        <RelationName>
                                                            {sp.relation}
                                                        </RelationName>
                                                        <ClientHoverBox
                                                            key={sp.id}
                                                            additionalContact={
                                                                sp
                                                            }
                                                            type="child"
                                                        />
                                                    </HContainer>
                                                )}
                                        </>
                                    );
                                },
                            )}
                        <Separator />
                    </Section>
                </HContainer>
                <HContainer>
                    <Section title="" rightBorder>
                        {(supplier.shippingAddress.firstName ||
                            supplier.shippingAddress.lastName) && (
                                <DetailValue
                                    label="Contact"
                                    value={`${supplier.shippingAddress.firstName || ""
                                        } ${supplier.shippingAddress.lastName || ""}`}
                                />
                            )}
                        {supplier.shippingAddress.cellPhone && (
                            <DetailValue
                                label="Mobile"
                                value={supplier.shippingAddress.cellPhone}
                            />
                        )}
                        {supplier.shippingAddress.officePhone && (
                            <DetailValue
                                label="Office Phone"
                                value={supplier.shippingAddress.officePhone}
                            />
                        )}
                        {supplier.shippingAddress.email && (
                            <DetailValue
                                label="Email"
                                value={
                                    <CopyToClipboard
                                        title={supplier.shippingAddress.email}
                                        copy={supplier.shippingAddress.email}
                                        tooltipMessage="Email Copied"
                                    />
                                }
                            />
                        )}
                        <Separator />
                    </Section>
                    <Section title="" rightBorder>
                        {supplier.billingAddress &&
                            (supplier.billingAddress.firstName ||
                                supplier.billingAddress.lastName) && (
                                <DetailValue
                                    label="Contact"
                                    value={`${supplier.billingAddress.firstName || ""
                                        } ${supplier.billingAddress.lastName || ""
                                        }`}
                                />
                            )}
                        {supplier.billingAddress &&
                            supplier.billingAddress.cellPhone && (
                                <DetailValue
                                    label="Mobile"
                                    value={supplier.billingAddress.cellPhone}
                                />
                            )}
                        {supplier.billingAddress &&
                            supplier.billingAddress.officePhone && (
                                <DetailValue
                                    label="Office Phone"
                                    value={supplier.billingAddress.officePhone}
                                />
                            )}
                        {supplier.billingAddress &&
                            supplier.billingAddress.email && (
                                <DetailValue
                                    label="Email"
                                    value={
                                        <CopyToClipboard
                                            title={
                                                supplier.billingAddress.email
                                            }
                                            copy={supplier.billingAddress.email}
                                            tooltipMessage="Email Copied"
                                        />
                                    }
                                />
                            )}
                        <Separator />
                    </Section>
                    <Section
                        title="LOGIN INFORMATION"
                        rightBorder={rightBorder}
                    >
                        {supplier.loginWebsite && (
                            <DetailValue
                                label="Website"
                                value={supplier.loginWebsite}
                                clickable
                                href={supplier.loginWebsite}
                                target="_blank"
                            />
                        )}
                        {supplier.loginUsername && (
                            <DetailValue
                                label="Username"
                                value={supplier.loginUsername}
                            />
                        )}
                        {supplier.loginPassword && (
                            <DetailValue
                                label="Password"
                                value={supplier.loginPassword}
                            />
                        )}
                        <Separator />
                    </Section>
                </HContainer>
                <HContainer uiFlex="1">
                    <Section title="SHIPPING ADDRESS" rightBorder>
                        {supplier.shippingAddress.streetAddress && (
                            <DetailValue
                                clickable
                                value={supplier.shippingAddress.streetAddress}
                                href={`http://maps.google.com/?q=${supplier.shippingAddress.streetAddress},${supplier.shippingAddress.cityTown},${supplier.shippingAddress.zipCode} ${supplier.shippingAddress.country}`}
                                target="_blank"
                            />
                        )}
                        {supplier.shippingAddress.unitSuite && (
                            <DetailValue
                                value={supplier.shippingAddress.unitSuite}
                            />
                        )}
                        {supplier.shippingAddress.cityTown &&
                            !supplier.shippingAddress.zipCode && (
                                <DetailValue
                                    value={`${supplier.shippingAddress.cityTown}`}
                                />
                            )}
                        {!supplier.shippingAddress.cityTown &&
                            supplier.shippingAddress.zipCode && (
                                <DetailValue
                                    value={`${supplier.shippingAddress.zipCode}`}
                                />
                            )}
                        {supplier.shippingAddress.cityTown &&
                            supplier.shippingAddress.zipCode &&
                            !supplier.shippingAddress.stateProvince && (
                                <DetailValue
                                    value={`${supplier.shippingAddress.cityTown}, ${supplier.shippingAddress.zipCode}`}
                                />
                            )}
                        {supplier.shippingAddress.cityTown &&
                            supplier.shippingAddress.zipCode &&
                            supplier.shippingAddress.stateProvince && (
                                <DetailValue
                                    value={`${supplier.shippingAddress.cityTown}, ${supplier.shippingAddress.stateProvince} ${supplier.shippingAddress.zipCode}`}
                                />
                            )}
                        {supplier.shippingAddress.country && (
                            <DetailValue
                                value={supplier.shippingAddress.country}
                            />
                        )}
                        <Separator />
                    </Section>
                    <Section title="BILLING ADDRESS" rightBorder>
                        {supplier.billingAddress &&
                            supplier.billingAddress.streetAddress && (
                                <DetailValue
                                    clickable
                                    value={
                                        supplier.billingAddress.streetAddress
                                    }
                                    href={`http://maps.google.com/?q=${supplier.billingAddress.streetAddress}`}
                                    target="_blank"
                                />
                            )}
                        {supplier.billingAddress &&
                            supplier.billingAddress.unitSuite && (
                                <DetailValue
                                    value={supplier.billingAddress.unitSuite}
                                />
                            )}
                        {supplier.billingAddress &&
                            supplier.billingAddress.cityTown &&
                            !supplier.billingAddress.zipCode && (
                                <DetailValue
                                    value={`${supplier.billingAddress.cityTown}`}
                                />
                            )}
                        {supplier.billingAddress &&
                            !supplier.billingAddress.cityTown &&
                            supplier.billingAddress.zipCode && (
                                <DetailValue
                                    value={`${supplier.billingAddress.zipCode}`}
                                />
                            )}
                        {supplier.billingAddress &&
                            supplier.billingAddress.cityTown &&
                            supplier.billingAddress.zipCode &&
                            !supplier.billingAddress.stateProvince && (
                                <DetailValue
                                    value={`${supplier.billingAddress.cityTown}, ${supplier.billingAddress.zipCode}`}
                                />
                            )}
                        {supplier.billingAddress &&
                            supplier.billingAddress.cityTown &&
                            supplier.billingAddress.zipCode &&
                            supplier.billingAddress.stateProvince && (
                                <DetailValue
                                    value={`${supplier.billingAddress.cityTown}, ${supplier.billingAddress.stateProvince} ${supplier.billingAddress.zipCode}`}
                                />
                            )}
                        {supplier.billingAddress &&
                            supplier.billingAddress.country && (
                                <DetailValue
                                    value={supplier.billingAddress.country}
                                />
                            )}
                        <Separator />
                    </Section>
                    <Section title="NOTES" rightBorder={rightBorder}>
                        {supplier.notes && (
                            <DetailValue value={supplier.notes} />
                        )}
                    </Section>
                    {/*<Section title="ATTACHMENTS" rightBorder={rightBorder}>

                        <Separator />
                        {!rightBorder && (
                            <EditButton onClick={handleEditClick} title="Edit Supplier" className="edit-button" />
                        )}
                        </Section>*/}
                </HContainer>
            </>
        );
    };

    return (
        <Container>
            <VContainer uiWidth="85%">{renderFirstVContainer(true)}</VContainer>
            <VContainer uiWidth="15%">
                <Section title={"TAGS"}>
                    {supplier.tags && renderTagList(supplier.tags.split("||"))}
                    <Separator />
                </Section>
            </VContainer>
        </Container>
    );
};

export default SupplierMoreDetails;
