import React, { FC } from "react";

interface DetailsPreviewProps {
    title: string;
}

const DetailsPreview: FC<DetailsPreviewProps> = ({ title }) => {
    const labelClassName = "font-bold mr-2";
    const previewRow = "mb-1";
    
    const getTitle = (title: string) => {
        return `${title.replace("Description", "")}Preview`;
    };

    return (
        <div className="w-full lg:w-1/2 mr-5 flex flex-col items-center">
            <span>{getTitle(title)}</span>
            <div className="mt-2 border max-w-[500px]">
                <div className="flex border-b bg-twBackDropColor">
                    <div className="p-2 flex flex-col justify-center">
                        ITEM DESCRIPTION
                    </div>
                </div>
                <div className="flex border-b">
                    <div className="p-2 flex flex-col justify-center text-twBlue">
                        MASTER BEDROOM
                    </div>
                </div>
                <div className="p-2">
                    <div className={previewRow}>
                        <span className="text-twBlue mr-2">WO #1234</span>Window
                        A
                    </div>
                    <div className={previewRow}>
                        <span className={labelClassName}>
                            Functional || Full Length || Finished Length:
                        </span>
                        111"
                    </div>
                    <div className={previewRow}>
                        <span className={labelClassName}>
                            Rods & Rings || Inverted Pleat
                        </span>
                    </div>
                    <div className={previewRow}>
                        <span className={labelClassName}>Panels:</span>1.5w -
                        1.5w
                    </div>
                    <div className={previewRow}>
                        <span className={labelClassName}>Total Panels:</span>2
                        || <span className={labelClassName}>Total Widths:</span>3
                    </div>
                    <div className={previewRow}>
                        <span className={labelClassName}>Lining:</span>
                        Standard/Ivory
                    </div>
                    <div className={previewRow}>
                        <span className={labelClassName}>Interlining:</span>
                        Standard/Natural
                    </div>
                    <div className={previewRow}>
                        <span className={labelClassName}>Embellishments:</span>
                        Trim (Pre-made) on bottom edge and 2 vertical edges
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsPreview;
