import Color from "color";
import React, { FC, memo } from "react";
import authBackground from "../assets/images/auth_background.jpg";
import styled, { theme } from "../theme";

const Container = styled.div`
    width: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    background-image: url(${authBackground});
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: row nowrap;
    justify-content: center;
    align-items: stretch;
    color: white;
`;

const Content = styled.div`
    max-width: 550px;
    width: 100%;
    display: flex;
    background: ${theme.colors.contentBackground};
    color: ${Color(theme.colors.darkText).alpha(0.7).string()};
    flex-flow: column nowrap;
    justify-content: center;
    align-items: stretch;
    margin: auto;
    text-align: center;
    vertical-align: middle;
    flex: 0 0 auto;
    border: 25px solid ${theme.colors.primary};
    padding: 2em;
    z-index: 100;
`;

const BackgroundImgOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
`;

/**
 * General auth container
 */
export const AuthContainer: FC = memo(props => (
    <Container>
        <BackgroundImgOverlay />
        <Content>
            {props.children}
        </Content>
    </Container>
));
