import React from 'react';
import styled, {theme} from "../../../theme";

const NotesContainer = styled.div`
    position: absolute;
   
`;

const NotesParagraph = styled.p`
    position: absolute; 
    left: 30px !important;
    top: 25px !important;
    display: flex;
    z-index: 999;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid ${theme.colors.grayRGBA};
    padding: 15px 15px;
    color: #4a4a4a;
    font-size: 13px;
    margin-right: 15px;
    flex-direction: column;
    right: 10px;
    overflow: auto;
    max-height: 100px;
    min-height: 60px; 
    width: 90%;
    max-width: 680px;
    min-width: 600px !important;
`;

const NotesHover = ({ notes }: { notes: string }) => {
    return (
        <NotesContainer>
            <NotesParagraph style={{ overflowWrap: 'break-word' }}>{notes}</NotesParagraph>
        </NotesContainer>
    );
};

export default NotesHover;
