import React, { FC } from "react";
import SearchSelect, {
    SearchSelectProps,
} from "../search_select/search_select";

import AddButtonE from "../buttons/add-button";
import { Checkbox } from "@ramble/ramble-ui";
import styled from "../../../theme";

export interface HeaderProps {
    headingText: string;
    addButtonText: string;
    inactiveFilter: boolean;
    onSearch: SearchSelectProps["onSearch"];
    onItemClick(id: number): void;
    onInactiveFilterChange(inactive: boolean): void;
    onAddClick?(): void;
}

const AddButton = styled(AddButtonE)`
    margin-left: auto;
    // @media screen and (max-width: 992px) {
    //     padding: 0px;
    // }
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    // margin-top: -15px;
`;

const IncludeInactiveCheckBox = styled(Checkbox)`
    font-size: 14px;
    color: #222;
    padding-left: 10px;
`;

const Heading = styled.h1`
    font-family: "Poppins", serif;
    font-size: 18px;
    letter-spacing: 0px;
    margin-right: 15px;
    color: #222222;
    font-weight: 400;
`;

const Header: FC<HeaderProps> = (props) => (
    <HeaderContainer>
        <Heading>{props.headingText}</Heading>

        <SearchSelect
            searchType="header"
            onItemClick={props.onItemClick}
            onSearch={props.onSearch}
            placeholder="Search..."
        />

        <IncludeInactiveCheckBox
            checked={props.inactiveFilter}
            onChange={() => props.onInactiveFilterChange(!props.inactiveFilter)}
        >
            Include Inactive
        </IncludeInactiveCheckBox>

        <AddButton onClick={props.onAddClick} title={props.addButtonText} />
    </HeaderContainer>
);

export default Header;
