import {
    ThemeInterface as UIThemeInterface,
    colors,
    getTheme as rambleUiTheme,
    breakpoints as uiBreakpoints,
    transitions as uiTransitions,
} from "@ramble/ramble-ui";

import Color from "color";

export { colors };

export const breakpoints = {
    ...uiBreakpoints,
    values: {
        ...uiBreakpoints.values,
        xsm: 450,
    },
};

export const transitions = {
    ...uiTransitions,
};

export type PortalUIChatType =
    | "incoming"
    | "active"
    | "answered"
    | "missed"
    | "message"
    | "group";

interface PortalThemeInterface {
    colors: {
        warning: string;
        info: string;
        mainBackground: string;
        contentBackground: string;
        contentBorder: string;
        white: string;
        grayText: string;
        grayRGBA: string;
        gray1: string;
        blue: string;
        blue1: string;
        red: string;
        red1: string;
        divider: string;
        lightGrayText: string;
        blackText: string;
        errorBackground: string;
    };
    elements: {
        headerFullSize: number;
        headerSmallSize: number;
        sidebarSmallSize: number;
        sidebarFullSize: number;
        // sidebarVerySmallSize: number;
    };
    notifications: {
        infoColor: string;
        warningColor: string;
        errorColor: string;
    };
}

export type ThemeInterface = UIThemeInterface & PortalThemeInterface;

/**
 * Return theme
 */
export function getTheme(): ThemeInterface {
    const uiTheme = rambleUiTheme();
    uiTheme.colors.primary = "#3B97B1";
    uiTheme.colors.secondary = "#BBBABB";
    uiTheme.colors.whiteText = colors.white;
    uiTheme.colors.primaryText = colors.black;
    uiTheme.colors.background = colors.white;
    return {
        ...uiTheme,
        colors: {
            ...uiTheme.colors,
            warning: "#fbbd08",
            info: colors.green,
            mainBackground: "#EEEEEE",
            contentBackground: colors.white,
            contentBorder: Color(uiTheme.colors.divider).alpha(0.15).string(),
            white: "#ffffff",
            grayText: "#969696",
            grayRGBA: "rgba(27,28,29,0.87)",
            gray1: "#aaaaaa",
            blue: "#338daa",
            blue1: "#c6c6c6",
            red: "#ff6084",
            red1: "#f3dfe4",
            lightGrayText: "#CCCCCC",
            divider: "#3B97B166",
            blackText: "#000000",
            errorBackground: "rgba(255, 0, 0, 0.4)",
        },
        elements: {
            headerFullSize: 65,
            // headerSmallSize: 50,
            headerSmallSize: 65,
            sidebarSmallSize: 65,
            sidebarFullSize: 250,
            // sidebarVerySmallSize: 50,
        },
        notifications: {
            infoColor: "rgb(0, 137, 123)",
            warningColor: "rgb(251, 189, 8)",
            errorColor: "rgb(219, 40, 40)",
        },
    };
}

/**
 * Default portal theme
 */
export const theme = getTheme();
