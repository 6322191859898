import { Modal, PageDimmer } from "@ramble/ramble-ui";
import React, { PureComponent } from "react";
import PopupImg from "../assets/images/popup.png";
import styled from "../theme";

const noop = () => {
    return;
};

const ModalContainer = styled(Modal)`
    flex-direction: column;
    background-color: transparent;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    max-width: none;
    display: flex;
`;

const SubContainer = styled.div`
    margin: auto auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PopupMessage = styled.div`
    margin-bottom: 55px;
    background-color: #fdf2dc;
    padding: 15px 18px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    color: #222;
`;

const Loading = styled.div`
    .lds-grid {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }

    .lds-grid div {
        position: absolute;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #fff;
        animation: lds-grid 1.2s linear infinite;
    }

    .lds-grid div:nth-child(1) {
        top: 6px;
        left: 6px;
        animation-delay: 0s;
    }

    .lds-grid div:nth-child(2) {
        top: 6px;
        left: 26px;
        animation-delay: -0.4s;
    }

    .lds-grid div:nth-child(3) {
        top: 6px;
        left: 45px;
        animation-delay: -0.8s;
    }

    .lds-grid div:nth-child(4) {
        top: 26px;
        left: 6px;
        animation-delay: -0.4s;
    }

    .lds-grid div:nth-child(5) {
        top: 26px;
        left: 26px;
        animation-delay: -0.8s;
    }

    .lds-grid div:nth-child(6) {
        top: 26px;
        left: 45px;
        animation-delay: -1.2s;
    }

    .lds-grid div:nth-child(7) {
        top: 45px;
        left: 6px;
        animation-delay: -0.8s;
    }

    .lds-grid div:nth-child(8) {
        top: 45px;
        left: 26px;
        animation-delay: -1.2s;
    }

    .lds-grid div:nth-child(9) {
        top: 45px;
        left: 45px;
        animation-delay: -1.6s;
    }

    @keyframes lds-grid {
        0%,
        100% {
            opacity: 1;
        }

        50% {
            opacity: 0.5;
        }
    }
`;

const Message = styled.div`
    font-size: 16px;
    flex: 4;
    padding: 5px;
    color: #fff;
`;

const CloseButton = styled.div`
    position: fixed;
    right: 50px;
    top: 50px;
    color: white;
    font-size: 40px;
    cursor: pointer;
    padding: 15px;
    border-radius: 50%;
`;

interface Props {
    isModalOpen: boolean;
    message?: string;
    onModalClose(): void;
}

class LoadingModal extends PureComponent<Props> {
    public render() {
        return (
            <>
                <PageDimmer uiActive={this.props.isModalOpen} />
                <ModalContainer
                    uiActive={this.props.isModalOpen}
                    uiOnRequestClose={noop}
                    uiDefaultSizes={false}
                >
                    <SubContainer>
                        <Loading>
                            <div className="lds-grid">
                                <div />
                                <div />
                                <div />
                                <div />
                                <div />
                                <div />
                                <div />
                                <div />
                                <div />
                            </div>
                        </Loading>
                        {this.props.message && (
                            <Message>{this.props.message}</Message>
                        )}
                    </SubContainer>
                    {this.props.message && (
                        <PopupMessage>
                            <img src={PopupImg} alt="" />
                            <span>Pop-ups in browser must be enabled</span>
                        </PopupMessage>
                    )}
                    <CloseButton onClick={this.props.onModalClose}>
                        &times;
                    </CloseButton>
                </ModalContainer>
            </>
        );
    }
}

export default LoadingModal;
