import React, { ChangeEvent, FC } from "react";

interface InchesInputProps {
    finishedCutId: string;
    CutAllowanceId: string;
    finishedCutValue?: string | number;
    cutAllowanceValue?: string | number;
    onChange: (params: ChangeEvent<HTMLInputElement>) => void;
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    isBottom?: boolean;
    className?: string;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const InchesInput: FC<InchesInputProps> = ({
    finishedCutId,
    CutAllowanceId,
    finishedCutValue,
    cutAllowanceValue,
    onChange,
    handleKeyDown,
    isBottom = false,
    className = "",
    onFocus,
}) => {
    return (
        <div
            className={`grid grid-cols-2 divide-x divide-black/25 ${
                isBottom ? "" : "border-b border-black/25"
            } h-14`}
        >
            {/* Finished Size */}
            <div className="flex flex-row items-center justify-center p-0">
                <input
                    id={finishedCutId}
                    name={finishedCutId}
                    value={finishedCutValue}
                    className="mr-2 rounded items-center border w-12 h-6 p-1 text-sm"
                    type="number"
                    step={0.001}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    onFocus={onFocus}
                />
                <p className="text-sm">in.</p>
            </div>

            {/* Cut Allowance */}
            <div className="flex flex-row p-4 justify-center">
                <input
                    id={CutAllowanceId}
                    name={CutAllowanceId}
                    value={cutAllowanceValue}
                    className="mr-2 rounded items-center border w-12 h-6 p-1"
                    onChange={onChange}
                    type="number"
                    onKeyDown={handleKeyDown}
                    onFocus={onFocus}
                />
                <p className="text-sm">in.</p>
            </div>
        </div>
    );
};

export default InchesInput;
