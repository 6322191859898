import { Api } from "@quipa/api";
import { flagRoot } from "../../utils";

export type WorkroomSetting = Api.WorkroomSetting;
export type WorkroomPresetEmbellishmentJson = Api.WorkroomPresetEmbellishmentJson;
export type WorkroomPresetHEMSJson = Api.WorkroomPresetHEMSJson;
export type WorkroomPresetReturnOverlapJson = Api.WorkroomPresetReturnOverlapJson;
export type WorkroomPresetCutLengthJson = Api.WorkroomPresetCutLengthJson;
export type WorkroomPresetDraperyFullnessJson = Api.WorkroomPresetDraperyFullnessJson;

export const defaultWorkroomPresetEmbellishmentJson: WorkroomPresetEmbellishmentJson = {};
export const defaultWorkroomPresetHEMSJson = {};
export const defaultWorkroomPresetReturnOverlapJson = {};
export const defaultWorkroomPresetCutLengthJson = {};
export const defaultWorkroomPresetDraperyFullnessJson = {};

// tslint:disable-next-line:no-empty-interface
export interface State {
    //
}

export function defaults(): State {
    const st: State = {
        //
    };
    return flagRoot(st);
}
