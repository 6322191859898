import React, { forwardRef } from "react";
import { CSSTransition } from "react-transition-group";
import styled, { css } from "../theme/styled";
import DimmerContent, { DimmerContentProps, DimmerContentUIProps } from "./dimmer_content";

export const dimmable: any = css`
    position: relative;
    /* overflow: hidden; */
`;

export interface DimmerUIProps extends DimmerContentUIProps {
    /**
     * Active dimmer
     */
    uiActive: boolean;
    /**
     * Blurring dimmer
     */
    uiBlurring?: boolean;
}

export type DimmerProps = DimmerContentProps & DimmerUIProps;

/**
 * Wrapper around dimmer. Makes child as dimmable
 */
const DimmerComponent = forwardRef<HTMLDivElement, DimmerProps>((props, ref) => {
    const { uiActive, uiBlurring, children, ...other } = props as DimmerProps;
    return (
        <CSSTransition
            in={uiActive}
            appear
            enter
            exit
            mountOnEnter
            unmountOnExit
            timeout={300}
            classNames="transition"
        >
            <DimmerContent {...other} ref={ref}>
                {children}
            </DimmerContent>
        </CSSTransition>
    );
});

DimmerComponent.defaultProps = {
    uiActive: false,
    uiColor: "black",
    uiBlurring: false,
};

/**
 * Dimmer dims parent component. Parent component must include $dimmable mixin or have position: relative
 * Important: Dimmer must be first child of parent component
 */
const Dimmer = styled(DimmerComponent)`
    &.transition-appear,
    &.transition-enter {
        opacity: 0;
        ${props => props.uiBlurring && css`
            & ~ * {
                filter: blur(0) grayscale(0) !important;
            }
        `};
    }

    &.transition-appear-active,
    &.transition-enter-active {
        opacity: 1;
        transition: opacity 0.3s ease;
        ${props => props.uiBlurring && css`
            & ~ * {
                transition: filter 0.3s linear !important;
                filter: blur(${props.theme.dimmer.blurAmount}) grayscale(${props.theme.dimmer.grayAmount}) !important;
            }
        `};
    }

    ${props => props.uiBlurring && css`
        &.transition-appear-done,
        &.transition-enter-done {
            & ~ * {
                filter: blur(${props.theme.dimmer.blurAmount}) grayscale(${props.theme.dimmer.grayAmount}) !important;
            }
        }
    `};

    &.transition-exit {
        opacity: 1;
        ${props => props.uiBlurring && css`
            & ~ * {
                filter: blur(${props.theme.dimmer.blurAmount}) grayscale(${props.theme.dimmer.grayAmount}) !important;
            }
        `};
    }

    &.transition-exit-active {
        opacity: 0;
        transition: opacity 0.3s ease;
        ${props => props.uiBlurring && css`
            & ~ * {
                transition: filter 0.3s linear !important;
                filter: blur(0) grayscale(0) !important;
            }
        `};
    }
`;

export default Dimmer;
