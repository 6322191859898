import styled from "../theme/styled";
import Button, { ButtonProps, ButtonUIProps } from "./button";

export type IconButtonUIProps = ButtonUIProps;

export type IconButtonProps = ButtonProps;

/**
 * @see See [Button documentation](/#!/Button)
 */
const IconButton = styled(Button)`
    padding: 0.78em;

    & > svg {
        fill: currentColor;
    }
`;

export default IconButton;
