import axiosInstance from "./axios-instance";
// import axios from "axios";

const createCustomer = async (accountId: number) => {
    try {
        const response = await axiosInstance.post(
            "/stripe/create-customer",
            { accountId },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createCheckout = async (accountId: number, expired: boolean) => {
    // const getRestBaseEndpoint = () => {
    //     //getDeployOptions() doesnt seem to be working
    //     const options = (window as any).__DEPLOY_OPTIONS;
    //     return options.restApiUrl;
    // };

    // const axiosInstance = axios.create({
    //     baseURL: getRestBaseEndpoint(),
    // });
    // try {
    //     const response = await axiosInstance.get("/stripe/createPaymentIntent");

    //     return response.data;
    // } catch (error) {
    //     console.log("Error fetching payment intent", error);
    // }
    try {
        const response = await axiosInstance.post(
            "/stripe/create-checkout-session",
            { accountId, expired },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getCustomerPlanInfo = async (accountId: number) => {
    try {
        const response = await axiosInstance.post("/stripe/get-plan-info", {
            accountId,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const checkIsBilling = async (accountId: number) => {
    try {
        const response = await axiosInstance.post("/stripe/check-is-billing", {
            accountId,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getCustomerBillingHistory = async (accountId: number) => {
    try {
        const response = await axiosInstance.post(
            "/stripe/get-billing-history",
            {
                accountId,
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const cancelSubscription = async (accountId: number) => {
    try {
        const response = await axiosInstance.post(
            "/stripe/cancel-subscription",
            {
                accountId,
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getPromoCode = async (code: string) => {
    // const getRestBaseEndpoint = () => {
    //     //getDeployOptions() doesnt seem to be working
    //     const options = (window as any).__DEPLOY_OPTIONS;
    //     return options.restApiUrl;
    // };

    // const axiosInstance = axios.create({
    //     baseURL: getRestBaseEndpoint(),
    // });
    try {
        const response = await axiosInstance.get(
            `/stripe/getPromoCode?code=${code}`,
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

const getLastDigits = async (accountId: number) => {
    try {
        const response = await axiosInstance.post(
            "/stripe/get-last-4",
            {
                accountId,
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateStripeProfile = async (accountId: number, billingInfo: any) => {
    try {
        const response = await axiosInstance.post(
            "/stripe/update-stripe-profile",
            {
                accountId,
                billingInfo
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateStripePayment = async (accountId: number, token: string) => {
    try {
        const response = await axiosInstance.post(
            "/stripe/update-stripe-payment",
            {
                accountId,
                token
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export {
    createCheckout,
    createCustomer,
    getPromoCode,
    getCustomerPlanInfo,
    getCustomerBillingHistory,
    cancelSubscription,
    getLastDigits,
    updateStripeProfile,
    checkIsBilling,
    updateStripePayment
};