import axiosInstance from "./axios-instance";


const addDraperyOrderPrice = async (payload: any, workroomSettings: any) => {

    try {
        const response = await axiosInstance.post("/drapery-order-price", { data: payload, workroomSettings });

        return response.data;
    } catch (error) {
        console.error("Error adding drapery order price:", error);
        throw error;
    }
}

const updateDraperyOrderPrice = async (payload: any, workroomSettings: any, id: number) => {
    try {
        const response = await axiosInstance.put("/drapery-order-price", { data: payload, workroomSettings, id });

        return response.data
    } catch (error) {
        console.error('Error while updating drapery order price:', error)
        throw error;
    }
}

const getDraperyOrderPrice = async (id: number) => {
    try {
        const response = await axiosInstance.get(`/drapery-order-price/${id}`);

        return response.data;
    } catch (error) {
        console.error("Error getting drapery order price:", error);
        throw error;
    }
}

export { addDraperyOrderPrice, getDraperyOrderPrice, updateDraperyOrderPrice }