import { Api } from "@quipa/api";
import { CancelToken } from "../../../utils/cancel_token";

export const searchUsers = (text: string, sort: "asc" | "desc", cancellationToken?: CancelToken) =>
    Api.createAction(Api.SEARCH_USERS_FOR_ADMIN, { text, sort }, { authenticated: true, cancelToken: cancellationToken });

export const getUserSubscriptionHistory = (accountId: number) =>
    Api.createAction(Api.GET_USER_SUBSCRIPTION_HISTORY, { accountId }, { authenticated: true });

export const editUserRole = (userId: number, role: "admin" | "office_worker") =>
    Api.createAction(Api.EDIT_USER_ROLE, { userId, role }, { authenticated: true });
