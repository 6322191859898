import { Api } from "@quipa/api";
import { actionReducer } from "../../utils";
import { ApplicationActions, SET_REALTIME_STATUS } from "./actions";
import { defaults, State } from "./model";

export default actionReducer<Api.RealtimeActionsMap & ApplicationActions, State>(defaults(), {
    [Api.LOGIN]: {
        success: draft => {
            draft.loaded = true;
        },
    },
    // [SET_APPLICATION_HEADER]: (draft, { payload }) => {
    //     draft.headerIcon = payload.icon;
    //     draft.headerText = payload.label;
    // },
    [SET_REALTIME_STATUS]: (draft, { payload }) => {
        draft.realtimeStatus = payload;
    },
});
