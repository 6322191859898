import {
    ClientPricingAction,
    DraperyWidthRoundUpType,
    PricingType,
} from "./types";

const draperyPricingTypes = [
    { key: 1, label: PricingType.Retail },
    { key: 2, label: PricingType.Trade },
];

const draperyClientAction = {
    discount: ClientPricingAction.Discount,
    markUp: ClientPricingAction.MarkUp,
};

const draperyWidthRoundUpTypes = [
    { key: 1, label: DraperyWidthRoundUpType.Half },
    { key: 2, label: DraperyWidthRoundUpType.Whole },
];

const percentage100RegEx = /^(100|[1-9]\d?)$/; //range 1 - 100
const percentageRegEx = /^(?:[1-9]\d{0,2}|1000)$/; //range 1 - 999
const percentageWithZeroRegEx = /^(?:[0-9]\d{0,2}|1000)$/;
const priceRegEx = /^\d+(\.\d{0,2})?$/; //Regular expression for numbers with up to 2 decimal places
const positiveNumRegEx = /^[1-9]\d*$/; //positive numbers

export {
    draperyPricingTypes,
    draperyClientAction,
    draperyWidthRoundUpTypes,
    percentage100RegEx,
    percentageRegEx,
    priceRegEx,
    positiveNumRegEx,
    percentageWithZeroRegEx
};
