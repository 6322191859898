import React, { ChangeEvent, Component, KeyboardEvent, MouseEvent as ReactMouseEvent, TouchEvent as ReactTouchEvent } from "react";
import { DraperyModule } from "../../../../redux";
import styled from "../../../../theme";
import { isTouchDevice } from "../../../../utils/is_touch_device";
import { Rect, rgbaToString, toRect } from "./board";

interface UiProps {
    uiX?: number;
    uiY?: number;
    uiWidth?: string;
    uiHeight?: string;
    uiMinHeight?: number;
    uiFontSize?: number;
    index?: number;
    uiColor?: string;
    isSelected?: boolean;
    isTransform?: boolean;
}

const Container = styled.div`
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #0009;
    overflow: hidden;
`;

const Cover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const InputTextArea = styled.textarea<UiProps>`
    position: absolute;
    background: #ff06;
    resize: unset;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    min-width: 50px;
    min-height: ${props => props.uiMinHeight}px;
    left: ${props => props.uiX}px;
    top: ${props => props.uiY}px;
    width: ${props => props.uiWidth};
    height: ${props => props.uiHeight};
    color: ${props => props.uiColor};
    font-size: ${props => props.uiFontSize}px;
    border: none;

    &:focus {
        outline: none;
    }
`;

interface Props {
    shape: DraperyModule.Shape;
    onFinished(shape?: DraperyModule.Shape, textInputed?: boolean): void;
    onDeleted?(): void;
    relativeCoordinatesForText(point: DraperyModule.Point): DraperyModule.Point | undefined;
    relativeCoordinatesForEvent(event: ReactMouseEvent | ReactTouchEvent): DraperyModule.Point | undefined;
}

interface State {
    status: "draging" | "idle";
    shape: DraperyModule.Shape;
    dragPoint?: DraperyModule.Point;
    dragPoints?: DraperyModule.Point[];
}

class InputText extends Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            status: "idle",
            shape: {...props.shape},
        };
    }

    public render() {
        const { shape } = this.state;

        const point1 = this.props.relativeCoordinatesForText(shape.points[0]);
        const point2 = this.props.relativeCoordinatesForText(shape.points[1]);
        const rect: Rect = toRect(point1!, point2!);
        const fontSize = this.props.relativeCoordinatesForText({x: shape.fontSize, y: 0})!.x;
        if (rect.width === 0) {
            rect.width = fontSize * 5;
        }

        return (
            <Container
                onMouseMove={!isTouchDevice() ? this.handleMouseEvent : undefined}
                onMouseUp={!isTouchDevice() ? this.handleMouseEvent : undefined}
                onTouchMove={this.handleMouseEvent}
                onTouchEnd={this.handleMouseEvent}
            >
                <Cover
                    onClick={!isTouchDevice() ? this.handleFinish : undefined}
                    onTouchEnd={this.handleFinish}
                />
                <InputTextArea
                    value={shape.text}
                    uiX={rect.x}
                    uiY={rect.y}
                    uiWidth={rect.width + "px"}
                    uiHeight={rect.height + "px"}
                    uiMinHeight={fontSize * 1.3}
                    uiColor={rgbaToString(shape.strokeColor)}
                    uiFontSize={fontSize}
                    onChange={this.handleChangeText}
                    autoFocus
                    onKeyDown={this.handleKeyPress}
                    onMouseDown={this.handleSelectedShapeMouseEvent}
                    onTouchStart={this.handleSelectedShapeMouseEvent}
                />
            </Container>
        );
    }

    private handleChangeText = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            shape: {
                ...this.state.shape,
                text: event.target.value,
            },
        });
    }

    private handleFinish = (e: ReactMouseEvent | ReactTouchEvent) => {
        e.stopPropagation();

        if (this.state.shape.text) {
            this.props.onFinished(this.state.shape, true);
        } else {
            if (this.props.onDeleted) {
                this.props.onDeleted();
            } else {
                this.props.onFinished();
            }
        }
    }

    private handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Escape") {
            this.props.onFinished();
        }
        if (e.key === "Enter") {
            const text = this.state.shape.text;
            if (text) {
                this.props.onFinished(this.state.shape, true);
            } else {
                this.props.onFinished();
            }
        }
    }

    private handleSelectedShapeMouseEvent = (event: ReactMouseEvent | ReactTouchEvent): void => {
        if (event.type === "mousedown" || event.type === "touchstart") {
            this.handleDragProcess(event);
        }
    }

    private handleMouseEvent = (event: ReactMouseEvent | ReactTouchEvent): void => {
        if (event.type === "mousemove" || event.type === "touchmove") {
            this.handleDragProcess(event);
        } else if (event.type === "mouseup" || event.type === "touchend") {
            this.handleDragProcess(event);
        }
    }

    private handleDragProcess = (event: ReactMouseEvent | ReactTouchEvent): void => {
        const point = this.props.relativeCoordinatesForEvent(event);
        if (point) {
            if (event.type === "mousedown" || event.type === "touchstart") {
                const dragPoints: DraperyModule.Point[] = [];
                this.state.shape.points.forEach(point => {
                    dragPoints.push({...point});
                });
                this.setState({
                    status: "draging",
                    dragPoint: point,
                    dragPoints,
                });
            } else if (event.type === "mousemove" || event.type === "touchmove") {
                if (this.state.dragPoint && this.state.dragPoints && this.state.status === "draging") {
                    this.setState({
                        shape: this.updateShapePos(this.state.shape, point, this.state.dragPoint, this.state.dragPoints),
                    });
                }
            } else if (event.type === "mouseup" || event.type === "touchend") {
                if (this.state.dragPoint && this.state.dragPoints && this.state.status === "draging") {
                    this.setState({
                        status: "idle",
                        shape: this.updateShapePos(this.state.shape, point, this.state.dragPoint, this.state.dragPoints),
                        dragPoint: undefined,
                        dragPoints: undefined,
                    });
                }
            }
        }
    }

    private updateShapePos = (shape: DraperyModule.Shape, coord: DraperyModule.Point, offset: DraperyModule.Point, points: DraperyModule.Point[]): DraperyModule.Shape => {
        shape.points = shape.points.map((point, index) => {
            const orgPoint = points[index];
            if (orgPoint) {
                point.x = orgPoint.x + coord.x - offset.x;
                point.y = orgPoint.y + coord.y - offset.y;
            }
            return {...point};
        });
        return shape;
    }
}

export default InputText;
