import Bind from "lodash-decorators/bind";
import React, { PureComponent } from "react";
import { ColorResult, RGBColor, SketchPicker } from "react-color";
import { FaTimes } from "react-icons/fa";
import styled from "../../../../theme";
import IconButton from "../../buttons/icon-button";
import { MenuButton, MenuButtonProps } from "./toolbar";

const ColorPopover = styled.div<MenuButtonProps>`
    position: absolute;
    margin-left: -310px;
    bottom: ${props => props.uiWidth ? `${props.uiWidth * 1.4}px` : `8.4vh`};
`;

const ColorCover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const ChildrenContainer = styled.div`
    width: 100%;
    height: 100%;
`;

const ColorPickerRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0 0 0 1px, rgba(0, 0, 0, 0.15) 0 8px 16px;
    padding: 5px 10px;
    position: relative;
    overflow: hidden;
`;

const ColorPickerColContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 10px;

    > .sketch-picker {
        padding: 0 !important;
        box-shadow: none !important;
    }
`;

const ColorPickerTitle = styled.div`
    margin: 5px 0;
`;

const CloseButton = styled(IconButton)`
    position: absolute;
    top: 10px;
    right: 15px;
`;

interface Props {
    className?: string;
    uiWidth: number;
    filledColor: RGBColor;
    strokeColor: RGBColor;
    onColorChanged(filledColor: RGBColor, strokeColor: RGBColor): void;
}

interface State {
    displayColorPicker: boolean;
}

class ColorPickerButton extends PureComponent<Props, State> {
    public state: State = {
        displayColorPicker: false,
    };

    public render() {
        return (
            <MenuButton uiWidth={this.props.uiWidth} className={this.props.className}>
                <ChildrenContainer title="" onClick={this.toggleColorPicker} >
                    {this.props.children}
                </ChildrenContainer>
                {this.state.displayColorPicker &&
                    <ColorPopover uiWidth={this.props.uiWidth}>
                        <ColorCover onClick={this.closeColorPicker} />
                        <ColorPickerRowContainer>
                            <ColorPickerColContainer>
                                <ColorPickerTitle>Filled Color</ColorPickerTitle>
                                <SketchPicker
                                    color={this.props.filledColor}
                                    onChange={this.changeFilledColorPicker}
                                    onSwatchHover={this.hoverFilledColorPicker}
                                    onChangeComplete={this.completeFilledColorPicker}
                                />
                            </ColorPickerColContainer>
                            <ColorPickerColContainer>
                                <ColorPickerTitle>Stroke Color</ColorPickerTitle>
                                <SketchPicker
                                    color={this.props.strokeColor}
                                    onChange={this.changeStrokeColorPicker}
                                    onSwatchHover={this.hoverStrokeColorPicker}
                                    onChangeComplete={this.completeStrokeColorPicker}
                                />
                            </ColorPickerColContainer>
                            <CloseButton type="button" uiSize={20} onClick={this.closeColorPicker}>
                                <FaTimes />
                            </CloseButton>
                        </ColorPickerRowContainer>
                    </ColorPopover>
                }
            </MenuButton>
        );
    }

    @Bind()
    private toggleColorPicker() {
      this.setState({ displayColorPicker: !this.state.displayColorPicker });
    }

    @Bind()
    private closeColorPicker() {
      this.setState({ displayColorPicker: false });
    }

    @Bind()
    private changeFilledColorPicker(color: ColorResult) {
        this.props.onColorChanged(color.rgb, this.props.strokeColor);
    }

    @Bind()
    private hoverFilledColorPicker(color: ColorResult) {
        // this.props.onColorChanged(color.rgb, this.props.strokeColor);
    }

    @Bind()
    private completeFilledColorPicker(color: ColorResult) {
        this.props.onColorChanged(color.rgb, this.props.strokeColor);
    }

    @Bind()
    private changeStrokeColorPicker(color: ColorResult) {
        this.props.onColorChanged(this.props.filledColor, color.rgb);
    }

    @Bind()
    private hoverStrokeColorPicker(color: ColorResult) {
        // this.props.onColorChanged(this.props.filledColor, color.rgb);
    }

    @Bind()
    private completeStrokeColorPicker(color: ColorResult) {
        this.props.onColorChanged(this.props.filledColor, color.rgb);
    }
}

export default ColorPickerButton;
