import {
    Button,
    Checkbox,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
    PageDimmer,
    ValidationForm,
    ValidationPopupTextArea,
} from "@ramble/ramble-ui";
import React, { ChangeEvent, FormEvent, PureComponent } from "react";
import { cancelUpdateCrisp } from "../api/crisp";
import Bind from "lodash-decorators/bind";
import styled from "../theme";

interface CancellationModalProps {
    /**
     * Modal is active
     */
    active: boolean;
    /**
     * Update password callback
     * @param oldPass
     * @param newPass
     */
    onUpdatePassword?(oldPass: string, newPass: string): Promise<void>;
    /**
     * Request close callback
     */
    onRequestClose(): void;
    handleConfirmCancellation(): void;
    /**
     * Account id
     */
    accountId: number;
}
const ModalCancellation = styled(Modal)`
    width: 600px;
`;

const CancelButton = styled(Button)`
    margin-right: auto !important;
    background-color: #f6f6f6;
    border-radius: 0.5em;
    border: 1px solid #cdcdcd;
    box-shadow: none;
    // width: 200px;
    color: #222222;
    display: block;

    &:hover {
        background-color: #eeeeee;
    }
`;

export const SaveButton = styled(Button)`
    width: 210px;
    box-shadow: none;
    border: 1px solid #c6c6c6;
    border-radius: 0.5em;
    display: block;
`;

const ModalHeaderCancellation = styled(ModalHeader)`
    color: #306f82;
    border-bottom: none;
    padding-bottom: 0;

    div {
        width: 100%;
        border-bottom: 10px solid #e6f1f4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.5rem;
    }

    .modal-title {
        font-size: 26px;
    }
`;

const CancellationCheckbox = styled(Checkbox)`
    > svg {
        width: 25px;
        height: 25px;
    }
`;

const CancellationSpan = styled.span`
    margin: 30px 0 10px;
`;

const CancellationTextArea = styled(ValidationPopupTextArea)`
    border-radius: 0.5em;
    width: 500px;
    height: 150px;
`;
interface State {
    isMemberShipCancel: boolean;
    memberShipCancelReasons: string;
}

export class CancellationModal extends PureComponent<
    CancellationModalProps,
    State
> {
    public state: State = {
        isMemberShipCancel: false,
        memberShipCancelReasons: "",
    };

    public render(): JSX.Element {
        const { active } = this.props;

        return (
            <>
                <PageDimmer uiActive={active} />
                <ModalCancellation
                    uiActive={active}
                    uiOnRequestClose={this.handleClose}
                >
                    <ModalHeaderCancellation>
                        <div>
                            <span className="modal-title">
                                Are you sure you want to cancel?
                            </span>
                        </div>
                    </ModalHeaderCancellation>
                    <ModalContent>
                        <ValidationForm
                            uiReportValidityMode="firstInvalid"
                            uiUseSubmitFailedMessageFromError
                            uiPadding={false}
                            id="unsubscribeForm"
                            autoComplete="off"
                            uiOnSubmit={this.handleUpdate}
                        >
                            <CancellationCheckbox
                                checked={this.state.isMemberShipCancel}
                                onChange={this.handleCheckbox}
                            >
                                Yes, I want to cancel my QUIPA membership.
                            </CancellationCheckbox>
                            {this.state.isMemberShipCancel && (
                                <>
                                    <CancellationSpan>
                                        Please tell us the reason for
                                        cancellation to help us improve.
                                    </CancellationSpan>
                                    <CancellationTextArea
                                        id="cancellation-text-area"
                                        value={
                                            this.state.memberShipCancelReasons
                                        }
                                        onChange={this.handleTextAreaChange}
                                        uiReportOnBlur={false}
                                        required
                                        maxLength={120} 
                                    />
                                </>
                            )}
                        </ValidationForm>
                    </ModalContent>
                    <ModalActions>
                        <CancelButton
                            uiColor="secondary"
                            onClick={this.handleClose}
                        >
                            Keep Membership
                        </CancelButton>
                        <SaveButton
                            form="unsubscribeForm"
                            uiColor="primary"
                            uiDisabled={
                                !this.state.memberShipCancelReasons.length
                            }
                        >
                            Confirm Cancellation
                        </SaveButton>
                    </ModalActions>
                </ModalCancellation>
            </>
        );
    }

    @Bind()
    private handleClose(): void {
        this.setState({ isMemberShipCancel: false }, () =>
            this.props.onRequestClose()
        );
    }

    @Bind()
    private async handleUpdate(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        const { accountId } = this.props;
        await cancelUpdateCrisp(accountId, this.state.memberShipCancelReasons);
        this.setState(
            {
                isMemberShipCancel: false,
                memberShipCancelReasons: "",
            },
            () => this.props.handleConfirmCancellation()
        );
    }

    private handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.currentTarget;
        this.setState({
            isMemberShipCancel: checked,
        });
    };

    private handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.currentTarget;
        this.setState({
            memberShipCancelReasons: value,
        });
    };
}
