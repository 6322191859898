import { FormValidationError } from "@ramble/ramble-ui";
import React, { PureComponent } from "react";
import { connect, DispatchProp } from "react-redux";
import SupplierMoreDetails from "../components/supplier/supplier_more_details";
import BasicDetail from "../components/UI/basic_detail";
import SupplierModal from "../containers/supplier/supplier_modal";
import { RootState, SuppliersModule } from "../redux/modules";
import styled from "../theme";
import { editSupplier, getSupplier } from "../api/supplier";

const SupplierInfo = styled.div`
    height: calc(100vh - 188px);
    overflow: auto;
    width: 100%;
    padding-right: 20px;
`;

export interface SupplierOwnProps {
    supplierId: number;
    onClose(): void;
    onSupplierChanged(supplier: SuppliersModule.Supplier): void;
}

interface SupplierProps extends SupplierOwnProps {
    accountId: number;
}

interface State {
    detailsDisplayed: boolean;
    isEditModalOpen: boolean;
    supplier?: SuppliersModule.Supplier;
}

export class Supplier extends PureComponent<SupplierProps & DispatchProp, State> {
    public state: State = {
        detailsDisplayed: true,
        isEditModalOpen: false,
    };

    public setFocusFirstFieldOnSupplierModal: any;

    public async componentDidMount(): Promise<void> {
        const { supplierId } = this.props;
        const res: any = await getSupplier({supplierId});
        if (res.error) {
            // todo: handle error
            return;
        }
        this.setState({
            supplier: res,
        });
    }

    public render(): JSX.Element {
        const { onClose, accountId } = this.props;
        const { supplier } = this.state;

        if (!supplier) {
            return (
                <SupplierInfo>
                    Loading...
                </SupplierInfo>
            );
        }

        return (
            <SupplierInfo>
                <BasicDetail
                    info={supplier}
                    detailsDisplayed={this.state.detailsDisplayed}
                    onToggleDetails={this.toggleSupplierDetails}
                    onGoBack={onClose}
                    onClick={this.openEditModal}
                />

                {this.state.detailsDisplayed && (
                    <SupplierMoreDetails
                        supplier={supplier}
                        onEditClick={this.openEditModal}
                    />
                )}

                <SupplierModal
                    accountId={accountId}
                    initialValues={supplier}
                    onSave={this.editSupplier}
                    isModalOpen={this.state.isEditModalOpen}
                    onModalClose={this.closeEditModal}
                    resetOnClose
                    setFocusFirstField={fn => this.setFocusFirstFieldOnSupplierModal = fn}
                />
            </SupplierInfo>
        );
    }

    private openEditModal = () => {
        this.setState({
            isEditModalOpen: true,
        }, () => {
            if (this.setFocusFirstFieldOnSupplierModal) {
                this.setFocusFirstFieldOnSupplierModal();
            }
        });
    }

    private toggleSupplierDetails = () => {
        this.setState({ detailsDisplayed: !this.state.detailsDisplayed });
    }

    private closeEditModal = () => {
        this.setState({
            isEditModalOpen: false,
        });
    }

    // private updateNote = (note: string) => {
    //     const { supplier } = this.state;
    //     if (!supplier) {
    //         return;
    //     }
    //     this.editSupplier({
    //         ...supplier,
    //         notes: note,
    //     });
    // }

    private editSupplier = async (e: SuppliersModule.Supplier) => {
        const res = await editSupplier((e));
        if (res.error) {
            if (res.error) {
                if (res.msg === "Supplier name not unique.") {
                  let errors ={displayName: "Display Name already in use"} 
                    throw FormValidationError.fromErrorDictionary(errors);
                } else {
                    throw new Error(res.msg);
                }
            }
        }
        this.setState({
            supplier: e,
        });
        this.props.onSupplierChanged(e);
    }
}

function mapStateToProps(state: RootState, ownProps: SupplierOwnProps): SupplierProps {
    return {
        ...ownProps,
        accountId: state.account.id,
    };
}

export const SupplierContainer = connect(mapStateToProps)(Supplier);
