import {
    Button,
    Modal,
    ModalActions,
    ModalCloseIcon,
    ModalContent,
    ModalHeader,
    PageDimmer,
} from "@ramble/ramble-ui";
import Bind from "lodash-decorators/bind";
import React, { PureComponent } from "react";
import { FaCheck } from "react-icons/fa";
import { PlanInfo } from "../redux/modules/account";
import styled from "../theme";

export const Content = styled.h4`
    color: #000;
    font-weight: 400;
    margin-top: 0;
`;

const PricingPlansBox = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
    margin-bottom: 15px;
    color: #3b97b1;

    > div:first-child {
        margin-top: 10px;
        font-size: 50px;
    }

    > div:last-child {
        font-size: 15px;
    }
`;

const PlanListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    > div {
    margin-right: 60px;
    }

    > div:last-child {
        margin-right: 0;
    }
`;

const PlanWrapper = styled.div`
    display: flex;
    flex-direction: column;

    > h4 {
        margin: 5;
    }
`;

export interface ChangePlanModalProps {
    /**
     * Modal is active
     */
    active: boolean;
    /**
     * Plans
     */
    plans: PlanInfo[];
    /**
     * Current Plan
     */
    currentPlan?: string;
    /**
     * Request close callback
     */
    onRequestClose(): void;
    /**
     * Change Plan callback
     * @param planType
     */
    onChangePlan(planType: string): Promise<void>;
}

interface State {
    updating: boolean;
}

export class ChangePlanModal extends PureComponent<ChangePlanModalProps, State> {
    public state: State = {
        updating: false,
    };

    public render(): JSX.Element {
        const { active, currentPlan, plans } = this.props;
        return (
            <>
                <PageDimmer uiActive={active}/>
                <Modal
                    uiActive={active}
                    uiOnRequestClose={this.handleClose}
                >
                    <ModalHeader>
                        Change Plan
                        <ModalCloseIcon onClick={this.handleClose} />
                    </ModalHeader>
                    <ModalContent>
                        <PlanListWrapper>
                        {plans.map(plan => {
                            const amount = plan.amount / 100;
                            const isCurrentPlan = plan.planType === currentPlan;
                            return (
                                <>
                                    <PlanWrapper>
                                        <PricingPlansBox>
                                            <div>${amount.toFixed(2)}</div>
                                            <div>/ MONTH</div>
                                        </PricingPlansBox>
                                        <Content><FaCheck /> Unlimited Client Projects</Content>
                                        <Content><FaCheck /> Unlimited Work Orders</Content>
                                        <Content><FaCheck /> Cancel Any Time</Content>
                                        { !isCurrentPlan && <Button
                                            type="button"
                                            uiColor="primary"
                                            uiDisabled={this.state.updating}
                                            onClick={() => this.handleChangePlan(plan.planType)}
                                        >
                                            Change Plan
                                        </Button> }
                                    </PlanWrapper>
                                </>
                            );
                        })}
                        </PlanListWrapper>
                    </ModalContent>
                    <ModalActions>
                        <Button
                            uiColor="secondary"
                            uiDisabled={this.state.updating}
                            onClick={this.handleClose}
                        >
                            Cancel
                        </Button>
                    </ModalActions>
                </Modal>
            </>
        );
    }

    @Bind()
    private handleClose(): void {
        if (this.state.updating) {
            return;
        }
        this.props.onRequestClose();
    }

    private handleChangePlan = async (planType: string) => {
        if (this.state.updating) {
            return;
        }
        this.setState({
            updating: true,
        });

        try {
            await this.props.onChangePlan(planType);
            this.props.onRequestClose();
        } finally {
            this.setState({
                updating: false,
            });
        }
    }
}
