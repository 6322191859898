import { Popup, PopupProps } from "@ramble/ramble-ui";

import { Bind } from "lodash-decorators/bind";
import React from "react";
import ReactDOM from "react-dom";
import { isElementContainsAnother } from "../../utils/dom";

/**
 * Composition props for other component
 */
export interface WithSimplePopupCompositionProps {
    /**
     * Mouse enter handler
     *
     * @param event
     */
    onMouseEnter?(event: React.MouseEvent<any>): void;
    /**
     * Mouse leave handler
     *
     * @param event
     */
    onMouseLeave?(event: React.MouseEvent<any>): void;
    /**
     * Click handler
     *
     * @param event
     */
    onClick?(event: React.MouseEvent<any>): void;
    /**
     * Touch end handler
     *
     * @param event
     */
    onTouchEnd?(event: React.TouchEvent<any>): void;
}

export interface WithSimpleInfoPopupProps {
    /**
     * Additional class name to apply to popup
     */
    className?: string;
    /**
     * Delay appearing of popup after hovering into element. In ms
     * Applicable only when popupOn: "hover"
     * @default 200
     */
    appearDelay?: number;
    /**
     * Popup position
     * @default "top center"
     */
    popupPosition?: PopupProps["uiPosition"];
    /**
     * Popup text
     */
    popupContent?: React.ReactChild;
    /**
     * Close button
     */
    closeButton?: React.ReactChild;
    /**
     * Popup is inverted
     * @default true
     */
    inverted?: boolean;
    /**
     * Single child is required
     */
    children: React.ReactChild;
    /**
     * Display popup on hover/click
     * @default "hover"
     */
    popupOn?: "hover" | "click" | "disabled";
    /**
     * Close popup on element click
     * @default true
     */
    closeOnClick?: boolean;
    /**
     * Popup behavior on touch events.
     * * click - display popup on touch (click),
     * * disable - disables popups when popup when popupOn is hover and after touch
     * @default "disable"
     */
    touchDeviceBehavior?: "click" | "disable";
    /**
     * Callback for opening popup
     */
    onPopupOpen?(): void;
    /**
     * Callback for closing popup
     */
    onPopupClose?(): void;
}

export interface WithSimpleInfoPopupState {
    /**
     * Popup active
     */
    active: boolean;
}

export class WithSimpleInfoPopup extends React.PureComponent<WithSimpleInfoPopupProps, WithSimpleInfoPopupState> {
    public static defaultProps: Partial<WithSimpleInfoPopupProps> = {
        appearDelay: 200,
        inverted: true,
        popupOn: "hover",
        touchDeviceBehavior: "disable",
        closeOnClick: true,
    };

    public state: WithSimpleInfoPopupState = {
        active: false,
    };

    /**
     * Reference to children element
     */
    private ref: HTMLElement | null;

    /**
     * Touch timer
     */
    private touchTimer?: any;

    /**
     * Appear timer
     */
    private appearTimer?: number;

    public componentDidMount(): void {
        // Will find first instance
        this.ref = ReactDOM.findDOMNode(this) as HTMLElement;
    }

    public componentWillUnmount(): void {
        if (this.touchTimer) {
            clearTimeout(this.touchTimer);
        }
        if (this.appearTimer) {
            clearTimeout(this.appearTimer);
        }
    }

    public render(): JSX.Element | null {
        const { children, className, popupPosition, inverted, popupContent, onPopupClose, onPopupOpen, closeButton } = this.props;
        const { active } = this.state;
        const child = React.Children.only(children);
        if (!React.isValidElement<any>(child)) {
            return null;
        }
        const closeBtn = React.Children.only(closeButton);
        if (!React.isValidElement<any>(closeBtn)) {
            return null;
        }
        return (
            <>
                {React.cloneElement(child, {
                    ...child.props,
                    key: "element",
                    // Rebind handlers
                    onMouseEnter: this.onElementMouseEnter,
                    onMouseLeave: this.onElementMouseLeave,
                    onTouchEnd: this.onElementTouchEnd,
                    onClick: this.onElementClick,
                })}
                <Popup
                    key="popup"
                    uiActive={active}
                    uiPosition={popupPosition}
                    className={className}
                    uiInverted={inverted}
                    uiTargetEl={this.ref}
                    uiOnRequestClose={this.onPopupRequestClose}
                    onClick={this.onPopupClick}
                    uiOnOpen={onPopupOpen}
                    uiOnClose={onPopupClose}
                >
                    {React.cloneElement(closeBtn, {
                        ...closeBtn.props,
                        key: "element",
                        // Rebind handlers
                        onClick: this.onCloseButtonClick,
                    })}
                    {popupContent}
                </Popup>
            </>
        );
    }

    /**
     * Popup outside click
     * @param [event]
     */
    @Bind()
    private onPopupRequestClose(event?: Event): void {
        if (event && isElementContainsAnother(this.ref, event.target as HTMLElement)) {
            return;
        }
        this.setState({ active: false });
    }

    /**
     * Close button click
     * @param [event]
     */
    @Bind()
    private onCloseButtonClick(event?: React.MouseEvent): void {
        this.setState({ active: false });
    }

    /**
     * Popup element clicked
     * @param event
     */
    @Bind()
    private onPopupClick(event: React.MouseEvent<any>): void {
        const { closeButton } = this.props;
        if (closeButton) {
            return;
        }
        // One case if when popupOn = click and clicked on the popup
        this.setState({ active: false });
    }

    /**
     * Wrapped mouse enter callback for child element
     * @param event
     */
    @Bind()
    private onElementMouseEnter(event: React.MouseEvent<any>): void {
        const { popupOn, appearDelay } = this.props;
        if (popupOn === "hover" && !this.touchTimer) {
            if (this.appearTimer) {
                clearTimeout(this.appearTimer);
            }
            this.appearTimer = setTimeout(() => {
                this.setState({ active: true });
            }, appearDelay);
        }
        const child = React.Children.only(this.props.children);
        if (React.isValidElement<any>(child) && child.props.onMouseEnter) {
            child.props.onMouseEnter(event);
        }
    }

    /**
     * Wrapped mouse leave callback for child element
     * @param event
     */
    @Bind()
    private onElementMouseLeave(event: React.MouseEvent<any>): void {
        const { popupOn } = this.props;
        if (popupOn === "hover") {
            if (this.appearTimer) {
                clearTimeout(this.appearTimer);
            }
            this.setState({ active: false });
        }
        const child = React.Children.only(this.props.children);
        if (React.isValidElement<any>(child) && child.props.onMouseLeave) {
            child.props.onMouseLeave(event);
        }
    }

    /**
     * Wrapped click callback for child element
     * @param event
     */
    @Bind()
    private onElementClick(event: React.MouseEvent<any>): void {
        event.stopPropagation();
        const { popupOn, closeOnClick, touchDeviceBehavior } = this.props;
        if (this.state.active && closeOnClick) {
            this.setState({ active: false });
        } else if (!this.state.active && (popupOn === "click" || touchDeviceBehavior === "click")) {
            this.setState({ active: true });
        }
        const child = React.Children.only(this.props.children);
        if (React.isValidElement<any>(child) && child.props.onClick) {
            child.props.onClick(event);
        }
    }

    /**
     * Wrapped touch end callback for child element
     * @param event
     */
    @Bind()
    private onElementTouchEnd(event: React.TouchEvent<any>): void {
        if (this.touchTimer) {
            clearTimeout(this.touchTimer);
        }
        this.touchTimer = setTimeout(() => {
            this.touchTimer = undefined;
        }, 300);

        const child = React.Children.only(this.props.children);
        if (React.isValidElement<any>(child) && child.props.onTouchEnd) {
            child.props.onTouchEnd(event);
        }
    }
}
