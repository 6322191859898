import axiosInstance from "./axios-instance";
// import axios from "axios";

const updateCrispProfile = async (accountId: number, accountPayload: any) => {
    try {
        const response = await axiosInstance.post(
            "/crisp/update-crisp-profile",
            {
                accountId,
                accountPayload,
            },
        );
        return response.data;
    } catch (error) {
        console.log("Error updating crisp profile", error);
    }
};

const updateCrispEmail = async (accountId: number, accountPayload: any) => {
    try {
        const response = await axiosInstance.post(
            "/crisp/update-crisp-email",
            {
                accountId,
                accountPayload,
            },
        );
        return response.data;
    } catch (error) {
        console.log("Error updating crisp email", error);
    }
};

const cancelUpdateCrisp = async (accountId: number, message: string) => {
    try {
        const response = await axiosInstance.post(
            "/crisp/cancel-update-crisp",
            {
                accountId,
                message,
            },
        );
        return response.data;
    } catch (error) {
        console.log("Error updating crisp profile", error);
    }
};

const getCrispProfile = async (accountId: number) => {
    try {
        const response = await axiosInstance.post(
            "/crisp/get-crisp-profile",
            {
                accountId,
            },
        );
        return response.data;
    } catch (error) {
        console.log("Error updating crisp profile", error);
    }
};

const createCrispProfile = async (accountId: number) => {
    try {
        const response = await axiosInstance.post(
            "/crisp/create-crisp-profile",
            {
                accountId,
            },
        );
        return response.data;
    } catch (error) {
        console.log("Error updating crisp profile", error);
    }
};

export { 
    updateCrispProfile,
    cancelUpdateCrisp,
    getCrispProfile,
    createCrispProfile,
    updateCrispEmail
};
