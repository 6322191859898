import { ActionDescriptor } from "../../common";
import { AccountInfo, BillingHistory, ChangeableAccountInfo, PlanInfo, SubscriptionInfo } from "./interfaces";

export const GET_ACCOUNT = "account/GET";
/**
 * Get account
 */
export type GetAccountAction = ActionDescriptor<typeof GET_ACCOUNT,
    {
        accountId: number;
    },
    AccountInfo
>;

export const UPDATE_ACCOUNT = "account/UPDATE";

/**
 * Update account settings/billing/card
 */
export type UpdateAccountAction = ActionDescriptor<typeof UPDATE_ACCOUNT,
    ChangeableAccountInfo & { accountId: number }, {}>;

export const PAY_ACCOUNT = "account/PAY";

/**
 * Pay account. For now just charge $1 from assigned card
 */
export type PayAccountAction = ActionDescriptor<typeof PAY_ACCOUNT,
    {
        /**
         * Account id
         */
        accountId: number;
    }, {}>;

export const GET_ACCOUNT_BILLING_HISTORY = "account/BILLING_HISTORY";

/**
 * Get billing history for the account
 */
export type GetAccountBillingHistoryAction = ActionDescriptor<typeof GET_ACCOUNT_BILLING_HISTORY,
    {
        /**
         * Account id
         */
        accountId: number;

    }, BillingHistory[]>;

export const UPLOAD_IMAGE = "account/UPLOAD_IMAGE";

export type UploadImageAction = ActionDescriptor<typeof UPLOAD_IMAGE,
    {
        /**
         * Account id
         */
        accountId: number;
        /**
         * Image blob/file
         */
        data: Blob;
    },
    {
        /**
         * Image url
         */
        url: string;
    }
>;

export const SUBSCRIBE_ACCOUNT = "account/SUBSCRIBE";

/**
 * Subscribe account.
 */
export type SubscribeAccountAction = ActionDescriptor<typeof SUBSCRIBE_ACCOUNT,
    {
        /**
         * Account id
         */
        accountId: number;
        /**
         * Payment method id
         */
        paymentMethod?: string;
        /**
         * Plan Type
         */
        planType?: string;
        /**
         * Coupon Code
         */
        couponCode?: string;
        /**
         * Is First Subscribe
         */
        isFirstSubscribe?: boolean;
    }, {}>;

export const BETA_SUBSCRIBE_ACCOUNT = "account/BETA_SUBSCRIBE";

/**
 * Beta Subscribe account.
 */
export type BetaSubscribeAccountAction = ActionDescriptor<typeof BETA_SUBSCRIBE_ACCOUNT,
    {
        /**
         * Account id
         */
        accountId: number;
    }, {}>;

export const UNSUBSCRIBE_ACCOUNT = "account/UNSUBSCRIBE";

/**
 * Unsubscribe account.
 */
export type UnsubscribeAccountAction = ActionDescriptor<typeof UNSUBSCRIBE_ACCOUNT,
    {
        /**
         * Account id
         */
        accountId: number;
    }, {}>;

export const GET_CURRENT_PLAN_INFO = "account/GET_CURRENT_PLAN_INFO";

/**
 * Get Current Plan Information
 */
export type GetCurrentPlanInfoAction = ActionDescriptor<typeof GET_CURRENT_PLAN_INFO,
    {
        /**
         * Account id
         */
        accountId: number;
    }, SubscriptionInfo | undefined>;

export const GET_PLANS = "account/GET_PLANS";

/**
 * Get Plans
 */
export type GetPlansAction = ActionDescriptor<typeof GET_PLANS,
    {
        /**
         * Account id
         */
        accountId: number;
    }, PlanInfo[]>;

export interface AccountActionsMap {
    [GET_ACCOUNT]: GetAccountAction;
    [UPDATE_ACCOUNT]: UpdateAccountAction;
    [PAY_ACCOUNT]: PayAccountAction;
    [GET_ACCOUNT_BILLING_HISTORY]: GetAccountBillingHistoryAction;
    [UPLOAD_IMAGE]: UploadImageAction;
    [SUBSCRIBE_ACCOUNT]: SubscribeAccountAction;
    [BETA_SUBSCRIBE_ACCOUNT]: BetaSubscribeAccountAction;
    [UNSUBSCRIBE_ACCOUNT]: UnsubscribeAccountAction;
    [GET_CURRENT_PLAN_INFO]: GetCurrentPlanInfoAction;
    [GET_PLANS]: GetPlansAction;
}