import ClientHoverBox, { getParentClients } from "../clients/client_hover_box";
import React, { FC, memo } from "react";
import { AvatarImage } from "./avatar";
import { ClientModule } from "../../redux";
import MoreDetailsButton from "./buttons/more-details-button";
import styled from "../../theme";
import EditSvgIcon from "../../assets/icons/pencil_edit.svg"

const Heading = styled.div`
    color: #4a4a4a;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    height: 100%;

    & .actions__container{
        display: flex;
        align-items: center;
        gap: 10px;
    }
`;

const DetailsContainer = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    font-size: 14px;
    padding: 5px 0;
    margin-bottom: 5px;
    background-color: #fff;
    z-index: 99;
`;

const EditIconContainer = styled.div`
    cursor: pointer;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
`;

const StyledEditIcon = styled(EditSvgIcon)`
    width: 24px;
    height: 24px;
`;

const DetailsBox = styled.div`
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
`;

const Avatar = styled(AvatarImage)`

`;

const NameSeperator = styled.span`
    font-size: 24px;
    color: #9e999f;
    padding: 0 1px;
`;

const Name = styled.a`
    color: #222222;
    font-size: 18px;

    &:hover {
        color: #41a1dc;
    }
`;

const VerticalCenterContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;
`;

const VerticalBorderImage = styled.div`
    border-left: 2px solid #E2E2E2;
    height: 58px;
    margin-left: 30px;
    margin-right: 24px;
`;

const VerticalBorder = styled.div`
    border-left: 1px solid #E2E2E2;
    height: 22px;
    margin: 0;
`;

export interface BasicDetailInfo {
    id: number;
    displayName: string;
    avatarId?: string;
    firstName?: string;
    lastName?: string;
    parent?: ClientModule.BasicClientInfo;
}

export interface DetailsProps {
    info: BasicDetailInfo;
    detailsDisplayed: boolean;
    onToggleDetails(): void;
    onGoBack(): void;
    onAncestorClick?(id: number): void;
    onClick?(id: number): void;
}

const BasicDetail: FC<DetailsProps> = memo((props) => (
    <DetailsContainer>
        <Avatar
            avatarId={props.info.avatarId}
            firstName={props.info.firstName}
            lastName={props.info.lastName}
            width={52}
            height={52}
            fontSize={24}
        />
        <VerticalBorderImage />

        <DetailsBox>
            <Heading>
                <VerticalCenterContainer>
                    {props.info.parent &&
                        getParentClients(props.info.parent).map(
                            (ancestorClient) => (
                                <>
                                    <ClientHoverBox
                                        key={ancestorClient.id}
                                        client={ancestorClient}
                                        onClick={props.onAncestorClick}
                                    />
                                    <NameSeperator>|</NameSeperator>
                                </>
                            )
                        )}
                    <Name>{`${props.info.displayName}`}</Name>
                </VerticalCenterContainer>

                <div className="actions__container">
                    <MoreDetailsButton
                        isDetailsDisplayed={props.detailsDisplayed}
                        onToggleClientDetails={props.onToggleDetails}
                    />
                    <VerticalBorder />
                    <EditIconContainer onClick={() => props.onClick && props.onClick(props.info.id)}>
                        <StyledEditIcon />
                    </EditIconContainer>
                </div>

            </Heading>
        </DetailsBox>
    </DetailsContainer>
));

export default BasicDetail;
