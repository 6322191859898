import * as UILoggerModule from "../redux/modules/ui-logger";

import React, { useEffect } from "react";

import { Dispatch } from "redux";
import { FallbackProps } from "react-error-boundary";
import { connect } from "react-redux";

const ErrorFallback = ({
    error,
    resetErrorBoundary,
    dispatch,
}: FallbackProps & { dispatch: Dispatch }) => {
    useEffect(() => {
        if (error) {
            dispatch(UILoggerModule.logError(error));
        }
    }, [error, dispatch]);

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            {error && <pre style={{ color: "red" }}>{error.message}</pre>}
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    );
};

export default connect(null)(ErrorFallback);
