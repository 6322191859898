import React, { ComponentType, forwardRef, SFC } from "react";

export const SPROP: unknown = undefined;

/**
 * Create component with filtered out props
 * @param Component Original component
 * @param styledProps Add styled props to component props type
 * @example
 *  const Elem = withStyledProps(Comp, {
 *    uiColor: SPROP as string,
 *    uiAnother: SPROP as "left" | "right",
 *  });
 *  const StyledComp = styled(Elem)`
 *    color: ${props => props.uiColor};
 *  `;
 */
export function withStyledProps<P = {}, CP = {}>(Component: ComponentType<CP>, styledProps: P): SFC<CP & P> {
    const NewComponent = forwardRef((props, ref) => {
        const newProps = { ...props };
        for (const key of Object.keys(styledProps)) {
            delete newProps[key];
        }
        const C = Component as any;
        return (<C {...newProps} ref={ref} />);
    });
    return NewComponent as any;
}

/**
 * Clean ui props. Note: This function mutates object
 * @param props Props
 */
export function cleanUiProps<T extends object>(props: T): T {
    for (const key of Object.keys(props)) {
        if (key.startsWith("ui")) {
            delete props[key];
        }
    }
    return props;
}
