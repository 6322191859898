import {GridTable as GridTableE, GridTableBody as GridTableBodyE, GridTableCell, GridTableRow} from "@ramble/ramble-ui";
import React, { PureComponent } from "react";
import {AdminRow} from "../components/admin/admin_row";
import Sort from "../components/UI/sort";
import { AdminModule } from "../redux";
import { UserSubscriptionInfo } from "../redux/modules/admin";
import styled from "../theme";

const AdminContainer = styled.div`
    /* ignore */
`;

const GridTable = styled(GridTableE)`
    border: 0;
`;

const GridTableBody = styled(GridTableBodyE)`
    height: calc(100vh - 208px);
    overflow: auto;
`;

const Row = styled(GridTableRow)`
    grid-template-columns: 70px 70px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "admin accountId companyName email planName workOrder totalClients totalSuppliers lastLogin";
    font-weight: 600;
    color: #4a4a4a;
    text-align: left;
    background-color: #ecf6f9;

    // &:hover {
    //     background-color: #c1dcec;
    // }
`;

export interface AdminTableProps {
    sort: "asc" | "desc";
    users: AdminModule.UserInfoForAdmin[];
    currentUserId: number;
    onSortingChanged(sort: "asc" | "desc"): void;
    onUserRoleChanged(userId: number, role: "admin" | "office_worker"): void;
    onGetUserSubscriptionHistory(accountId: number): Promise<UserSubscriptionInfo[]>;
}

// tslint:disable-next-line:no-empty-interface
interface State { }

export class AdminTable extends PureComponent<AdminTableProps> {
    public state: State = {
    };

    public render(): JSX.Element {
        const { users, sort } = this.props;
        return (
            <AdminContainer>
                <GridTable>
                    <Row>
                        <GridTableCell uiGridArea="admin">ADMIN</GridTableCell>
                        <GridTableCell uiGridArea="accountId">ID</GridTableCell>
                        <GridTableCell uiGridArea="companyName">COMPANY NAME</GridTableCell>
                        <GridTableCell uiGridArea="email" onClick={this.changeSorting}>
                            <Sort direction={sort} name="EMAIL"/>
                        </GridTableCell>
                        <GridTableCell uiGridArea="planName">PLAN NAME</GridTableCell>
                        <GridTableCell uiGridArea="workOrder">WO COUNT</GridTableCell>
                        <GridTableCell uiGridArea="totalClients">TOTAL CLIENTS</GridTableCell>
                        <GridTableCell uiGridArea="totalSuppliers">TOTAL SUPPLIERS</GridTableCell>
                        <GridTableCell uiGridArea="lastLogin">LAST LOGIN</GridTableCell>
                    </Row>
                    <GridTableBody>
                        {users.map(user => (
                            this.renderUserRow(user)
                        ))}
                    </GridTableBody>
                </GridTable>
            </AdminContainer>
        );
    }

    public renderUserRow(user: AdminModule.UserInfoForAdmin): JSX.Element {
        const { onUserRoleChanged, currentUserId, onGetUserSubscriptionHistory } = this.props;
        return (
            <AdminRow
                key={user.id}
                user={user}
                currentUserId={currentUserId}
                onUserRoleChanged={onUserRoleChanged}
                onGetUserSubscriptionHistory={onGetUserSubscriptionHistory}
            />
        );
    }

    private changeSorting = () => {
        const { onSortingChanged, sort } = this.props;
        onSortingChanged(sort === "asc" ? "desc" : "asc");
    }
}
