/// <reference types="stripe-v3" />
import { dimmable, Dimmer, FormField, Input, Loader, ValidationForm } from "@ramble/ramble-ui";
import Bind from "lodash-decorators/bind";
import React, { FormEvent, PureComponent, ReactNode } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import styled from "../../theme";
// import loadScript from "../../utils/load_script";
import { CreditCardInput } from "./credit_card_input";
import { loadStripe } from "@stripe/stripe-js";
export interface CreditCardFormProps {
    /**
     * Stripe config key
     */
    configKey: string;
    /**
     * Render loader while stripe is being initialized
     * @default true
     */
    renderLoader?: boolean;
    /**
     * Additional class name
     */
    className?: string;
    /**
     * Children
     */
    children?: ReactNode;
    /**
     * Card token
     * @param token Stripe card token
     * @param digits New card digits
     */
    onAddCard(token: string): Promise<void>;
    onUpdateCardError(error: string): void;
}

const LoadingContent = styled.div`
    min-height: 200px;
    width: 100%;
    ${dimmable};
`;

interface State {
    /**
     * Stripe error
     */
    stripeInitError?: string;
    /**
     * Stripe lib
     */
    stripe?: any;
}

export class CreditCardForm extends PureComponent<CreditCardFormProps, State> {
    public static defaultProps: Partial<CreditCardFormProps> = {
        renderLoader: true,
    };

    public state: State = {};

    /**
     * Card element reference
     */
    private cardElement?: HTMLElement;

    public async componentDidMount(): Promise<void> {
        const { configKey } = this.props;
        try {
            const stripe = await loadStripe(configKey);
            if(stripe){
                this.setState({
                    stripe: stripe,
                });
            }
            
        } catch (e) {
            this.setState({
                stripeInitError: e.message,
            });
        }
    }

    public render(): JSX.Element {
        return this.state.stripe ? this.renderForm() : this.renderLoading();
    }

    public renderLoading(): JSX.Element {
        const { renderLoader, className } = this.props;
        return (
            <LoadingContent className={className}>
                {renderLoader && (
                    <Dimmer uiActive uiColor="transparent"><Loader /></Dimmer>
                )}
            </LoadingContent>
        );
    }

    public renderForm(): JSX.Element {
        const { className, children } = this.props;
        return (
            <StripeProvider stripe={this.state.stripe!}>
                <Elements
                    fonts={[
                        {
                            cssSrc: "https://fonts.googleapis.com/css?family=Poppins:200,400",
                        },
                    ]}
                >
                    <ValidationForm
                        className={className}
                        uiDimmerColor="transparent"
                        uiDimmerBlurring={false}
                        uiUseSubmitFailedMessageFromError
                        uiOnSubmit={this.submit}
                        uiSubmitFailedIcon
                        uiReportValidityMode="firstInvalid"
                        id="cardform"
                    >
                        <FormField>
                            <CreditCardInput
                                hidePostalCode
                                onReady={this.handleElementRef}
                            />
                        </FormField>
                        <FormField>
                            <Input
                                placeholder="Cardholder name"
                                id="name"
                                name="name"
                                type="search"
                                autoComplete="off"
                                role="presentation"
                            />
                        </FormField>
                        {children}
                    </ValidationForm>
                </Elements>
            </StripeProvider>
        );
    }

    /**
     * Get stripe element reference to card input
     */
    @Bind()
    private handleElementRef(el: HTMLElement): void {
        this.cardElement = el;
    }

    @Bind()
    private async submit(e: FormEvent<HTMLFormElement>): Promise<void> {
        const { onAddCard } = this.props;
        
        if (!this.state.stripe || !this.cardElement) {
            throw new Error("Stripe initialization failed");
        }

        // tslint:disable-next-line:no-string-literal
        const name = (e.currentTarget.elements["name"] as HTMLInputElement).value.trim();

        const { error, token } = await this.state.stripe.createToken(this.cardElement as any, name ? { name } : undefined);
        if (error || !token || !token.card) {
            this.props.onUpdateCardError(error && error.message ? error.message : "Unable to create the card token");
            throw new Error(error && error.message ? error.message : "Unable to create the card token");
        }

        await onAddCard(token.id);
    }
}
