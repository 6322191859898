import React, { SFC } from "react";
import { connect, DispatchProp } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "styled-components";

import { NotificationsModule, RootState } from "../../redux";
import { Notification } from "./notification";

const StackContainer: SFC<{ component: string }> = styled(TransitionGroup)`
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    display: flex;
    flex-flow: column-reverse nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: hidden;
    padding: 1rem 24px 1rem 0;
` as any;

const TransitionNotification = styled(Notification)`
    border: 1px solid #afb0b0;
    &.transition-enter {
        transform: translateX(250px);
    }

    &.transition-enter-active {
        transform: translateX(0);
        transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    }

    &.transition-exit {
        opacity: 1;
    }

    &.transition-exit-active {
        opacity: 0;
        transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    }
`;

/**
 * Notification stack properties
 */
export interface NotificationStackProps {
    /**
     * List of notifications
     */
    notifications: NotificationsModule.State;
}

/**
 * Notification stack component
 */
export class NotificationStack extends React.PureComponent<
    NotificationStackProps & DispatchProp
> {
    public render(): JSX.Element {
        const { notifications } = this.props;
        return (
            <StackContainer component="div">
                {notifications.map((notification) => {
                    return (
                        <CSSTransition
                            key={notification.id}
                            mountOnEnter
                            unmountOnExit
                            timeout={100}
                            classNames="transition"
                        >
                            <TransitionNotification
                                id={notification.id}
                                message={notification.message}
                                subMessage={notification.subMessage}
                                emphasis={notification.type}
                                onClick={this.onNotificationClick}
                            />
                        </CSSTransition>
                    );
                })}
            </StackContainer>
        );
    }

    /**
     * Notification click
     */
    private onNotificationClick = (id: number): void => {
        const { dispatch } = this.props;
        dispatch(NotificationsModule.removeNotification(id));
    };
}

/**
 * State mapper
 *
 * @param state
 * @returns
 */
/* istanbul ignore next */
function mapStateToProps(state: RootState): NotificationStackProps {
    return {
        notifications: state.notifications,
    };
}

export const NotificationStackContainer =
    connect(mapStateToProps)(NotificationStack);
