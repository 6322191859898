import styled, { css } from "../../../theme";

interface ContainerProps {
    uiWidth?: string;
    uiHeight?: string;
    uiFlex?: string;
    uiBorderColor?: string;
    uiMinWidth?: string;
}

export const Separator = styled.div`
    min-height: 20px;
`;

export const HContainer = styled.div<ContainerProps>`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    ${props => props.uiFlex && css`
        flex: ${props.uiFlex};
    `};
`;

export const VContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    min-width: 0;
    ${props => props.uiWidth && css`
        width: ${props.uiWidth};
    `};
    ${props => props.uiFlex && css`
        flex: ${props.uiFlex};
    `};
    ${props => props.uiMinWidth && css`
        min-width: ${props.uiMinWidth};
    `};
`;

export const VCenterContainer = styled.div<ContainerProps>`
    align-items: center;
    display: grid;
    ${props => props.uiFlex && css`
        flex: ${props.uiFlex};
    `};
`;

export const Container = styled(HContainer)`
    margin-bottom: 5px;
    padding: 5px 25px 5px 25px;
    font-size: 14px;
    justify-content: space-around;
    border-bottom: 11px solid #cbe3f0;
    overflow: auto;
`;

export const Space = styled.div`
    flex: 1;
`;

export const VSpace = styled.div<ContainerProps>`
    width: 100%;
    display: flex;
    ${props => props.uiHeight && css`
        height: ${props.uiHeight};
    `};
`;

export const VBorder = styled.div<ContainerProps>`
    width: 100%;
    display: flex;
    ${props => props.uiHeight && css`
        height: ${props.uiHeight};
    `};
    ${props => props.uiBorderColor && css`
        background: ${props.uiBorderColor};
    `};
`;

export const HSpace = styled.div<ContainerProps>`
    display: flex;
    ${props => props.uiWidth && css`
        width: ${props.uiWidth};
    `};
`;

export const HBorder = styled.div<ContainerProps>`
    display: flex;
    ${props => props.uiWidth && css`
        width: ${props.uiWidth};
    `};
    ${props => props.uiBorderColor && css`
        background: ${props.uiBorderColor};
    `};
`;
