import React, { forwardRef, HTMLAttributes } from "react";
import List, { ListProps, ListUIProps } from "../list/list";
import styled, { css } from "../theme/styled";

export interface MenuUIProps extends ListUIProps {
    /**
     * True if should use inverted colors
     */
    uiInverted?: boolean;
}

export type MenuProps = HTMLAttributes<HTMLDivElement> & MenuUIProps;

const MenuComponent = forwardRef<HTMLDivElement, MenuProps>((props, ref) => <List as="div" {...props as ListProps} ref={ref as any} />);
const Menu = styled(MenuComponent) `
    padding-top: 0;
    padding-bottom: 0;
    ${({ theme, uiInverted }) => uiInverted && css`
        color: ${theme.getContrastTextColor(theme.colors.invertedBackground)};
        background-color: ${theme.colors.invertedBackground};
    `};
`;

/**
 * Menu is the subset of simple list, but can be inverted and always selectable
 */
export default Menu;
