import { PricingSimpleItem } from "./drapery-item-details-fields-reducer";
import { FabricsOrLiningsItemDetailsFields } from "./fabrics-or-linings-item-details-fields-reducer";

export interface FormFields {
    pleatTypesWithPricing: PricingSimpleItem[];
    id: number;
    avatarId: string | null;
    name: string;
    itemNumber: string; //assuming alphanumeric
    itemTypeId: number;
    categoryId: number;
    parentCategoryId?: number;
    categoryName: string;
    supplierId: number;
    supplierName: string;
    salesDescription: string;
    orderingDescription: string;
    notes: string;
    isActive: boolean;
    isDefault: boolean;
    cost: number | "";
    msrp: number | "";
    retailPricingMethod: string;
    retailPricingValue: number | "";
    tradePricingMethod: string;
    tradePricingValue: number | "";
    shippingCost: number | "";
    handlingFeeMethod: string;
    handlingFeeValue: number | "";
    columnType?: string | "";
    itemId?: number;
    itemClassId?: number;
    fabricsOrLiningsItemDetails?: FabricsOrLiningsItemDetailsFields;
    isPartialWidthRoundingOn?: boolean;
    partialWidthRoundUpTypeId?: number;
    embellishmentsWithPricing?: any;
    addOnsWithPricing?: any;
    specialityFabricsMarkUpPercentage?: string;
    draperyPinningWidthPricing?: any;
    pleatTypeListNew: any[];
}

export type FormFieldsPartial = Partial<FormFields>;

export type FormFieldsAction =
    | { type: "updateFormFields"; formFieldUpdates: FormFieldsPartial }
    | { type: "resetFormFields" };

export const formFieldsInitialState: FormFields = {
    id: 0,
    avatarId: "",
    name: "",
    itemNumber: "",
    itemTypeId: 0,
    categoryId: 0,
    categoryName: "",
    supplierId: 0,
    supplierName: "",
    salesDescription: "",
    orderingDescription: "",
    notes: "",
    isActive: true,
    isDefault: false,
    cost: "",
    msrp: "",
    retailPricingMethod: "",
    retailPricingValue: "",
    tradePricingMethod: "",
    tradePricingValue: "",
    shippingCost: "",
    handlingFeeMethod: "",
    handlingFeeValue: "",
    pleatTypesWithPricing: [],
    pleatTypeListNew: [],
};

export const formFieldsReducer = (
    state: FormFields,
    action: FormFieldsAction
): FormFields => {
    switch (action.type) {
        case "updateFormFields": {
            return {
                ...state,
                ...action.formFieldUpdates,
            };
        }
        case "resetFormFields": {
            return formFieldsInitialState;
        }
        default: {
            throw new Error("Invalid action type");
        }
    }
};
