import styled, { css, theme } from "../../../theme";
import React from "react";
import IconButton from '../buttons/icon-button'
import { FaPlusCircle } from "react-icons/fa";

interface SectionBorderProps {
    rightBorder?: boolean;
}

interface SectionProps extends SectionBorderProps {
    title: string;
    className?: string;
    children: any;
    addSubClient?: boolean;
    createSubClient?(): void;
}

const Container = styled.div<SectionBorderProps>`
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    color: #4a4a4a;
    padding: 0 10px;
    word-break: break-all;
    ${props => props.rightBorder && css`
        border-right: 1px solid ${theme.colors.grayRGBA};
    `};
`;

const AddSubClientButton = styled(IconButton)`
`

export const Title = styled.div`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #4a4a4a;
`;

const Section: React.FunctionComponent<SectionProps> = props => (
    <Container rightBorder={props.rightBorder} className={props.className}>
        <div className="flex items-center">
            <Title>{props.title}</Title>
            {props.addSubClient && (
                <AddSubClientButton onClick={() => props.createSubClient && props.createSubClient()} uiSize={20} style={{ marginLeft: '10px', marginBottom: '5px' }}>
                    <FaPlusCircle />
                </AddSubClientButton>
            )}
        </div>
        {props.children}
    </Container>
);

export const SubSection = styled.div`
    max-height: 400px;
    overflow: auto;
    flex-direction: column;
    display: flex;
`;

export default Section;
