import { FaPlus, FaTimes } from "react-icons/fa";
import {
    LeftCell,
    RightCell,
    RightCellWithBorder,
    SupplierBlock,
    SupplierFormInput,
    SupplierLabel,
    SupplierSubtitle,
    ThreeItemsWrapper,
    TwoItemsWrapper,
} from "./supplier_modal";
import React, { ChangeEvent, FC, KeyboardEvent, MouseEvent, PureComponent } from "react";
import styled, { theme } from "../../theme";

import Avatar from "../../components/UI/avatar";
import DisplayName from "./supplier-display-name";
import SelectList from "../../components/UI/select-list";
import { SuppliersModule } from "../../redux";
import { ValidationPopupInput } from "@ramble/ramble-ui";
import { detect } from "detect-browser";

const CompanyInformation = styled.div `
    width: 60%;
    padding-left: 1rem;
    padding-right: 2.5rem;
    border-left: 1px solid #338daa;
`;

const FullRow = styled.div`
    width: 100%;
    display: flex;
    padding: 1.5rem 0;
    border-top: 1px solid gray;
`;

const TagsContainer = styled.div`
    width: 100%;
    padding-bottom: 2px;

    > .label {
        margin-right: 4px;
    }
`;

const TagE: FC<{ tag: string; className?: string; onDeleteClick(tag: string): void; }> = props => (
    <div className={props.className} onClick={() => props.onDeleteClick(props.tag)}>
        <div className="tag">{props.tag}</div>
        <FaTimes className="close" />
    </div>
);

const Tag = styled(TagE)`
    display: inline-flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 0.5em;
    padding: 5px 15px;
    cursor: pointer;
    background-color: #f7f8f7;
    color: #929292;
    max-width: 100%;
    margin-bottom: 10px;

    &:not(:last-child) {
        margin-right: 15px;
    }

    > .close {
        font-size: 1.5em;
        fill: #438cab;
    }

    .tag {
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const TagsInput = styled(ValidationPopupInput)`
    margin: 21px 0;
    width: 85% !important;
    padding: 0.35em 0.5em;

    #tags {
        border-right: 1px solid #d3d3ce;
    }

    #tags::placeholder {
        color: ${theme.colors.grayRGBA};
    }
`;

const TagAddBtn = styled.button`
    background-color: #a4b2bc;
    padding: 0;
    border-radius: 50%;
    border: none;
    width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 10px;
    cursor: pointer;
    flex-shrink: 0;
    outline: none;

    .add-tag {
        fill: #fff;
        width: 0.7em;
        height: 0.7em;
    }
`;
// temp ?

interface Props {
    formFields: SuppliersModule.Supplier;
    stateList: string[];
    tags?: string;

    openAvatarModal(): void;
    deleteAvatarImage(): void;
    onSelectChange(value: string, name: string): void;
    onInputChange(e: ChangeEvent<HTMLInputElement>): void;
    onTagsChanged(tags: string): void;
    handleChangeDisplayName(value: string): void;
}

function processTags(oldTags: string, newTagsStr: string): string {
    const currentTags = oldTags.split(oldTags ? "||" : "");
    if (currentTags.includes(newTagsStr) || !newTagsStr.length) {
        return oldTags;
    }
    return [...currentTags, newTagsStr].join("||");
}

interface State {
    currentTagStr: string;
}

export class OrderingInfo extends PureComponent<Props, State> {
    public state: State = {
        currentTagStr: "",
    };

    public companyNameRef = React.createRef<HTMLInputElement>();

    public render(): JSX.Element {
        const browser = detect();
        const {
            formFields, openAvatarModal, deleteAvatarImage, stateList, onSelectChange, handleChangeDisplayName,
            onInputChange } = this.props;

        return (
            <SupplierBlock>
                <Avatar
                    avatarId={formFields.avatarId}
                    openAvatarModal={openAvatarModal}
                    deleteAvatarImage={deleteAvatarImage}
                    firstName={formFields.companyName}
                    lastName=""
                />
                <CompanyInformation>
                    <SupplierSubtitle>COMPANY INFORMATION</SupplierSubtitle>
                    <div className="mb-2">
                        <SupplierLabel>Company</SupplierLabel>
                        <SupplierFormInput
                            id="companyName"
                            name="companyName"
                            type="text"
                            className="label"
                            uiReportOnBlur={false}
                            value={formFields.companyName || ""}
                            onChange={onInputChange}
                            autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                            list="autocompleteOff"
                            ref={this.companyNameRef}
                        />
                    </div>
                    <div className="mb-2">
                        <DisplayName
                            value={formFields.displayName}
                            companyName={formFields.companyName}
                            onInputChange={handleChangeDisplayName}
                        />
                    </div>

                    <TwoItemsWrapper>
                        <div>
                            <SupplierLabel>Main Phone</SupplierLabel>
                            <SupplierFormInput
                                id="mainPhone"
                                name="mainPhone"
                                type="text"
                                className="label"
                                uiReportOnBlur={false}
                                value={formFields.mainPhone || ""}
                                onChange={onInputChange}
                                autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                                list="autocompleteOff"
                            />
                        </div>
                        <div>
                            <SupplierLabel>Fax</SupplierLabel>
                            <SupplierFormInput
                                id="fax"
                                name="fax"
                                type="text"
                                className="label"
                                uiReportOnBlur={false}
                                value={formFields.fax || ""}
                                onChange={onInputChange}
                                autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                                list="autocompleteOff"
                            />
                        </div>
                    </TwoItemsWrapper>
                    <div>
                        <SupplierLabel>Account #</SupplierLabel>
                        <SupplierFormInput
                            id="shippingAddressAccountNumber"
                            name="shippingAddress.accountNumber"
                            type="text"
                            className="label"
                            uiReportOnBlur={false}
                            value={formFields.shippingAddress.accountNumber || ""}
                            onChange={onInputChange}
                            autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                            list="autocompleteOff"
                        />
                    </div>
                </CompanyInformation>
                <LeftCell>
                    <SupplierSubtitle>ORDERING CONTACT</SupplierSubtitle>
                    <TwoItemsWrapper>
                        <div>
                            <SupplierLabel>First Name</SupplierLabel>
                            <SupplierFormInput
                                id="shippingAddressFirstName"
                                name="shippingAddress.firstName"
                                type="text"
                                className="label"
                                uiReportOnBlur={false}
                                value={formFields.shippingAddress.firstName || ""}
                                onChange={onInputChange}
                                autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                                list="autocompleteOff"
                            />
                        </div>
                        <div>
                            <SupplierLabel>Last Name</SupplierLabel>
                            <SupplierFormInput
                                id="shippingAddressLastName"
                                name="shippingAddress.lastName"
                                value={formFields.shippingAddress.lastName || ""}
                                onChange={onInputChange}
                                type="text"
                                className="label"
                                uiReportOnBlur={false}
                                autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                                list="autocompleteOff"
                            />
                        </div>
                    </TwoItemsWrapper>
                    <TwoItemsWrapper>
                        <div>
                            <SupplierLabel>Cell Phone</SupplierLabel>
                            <SupplierFormInput
                                id="shippingAddressCellPhone"
                                name="shippingAddress.cellPhone"
                                value={formFields.shippingAddress.cellPhone || ""}
                                onChange={onInputChange}
                                type="text"
                                className="label"
                                uiReportOnBlur={false}
                                autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                                list="autocompleteOff"
                            />
                        </div>
                        <div>
                            <SupplierLabel>Office Phone</SupplierLabel>
                            <SupplierFormInput
                                id="shippingAddressOfficePhone"
                                name="shippingAddress.officePhone"
                                value={formFields.shippingAddress.officePhone || ""}
                                onChange={onInputChange}
                                type="text"
                                className="label"
                                uiReportOnBlur={false}
                                autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                                list="autocompleteOff"
                            />
                        </div>
                    </TwoItemsWrapper>
                    <div>
                        <SupplierLabel>Email</SupplierLabel>
                        <SupplierFormInput
                            id="shippingAddressEmail"
                            name="shippingAddress.email"
                            value={formFields.shippingAddress.email || ""}
                            onChange={onInputChange}
                            type="text"
                            className="label"
                            uiReportOnBlur={false}
                            autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                            list="autocompleteOff"
                        />
                    </div>
                </LeftCell>
                <RightCellWithBorder>
                    <SupplierSubtitle>SHIPPING ADDRESS</SupplierSubtitle>
                    <div className="mb-2">
                        <SupplierLabel>Street Address</SupplierLabel>
                        <SupplierFormInput
                            id="shippingAddressStreetAddress"
                            name="shippingAddress.streetAddress"
                            value={formFields.shippingAddress.streetAddress || ""}
                            onChange={onInputChange}
                            type="text"
                            className="label"
                            uiReportOnBlur={false}
                            autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                            list="autocompleteOff"
                        />
                    </div>
                    <div className="mb-2">
                        <SupplierLabel>Suite / Unit</SupplierLabel>
                        <SupplierFormInput
                            id="shippingAddressUnitSuite"
                            name="shippingAddress.unitSuite"
                            value={formFields.shippingAddress.unitSuite || ""}
                            onChange={onInputChange}
                            type="text"
                            className="label"
                            uiReportOnBlur={false}
                            autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                            list="autocompleteOff"
                        />
                    </div>
                    <ThreeItemsWrapper>
                        <div>
                            <SupplierLabel>City</SupplierLabel>
                            <SupplierFormInput
                                id="shippingAddressCityTown"
                                name="shippingAddress.cityTown"
                                value={formFields.shippingAddress.cityTown || ""}
                                onChange={onInputChange}
                                type="text"
                                className="label"
                                uiReportOnBlur={false}
                                autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                                list="autocompleteOff"
                            />
                        </div>
                        <div>
                            <SelectList
                                label={"State"}
                                selectList={stateList}
                                handleClickSelect={onSelectChange}
                                valueSelect={formFields.shippingAddress.stateProvince}
                                flexDirection={"column"}
                                name="shippingAddress.stateProvince"
                                smallPadding
                                customValue
                                noAddButton
                                noClearBox
                                uiDropArrow
                            />
                        </div>
                        <div>
                            <SupplierLabel>Zip</SupplierLabel>
                            <SupplierFormInput
                                id="shippingAddressZipCode"
                                name="shippingAddress.zipCode"
                                value={formFields.shippingAddress.zipCode || ""}
                                onChange={onInputChange}
                                type="number"
                                className="label"
                                uiReportOnBlur={false}
                                autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                                list="autocompleteOff"
                            />
                        </div>
                    </ThreeItemsWrapper>
                    <div>
                        <SupplierLabel>Country</SupplierLabel>
                        <SupplierFormInput
                            id="shippingAddressCountry"
                            name="shippingAddress.country"
                            value={formFields.shippingAddress.country || ""}
                            onChange={onInputChange}
                            type="text"
                            className="label"
                            uiReportOnBlur={false}
                            autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                            list="autocompleteOff"
                        />
                    </div>
                </RightCellWithBorder>
                <FullRow>
                    <LeftCell>
                        <SupplierSubtitle>LOGIN INFORMATION</SupplierSubtitle>
                        <div className="mb-2">
                            <SupplierLabel>Website</SupplierLabel>
                            <SupplierFormInput
                                id="website"
                                name="loginWebsite"
                                value={formFields.loginWebsite || ""}
                                onChange={onInputChange}
                                type="text"
                                className="label"
                                uiReportOnBlur={false}
                                autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                                list="autocompleteOff"
                            />
                        </div>
                        <div className="mb-2">
                            <SupplierLabel>Username</SupplierLabel>
                            <SupplierFormInput
                                id="username"
                                name="loginUsername"
                                value={formFields.loginUsername || ""}
                                onChange={onInputChange}
                                type="text"
                                className="label"
                                uiReportOnBlur={false}
                                autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                                list="autocompleteOff"
                            />
                        </div>
                        <div className="mb-2">
                            <SupplierLabel>Password</SupplierLabel>
                            <SupplierFormInput
                                id="password"
                                name="loginPassword"
                                value={formFields.loginPassword || ""}
                                onChange={onInputChange}
                                type="text"
                                className="label"
                                uiReportOnBlur={false}
                                autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                                list="autocompleteOff"
                            />
                        </div>
                    </LeftCell>
                    <RightCell>
                        <SupplierSubtitle>TAGS</SupplierSubtitle>
                        <TagsInput
                            id="tags"
                            name="tags"
                            type="text"
                            className="label"
                            onKeyDown={this.handleTagChange}
                            placeholder="... Add New"
                            value={this.state.currentTagStr}
                            onChange={this.changeTagVal}
                            autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                            list="autocompleteOff"
                        >
                            <TagAddBtn
                                onClick={this.handleTagChange}
                                type="button"
                            >
                                <FaPlus className="add-tag" id="add-tag" />
                            </TagAddBtn>
                        </TagsInput>
                        <TagsContainer>
                            {(formFields.tags || "").split(formFields.tags ? "||" : "").map(t => (
                                <Tag key={t} tag={t} onDeleteClick={this.handleDeleteTag} />
                            ))}
                        </TagsContainer>
                    </RightCell>
                </FullRow>
            </SupplierBlock>
        );
    }

    public setFocusOnFirstField = () => {
        if (this.companyNameRef.current) {
            this.companyNameRef.current.focus();
        }
    }

    private changeTagVal = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            currentTagStr: e.currentTarget.value,
        });
    }

    private handleTagChange = (e: KeyboardEvent<HTMLInputElement> | MouseEvent<Element>) => {
        const { onTagsChanged, formFields } = this.props;
        const { currentTagStr } = this.state;
        if (!currentTagStr || ("keyCode" in e && e.keyCode !== 13)) {
            return;
        }
        e.preventDefault();
        onTagsChanged(processTags(formFields.tags || "", currentTagStr.trim()));
        this.setState({
            currentTagStr: "",
        });
    }

    private handleDeleteTag = (tag: string) => {
        const { onTagsChanged, formFields } = this.props;
        const newTags = (formFields.tags || "").split("||").filter(t => t !== tag).join("||");
        onTagsChanged(newTags);
    }
}

export default OrderingInfo;
