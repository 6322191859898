import React, { forwardRef, InputHTMLAttributes, ReactElement } from "react";
import { FaCheckCircle, FaExclamationCircle, FaSpinner } from "react-icons/fa";
import { createAnimatedIcon } from "../icons/animated_icon";
import styled from "../theme/styled";
import Input, { InputUIProps } from "./input";

export interface StateInputUIProps extends InputUIProps {
    /**
     * State
     */
    uiState?: "pending" | "error" | "success";
    /**
     * Pending icon to display
     * @default <FaSpinner />
     */
    uiPendingIcon?: ReactElement<any>;
    /**
     * Success icon to display
     * @default <FaCheckCircle />
     */
    uiSuccessIcon?: ReactElement<any>;
    /**
     * Error icon to display
     * @default <FaExclamationCircle />
     */
    uiErrorIcon?: ReactElement<any>;
}

export type StateInputProps = InputHTMLAttributes<HTMLInputElement> & StateInputUIProps;

const SuccessIcon = styled(FaCheckCircle) `
    fill: green;
`;

const ErrorIcon = styled(FaExclamationCircle) `
    fill: ${props => props.theme.colors.danger};
`;

const DefaultSpinnerIcon = createAnimatedIcon(FaSpinner);

/**
 * Just ordinary input with some states
 *
 * @param props
 * @returns
 */
const IconStateInput = forwardRef<HTMLInputElement, StateInputProps>((props, ref) => {
    const { uiState, uiPendingIcon, uiSuccessIcon, uiErrorIcon, ...other } = props;
    let icon: ReactElement<any> | null = null;
    if (uiState) {
        icon = uiState === "pending"
            ? uiPendingIcon || <DefaultSpinnerIcon />
            : uiState === "success"
                ? uiSuccessIcon || <SuccessIcon />
                : uiErrorIcon || <ErrorIcon />;
    }
    return (
        <Input
            uiDisabled={uiState === "pending"}
            {...other}
            ref={ref}
        >
            {icon}
        </Input>
    );
});

export default styled(IconStateInput)`
    /* ignore */
`;
