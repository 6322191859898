import React, { FC } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

// import CategoriesList from "../components/my-shop/category/categories-list";
import MyShop from "../components/my-shop/my-shop";

interface MyShopPageProps extends RouteComponentProps {
    isOpenModal: boolean;
    handleCloseModal: () => void;
}

const MyShopPage: FC<RouteComponentProps & MyShopPageProps> = (props) => {
    return (
        <Switch>
            <Route
                exact
                path="/myshop/categories"
                render={() => {
                    // return <CategoriesList history={props.history} />;
                    return <Redirect to="/myshop"/>
                }}
            />
            <Route
                path="/myshop"
                render={() => {
                    return (
                        <MyShop
                            history={props.history}
                            location={props.location}
                            match={props.match}
                            isOpenModal={props.isOpenModal}
                            handleCloseModal={props.handleCloseModal}
                        />
                    );
                }}
            />
        </Switch>
    );
};

export default MyShopPage;
