import { ClassAttributes, ComponentProps, SFC } from "react";
import { Checkbox, CheckboxProps, Toggle, ToggleProps } from "../checkbox";
import { DateInput, DateInputProps, Input, InputProps, StateInput, StateInputProps, TimeInput, TimeInuptProps } from "../input";
import { Select, SelectProps } from "../select";
import { TextArea, TextAreaProps } from "../textarea";
import WithValidationErrorMessage from "./with_error_message";
import WithValidationErrorPopup from "./with_popup_error_message";
import WithValidation, { WithValidationProps } from "./with_validation";

// Type components to prevent veeeeery big types
const ValidationInput: SFC<InputProps & WithValidationProps & ClassAttributes<HTMLInputElement>> = WithValidation(Input);
const ValidationSelect: SFC<SelectProps & WithValidationProps & ClassAttributes<HTMLSelectElement>> = WithValidation(Select);
const ValidationToggle: SFC<ToggleProps & WithValidationProps & ClassAttributes<HTMLInputElement>> = WithValidation(Toggle);
const ValidationCheckbox: SFC<CheckboxProps & WithValidationProps & ClassAttributes<HTMLInputElement>> = WithValidation(Checkbox);
const ValidationStateInput: SFC<StateInputProps & WithValidationProps & ClassAttributes<HTMLInputElement>> = WithValidation(StateInput);
const ValidationDateInput: SFC<DateInputProps & WithValidationProps & ClassAttributes<HTMLInputElement>> = WithValidation(DateInput);
const ValidationTimeInput: SFC<TimeInuptProps & WithValidationProps & ClassAttributes<HTMLInputElement>> = WithValidation(TimeInput);
const ValidationTextArea: SFC<TextAreaProps & WithValidationProps & ClassAttributes<HTMLTextAreaElement>> = WithValidation(TextArea);

const ValidationMessageInput = WithValidationErrorMessage(ValidationInput, { uiErrorProp: "uiError" });
const ValidationMessageSelect = WithValidationErrorMessage(ValidationSelect);
const ValidationMessageToggle = WithValidationErrorMessage(ValidationToggle);
const ValidationMessageCheckbox = WithValidationErrorMessage(ValidationCheckbox);
const ValidationMessageStateInput = WithValidationErrorMessage(ValidationStateInput, { uiAsyncValidationStatusProp: "uiState", uiErrorProp: "uiError" });
const ValidationMessageDateInput = WithValidationErrorMessage(ValidationDateInput, { uiErrorProp: "uiError" });
const ValidationMessageTimeInput = WithValidationErrorMessage(ValidationTimeInput, { uiErrorProp: "uiError" });
const ValidationMessageTextArea = WithValidationErrorMessage(ValidationTextArea, { uiErrorProp: "uiError" });

const ValidationPopupInput = WithValidationErrorPopup(ValidationInput, { uiErrorProp: "uiError" });
const ValidationPopupSelect = WithValidationErrorPopup(ValidationSelect);
const ValidationPopupToggle = WithValidationErrorPopup(ValidationToggle);
const ValidationPopupCheckbox = WithValidationErrorPopup(ValidationCheckbox);
const ValidationPopupStateInput = WithValidationErrorPopup(ValidationStateInput, { uiAsyncValidationStatusProp: "uiState", uiErrorProp: "uiError" });
const ValidationPopupDateInput = WithValidationErrorPopup(ValidationDateInput, { uiErrorProp: "uiError" });
const ValidationPopupTimeInput = WithValidationErrorPopup(ValidationTimeInput, { uiErrorProp: "uiError" });
const ValidationPopupTextArea = WithValidationErrorPopup(ValidationTextArea, { uiErrorProp: "uiError" });

export {
    ValidationInput,
    ValidationSelect,
    ValidationToggle,
    ValidationCheckbox,
    ValidationStateInput,
    ValidationMessageInput,
    ValidationMessageSelect,
    ValidationMessageToggle,
    ValidationMessageCheckbox,
    ValidationMessageStateInput,
    ValidationDateInput,
    ValidationTimeInput,
    ValidationMessageDateInput,
    ValidationMessageTimeInput,
    ValidationTextArea,
    ValidationMessageTextArea,
    ValidationPopupInput,
    ValidationPopupSelect,
    ValidationPopupToggle,
    ValidationPopupCheckbox,
    ValidationPopupStateInput,
    ValidationPopupDateInput,
    ValidationPopupTimeInput,
    ValidationPopupTextArea,
};

export type ValidationInputProps = ComponentProps<typeof ValidationInput>;
export type ValidationSelectProps = ComponentProps<typeof ValidationSelect>;
export type ValidationToggleProps = ComponentProps<typeof ValidationToggle>;
export type ValidationCheckboxProps = ComponentProps<typeof ValidationCheckbox>;
export type ValidationStateInputProps = ComponentProps<typeof ValidationStateInput>;
export type ValidationDateInputProps = ComponentProps<typeof ValidationDateInput>;
export type ValidationTimeInputProps = ComponentProps<typeof ValidationTimeInput>;
export type ValidationTextAreaProps = ComponentProps<typeof ValidationTextArea>;

export type ValidationMessageInputProps = ComponentProps<typeof ValidationMessageInput>;
export type ValidationMessageSelectProps = ComponentProps<typeof ValidationMessageSelect>;
export type ValidationMessageToggleProps = ComponentProps<typeof ValidationMessageToggle>;
export type ValidationMessageCheckboxProps = ComponentProps<typeof ValidationMessageCheckbox>;
export type ValidationMessageStateInputProps = ComponentProps<typeof ValidationMessageStateInput>;
export type ValidationMessageDateInputProps = ComponentProps<typeof ValidationMessageDateInput>;
export type ValidationMessageTimeInputProps = ComponentProps<typeof ValidationMessageTimeInput>;
export type ValidationMessageTextAreaProps = ComponentProps<typeof ValidationMessageTextArea>;

export type ValidationPopupInputProps = ComponentProps<typeof ValidationPopupInput>;
export type ValidationPopupSelectProps = ComponentProps<typeof ValidationPopupSelect>;
export type ValidationPopupToggleProps = ComponentProps<typeof ValidationPopupToggle>;
export type ValidationPopupCheckboxProps = ComponentProps<typeof ValidationPopupCheckbox>;
export type ValidationPopupStateInputProps = ComponentProps<typeof ValidationPopupStateInput>;
export type ValidationPopupDateInputProps = ComponentProps<typeof ValidationPopupDateInput>;
export type ValidationPopupTimeInputProps = ComponentProps<typeof ValidationPopupTimeInput>;
export type ValidationPopupTextAreaProps = ComponentProps<typeof ValidationPopupTextArea>;
