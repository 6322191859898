import React, { Component, createRef, MouseEvent as ReactMouseEvent, TouchEvent as ReactTouchEvent } from "react";
import { DraperyModule } from "../../../../redux";
import { viewBoxStr } from "./board";
import DrawShape from "./shape";

interface DrawingProps {
    tool: "line" | "pen" | "arrow" | "text" | "selection" | "rect" | "circle";
    shapes: DraperyModule.Shape[];
    selectedShape?: DraperyModule.Shape;
    onShapeMouseEvent(e: ReactMouseEvent | ReactTouchEvent, shape: DraperyModule.Shape, index: number): void;
}

class DrawingShapes extends Component<DrawingProps> {
    private svgRef = createRef<SVGSVGElement>();

    public render() {
        return(
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={viewBoxStr}
                style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    left: 0,
                }}
                ref={this.svgRef}
            >
                {this.props.shapes.map((shape, index) => (
                    !(this.props.tool === "selection" && shape === this.props.selectedShape) &&
                    <DrawShape
                        key={index}
                        tool={this.props.tool}
                        shape={shape}
                        index={index}
                        status={this.props.tool === "selection" && shape === this.props.selectedShape ? "selected" : undefined}
                        onShapeMouseEvent={this.props.onShapeMouseEvent}
                    />
                ))}
            </svg>
        );
    }

    public createImageUrl = (): string | null => {
        if (this.svgRef.current) {
            const svg = this.svgRef.current.outerHTML;
            const blob = new Blob([svg], {type: "image/svg+xml"});
            const url = URL.createObjectURL(blob);
            return url;
        } else {
            return null;
        }
    }
}

export default DrawingShapes;
