import { Omit } from "../../../../types/index";
import { ActionDescriptor } from "../../common";
import {
    WorkOrder,
    WorkOrderOverrides,
    WorkOrderPdfContent,
    WorkOrderRecommendations,
} from "./interfaces";

export const ADD_WORKORDER = "workOrder/ADD";
export type AddWorkOrderAction = ActionDescriptor<
    typeof ADD_WORKORDER,
    Omit<WorkOrder, "id"> & { accountId: number },
    WorkOrder
>;

export const EDIT_WORKORDER = "workOrder/EDIT";
export type EditWorkOrderAction = ActionDescriptor<
    typeof EDIT_WORKORDER,
    WorkOrder,
    {}
>;

export const GET_WORKORDER = "workOrder/GET";
export type GetWorkOrderAction = ActionDescriptor<
    typeof GET_WORKORDER,
    { id: number },
    WorkOrder
>;

export const GET_WORKORDERS = "workOrder/GET_ALL";
export type GetWorkOrdersAction = ActionDescriptor<
    typeof GET_WORKORDERS,
    {},
    WorkOrder[]
>;

export const SEARCH_WORKORDERS = "workOrder/SEARCH";
export type SearchWorkOrdersAction = ActionDescriptor<
    typeof SEARCH_WORKORDERS,
    { accountId: number },
    WorkOrder[]
>;

export const GET_NEXT_WORKORDER_NUM = "workOrder/GET_NEXT_NUM";
export type GetNextWorkOrderNumAction = ActionDescriptor<
    typeof GET_NEXT_WORKORDER_NUM,
    { accountId: number },
    number
>;

export const GET_WORKORDER_PDF_CONTENT = "workOrder/GET_WORKORDER_PDF_CONTENT";
export type GetWorkOrderPdfContentAction = ActionDescriptor<
    typeof GET_WORKORDER_PDF_CONTENT,
    { draperyOrderId: number },
    WorkOrderPdfContent
>;

export const GET_WORKORDER_EDITABLE = "workOrder/GET_WORKORDER_EDITABLE";
export type GetWorkOrderEditableAction = ActionDescriptor<
    typeof GET_WORKORDER_EDITABLE,
    { draperyOrderId: number },
    boolean
>;

export const GET_WORKORDER_RECOMMENDATIONS =
    "workOrder/GET_WORKORDER_RECOMMENDATIONS";
export type GetWorkOrderRecommendationsAction = ActionDescriptor<
    typeof GET_WORKORDER_RECOMMENDATIONS,
    { draperyOrderId: number; type: "panel" | "yardage" },
    WorkOrderRecommendations
>;

export const SAVE_WORKORDER_OVERRIDES = "workOrder/SAVE_WORKORDER_OVERRIDES";
export type SaveWorkOrderOverridesAction = ActionDescriptor<
    typeof SAVE_WORKORDER_OVERRIDES,
    { accountId: number } & { draperyOrderId: number } & {
        draperyOrderPriceId?: number;
    } & WorkOrderOverrides,
    {}
>;

export const UPLOAD_WORK_ORDER_PDF = "workOrder/UPLOAD_WORK_ORDER_PDF";
export type UploadWorkOrderPdfAction = ActionDescriptor<
    typeof UPLOAD_WORK_ORDER_PDF,
    { data: Blob; accountId: number },
    { url: string }
>;

export interface WorkOrderActionsMap {
    [ADD_WORKORDER]: AddWorkOrderAction;
    [EDIT_WORKORDER]: EditWorkOrderAction;
    [GET_WORKORDER]: GetWorkOrderAction;
    [GET_WORKORDERS]: GetWorkOrdersAction;
    [SEARCH_WORKORDERS]: SearchWorkOrdersAction;
    [GET_NEXT_WORKORDER_NUM]: GetNextWorkOrderNumAction;
    [GET_WORKORDER_PDF_CONTENT]: GetWorkOrderPdfContentAction;
    [GET_WORKORDER_RECOMMENDATIONS]: GetWorkOrderRecommendationsAction;
    [SAVE_WORKORDER_OVERRIDES]: SaveWorkOrderOverridesAction;
    [GET_WORKORDER_EDITABLE]: GetWorkOrderEditableAction;
    [UPLOAD_WORK_ORDER_PDF]: UploadWorkOrderPdfAction;
}
