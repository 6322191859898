import { Omit } from "../../../../types/index";
import { ActionDescriptor } from "../../common";
import { BasicSupplier, Supplier } from "./interfaces";

export const ADD_SUPPLIER = "supplier/ADD";
export type AddSupplierAction = ActionDescriptor<
    typeof ADD_SUPPLIER,
    Omit<Supplier, "id"> & { accountId: number },
    Supplier
>;

export const EDIT_SUPPLIER = "supplier/EDIT";
export type EditSupplierAction = ActionDescriptor<
    typeof EDIT_SUPPLIER,
    Supplier,
    {}
>;

export const GET_SUPPLIER = "supplier/GET";
export type GetSupplierAction = ActionDescriptor<
    typeof GET_SUPPLIER,
    { id: number },
    Supplier
>;

export const GET_SUPPLIERS = "supplier/GET_ALL";
export type GetSuppliersAction = ActionDescriptor<
    typeof GET_SUPPLIERS,
    {
        accountId: number;
        nameSort?: "asc" | "desc";
        typeSort?: "asc" | "desc";
        includeInactive?: boolean;
    },
    BasicSupplier[]
>;

export const SEARCH_SUPPLIERS = "supplier/SEARCH";
export type SearchSuppliersAction = ActionDescriptor<
    typeof SEARCH_SUPPLIERS,
    {
        accountId: number;
        text: string;
        nameSort?: "asc" | "desc";
        typeSort?: "asc" | "desc";
        includeInactive?: boolean;
    },
    BasicSupplier[]
>;

export interface SuppliersActionsMap {
    [ADD_SUPPLIER]: AddSupplierAction;
    [EDIT_SUPPLIER]: EditSupplierAction;
    [GET_SUPPLIER]: GetSupplierAction;
    [GET_SUPPLIERS]: GetSuppliersAction;
    [SEARCH_SUPPLIERS]: SearchSuppliersAction;
}
