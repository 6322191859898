import { Api } from "@quipa/api";
import { actionReducer } from "../../utils";
import { defaults, State } from "./model";

export default actionReducer<Api.RealtimeActionsMap, State>(defaults(), {
    [Api.LOGIN]: {
        success: (draft, { payload }) => {
            draft.id = payload.account.id;
            draft.type = payload.account.type;
            draft.isBeta = payload.account.isBeta;
            // draft.cardDigits = payload.account.cardDigits;
            draft.quickbookConnected = payload.account.quickbookConnected;
            draft.calculatorDefaults = {
                ...draft.calculatorDefaults,
            };
            draft.avatarId = payload.account.avatarId;
        },
    },
    [Api.SIGNUP]: {
        success: (draft, { payload }) => {
            draft.id = payload.account.id;
            draft.type = payload.account.type;
            draft.isBeta = payload.account.isBeta;
            // draft.cardDigits = payload.account.cardDigits;
            draft.calculatorDefaults = {
                ...draft.calculatorDefaults,
            };
            draft.avatarId = payload.account.avatarId;
        },
    },
    [Api.SUBSCRIBE_ACCOUNT]: {
        success: (draft) => {
            draft.type = "subscribed";
        },
    },
    [Api.BETA_SUBSCRIBE_ACCOUNT]: {
        success: (draft) => {
            draft.type = "unsubscribed";
            draft.isBeta = true;
        },
    },
    [Api.UNSUBSCRIBE_ACCOUNT]: {
        success: (draft) => {
            draft.type = "unsubscribed";
        },
    },
    [Api.UPDATE_ACCOUNT]: {
        request: (draft, { payload }) => {
            // if (typeof payload.cardDigits !== "undefined") {
            //     draft.cardDigits = payload.cardDigits;
            // }
            draft.avatarId = payload.avatarId;
        },
    },
    [Api.CONNECT_TO_QUICKBOOK]: {
        request: (draft) => {
            draft.quickbookConnected = true;
        },
    },
});
