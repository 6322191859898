import Color from "color";
import React, { forwardRef, HTMLAttributes } from "react";
import { Position } from "../popover";
import styled, { css } from "../theme/styled";
import { cleanUiProps } from "../utils/withStyledProps";

export interface PopupElementUIProps {
    /**
     * Popup position
     * @default "top left"
     */
    uiPosition?: Position;
    /**
     * Inverted popup
     * @default false
     */
    uiInverted?: boolean;
}

export type PopupElementProps = HTMLAttributes<HTMLDivElement> & PopupElementUIProps;

const PopupElementComponent = forwardRef<HTMLDivElement, PopupElementProps>((props, ref) => {
    const { uiInverted, uiPosition, className, ...other } = props;
    cleanUiProps(other);
    return (
        <div
            {...other}
            ref={ref}
            className={`${className} ${uiPosition}`}
        />
    );
});

/**
 * Main popup element used in popup
 */
const PopupElement = styled(PopupElementComponent)`
    font-weight: 400;
    font-style: normal;
    font-size: 0.85rem;
    min-width: min-content;
    z-index: ${({ theme }) => theme.zIndex.popover};
    border: 1px solid ${({ theme }) => theme.colors.divider};
    line-height: 1.4;
    max-width: 250px;
    padding: 0.83em 1em;
    border-radius: 0.28rem;
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.getContrastTextColor(theme.colors.background)};
    will-change: top, left, right, bottom, transform, opacity;
    box-shadow:
        0 2px 4px 0 ${({ theme }) => Color(theme.colors.divider).alpha(0.12).string()},
        0 2px 10px 0 ${({ theme }) => Color(theme.colors.divider).alpha(0.15).string()};

    &::before {
        position: absolute;
        content: "";
        width: 0.71em;
        height: 0.71em;
        transform: rotate(45deg);
        z-index: 2;
        background: ${({ theme }) => theme.colors.background};
    }

    ${({ theme, uiPosition }) => uiPosition === "top left" && css`
        margin: 0 0 0.71em;
        transform-origin: 0% 100%;

        &::before {
            box-shadow: 1px 1px 0 0 ${Color(theme.colors.divider).alpha(0.12).string()};
            bottom: -0.3em;
            left: 1em;
            top: auto;
            right: auto;
            margin-left: 0;
        }
    `};
    ${({ theme, uiPosition }) => uiPosition === "top center" && css`
        margin: 0 0 0.71em;
        transform-origin: 50% 100%;

        &::before {
            box-shadow: 1px 1px 0 0 ${Color(theme.colors.divider).alpha(0.12).string()};
            bottom: -0.3em;
            left: 50%;
            top: auto;
            right: auto;
            margin-left: -0.3em;
        }
    `};
    ${({ theme, uiPosition }) => uiPosition === "top right" && css`
        margin: 0 0 0.71em;
        transform-origin: 100% 100%;

        &::before {
            box-shadow: ${Color(theme.colors.divider).alpha(0.12).string()};
            bottom: -0.3em;
            left: auto;
            top: auto;
            right: 1em;
            margin-left: 0;
        }
    `};
    ${({ theme, uiPosition }) => uiPosition === "left center" && css`
        margin: 0 0.71em 0 0;
        transform-origin: 100% 50%;

        &::before {
            box-shadow: 1px -1px 0 0 ${Color(theme.colors.divider).alpha(0.30).string()};
            bottom: auto;
            left: auto;
            top: 50%;
            right: -0.3em;
            margin-top: -0.3em;
        }
    `};
    ${({ theme, uiPosition }) => uiPosition === "right center" && css`
        margin: 0 0 0 0.71em;
        transform-origin: 0% 50%;

        &::before {
            box-shadow: -1px 1px 0 0 ${Color(theme.colors.divider).alpha(0.30).string()};
            bottom: auto;
            left: -0.3em;
            top: 50%;
            right: auto;
            margin-top: -0.3em;
        }
    `};
    ${({ theme, uiPosition }) => uiPosition === "bottom right" && css`
        margin: 0.71em 0 0;
        transform-origin: 100% 0%;

        &::before {
            box-shadow: -1px -1px 0 0 ${Color(theme.colors.divider).alpha(0.30).string()};
            bottom: auto;
            left: auto;
            top: -0.3em;
            right: 1em;
            margin-left: 0;
        }
    `};
    ${({ theme, uiPosition }) => uiPosition === "bottom center" && css`
        margin: 0.71em 0 0;
        transform-origin: 50% 0%;

        &::before {
            box-shadow: -1px -1px 0 0 ${Color(theme.colors.divider).alpha(0.30).string()};
            bottom: auto;
            left: 50%;
            top: -0.3em;
            right: auto;
            margin-left: -0.3em;
        }
    `};
    ${({ theme, uiPosition }) => uiPosition === "bottom left" && css`
        margin: 0.71em 0 0;
        transform-origin: 0% 0%;

        &::before {
            box-shadow: -1px -1px 0 0 ${Color(theme.colors.divider).alpha(0.30).string()};
            bottom: auto;
            left: 1em;
            top: -0.3em;
            right: auto;
            margin-left: 0;
        }
    `};
    ${({ theme, uiInverted }) => uiInverted && css`
        border: none;
        box-shadow: none;
        background: ${theme.colors.invertedBackground};
        color: ${theme.getContrastTextColor(theme.colors.invertedBackground)};

        &::before {
            background: ${theme.colors.invertedBackground};
            box-shadow: none;
        }
    `};
`;
PopupElement.defaultProps = {
    uiPosition: "top left",
};

export default PopupElement;
