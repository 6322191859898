import {
    CalculatorCheckbox,
    CalculatorFieldInput,
    CalculatorFormLabel,
    CalculatorInput,
    TooltipContainer,
    TooltipHeader,
    TooltipPopup,
    TooltipText,
} from "./styles";
import { DraperyModule, WorkOrderModule } from "../../redux";
import React, { ChangeEvent, PureComponent } from "react";
import SearchSelect, {
    SearchSelectProps,
} from "../../components/UI/search_select/search_select";
import { ValidationErrorInfo } from "./interfaces-common";
import { highlightStyle } from "./calculator";
import styled from "../../theme";
import { Bind } from "lodash-decorators";

const SetupContainer = styled.div`
    position: absolute;
    overflow: auto;
    top: 7%;
    bottom: 0;
    width: 100%;
    max-width: 700px;
`;

const SetupRow = styled.div`
    margin-bottom: 10px;
`;

const SetupFormInput = styled(CalculatorInput)`
    flex: 1;
    padding: 0.67em 38px 0.67em 8px;
    color: rgba(27,28,29,0.87) !important;
    border: 1px solid hsla(0,0%,13.9%,0.15);
    &.draperyQuantity {
        min-width: 70px !important;
        max-width: 90px !important;
    }
    &.draperyRelated {
        min-width: 180px !important;
        max-width: 200px !important;
        padding-right: 8px !important;
    }
`;

const SetupFormLabel = styled(CalculatorFormLabel)`
    min-width: 150px;
    max-width: 180px;
`;

export const validateDraperyOrder = (
    draperyOrder: DraperyModule.DraperyOrder
): ValidationErrorInfo[] => {
    const prefixName = "draperyOrder.";
    const tabIndex = 0;
    const errors: ValidationErrorInfo[] = [];
    if (!(draperyOrder.clientId && draperyOrder.clientId > 0)) {
        errors.push({
            tabIndex,
            name: prefixName + "clientId",
            message: "Please select a client.",
        });
    }
    if ((!(draperyOrder.roomId && draperyOrder.roomId > 0) || !draperyOrder.roomId) && !draperyOrder.customRoom) {
        errors.push({
            tabIndex,
            name: prefixName + "roomId",
            message: "Please select a room.",
        });
    }
    if (!draperyOrder.window) {
        errors.push({
            tabIndex,
            name: prefixName + "window",
            message: "Please input window.",
        });
    }
    if (draperyOrder.quantity === undefined || draperyOrder.quantity === null || draperyOrder.quantity === 0) {
        errors.push({
            tabIndex,
            name: prefixName + "quantity",
            message: "Order Quantity must be a whole number greater than zero.",
        });
    }
    // keep for reference: if (draperyOrder.hasRelatedWorkOrders && draperyOrder.relatedWorkOrderIds.length === 0) { errors.push({tabIndex, name: prefixName + "relatedWorkOrderIds", message: "Please select related work orders."}); }
    return errors;
};

interface Props {
    roomList: Array<{ key: string | number; label: string }>;
    roomValue: string | undefined;
    disabled: boolean;
    draperyOrder: DraperyModule.DraperyOrder & { clientName: string };
    workOrderList: WorkOrderModule.WorkOrder[];
    highlightedSection: string | null | undefined;
    handleOpenClientModalClick(pathId: string, pathName: string): void;
    handleSearchSelectChange(
        id: number,
        name: string,
        pathId: string,
        pathName: string,
    ): void;
    handleSearchClient: SearchSelectProps["onSearch"];
    handleSearchSelectClear(pathId: string, pathName: string): void;
    handleSelectListChange(value: string | number, name?: string): void;
    handleAddRoom(value: string): Promise<never[] | undefined>;
    handleInputChangeForString(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void;
    handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void;
    handleInputChangeForBoolean(e: ChangeEvent<HTMLInputElement>): void;
    updateStateByName(
        name: string,
        value: string | number | boolean | number[] | undefined,
    ): void;
    openModalClick?(): void;
    handleSearchRoom: SearchSelectProps["onSearch"];
    workOrderEditMode: boolean;
    isCopy?: boolean;
}

// tslint:disable-next-line:no-empty-interface
interface State {
    displayResults: boolean[];
}
class SetupTab extends PureComponent<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            displayResults: [true, true],
        }
    }
    @Bind()
    private displayResults(index: number, value: boolean): void {
        const displayResultsArr = [...this.state.displayResults];
        displayResultsArr[index] = value;
        this.setState({ displayResults: displayResultsArr })

    }

    public render() {
        const {
            disabled,
            draperyOrder,
            highlightedSection,
            handleOpenClientModalClick,
            handleSearchSelectChange,
            handleSearchClient,
            handleSearchSelectClear,
            handleInputChangeForString,
            handleInputChangeForNumber,
            handleInputChangeForBoolean,
            openModalClick,
            handleSearchRoom,
            workOrderEditMode
        } = this.props;

        return (
            <SetupContainer>
                <SetupRow>
                    <CalculatorFieldInput
                        style={
                            highlightedSection === "draperyOrder.clientId"
                                ? highlightStyle
                                : {}
                        }
                    >
                        <SetupFormLabel>Client</SetupFormLabel>
                        <SearchSelect
                            name="draperyOrder.clientId"
                            openModalClick={() =>
                                handleOpenClientModalClick(
                                    "draperyOrder.clientId",
                                    "draperyOrder.clientName",
                                )
                            }
                            searchPage="calculator"
                            searchType="calculator"
                            searchItem="client"
                            onItemClick={(id, name) =>
                                handleSearchSelectChange(
                                    id,
                                    name,
                                    "draperyOrder.clientId",
                                    "draperyOrder.clientName",
                                )
                            }
                            onSearch={handleSearchClient}
                            placeholder="Please select"
                            keepKeyword
                            initialValue={draperyOrder.clientName}
                            onKeywordCleared={() => {
                                handleSearchSelectClear(
                                    "draperyOrder.clientId",
                                    "draperyOrder.clientName",
                                )
                            }}
                            disabled={workOrderEditMode ? false : disabled}
                            required={true}
                            displayResults={this.displayResults}
                            resultsDisplayed={this.state.displayResults[0]}
                        />
                    </CalculatorFieldInput>
                </SetupRow>
                <SetupRow>
                    <CalculatorFieldInput
                        style={
                            highlightedSection === "draperyOrder.roomId"
                                ? highlightStyle
                                : {}
                        }
                    >
                        <SetupFormLabel>Room</SetupFormLabel>
                        <SearchSelect
                            name="draperyOrder.roomId"
                            openModalClick={openModalClick}
                            searchPage="calculator"
                            searchType="calculator"
                            searchItem="room"
                            onItemClick={(id, name) => {
                                handleSearchSelectChange(
                                    id,
                                    name,
                                       "draperyOrder.roomId",
                                    "draperyOrder.roomName",
                                )
                            }
                            }
                            onSearch={handleSearchRoom}
                            placeholder="Please select"
                            keepKeyword
                            initialValue={!draperyOrder.customRoom ? draperyOrder.roomName : draperyOrder.customRoom}
                            onKeywordCleared={() => {
                                handleSearchSelectClear(
                                    "draperyOrder.roomId",
                                    "draperyOrder.roomName",
                                )
                            }}
                            disabled={workOrderEditMode ? false : (disabled || !draperyOrder.clientId)}
                            required={true}
                            displayResults={this.displayResults}
                            resultsDisplayed={this.state.displayResults[1]}
                        />
                    </CalculatorFieldInput>
                </SetupRow>
                <SetupRow>
                    <CalculatorFieldInput
                        style={
                            highlightedSection === "draperyOrder.window"
                                ? highlightStyle
                                : {}
                        }
                    >
                        <SetupFormLabel>Window Name</SetupFormLabel>
                        <SetupFormInput
                            id="draperyOrder.window"
                            name="draperyOrder.window"
                            type="text"
                            value={draperyOrder.window || ""}
                            onChange={handleInputChangeForString}
                            disabled={workOrderEditMode ? false : disabled}
                            uiDisabled={workOrderEditMode ? false : disabled}
                            autoComplete="off"
                        />
                    </CalculatorFieldInput>
                </SetupRow>
                <SetupRow>
                    <CalculatorFieldInput
                        style={
                            highlightedSection === "draperyOrder.quantity"
                                ? highlightStyle
                                : {}
                        }
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <SetupFormLabel style={{ marginRight: '-20px' }}>Order Quantity
                            </SetupFormLabel>
                            <TooltipPopup
                                text={
                                    <TooltipContainer>
                                        <TooltipHeader>
                                            Order Quantity
                                        </TooltipHeader>
                                        <TooltipText>
                                            Indicates the quantity
                                            of the exact same treatment (design and fabric)
                                            applied to multiple windows or window
                                            sets for an order.
                                            For example, if I have 2 bay windows
                                            that will use 4 panels
                                            each and all details are exactly
                                            the same for each window,
                                            the order quantity is 2
                                            and my panel configuration is 4
                                            (this is selected under Design).

                                        </TooltipText>
                                    </TooltipContainer>
                                }
                                position="top left"
                                className="normal"
                            />
                        </div>
                        <SetupFormInput
                            id="draperyOrder.quantity"
                            name="draperyOrder.quantity"
                            type="number"
                            value={draperyOrder.quantity !== undefined ? draperyOrder.quantity : ""}
                            onChange={handleInputChangeForNumber}
                            onKeyDown={(evt) => {
                                const target = evt.target as HTMLInputElement;
                                const currentValue = target.value;
                                const key = evt.key;
                                if (!/[0-9]/.test(key) && key !== "Backspace" && key !== "Delete") {
                                    evt.preventDefault();
                                    return;
                                }
                                if (currentValue === "" && key === "0") {
                                    evt.preventDefault();
                                    return;
                                }
                                if (currentValue.includes("-") && key === "-") {
                                    evt.preventDefault();
                                    return;
                                }
                                if (key === "-" && target.selectionStart !== 0) {
                                    evt.preventDefault();
                                    return;
                                }
                            }}
                            disabled={workOrderEditMode ? false : disabled}
                            uiDisabled={workOrderEditMode ? false : disabled}
                            autoComplete="off"
                            className="draperyQuantity"
                            min="0"
                            max="999"
                        />
                    </CalculatorFieldInput>
                </SetupRow>

                <SetupRow>
                    <SetupFormLabel>
                        <CalculatorCheckbox
                            id="draperyOrder.hasRelatedWorkOrders"
                            name="draperyOrder.hasRelatedWorkOrders"
                            checked={draperyOrder.hasRelatedWorkOrders}
                            onChange={handleInputChangeForBoolean}
                            uiDisabled={draperyOrder.archived}
                            disabled={draperyOrder.archived}
                        />
                        Related Work Orders
                    </SetupFormLabel>

                    {draperyOrder.hasRelatedWorkOrders && (
                        <SetupFormInput
                            id="draperyOrder.related"
                            name="draperyOrder.related"
                            type="text"
                            value={draperyOrder.related || ""}
                            onChange={handleInputChangeForString}
                            autoComplete="off"
                            maxLength={20}
                            uiDisabled={draperyOrder.archived}
                            className="draperyRelated"
                        />
                    )}
                </SetupRow>
            </SetupContainer>
        );
    }
}

export default SetupTab;
