import React, { FC, memo } from "react";
import styled from "../../theme";

interface UserMenuItemProps {
    /**
     * Item label
     */
    label: string;
    /**
     * Item value
     */
    value: string;
    /**
     * Item icon
     */
    icon: React.ReactElement<any>;
    alignment?: "left" | "right";
    topMargin?: string;
    /**
     * Click callback
     */
    onClick(val: string): void;
}

interface ItemContainerProps {
    uiAlignment?: UserMenuItemProps["alignment"];
    uiTopMargin?: UserMenuItemProps["topMargin"];
}

const ItemContainer = styled.div<ItemContainerProps>`
    display: flex;
    flex-flow: row nowrap;
    justify-content: ${props => props.uiAlignment === "left" ? "space-between" : "flex-end"};
    align-items: center;
    padding: 1em 3em 1em 1em;
    transition: background 0.1s ease;
    cursor: pointer;
    text-align: ${props => props.uiAlignment === "right" ? "right" : "left"};
    margin-top: ${props => props.uiTopMargin === "8px" ? "8px" : "0" };

    &:hover {
        background: #377a8d;
    }

    > svg {
        height: 100%;
        width: auto;
        margin-left: 1.5em;
    }
`;

export const UserMenuItem: FC<UserMenuItemProps> = memo(props => {
    const handleClick = () => props.onClick(props.value);
    return (
        <ItemContainer
            onClick={handleClick}
            uiAlignment={props.alignment}
            uiTopMargin={props.topMargin}
        >
            {props.label}
        </ItemContainer>
    );
});

UserMenuItem.defaultProps = {
    alignment: "left",
    topMargin: "0px",
 };
