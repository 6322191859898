import React, { Component, KeyboardEvent, MouseEvent as ReactMouseEvent, TouchEvent as ReactTouchEvent } from "react";
import { RGBColor } from "react-color";
import { DraperyModule } from "../../../../redux";
import styled from "../../../../theme";
import { isTouchDevice } from "../../../../utils/is_touch_device";
import { viewBoxStr } from "./board";
import DrawShape from "./shape";

const Cover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const Svg = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`;

const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: #0009;
`;

export enum ResizePosition {
    First = "First",
    Second = "Second",
    TopLeft = "TopLeft",
    TopRight = "TopRight",
    BottomLeft = "BottomLeft",
    BottomRight = "BottomRight",
}

interface Props {
    tool: "line" | "pen" | "arrow" | "text" | "selection" | "rect" | "circle";
    selectedShape: DraperyModule.Shape;
    selectedShapeIndex: number;
    onFinished(shape?: DraperyModule.Shape): void;
    onDeleted(): void;
    relativeCoordinatesForEvent(event: ReactMouseEvent | ReactTouchEvent): DraperyModule.Point | undefined;
}

interface State {
    status: "draging" | "idle" | "resizing";
    position?: ResizePosition;
    shape: DraperyModule.Shape;
    index: number;
    dragPoint?: DraperyModule.Point;
    dragPoints?: DraperyModule.Point[];
}

class SelectedShape extends Component<Props, State> {
    public state: State = {
        status: "idle",
        shape: {...this.props.selectedShape},
        index: this.props.selectedShapeIndex,
    };

    public componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress, false);
    }

    public componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress, false);
    }

    public render() {
        return (
            <Container
                onMouseMove={!isTouchDevice() ? this.handleMouseEvent : undefined}
                onMouseUp={!isTouchDevice() ? this.handleMouseEvent : undefined}
                onTouchMove={this.handleMouseEvent}
                onTouchEnd={this.handleMouseEvent}
            >
                <Cover
                    onClick={!isTouchDevice() ? this.handleFinish : undefined}
                    onTouchEnd={this.handleFinish}
                />
                <Svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={viewBoxStr}
                >
                    <DrawShape
                        tool={this.props.tool}
                        shape={this.state.shape}
                        index={this.state.index}
                        status="selected"
                        onShapeMouseEvent={this.handleShapeMouseEvent}
                        onShapeMouseEventForResize={this.handleShapeMouseEventForResize}
                    />
                </Svg>
            </Container>
        );
    }

    public handleColor = (filledColor: RGBColor, strokeColor: RGBColor) => {
        this.setState({
            shape: {
                ...this.state.shape,
                filledColor,
                strokeColor,
            },
        });
    }

    public handleLineWeight = (lineWeight: number, lineType: "solid" | "dashed") => {
        this.setState({
            shape: {
                ...this.state.shape,
                lineWeight,
                lineType,
            },
        });
    }

    private handleKeyPress = (e: Event) => {
        const event: KeyboardEvent<Element> = e as unknown as KeyboardEvent<Element>;
        if (event.key === "Delete" || event.key === "Backspace") {
            this.props.onDeleted();
        } else if (event.key === "Escape") {
            this.props.onFinished();
        } else if (event.key === "Enter") {
            this.props.onFinished(this.state.shape);
        }
    }

    private handleShapeMouseEvent = (event: ReactMouseEvent | ReactTouchEvent): void => {
        if (event.type === "mousedown" || event.type === "touchstart") {
            this.processStart(event, "draging");
        }
    }

    private handleShapeMouseEventForResize = (event: ReactMouseEvent | ReactTouchEvent, position: ResizePosition): void => {
        if (event.type === "mousedown" || event.type === "touchstart") {
            this.processStart(event, "resizing", position);
        }
    }

    private handleMouseEvent = (event: ReactMouseEvent | ReactTouchEvent): void => {
        if (event.type === "mousemove" || event.type === "touchmove" || event.type === "mouseup" || event.type === "touchend") {
            if (this.state.status === "resizing") {
                this.processForResize(event);
            } else if (this.state.status === "draging") {
                this.processForDrag(event);
            }
        }
    }

    private processStart = (event: ReactMouseEvent | ReactTouchEvent, status: "draging" | "resizing", position?: ResizePosition) => {
        const point = this.props.relativeCoordinatesForEvent(event);
        if (point) {
            const dragPoints: DraperyModule.Point[] = [];
            this.state.shape.points.forEach(point => {
                dragPoints.push({...point});
            });
            this.setState({
                status,
                position,
                dragPoint: point,
                dragPoints,
            });
        }
    }

    private processForDrag = (event: ReactMouseEvent | ReactTouchEvent): void => {
        const point = this.props.relativeCoordinatesForEvent(event);
        if (point && this.state.dragPoint && this.state.dragPoints) {
            const shape = this.updateShapePosForDrag(this.state.shape, point, this.state.dragPoint, this.state.dragPoints);
            if (event.type === "mousemove" || event.type === "touchmove") {
                this.setState({ shape });
            } else if (event.type === "mouseup" || event.type === "touchend") {
                this.setState({
                    status: "idle",
                    shape,
                    dragPoint: undefined,
                    dragPoints: undefined,
                });
            }
        }
    }

    private processForResize = (event: ReactMouseEvent | ReactTouchEvent): void => {
        const point = this.props.relativeCoordinatesForEvent(event);
        if (point && this.state.dragPoint && this.state.dragPoints && this.state.position) {
            const shape = this.updateShapePosForResize(this.state.shape, point, this.state.dragPoint, this.state.dragPoints, this.state.position);
            if (event.type === "mousemove" || event.type === "touchmove") {
                this.setState({ shape });
            } else if (event.type === "mouseup" || event.type === "touchend") {
                this.setState({
                    status: "idle",
                    position: undefined,
                    shape,
                    dragPoint: undefined,
                    dragPoints: undefined,
                });
            }
        }
    }

    private handleFinish = () => {
        this.props.onFinished(this.state.shape);
    }

    private updateShapePosForDrag = (shape: DraperyModule.Shape, coord: DraperyModule.Point, offset: DraperyModule.Point, orgPoints: DraperyModule.Point[]): DraperyModule.Shape => {
        shape.points = shape.points.map((point, index) => {
            const orgPoint = orgPoints[index];
            if (orgPoint) {
                point.x = orgPoint.x + coord.x - offset.x;
                point.y = orgPoint.y + coord.y - offset.y;
            }
            return {...point};
        });
        return shape;
    }

    private updateShapePosForResize = (shape: DraperyModule.Shape, coord: DraperyModule.Point, offset: DraperyModule.Point, orgPoints: DraperyModule.Point[], position: ResizePosition): DraperyModule.Shape => {
        const point0 = {...shape.points[0]};
        const point1 = {...shape.points[1]};
        if (position === ResizePosition.First) {
            point0.x = orgPoints[0].x + coord.x - offset.x;
            point0.y = orgPoints[0].y + coord.y - offset.y;
        } else if (position === ResizePosition.Second) {
            point1.x = orgPoints[1].x + coord.x - offset.x;
            point1.y = orgPoints[1].y + coord.y - offset.y;
        } else {
            if (position === ResizePosition.TopLeft || position === ResizePosition.BottomLeft) {
                if (point0.x < point1.x) {
                    const x = orgPoints[0].x + coord.x - offset.x;
                    point0.x = x < point1.x ? x : point1.x - 5;
                } else if (point0.x > point1.x) {
                    const x = orgPoints[1].x + coord.x - offset.x;
                    point1.x = point0.x > x ? x : point0.x - 5;
                }
            }
            if (position === ResizePosition.TopRight || position === ResizePosition.BottomRight) {
                if (point0.x < point1.x) {
                    const x = orgPoints[1].x + coord.x - offset.x;
                    point1.x = point0.x < x ? x : point0.x + 5;
                } else if (point0.x > point1.x) {
                    const x = orgPoints[0].x + coord.x - offset.x;
                    point0.x = x > point1.x ? x : point1.x + 5;
                }
            }
            if (position === ResizePosition.TopLeft || position === ResizePosition.TopRight) {
                if (point0.y < point1.y) {
                    const y = orgPoints[0].y + coord.y - offset.y;
                    point0.y = y < point1.y ? y : point1.y - 5;
                } else if (point0.y > point1.y) {
                    const y = orgPoints[1].y + coord.y - offset.y;
                    point1.y = point0.y > y ? y : point0.y - 5;
                }
            }
            if (position === ResizePosition.BottomLeft || position === ResizePosition.BottomRight) {
                if (point0.y < point1.y) {
                    const y = orgPoints[1].y + coord.y - offset.y;
                    point1.y = point0.y < y ? y : point0.y + 5;
                } else if (point0.y > point1.y) {
                    const y = orgPoints[0].y + coord.y - offset.y;
                    point0.y = y > point1.y ? y : point1.y + 5;
                }
            }
        }
        shape.points[0] = point0;
        shape.points[1] = point1;
        return shape;
    }
}

export default SelectedShape;
