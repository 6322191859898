import { Bind } from "lodash-decorators/bind";
import React, { PureComponent } from "react";
import Transition, { TransitionProps } from "react-transition-group/Transition";

export interface StyleDefinition {
    [key: string]: string | null;
}

interface StyleWithPriority {
    [key: string]: {
        /**
         * Null means unset value
         */
        value: string | null,
        /**
         * important or empty string
         */
        priority: string;
    };
}

export type StyleAnimationTimeout = number | { enter?: number; exit?: number; };

export interface StyleAnimationConfig {
    /**
     * Initial enter style
     */
    enterStyle?: StyleDefinition;
    /**
     * Enter style
     */
    enterStyleActive?: StyleDefinition;
    /**
     * Initial exit style
     */
    exitStyle?: StyleDefinition;
    /**
     * Exit style
     */
    exitActiveStyle?: StyleDefinition;
    /**
     * Animation timeout(s)
     */
    timeout: StyleAnimationTimeout;
}

export interface StyleTransitionProps extends StyleAnimationConfig, TransitionProps { }

/**
 * Simple style based transition
 */
class StyleTransition extends PureComponent<StyleTransitionProps> {
    /**
     * Styles to revert after completing the animation
     */
    private revertStyles: StyleWithPriority = {};

    /**
     * For some reason transition calls onEnter() two times and it will restore enterStyle styles after transition
     * Prevent it
     */
    private entering: boolean = false;

    public render(): JSX.Element {
        const { enterStyle, enterStyleActive, exitStyle, exitActiveStyle, ...other } = this.props;
        return (
            <Transition
                {...other}
                onEnter={this.onEnter}
                onEntering={this.onEntering}
                onEntered={this.onEntered}
                onExit={this.onExit}
                onExiting={this.onExiting}
                onExited={this.onExited}
            />
        );
    }

    /**
     * Before entering to animation. Applies initial enter style here
     *
     * @param node
     * @param isAppearing
     */
    @Bind()
    protected onEnter(node: HTMLElement, isAppearing: boolean): void {
        // store old style to restore it after enimation
        // this.oldStyle = node.style.cssText;
        const { enterStyle, onEnter } = this.props;
        if (this.entering) {
            return;
        }
        this.entering = true;

        if (enterStyle) {
            // store old styles
            this.revertStyles = this.getRevertStyles(node, enterStyle);
            this.applyStyleObjectToNode(node, this.makeAllImportant(enterStyle));
        }
        if (onEnter) {
            onEnter(node, isAppearing);
        }
    }

    /**
     * Entering to animation. Applies active enter style here
     *
     * @param node
     * @param isAppearing
     */
    @Bind()
    protected onEntering(node: HTMLElement, isAppearing: boolean): void {
        const { enterStyleActive, onEntering } = this.props;
        if (enterStyleActive) {
            this.revertStyles = this.getRevertStyles(node, enterStyleActive, this.revertStyles);
            // often browser doesn't start transition without some waiting
            setTimeout(() => {
                this.applyStyleObjectToNode(node, this.makeAllImportant(enterStyleActive));
            }, 0);
        }
        if (onEntering) {
            onEntering(node, isAppearing);
        }
    }

    /**
     * Finished enter animation. Restores styles here
     *
     * @param node
     * @param isAppearing
     */
    @Bind()
    protected onEntered(node: HTMLElement, isAppearing: boolean): void {
        this.entering = false;
        const { onEntered } = this.props;
        // Restore original node styles before animation
        // All animation added styles will be deleted
        // All replaced by animation styles will be restored
        this.applyStyleObjectToNode(node, this.revertStyles);
        this.revertStyles = {};
        if (onEntered) {
            onEntered(node, isAppearing);
        }
    }

    /**
     * Before leaving. Applies initial leave style here
     *
     * @param node
     */
    @Bind()
    protected onExit(node: HTMLElement): void {
        this.entering = false;
        const { exitStyle, onExit } = this.props;
        if (exitStyle) {
            this.revertStyles = this.getRevertStyles(node, exitStyle);
            this.applyStyleObjectToNode(node, this.makeAllImportant(exitStyle));
        }
        if (onExit) {
            onExit(node);
        }
    }

    /**
     * Exiting. Applies exit active style here
     *
     * @param node
     */
    @Bind()
    protected onExiting(node: HTMLElement): void {
        const { exitActiveStyle, onExiting } = this.props;
        if (exitActiveStyle) {
            this.revertStyles = this.getRevertStyles(node, exitActiveStyle, this.revertStyles);
            setTimeout(() => {
                this.applyStyleObjectToNode(node, exitActiveStyle);
            }, 0);
        }
        if (onExiting) {
            onExiting(node);
        }
    }

    /**
     * Exiting finished. Restores styles and calls original onExited() callback
     *
     * @param node
     */
    @Bind()
    protected onExited(node: HTMLElement): void {
        const { onExited } = this.props;
        this.applyStyleObjectToNode(node, this.revertStyles);
        this.revertStyles = {};
        if (onExited) {
            onExited(node);
        }

    }

    /**
     * Applies style declaration with !important to given node
     *
     * @param node
     * @param style
     */
    private applyStyleObjectToNode(node: HTMLElement, style: StyleDefinition | StyleWithPriority): void {
        if (!node.style) {
            return;
        }
        for (const k of Object.keys(style)) {
            const val = style[k];
            if (typeof val === "object" && val) {
                node.style.setProperty(k, val.value, val.priority);
            } else {
                node.style.setProperty(k, val);
            }
        }
    }

    /**
     * Return reverted styles for applied styles. New added styles in appliedStyle will be reocreded as null
     * Replaced styles will be recorded with original value
     *
     * @param nodeo
     * @param appliedStyle
     * @param initialStyle
     */
    private getRevertStyles(node: HTMLElement, appliedStyle: StyleDefinition, initialStyle: StyleWithPriority = {}): StyleWithPriority {
        if (!node.style) {
            return initialStyle;
        }
        return Object.keys(appliedStyle).reduce((obj, key) => {
            if (!obj[key]) {
                obj[key] = {
                    value: node.style.getPropertyValue(key),
                    priority: node.style.getPropertyPriority(key),
                };
            }
            return obj;
        }, initialStyle);
    }

    /**
     * Mark all styles in style definition as important ones
     *
     * @param style
     */
    private makeAllImportant(style: StyleDefinition): StyleWithPriority {
        return Object.keys(style).reduce((obj, key) => {
            obj[key] = {
                value: style[key],
                priority: "important",
            };
            return obj;
        // tslint:disable-next-line:no-object-literal-type-assertion
        }, {} as StyleWithPriority);
    }
}

export default StyleTransition;
