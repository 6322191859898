import {
    Button,
    Checkbox,
    FormField,
    ValidationForm,
    ValidationPopupInput,
} from "@ramble/ramble-ui";
import { DispatchProp, connect } from "react-redux";
import { FaEye, FaLock, FaUser } from "react-icons/fa";
import React, { FormEvent, PureComponent } from "react";
import { RootState, UserModule, WorkroomSettingModule } from "../redux";

import { AuthHeader } from "../components/auth/auth_header";
import Bind from "lodash-decorators/bind";
import { Logo } from "../components/auth/logo";
import { RouteComponentProps } from "react-router-dom";
import { RouteLink } from "../components/navigation/route_link";
import { TermMessage } from "../components/auth/term_message";
import { FormValidationError } from "@ramble/ramble-ui";
import styled from "../theme";
const LeftIconInput = styled(ValidationPopupInput)`
    flex-direction: row-reverse;

    & > svg {
        margin-right: 0.5em;
    }
`;

const LoginForm = styled(ValidationForm)`
    padding: 0 1em;
`;
// Old styling for signup link
const SignupLink = styled(RouteLink)`
    font-size: 0.8em;
`;
// // Temp styling for signup link (TEMP CODE)
// const SignupLink = styled.a`
//     font-size: 0.8em;
// `;
const RememberCheckbox = styled(Checkbox)`
    > svg {
        font-size: 1.2em;
    }
`;

const Actions = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    flex: 1 1 auto;
`;

const ForgotMessage = styled.div`
    margin: 1.5em 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
`;

// tslint:disable-next-line:no-empty-interface
export interface LoginOwnProps extends RouteComponentProps { }

export interface LoginProps extends LoginOwnProps {
    /**
     * Pre-filled username (if exists)
     */
    email?: string;
}

export class Login extends PureComponent<LoginProps & DispatchProp> {
    public render(): JSX.Element {
        const { email } = this.props;

        return (
            <>
                <Logo />
                <AuthHeader uiSize="1em">
                    LOGIN
                    {/*OLD LOGIC COMMENTED*/}
                    <SignupLink to="/signup">New to QUIPA? Join</SignupLink>
                    {/*(TEMP CODE)*/}
                    {/* <SignupLink
                        href="https://buy.stripe.com/eVa002e420up9cA000"
                        target="_blank"
                    >
                        New to QUIPA? Join
                    </SignupLink> */}
                </AuthHeader>
                <LoginForm
                    uiOnSubmit={this.login}
                    uiPadding={false}
                    uiSubmitFailedMessage="Login failed"
                    uiSubmitFailedIcon
                    uiReportValidityMode="firstInvalid"
                    uiDimmerBlurring={false}
                >
                    <FormField uiRequired uiErrorAlignment="left">
                        <LeftIconInput
                            id="email"
                            name="email"
                            required
                            minLength={1}
                            maxLength={255}
                            placeholder="E-Mail"
                            defaultValue={email}
                            pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                            uiReportOnBlur={false}
                        >
                            <FaUser />
                        </LeftIconInput>
                    </FormField>
                    <FormField uiRequired uiErrorAlignment="left">
                        <LeftIconInput
                            id="password"
                            name="password"
                            type="password"
                            required
                            minLength={1}
                            maxLength={255}
                            placeholder="Password"
                            uiReportOnBlur={false}
                            className="eye"
                        >
                            <FaEye
                                style={{
                                    position: "absolute",
                                    cursor: "pointer",
                                }}
                                onClick={this.handleShowPassword}
                            />
                            <FaLock />
                        </LeftIconInput>
                    </FormField>
                    <TermMessage>
                        By logging in, you agree to our{" "}
                        <a
                            href="https://www.quipa.com/terms-of-use"
                            target="_blank"
                        >
                            Terms of Use
                        </a>{" "}
                        and{" "}
                        <a
                            href="https://www.quipa.com/privacy-policy"
                            target="_blank"
                        >
                            Privacy Policy
                        </a>
                        .
                    </TermMessage>
                    <Actions>
                        <RememberCheckbox id="remember" defaultChecked>
                            Remember me
                        </RememberCheckbox>
                        <Button uiColor="primary" type="submit">
                            Login
                        </Button>
                    </Actions>
                </LoginForm>
                <ForgotMessage>
                    <RouteLink to="/request_reset">Forgot password?</RouteLink>
                </ForgotMessage>
            </>
        );
    }
    @Bind()
    private handleShowPassword() {
        const eye = document
            .querySelector(".eye")!
            .querySelector("input")! as HTMLInputElement;
        if (eye.type === "password") {
            eye.type = "text";
        } else {
            eye.type = "password";
        }
    }

    @Bind()
    private async login(e: FormEvent<HTMLFormElement>): Promise<void> {
        sessionStorage.setItem("showModal", "true");

        const { dispatch, location, history } = this.props;
        const elements = e.currentTarget.elements;
        // tslint:disable-next-line:no-string-literal
        const email = (elements["email"] as HTMLInputElement).value.trim();
        // tslint:disable-next-line:no-string-literal
        const password = (
            elements["password"] as HTMLInputElement
        ).value.trim();
        // tslint:disable-next-line:no-string-literal
        const remember =
            (elements["remember"] as HTMLInputElement).checked || false;

        const res = await dispatch(
            UserModule.loginByEmail(email, password, remember)
        );

        if (res.error) {
            if (res.payload.message === "Email not recognized") {
                throw FormValidationError.fromErrorDictionary({
                    email: res.payload.message,
                });
            } else if (res.payload.message === "Password incorrect") {
                throw FormValidationError.fromErrorDictionary({
                    password: res.payload.message,
                });
            } else {
                throw new Error(res.payload.message);
            }
        }
        if (location.state && location.state.redirectAfterLogin) {
            history.push({
                pathname: location.state.redirectAfterLogin,
                search: location.state.redirectAfterLoginSearch,
            });
        } else {
            if (res.payload.account.loginAllowed) {
                const res1 = await dispatch(
                    WorkroomSettingModule.searchWorkroomSetting(
                        res.payload.account.id
                    )
                );
                if (res1.error) {
                    throw new Error(res1.payload.message);
                } else {
                    const { token, account } = res.payload;
                    document.cookie = `access=${res.payload.crispAccessCookie}`;
                    localStorage.setItem(
                        `login-event-${account.id}`,
                        "login" + Math.random()
                    );
                    if (token) {
                        const jwtPayload = JSON.parse(
                            window.atob(token.split(".")[1])
                        );
                        const { exp } = jwtPayload;
                        const timeToExpire = exp * 1000 - new Date().getTime();
                        let logoutTimeout: any;
                        const events: any = [
                            "mousedown",
                            "mousemove",
                            "wheel",
                            "keydown",
                            "touchstart",
                            "scroll",
                        ];

                        events.forEach((event: any) => {
                            window.addEventListener(event, () => {
                                if (new Date() >= new Date((exp - 60) * 1000)) {
                                    clearTimeout(logoutTimeout);
                                }
                            });
                        });

                        logoutTimeout = setTimeout(async () => {
                            await dispatch(UserModule.logout());
                            localStorage.setItem(
                                `logout-event-${account.id}`,
                                "logout" + Math.random()
                            );
                            history.push("/login");
                            events.forEach((event: any) => {
                                window.removeEventListener(event, () => { });
                            });
                        }, timeToExpire);
                    }
                    history.push("/");
                }
            } else {
                history.push("/card");
            }
        }
    }
}

function mapStateToProps(
    state: RootState,
    ownProps: LoginOwnProps
): LoginProps {
    return {
        ...ownProps,
        email: state.user.email,
    };
}

export const LoginContainer = connect(mapStateToProps)(Login);
