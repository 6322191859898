import React, { ChangeEvent, PureComponent, UIEvent } from 'react'
import { connect, DispatchProp } from 'react-redux'
import {
  ClientDashboardModule,
  ClientModule,
  NotificationsModule,
  RoomModule,
  RootState,
  WorkOrderModule,
} from "../redux";
import { RouteComponentProps } from 'react-router-dom'
import { PageContent } from "../components/content/page_content";
import styled from '../theme'
import ProjectOverviewTable, {
  ProjectOverviewTableProps,
} from "../components/clients/project_overview_table";
import { SortTableRowInfo } from '../components/UI/table/sort_table'
import { exportPdf } from "../api/export-pdf";
import { Config } from "../utils";
import { SortTable } from '../components/clients/project_overview_table'
import { AccountModule } from "../redux";
import { checkIsBilling } from "../api/stripe";
import { createCrispProfile, getCrispProfile } from "../api/crisp";
import { Crisp } from "crisp-sdk-web";
import { ConfirmationModal } from "../../src/modals/confirmation_modal";
import {
  EditItemsModal,
  EditItemsModalProps,
  EditValues,
} from "../modals/edit-items-modal";
import { SearchSelectProps } from "../components/UI/search_select/search_select";
import LoadingModal from "../modals/loading-modal";
import { CSSTransition } from "react-transition-group";
import { PopoverScrollContext, Select as SelectE, Checkbox } from "@ramble/ramble-ui";
import { getRooms } from "../api/room";
import PaginationEndIcon from '../assets/icons/chevron-end.svg'
import PaginationNextIcon from '../assets/icons/chevron-right.svg'
import ClearIconE from "../assets/icons/clear.svg";
import SearchIconE from '../assets/icons/search.svg';
import Confetti from '../assets/icons/confetti.svg';
import PopUpClose from '../assets/icons/pop_up_close.svg';
import AddButtonE from "../components/UI/buttons/add-button";
import { FadeLoader } from 'react-spinners';

interface OverviewOwnProps extends RouteComponentProps { }

interface OverviewProps extends OverviewOwnProps {
  accountId: number;
  email: string;
  firstName: string;
  lastName: string;
  ripplefoldPopUp?: number;
}

interface OverviewModalProps extends OverviewOwnProps {
  isOpenModal: boolean;
  handleCloseModal(): void;
}

interface StateProps {
  showArchived: boolean;
  projectList: ClientDashboardModule.ClientProject[];
  sortValue: { field: string; direction: "ASC" | "DESC" };
  roomList: RoomModule.Room[];
  checkedProjects: number[];
  workOrderPdfStatus: "none" | "downloading" | "error";
  isLoadingOpen: boolean;
  accountId: number;
  workOrderPdfContent?: WorkOrderModule.WorkOrderPdfContent;
  isEditItemsModalOpen: boolean;
  isModalOpen: boolean;
  sort: "asc" | "desc";
  client?: ClientModule.Client;
  clients: SortTableRowInfo[];
  showModal: string | null;
  isPopUpModalOpen: boolean;
  value: string;
  isFetched: boolean;
  isEditClientModalOpen: boolean;
  displayContent: boolean;
  areaScrollInfo: {
    scrollX: number;
    scrollY: number;
  };
  pageLimit: number;
  pageNumber: number;
  totalPages: number;
  ordersLength: number;
  searchTerm: any;
  searchHighlightWord: any;
  ripplefoldPopUp?: number;
  pageLoading: boolean;
}

const OverviewHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  >div{
    padding-right: 10px;
    display: flex;
    align-items: center;

  >h1{
    font-family: "Poppins", serif;
    font-size: 18px;
    letter-spacing: 0px;
    margin-right: 15px;
    color: #222222;
    font-weight: 400;
  }

  & .search_container{
    display: flex;
    align-items: center;
    width: 402px;
    border: 1px solid hsla(0, 0%, 13.9%, 0.15);
    border-radius: 0.5em;
    overflow: hidden;
    padding-right: 8px;

    &.disabled {
      background: #f8f8f8;
    }
    
    & .search_input{
      padding: 0.67em 8px;
      fonst-size: 15px;
      color: rgba(27, 28, 29, 0.87);
      width: 100%;
      height: 100%;
      outline: none;
      font-style: italic;
    }
  }
  }
`

const ClearIcon = styled(ClearIconE)`
    width: 16px;
    fill: #3b87a0;
    cursor: pointer;
`;

const SearchIcon = styled(SearchIconE)`
    width: 16px;
    fill: #3b87a0;
`;

const OverviewBody = styled.div`
`
const OverviewInfo = styled.div`
    height: calc(100vh - 265px);
    overflow: auto;
    width: 100%;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
`;

const CSSTransitionBox = styled.div`
    .content-enter {
        opacity: 0;
        transform: scale(0.9);
    }

    .content-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
    }

    .content-exit {
        opacity: 1;
    }

    .content-exit-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 300ms, transform 300ms;
    }
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content: flex-end;
  min-height: 52px;
  height: 100%;
  margin-right: 10px;
  padding: 10px 33px;
  border: 1px solid #e2e2e2;
  border-bottom-left-radius: 0.28rem;
  border-bottom-right-radius: 0.28rem;
  
  & .pagination_options{
    display: flex;
    align-items: center;
    gap: 27px;
    height: 32px;
    
    & .pagination_option{
      display: flex;
      align-items:center;
      justify-content: center; 

      & span{
        font-weight: 500;
      }

      &.arrows{
        gap: 5px;

        & >div{
          display:flex;
          align-items:center;
          justify-content:center;
        }

        & svg{
          cursor: pointer;

          &.disabled{
            opacity: 0.4;
            cursor: auto;
          }
        }

        & .left_end_icon,
        & .prev_icon{
          transform: rotate(180deg);
        }
      }
    }
  }
`

const SelectPagination = styled(SelectE)`
  > .select {
    padding-right: 2.5em;
    border-color: #e2e2e2;
    border-radius: 6px;
  }

  > .chevron {
    color: #222222;
  }
`

interface PopUpProps {
  active?: number;
}

const RipplefoldPopUpMessageContainer = styled.div<PopUpProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) scale(${(props) => props.active ? '1' : '0'});
  width: 575px;
  height: 308px;
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0px 6px 5px 0px rgba(0,0,0,.3), 0px 10px 14px 8px rgba(0,0,0,.15);
  z-index: 999;
  transition : transform .15s ease;
`

const RipplefoldPopUpMessageDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & .desc_container{
    display: flex;
    flex-direction: column;
    gap: 12px;

    & .desc_with_icon{
      display: flex;
      gap: 21px;

      & .pop_up_heading{
        line-height: 42px;
        font-size: 32px;
        color: ${({ theme }) => theme.colors.primary};
      }

      & .pop_up_text{
        line-height: 24px;
        font-size: 16px;
        font-weight: 400;
        color: #545454;
        width: 90%;
      }
    }

    & .pop_up_instructions{
      & p{
        line-height: 24px;
        font-size: 16px;
        font-weight: 400;
        color: #545454;
        padding: 10px 0px;
      }
    }
  }

  & .action_container{
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & .continue_btn{
      background-color:  ${({ theme }) => theme.colors.primary};
      padding: 10px 16px;
      border-radius: 8px;
      color: white;
      font-size: 14px;
      line-height: 16px;
      border: 1px solid ${({ theme }) => theme.colors.primary};
      transition: all .15s ease;

      &:focus {
        border-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.primary};
        background: white;
      }
    }
  }
`

const AddButton = styled(AddButtonE)`
    margin-left: auto;
    font-size: 14px;
    border-radius: 8px;
    z-index: 10;
`;

const ShowArchivedCheckBox = styled(Checkbox)`
    font-size: 14px;
    color: #222;
    padding-left: 10px;
`;

export class OverviewPage extends PureComponent<OverviewProps & OverviewModalProps & DispatchProp, StateProps> {
  private debounceTimer: ReturnType<typeof setTimeout> | null = null;
  private searchInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: OverviewProps & OverviewModalProps & DispatchProp) {
    super(props)

    this.searchInputRef = React.createRef()
  }
  public state: StateProps = {
    showArchived: false,
    projectList: [],
    sortValue: { field: '', direction: 'ASC' },
    roomList: [
      {
        id: 0,
        name: "Please select",
        description: "",
      },
    ],
    checkedProjects: [],
    workOrderPdfStatus: "none",
    isLoadingOpen: false,
    accountId: 0,
    isEditItemsModalOpen: false,
    isModalOpen: false,
    sort: 'asc',
    clients: [],
    showModal: "",
    isPopUpModalOpen: true,
    value: '',
    isFetched: true,
    isEditClientModalOpen: false,
    displayContent: false,
    areaScrollInfo: {
      scrollX: 0,
      scrollY: 0,
    },
    pageLimit: 25,
    pageNumber: 1,
    totalPages: 0,
    ordersLength: 0,
    searchTerm: '',
    searchHighlightWord: '',
    ripplefoldPopUp: 0,
    pageLoading: true,
  }

  public async componentDidMount(): Promise<void> {
    const { accountId, dispatch } = this.props;
    const showModal = sessionStorage.getItem("showModal");
    this.setState({ showModal: showModal });

    let account: any

    try {
      if (accountId) {
        const res = await dispatch(AccountModule.getAccount(accountId));
        if (res.payload && "isModalOpen" in res.payload)
          this.setState({
            isPopUpModalOpen: res.payload.isModalOpen,
          });

        account = res.payload
      }
    } catch (error) {
      console.log(error);
    }
    try {
      await checkIsBilling(accountId);
      await createCrispProfile(accountId);
      const profile = await getCrispProfile(accountId);

      if (profile) {
        Crisp.user.setEmail(profile.email);
        Crisp.user.setNickname(`${profile.person.nickname}`);
      }
    } catch (error) {
      console.error(
        "An error occurred during component mounting:",
        error
      );
    } finally {
      this.loadClients();
      this.loadProjects();
    }

    if (account && (account.ripplefoldPopUp === 0 || !account.ripplefoldPopUp)) {
      await dispatch(AccountModule.updateAccountSettings(
        accountId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        1
      ));
      this.setState(prev => {
        return {
          ...prev,
          ripplefoldPopUp: 1
        }
      })
    } else {
      this.setState(prev => {
        return {
          ...prev,
          ripplefoldPopUp: 0
        }
      })
    }

  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.sortValue !== this.state.sortValue ||
      prevState.pageLimit !== this.state.pageLimit ||
      prevState.pageNumber !== this.state.pageNumber
    ) {
      this.loadProjects();
    }

    if (prevState.searchTerm !== this.state.searchTerm) {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer)
      }

      this.debounceTimer = setTimeout(() => {
        this.setState(prev => {
          return {
            ...prev,
            pageNumber: 1,
          }
        })
        this.loadProjects()
      }, 500)
    }

    if (this.state.projectList && this.state.projectList.length < 1 && this.state.pageNumber > 1) {
      this.setState(prev => {
        return {
          ...prev,
          pageNumber: prev.totalPages
        }
      })
    }
  }

  private handlePageLimitChange(e: ChangeEvent<HTMLSelectElement>): void {
    const { value } = e.currentTarget

    const numberValue = Number(value)

    if (isNaN(numberValue)) {
      return
    }

    this.setState(prev => {
      return {
        ...prev,
        pageNumber: 1,
        pageLimit: numberValue
      }
    })
  }

  private handleNextPage(): void {
    this.setState(prev => {
      return {
        ...prev,
        pageNumber: prev.pageNumber + 1
      }
    })
  }

  private handlePrevPage(): void {
    if (this.state.pageNumber <= 1) {
      return
    }
    this.setState(prev => {
      return {
        ...prev,
        pageNumber: prev.pageNumber - 1
      }
    })
  }

  private handleFirstPage(): void {
    this.setState(prev => {
      return {
        ...prev,
        pageNumber: 1
      }
    })
  }

  private handleLastPage(): void {
    this.setState(prev => {
      return {
        ...prev,
        pageNumber: prev.totalPages
      }
    })
  }

  private checkAllRows = (checked: boolean) => {
    this.setState({
      checkedProjects: checked
        ? this.state.projectList.map((p) => p.itemId)
        : [],
    });
  };

  private checkRow = (id: number, checked: boolean) => {
    if (checked) {
      if (this.state.checkedProjects.includes(id)) {
        return;
      }
      this.setState({
        checkedProjects: [...this.state.checkedProjects, id],
      });
    } else {
      this.setState({
        checkedProjects: this.state.checkedProjects.filter(
          (cid) => cid !== id
        ),
      });
    }
  };

  private handleNotCheckedBatchRow = (id: number, project: ClientDashboardModule.ClientProject) => {
    if (project.archived) {
      return
    }
    if (this.state.checkedProjects.includes(id)) {
      return
    }
    this.setState({
      checkedProjects: [...this.state.checkedProjects, id]
    })
  }

  private handleProjectItemClicked = (
    id?: number | undefined,
    itemId?: number,
    item?: string,
    target?: "summary"
  ) => {
    if (id && itemId) {
      this.gotoDraperyOrder(id, itemId, item, "summary");
    }
  };

  private handleProjectWorkOrderNumClicked = async (id: number) => {
    const { dispatch } = this.props;
    const { workOrderPdfStatus: draperyPdf } = this.state;
    if (draperyPdf === "none") {
      const res = await dispatch(
        WorkOrderModule.getWorkOrderPdfContent(id)
      );
      if (res.error) {
        return;
      }
      this.setState({
        isLoadingOpen: true,
        workOrderPdfStatus: "downloading",
        workOrderPdfContent: res.payload,
      }, async () => {
        const resPdf: any = await exportPdf(
          res.payload,
          Config.fileStoreUrl
        );
        if (resPdf.error) return;

        const buffer = Buffer.from(resPdf.buffer);
        const blob = new Blob([buffer], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        window.open(url, "_blank");
        this.handleDraperyPdfFinished(true);
      });
    }
  };

  private handleDraperyPdfFinished = (success: boolean) => {
    const { dispatch } = this.props;
    if (success) {
      if (!this.state.isLoadingOpen) {
        const notification: NotificationsModule.Notification = {
          id: 0,
          message: "Work order created successfully!",
          type: "info",
        };
        dispatch(NotificationsModule.addNotification(notification));
      }
      this.setState({
        workOrderPdfStatus: "none",
        isLoadingOpen: false,
      });
    } else {
      if (!this.state.isLoadingOpen) {
        const notification: NotificationsModule.Notification = {
          id: 0,
          message: "Failed to create work order !",
          type: "error",
        };
        dispatch(NotificationsModule.addNotification(notification));
      }
      this.setState({
        workOrderPdfStatus: "error",
        isLoadingOpen: false,
      });
    }
  };

  private updateProjects: ProjectOverviewTableProps["onEdited"] = (
    projects
  ) => {
    const newProjects = [...this.state.projectList];
    for (const proj of projects) {
      const projIdx = this.state.projectList.findIndex(
        (p) => p.itemId === proj.itemId
      );
      if (projIdx === -1) {
        continue;
      }
      newProjects[projIdx] = proj;
      // actually API should support batch editing multiple project at once and we need to await here to handle any errors
      this.editProjectItem(proj);
    }
    this.setState({ projectList: newProjects });
  };

  private editProjectItem = async (
    project: ClientDashboardModule.ClientProject
  ): Promise<void> => {
    const { dispatch } = this.props;

    const res = await dispatch(
      ClientDashboardModule.editClientProject(project)
    );

    if (res.error) {
      return;
    }
  };

  private handleProjectAction = async (value: string): Promise<void> => {
    switch (value) {
      case "Archive": {
        this.processItems("archive");
        return;
      }
      case "Unarchive": {
        this.processItems("unarchive");
        return;
      }
      case "Batch Edit": {
        this.setState({ isEditItemsModalOpen: true });
        return;
      }
      case "Duplicate": {
        this.processItems("duplicate");
        return;
      }
      case "Delete": {
        this.processItems("delete");
        return;
      }
    }
  };

  private processItems = async (
    action: "archive" | "unarchive" | "duplicate" | "edit" | "delete",
    isActive?: boolean
  ) => {
    const { dispatch, accountId } = this.props;
    const { projectList, checkedProjects } = this.state;
    const projectsToProcess = projectList.filter((project) =>
      checkedProjects.includes(project.itemId)
    );
    if (action === "edit" && isActive === false) {
      for (const project of projectList) {
        const res = await dispatch(
          ClientDashboardModule.archiveClientProjectItem(
            accountId,
            project.itemId,
            action === "edit"
          )
        );
        if (res.error) {
          return;
        }
      }
      this.loadProjects();
    } else if (projectList.length === 0) {
      const { dispatch } = this.props;
      const sort = this.state.sortValue
      const getProjectsRes = await dispatch(
        ClientDashboardModule.getAllProjects(
          this.props.accountId,
          true,
          sort,
          this.state.pageLimit,
          this.state.pageNumber,
          this.state.searchTerm,
        )
      );
      if (Array.isArray(getProjectsRes.payload)) {
        for (const project of getProjectsRes.payload) {
          const res = await dispatch(
            ClientDashboardModule.archiveClientProjectItem(
              accountId,
              project.itemId,
              false
            )
          );
          this.loadProjects();

          if (res.error) {
            return;
          }
        }
      }
    }

    if (!projectsToProcess.length) {
      return;
    }
    for (const project of projectsToProcess) {
      if (
        (action === "archive" && !project.archived) ||
        (action === "unarchive" && project.archived)
      ) {
        const res = await dispatch(
          ClientDashboardModule.archiveClientProjectItem(
            accountId,
            project.itemId,
            action === "archive"
          )
        );
        if (res.error) {
          return;
        }
      } else if (action === "duplicate") {
        const res = await dispatch(
          ClientDashboardModule.copyDraperyOrder(
            accountId,
            project.itemId
          )
        );
        if (res.error) {
          return;
        }
      }
    }
    if (action === "delete") {
      for (const project of projectsToProcess) {
        const res = await dispatch(
          ClientDashboardModule.deleteClientProject(project)
        );

        if (res.error) {
          return;
        }
      }
    }
    this.setState({
      checkedProjects: [],
      isModalOpen: false,
    });
    this.loadProjects();
  };

  private onShowArchiveChange = (isShow: boolean): void => {
    this.setState(
      {
        showArchived: isShow,
      },
      () => {
        this.loadProjects();
      }
    );
  };

  private onOpenModal = (value: string) => {
    this.setState({ isModalOpen: true, value });
  };

  private mapSortTableToSortOptions = (sortTable: SortTable): void => {
    this.setState(prev => {
      return {
        ...prev,
        sortValue: {
          field: sortTable.field,
          direction: sortTable.position === 1 ? "ASC" : 'DESC'
        }
      }
    })
  };

  private onAreaScroll = (e: UIEvent<HTMLDivElement>): void => {
    this.setState({
      areaScrollInfo: {
        scrollX: e.currentTarget.scrollLeft,
        scrollY: e.currentTarget.scrollTop,
      },
    });
  };

  private gotoDraperyOrder = (
    clientId: number | undefined,
    draperyOrderId?: number,
    clientDisplayName?: string,
    tab?: "summary"
  ) => {
    const { history } = this.props;
    let draperyItem: any = localStorage.getItem("draperyItem");
    if (draperyItem) {
      draperyItem = JSON.parse(draperyItem);
    } else {
      draperyItem = {};
    }
    draperyItem.from = location.pathname;
    localStorage.setItem("draperyItem", JSON.stringify(draperyItem));

    history.push("/calculator", {
      clientId,
      draperyOrderId,
      clientDisplayName,
      tab,
    });
  };

  private editItemModalClose = () => {
    this.setState({ isEditItemsModalOpen: false });
  };

  private batchEdit: EditItemsModalProps["onSave"] = async (newValues) => {
    if (!this.state.checkedProjects.length) {
      return;
    }
    this.setState({ isEditClientModalOpen: false });
    const nonEmptyValues = Object.entries(newValues).reduce(
      (all, [key, val]) => {
        if (val) {
          all[key] = val;
        }
        return all;
      },
      {} as Partial<EditValues>
    );

    const filteredNewProjects = this.state.projectList.filter(i => !i.archived)

    let newProjects = [...filteredNewProjects];
    for (const id of this.state.checkedProjects) {
      const projIdx = newProjects.findIndex((proj) => proj.itemId === id);
      if (projIdx === -1) {
        continue;
      }
      const proj = newProjects[projIdx];
      if (!proj) {
        continue;
      }
      const newProj: ClientDashboardModule.ClientProject = {
        ...proj,
        ...nonEmptyValues,
      };

      newProjects[projIdx] = newProj;

      await this.editProjectItem(newProj);
      this.loadProjects()
    }
    this.setState({ projectList: newProjects, checkedProjects: [], isEditItemsModalOpen: false });
  };

  private handleSearchClient: SearchSelectProps["onSearch"] = async (
    search: string
  ) => {
    const { dispatch } = this.props
    const res = await dispatch(
      ClientModule.searchClient(search, this.props.accountId)
    );
    if (res.error) {
      return [];
    }
    return res.payload.map((r) => ({
      id: r.id,
      displayName:
        (r.parent ? r.parent.displayName + " | " : "") + r.displayName,
    }));
  };

  private toggleLoadingModal = () => {
    this.setState({ isLoadingOpen: !this.state.isLoadingOpen });
  };

  private async loadClients(): Promise<void> {
    const { accountId, dispatch } = this.props;
    const res = await dispatch(
      ClientModule.getClients(accountId, this.state.sort, false)
    );
    if (!res.error) {
      const convertToSortTableRowInfo = (
        client: ClientModule.BasicClientInfo
      ) => {
        const rowInfo: SortTableRowInfo = client;
        if (client.subClients) {
          rowInfo.subList = client.subClients.map(
            convertToSortTableRowInfo
          );
        }
        return rowInfo;
      };

      const clients = res.payload.map(convertToSortTableRowInfo);
      const allSubClients = clients.reduce(
        (acc, client) => acc.concat(client.subList || []),
        [] as SortTableRowInfo[]
      );

      const updatedClients = [...clients, ...allSubClients];

      this.setState({
        clients: updatedClients,
      });
    }
  }

  private handleNewClientProject = () => {
    const { client } = this.state;
    if (client) {
      this.gotoDraperyOrder(client.id, 0, client.displayName);
    } else {
      this.props.history.push('/calculator')
    }
  };

  private loadProjects = async () => {
    const { dispatch } = this.props;
    const { showArchived } = this.state;
    const sort = this.state.sortValue

    this.setState(prev => {
      return {
        ...prev,
        pageLoading: true,
      }
    })

    const getProjectsRes = await dispatch(
      ClientDashboardModule.getAllProjects(
        this.props.accountId,
        showArchived,
        sort,
        this.state.pageLimit,
        this.state.pageNumber,
        this.state.searchTerm
      )
    );

    if (getProjectsRes.error) {
      return;
    }

    const projectList: ClientDashboardModule.ClientProject[] =
      getProjectsRes.payload.result;
    const getRoomListRes = await getRooms();
    if (getRoomListRes.error) {
      return;
    }
    const roomList = [...this.state.roomList.concat(getRoomListRes)];

    this.setState({
      projectList,
      roomList,
      isFetched: false,
      totalPages: getProjectsRes.payload.totalPages,
      ordersLength: getProjectsRes.payload.ordersLength,
      searchHighlightWord: this.state.searchTerm,
      pageLoading: false,
    });

    if (this.searchInputRef.current) {
      this.searchInputRef.current.focus()
    }
  };

  private handleChangeValueSearchInput(e: ChangeEvent<HTMLInputElement>): void {
    const { value } = e.currentTarget

    this.setState(prev => {
      return {
        ...prev,
        searchTerm: value
      }
    })
  }

  private handleClearSearchKeyword(): void {
    this.setState(prev => {
      return {
        ...prev,
        searchTerm: ''
      }
    })
  }

  private handleCloseRipplefoldPopUp(): void {
    this.setState(prev => {
      return {
        ...prev,
        ripplefoldPopUp: 0
      }
    })
  }

  private handleOpenMyShop(): void {
    this.props.history.push('/myShop?ripplefold_pop_up=true')
  }

  private onShowArchiveChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    this.onShowArchiveChange(checked);
  };

  public render(): JSX.Element {
    setTimeout(() => {
      this.setState({ displayContent: true });
    }, 100);
    const { showArchived, projectList, roomList, checkedProjects, isFetched, client } = this.state

    const numberDelete = projectList.filter((project) =>
      checkedProjects.includes(project.itemId)
    ).length;

    return (
      <PageContent>
        <OverviewHeader>
          <div>
            <h1>Workroom Dashboard</h1>

            <div className={`search_container ${this.state.pageLoading ? 'disabled' : ''}`}>
              <input ref={this.searchInputRef} disabled={this.state.pageLoading} value={this.state.searchTerm || ''} onChange={(e) => this.handleChangeValueSearchInput(e)} className="search_input" placeholder="Search..." />

              {this.state.searchTerm ? (
                <button disabled={this.state.pageLoading} type="button" onClick={() => this.handleClearSearchKeyword()}>
                  <ClearIcon />
                </button>
              ) : (
                <SearchIcon />
              )}
            </div>

            <div>
              <ShowArchivedCheckBox
                checked={showArchived}
                onChange={this.onShowArchiveChanges}
              >
                Show archived
              </ShowArchivedCheckBox>
            </div>
          </div>

          <div>
            <AddButton
              title="Add Item"
              onClick={() => this.handleNewClientProject()}
            />
          </div>
        </OverviewHeader>

        <OverviewBody>

          {isFetched ? (
            // <OverviewInfo>Loading...</OverviewInfo>
            <FadeLoader
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(50%,-50%)",
                    zIndex: 9999,
                    display: !isFetched ? "none" : "block",
                }}
                color="#3B97B1"
                aria-label="Loading Spinner"
                data-testid="loader"
            />

          ) : (
            <CSSTransitionBox>
              <CSSTransition
                in={this.state.displayContent}
                timeout={300}
                classNames="content"
                unmountOnExit
              >
                <OverviewInfo onScroll={this.onAreaScroll}>
                  <PopoverScrollContext.Provider value={this.state.areaScrollInfo}>
                    <ConfirmationModal
                      uiActive={this.state.isModalOpen && this.state.value === "Duplicate"}
                      uiOnConfirm={() =>
                        this.handleProjectAction("Duplicate")
                      }
                      uiOnClose={() => {
                        this.setState({ isModalOpen: false, value: "" });
                      }}
                      uiOnRequestClose={() => {
                        this.setState({ isModalOpen: false, value: "" });
                      }}
                      uiCloseOnOutsideClick={true}
                      uiCloseOnEsc={true}
                    >
                      <p>
                        {`Are you sure you want to duplicate ${numberDelete} ${numberDelete === 1 ? "item" : "items"
                          }?`}
                      </p>
                    </ConfirmationModal>
                    <ConfirmationModal
                      uiActive={this.state.isModalOpen && this.state.value === "Delete"}
                      uiOnConfirm={() =>
                        this.handleProjectAction("Delete")
                      }
                      uiOnClose={() => {
                        this.setState({ isModalOpen: false, value: "" });
                      }}
                      uiOnRequestClose={() => {
                        this.setState({ isModalOpen: false, value: "" });
                      }}
                      uiCloseOnOutsideClick={true}
                      uiCloseOnEsc={true}
                    >
                      <p>
                        {`Are you sure you want to delete ${numberDelete} ${numberDelete === 1 ? "item" : "items"
                          }?`}
                      </p>
                    </ConfirmationModal>
                    <ConfirmationModal
                      uiActive={this.state.isModalOpen && this.state.value === "Archive"}
                      uiOnConfirm={() =>
                        this.handleProjectAction("Archive")
                      }
                      uiOnClose={() => {
                        this.setState({ isModalOpen: false, value: "" });
                      }}
                      uiOnRequestClose={() => {
                        this.setState({ isModalOpen: false, value: "" });
                      }}
                      uiCloseOnOutsideClick={true}
                      uiCloseOnEsc={true}
                    >
                      <p>
                        {`Are you sure you want to archive ${numberDelete} ${numberDelete === 1 ? "item" : "items"
                          }?`}
                      </p>
                    </ConfirmationModal>

                    <ProjectOverviewTable
                      projectList={projectList}
                      roomList={roomList}
                      checkedProjects={checkedProjects}
                      showArchived={showArchived}
                      isParent={true}
                      onNewClientProject={this.handleNewClientProject}
                      onCheckAllRows={this.checkAllRows}
                      onCheckRow={this.checkRow}
                      onItemColumnClick={
                        this.handleProjectItemClicked
                      }
                      onWorkOrderColumnClick={
                        this.handleProjectWorkOrderNumClicked
                      }
                      onEdited={this.updateProjects}
                      onActionSelected={this.handleProjectAction}
                      onShowArchiveChange={this.onShowArchiveChange}
                      onOpenModal={this.onOpenModal}
                      handleNotCheckedBatchRow={this.handleNotCheckedBatchRow}
                      client={client}
                      clients={this.state.clients}
                      // accountId={this.props.accountId}
                      editProjectItem={this.editProjectItem}
                      mapSortTableToSortOptions={this.mapSortTableToSortOptions}
                      overviewPage={true}
                      loadProjects={this.loadProjects}
                      borderBottom
                      paddingBottom
                      searchTerm={this.state.searchHighlightWord}
                      pageLoading={this.state.pageLoading}
                    />

                    <EditItemsModal
                      active={this.state.isEditItemsModalOpen}
                      onRequestClose={this.editItemModalClose}
                      roomList={roomList}
                      onSave={this.batchEdit}
                      clients={this.state.clients}
                      handleSearchClient={this.handleSearchClient}
                    />
                    <LoadingModal
                      message="Creating work order..."
                      isModalOpen={this.state.isLoadingOpen}
                      onModalClose={this.toggleLoadingModal}
                    />
                  </PopoverScrollContext.Provider>
                </OverviewInfo>
              </CSSTransition>

              <PaginationContainer>
                <div className="pagination_options">
                  <div className="pagination_option">
                    <span>Rows per page</span>
                  </div>
                  <div className="pagination_option select">
                    <SelectPagination onChange={(e) => this.handlePageLimitChange(e)} defaultValue={25}>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </SelectPagination>
                  </div>
                  <div className="pagination_option">
                    <span>{this.state.pageNumber > 1 ? (this.state.pageLimit * this.state.pageNumber) - (this.state.pageLimit - 1) : 1}-{this.state.ordersLength >= this.state.pageLimit * this.state.pageNumber ? this.state.pageLimit * this.state.pageNumber : this.state.ordersLength} of {this.state.ordersLength}</span>
                  </div>
                  <div className="pagination_option arrows">
                    <div>
                      <button disabled={this.state.pageNumber <= 1 || this.state.pageLoading} onClick={() => this.handleFirstPage()}>
                        <PaginationEndIcon className={`left_end_icon ${this.state.pageNumber <= 1 ? 'disabled' : ''}`} />
                      </button>
                    </div>

                    <div>
                      <button disabled={this.state.pageNumber <= 1 || this.state.pageLoading} onClick={() => this.handlePrevPage()}>
                        <PaginationNextIcon className={`prev_icon ${this.state.pageNumber <= 1 ? 'disabled' : ''}`} />
                      </button>
                    </div>

                    <div>
                      <button disabled={this.state.pageNumber >= this.state.totalPages || this.state.pageLoading} onClick={() => this.handleNextPage()}>
                        <PaginationNextIcon className={`next_icon ${this.state.pageNumber >= this.state.totalPages ? 'disabled' : ''}`} />
                      </button>
                    </div>

                    <div>
                      <button disabled={this.state.pageNumber >= this.state.totalPages || this.state.pageLoading} onClick={() => this.handleLastPage()}>
                        <PaginationEndIcon className={`right_end_icon ${this.state.pageNumber >= this.state.totalPages ? 'disabled' : ''}`} />
                      </button>
                    </div>
                  </div>
                </div>
              </PaginationContainer>
            </CSSTransitionBox>
          )}
        </OverviewBody>

        <RipplefoldPopUpMessageContainer active={this.state.ripplefoldPopUp}>
          <RipplefoldPopUpMessageDescription>
            <div className='desc_container'>
              <div className="desc_with_icon">
                <div style={{ gap: '10px' }} className="flex flex-col">
                  <span className="pop_up_heading">Ripplefold has arrived!</span>
                  <span className="pop_up_text">
                    We are pleased to announce that QUIPA Drapery Orders will now include an option for Ripplefold!
                  </span>
                </div>

                <div className="flex items-center">
                  <Confetti style={{ width: '110px' }} />
                </div>

                <div>
                  <button type="button" onClick={() => this.handleCloseRipplefoldPopUp()}>
                    <PopUpClose />
                  </button>
                </div>
              </div>
              <div className="pop_up_instructions">
                <p>Please click “Continue” to add Ripplefold pricing (required) and construction settings (recommended) in My Shop.</p>
              </div>
            </div>

            <div className="action_container">
              <button className="continue_btn" type='button' onClick={() => this.handleOpenMyShop()}>Continue</button>
            </div>
          </RipplefoldPopUpMessageDescription>
        </RipplefoldPopUpMessageContainer>
      </PageContent>
    )
  }
}

function mapStateToProps(
  state: RootState,
  ownProps: OverviewOwnProps
): OverviewProps {
  return {
    ...ownProps,
    accountId: state.account.id,
    email: state.user.email,
    firstName: state.user.firstName,
    lastName: state.user.lastName,
  };
}

export const Overview = connect(mapStateToProps)(OverviewPage);