import Color from "color";
import { ClassAttributes, HTMLAttributes } from "react";
import styled, { css } from "../theme/styled";
import { breakpoints } from "../theme/theme";

export interface ModalElementUIProps {
    /**
     * Basic modal
     */
    uiBasic?: boolean;
    /**
     * Render default size
     * @default true
     */
    uiDefaultSizes?: boolean;
}

export type ModalElementProps = HTMLAttributes<HTMLElement> & ModalElementUIProps;

/**
 * Modal element
 */
const ModalElement = styled.article<ModalElementProps & ClassAttributes<HTMLElement>> `
    z-index: ${({ theme }) => theme.zIndex.modal};
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 90%;
    margin: 1rem;
    border: none;
    font-size: 1rem;
    background: ${({ theme }) => theme.colors.background};
    border-radius: 0.28rem;
    box-shadow:
        1px 3px 3px 0 ${({ theme }) => Color(theme.colors.divider).alpha(0.2).string()},
        1px 3px 15px 2px ${({ theme }) => Color(theme.colors.divider).alpha(0.2).string()};
    ${props => props.uiBasic && css`
        border: initial;
        font-size: initial;
        background: initial;
        border-radius: initial;
        box-shadow: initial;
    `};
    ${({ uiDefaultSizes }) => uiDefaultSizes && css`
         ${breakpoints.up("xs", css` max-width: 90%; `)};
         ${breakpoints.up("sm", css` max-width: 550px; `)};
         ${breakpoints.up("md", css` max-width: 800px; `)};
    `};
`;

ModalElement.defaultProps = {
    uiBasic: false,
    uiDefaultSizes: true,
};

export default ModalElement;
