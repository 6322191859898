import { ClassAttributes, HTMLAttributes } from "react";
import styled from "../theme/styled";

export interface HeaderUIProps {
    /**
     * Header size
     */
    uiSize?: string | number;
}

export type HeaderProps = HTMLAttributes<HTMLHeadingElement> & HeaderUIProps;

/**
 * Just header
 */
const Header = styled.h1<HeaderProps & ClassAttributes<HTMLHeadingElement>> `
    text-transform: none;
    padding: 0;
    line-height: 1.28;
    font-weight: 200;
    font-size: ${props => typeof props.uiSize === "string" ? props.uiSize : props.uiSize + "rem"};
    display: inline-flex;
    flex: 0 0 auto;
    margin: 0.5rem 0 1rem;
`;

Header.defaultProps = {
    uiSize: "2rem",
};

export default Header;
