import { Api, Req } from "@quipa/api";

export const getSuppliers = (accountId: number, nameSort: "asc" | "desc", typeSort: "asc" | "desc", includeInactive?: boolean) =>
    Api.createAction(Api.GET_SUPPLIERS, { accountId, nameSort, typeSort, includeInactive }, { authenticated: true });

export const getSupplier = (id: number) => Api.createAction(Api.GET_SUPPLIER, { id }, { authenticated: true });

export const addSupplier = (supplier: Api.AddSupplierAction[Req]) => Api.createAction(Api.ADD_SUPPLIER, supplier, { authenticated: true });

export const editSupplier = (supplier: Api.EditSupplierAction[Req]) => Api.createAction(Api.EDIT_SUPPLIER, supplier, { authenticated: true });

export const searchSuppliers = (accountId: number, text: string, nameSort: "asc" | "desc", typeSort: "asc" | "desc", includeInactive?: boolean) =>
    Api.createAction(Api.SEARCH_SUPPLIERS, { accountId, text, nameSort, typeSort, includeInactive }, { authenticated: true });
