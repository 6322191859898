import React, { ChangeEvent, PureComponent } from "react";
import styled, { css } from "../../../theme";

import Bind from "lodash-decorators/bind";
import ClearIconE from "../../../assets/icons/clear.svg";
import SearchIcon from "../../../assets/icons/search.png";
import { ValidationPopupInput } from "@ramble/ramble-ui";
import { detect } from "detect-browser";

const Container = styled.div`
    position: relative;
    width: 402px;
`;

const SearchInput = styled(ValidationPopupInput)<SearchInputProps>`
    padding: 0.67em 25px 0.67em 8px;
    color: rgba(27, 28, 29, 0.87);
    font-size: 15px;
    border: 1px solid hsla(0, 0%, 13.9%, 0.15);
    width: 100%;
    background: url(${SearchIcon}) #fff no-repeat 98% center;
    border-radius: 0.5em;

    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px rgb(154, 184, 124);
    }

    ::placeholder {
        color: #838790;
    }

    ${props => props.searchTextExist && css`
        background: none;
    `}
`;

const ClearIconBox = styled.span`
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    cursor: pointer;
    line-height: 0;
    right: 7px;
`;

const ClearIcon = styled(ClearIconE)`
    width: 15px;
    fill: #3b87a0;
`;

export interface SearchInputProps {
    searchTextExist?: boolean;
}

export interface SearchSelectProps {
    name?: string;
    placeholder?: string;
    keepKeyword?: boolean;
    initialValue?: string;
    modalSearch?: boolean;
    disabled?: boolean;
    onSearch(str: string): void;
    onKeywordCleared(): void;
}

interface State {
    searchKeyword: string;
}

class SearchSelect extends PureComponent<SearchSelectProps, State> {
    public state: State = {
        searchKeyword: this.props.initialValue || "",
    };

    public componentDidUpdate(prevProps: SearchSelectProps) {
        if (prevProps.initialValue !== this.props.initialValue && this.props.initialValue) {
            this.setState({searchKeyword: this.props.initialValue});
        }
    }

    public render(): JSX.Element {
        const browser = detect();
        const { searchKeyword } = this.state;
        return (
            <Container>
                {searchKeyword && !this.props.disabled && (
                    <ClearIconBox onMouseDown={this.handleClearKeyword}>
                        <ClearIcon />
                    </ClearIconBox>
                )}
                <SearchInput
                    id={""}
                    searchTextExist={searchKeyword.length > 0}
                    type="text"
                    value={searchKeyword}
                    onChange={this.changeSearchKeyword}
                    onKeyUp={this.performSearch}
                    onFocus={this.performSearch}
                    placeholder={this.props.placeholder || ""}
                    uiReportOnBlur={false}
                    data-index={0}
                    autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                    list="autocompleteOff"
                    className="search-select"
                    disabled={this.props.disabled}
                    uiDisabled={this.props.disabled}
                />
            </Container>
        );
    }

    @Bind()
    private handleClearKeyword(): void {
        this.setState({ searchKeyword: "" });
        if (this.props.onKeywordCleared) {
            this.props.onKeywordCleared();
        }
    }

    @Bind()
    private changeSearchKeyword(e: ChangeEvent<HTMLInputElement>): void {
        this.setState({
            searchKeyword: e.currentTarget.value,
        });
    }

    @Bind()
    private async performSearch(): Promise<void> {
        if (this.props.disabled) {
            return;
        }

        const search = this.state.searchKeyword;
        await this.props.onSearch(search);
    }
}

export default SearchSelect;
