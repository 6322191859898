import React, { forwardRef, SVGAttributes } from "react";

export interface LoaderIconUIProps {
    children?: never;
}

export type LoaderIconProps = SVGAttributes<SVGSVGElement> & LoaderIconUIProps;

const LoaderIcon = forwardRef<SVGSVGElement, LoaderIconProps>((props, ref) => {
    const { style, ...other } = props;
    return (
        <svg
            fill="currentColor"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 40 40"
            {...other}
            style={{
                verticalAlign: "middle",
                ...style,
            }}
            ref={ref}
        >
            {/* tslint:disable-next-line:max-line-length */}
            <g><path d="m11.7 31.1q0 1.2-0.8 2t-2 0.9q-1.2 0-2-0.9t-0.9-2q0-1.2 0.9-2t2-0.8 2 0.8 0.8 2z m11.2 4.6q0 1.2-0.9 2t-2 0.9-2-0.9-0.9-2 0.9-2 2-0.8 2 0.8 0.9 2z m-15.8-15.7q0 1.2-0.8 2t-2 0.9-2-0.9-0.9-2 0.9-2 2-0.9 2 0.9 0.8 2z m26.9 11.1q0 1.2-0.9 2t-2 0.9q-1.2 0-2-0.9t-0.8-2 0.8-2 2-0.8 2 0.8 0.9 2z m-21.5-22.2q0 1.5-1.1 2.5t-2.5 1.1-2.5-1.1-1.1-2.5 1.1-2.5 2.5-1.1 2.5 1.1 1.1 2.5z m26.1 11.1q0 1.2-0.9 2t-2 0.9-2-0.9-0.8-2 0.8-2 2-0.9 2 0.9 0.9 2z m-14.3-15.7q0 1.8-1.3 3t-3 1.3-3-1.3-1.3-3 1.3-3.1 3-1.2 3 1.3 1.3 3z m11.8 4.6q0 2.1-1.5 3.5t-3.5 1.5q-2.1 0-3.5-1.5t-1.5-3.5q0-2.1 1.5-3.5t3.5-1.5q2.1 0 3.5 1.5t1.5 3.5z" /></g>
        </svg>
    );
});
LoaderIcon.defaultProps = {
    height: "1em",
    width: "1em",
};

/**
 * Just loader icon. Taken from react-icons
 */
export default LoaderIcon;
