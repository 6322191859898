import { Omit } from "../../../../types/index";
import { ActionDescriptor } from "../../common";
import { Room } from "./interfaces";

export const ADD_ROOM = "room/ADD";
export type AddRoomAction = ActionDescriptor<
    typeof ADD_ROOM,
    Omit<Room, "id"> & { accountId: number },
    Room
>;

export const EDIT_ROOM = "room/EDIT";
export type EditRoomAction = ActionDescriptor<typeof EDIT_ROOM, Room, {}>;

export const GET_ROOM = "room/GET";
export type GetRoomAction = ActionDescriptor<
    typeof GET_ROOM,
    { id: number },
    Room
>;

export const GET_ROOMS = "room/GET_ALL";
export type GetRoomsAction = ActionDescriptor<
    typeof GET_ROOMS,
    {
        clientId?: number;
        nameSort?: "asc" | "desc";
        searchStr?: string;
    },
    Room[]
>;

export const SEARCH_ROOMS = "room/SEARCH";
export type SearchRoomsAction = ActionDescriptor<
    typeof SEARCH_ROOMS,
    {
        clientId?: number;
        text: string;
    },
    Room[]
>;

export interface RoomActionsMap {
    [ADD_ROOM]: AddRoomAction;
    [EDIT_ROOM]: EditRoomAction;
    [GET_ROOM]: GetRoomAction;
    [GET_ROOMS]: GetRoomsAction;
    [SEARCH_ROOMS]: SearchRoomsAction;
}
