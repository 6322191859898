export interface FabricsOrLiningsItemDetailsFields {
    id: number;
    fabricWidthInches: string;
    verticalRepeatInches: string;
    horizontalRepeatInches: string;
    isDropRepeatOn: boolean;
    isMatchRepeatAtSeamsOn: boolean;
    isPleatToPatternOn: boolean;
    directionOfUseTypeId: number;
    isSpecialtyFabricHandlingOn: boolean;
}

export type FabricsOrLiningsItemDetailsFieldsPartial =
    Partial<FabricsOrLiningsItemDetailsFields>;

export type FabricsOrLiningsItemDetailsFieldsAction =
    | {
          type: "updateFormFields";
          formFieldUpdates: FabricsOrLiningsItemDetailsFieldsPartial;
      }
    | { type: "resetFormFields" };

export const fabricsOrLiningsItemDetailsFieldsInitialState: FabricsOrLiningsItemDetailsFields =
    {
        id: 0,
        fabricWidthInches: "",
        verticalRepeatInches: "",
        horizontalRepeatInches: "",
        isDropRepeatOn: false,
        isMatchRepeatAtSeamsOn: true,
        isPleatToPatternOn: false,
        directionOfUseTypeId: 1,
        isSpecialtyFabricHandlingOn: false,
    };

export const fabricsOrLiningsItemDetailsFieldsReducer = (
    state: FabricsOrLiningsItemDetailsFields,
    action: FabricsOrLiningsItemDetailsFieldsAction
): FabricsOrLiningsItemDetailsFields => {
    switch (action.type) {
        case "updateFormFields": {
            return {
                ...state,
                ...action.formFieldUpdates,
            };
        }
        case "resetFormFields": {
            return fabricsOrLiningsItemDetailsFieldsInitialState;
        }
        default: {
            throw new Error("Invalid action type");
        }
    }
};
