import { ClassAttributes, FormHTMLAttributes } from "react";
import { dimmable } from "../dimmer/dimmer";
import styled, { css } from "../theme/styled";

// tslint:disable-next-line:no-empty-interface
export interface FormUIProps {
    uiPadding?: boolean;
}

export type FormProps = FormHTMLAttributes<HTMLFormElement> & FormUIProps;

/**
 * Simple flex form
 */
const Form = styled.form<FormProps & ClassAttributes<HTMLFormElement>>`
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 0 0 auto;
    align-items: stretch;
    justify-content: flex-start;
    ${dimmable};
    ${props => props.uiPadding && css`
        padding: 20px;
    `};
`;
Form.defaultProps = {
    uiPadding: true,
};

export default Form;
