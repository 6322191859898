import Bind from "lodash-decorators/bind";
import React, {
    Component,
    ComponentClass,
    ComponentType,
    FocusEvent,
    SFC,
} from "react";
import Popup, { PopupProps } from "../popup/popup";
import { forwardRef, WithForwardedRef } from "../utils/ref";
import { WithValidationProps } from "./with_validation";

const noop = () => {
    /* ignore */
};

export interface WithValidationPopupRequiredProps {
    onFocus?(e: FocusEvent<any>): void;
    onBlur?(e: FocusEvent<any>): void;
}

interface State {
    error?: string;
    asyncStatus?: "pending" | "success" | "error";
    popupActive: boolean;
}

export interface Options<T> {
    // uiAsyncValidationStatusProp?: TypePropertyNames<T, string>;
    uiAsyncValidationStatusProp?: keyof T;
    // uiErrorProp?: TypePropertyNames<T, boolean>;
    uiErrorProp?: keyof T;
}

export interface WithValidationErrorPopupProps {
    /**
     * Element Id is required
     */
    id: string;
    /**
     * Popup position
     */
    uiPopupPosition?: PopupProps["uiPosition"];
    /**
     * Inverted popup
     */
    uiPopupInverted?: PopupProps["uiInverted"];
    /**
     * Popup offset
     */
    uiPopupOffset?: PopupProps["uiOffset"];
    /**
     * Popup distance away
     */
    uiPopupDistanceAway?: PopupProps["uiDistanceAway"];
    /**
     * Report pending async validation to wrapped component
     * @default false
     */
    uiReportAsyncValidationPending?: boolean;
    /**
     * Report success/error result of async validation to wrapped component
     * @default false
     */
    uiReportAsyncValidationResult?: boolean;
    /**
     * Clean success/error result after timeout
     * @default undefined
     */
    uiReportResultTimeout?: number;
    /**
     * Hide error popup on element focus
     * @default true
     */
    uiHideErrorOnFocus?: boolean;
    /**
     * Ui popup element.
     * @default Popup
     */

    uiError?: boolean;

    /**
     * Ui popup element.
     * @default false
     */
    uiPopupComponent?: ComponentType<PopupProps>;
    /**
     * Error message renderer. Pass it to override error message/add other elements (such as icon)
     * @param message Error message
     */
    uiRenderErrorMessage?(message: string): string | JSX.Element;

    popupString?: boolean;

    name?: string;

    value?: string | number;

    handleEmptyInputPassword?(): void;

    required?: boolean;

    uiValidityMessages?: any;
}

/**
 * Wrap validation component to automatically display error popup on validation error
 * @param WrappedComponent Component to wrap. Must implement validation props
 * @param options Wrapping options
 */
export function WithValidationErrorPopup<T extends WithValidationProps>(
    WrappedComponent: React.ComponentType<T & WithValidationPopupRequiredProps>,
    options: Options<T> = {}
): SFC<T & WithValidationErrorPopupProps> {
    class WithErrorMessageComponent extends Component<
        WithValidationProps &
            WithValidationErrorPopupProps &
            WithValidationPopupRequiredProps,
        State
    > {
        public static displayName = `WithErrorMessage(${
            WrappedComponent.displayName ||
            WrappedComponent.name ||
            "WrappedComponent"
        })`;

        public static defaultProps: Partial<
            WithValidationProps &
                WithValidationErrorPopupProps &
                WithValidationPopupRequiredProps
        > = {
            uiRenderErrorMessage: (err) => err,
            uiPopupDistanceAway: 10,
            uiHideErrorOnFocus: true,
        };

        public state: State = {
            error: undefined,
            asyncStatus: undefined,
            popupActive: false,
        };

        private statusTimer?: number;

        public componentWillUnmount(): void {
            if (this.statusTimer) {
                clearTimeout(this.statusTimer);
            }
        }
        public render(): JSX.Element {
            const { asyncStatus, popupActive } = this.state;
            const additionalProps = {
                uiError: false,
            };
            if (options.uiAsyncValidationStatusProp) {
                if (
                    asyncStatus === "pending" &&
                    this.props.uiReportAsyncValidationPending
                ) {
                    additionalProps[
                        options.uiAsyncValidationStatusProp as string
                    ] = asyncStatus;
                } else if (
                    (asyncStatus === "success" || asyncStatus === "error") &&
                    this.props.uiReportAsyncValidationResult
                ) {
                    additionalProps[
                        options.uiAsyncValidationStatusProp as string
                    ] = asyncStatus;
                }
            }
            if (options.uiErrorProp) {
                additionalProps[options.uiErrorProp as any] =
                    !!this.state.error;
            }
            const {
                uiReportAsyncValidationPending,
                uiReportAsyncValidationResult,
                uiReportResultTimeout,
                forwardedRef,
                uiPopupDistanceAway,
                uiPopupInverted,
                uiPopupOffset,
                uiPopupPosition,
                uiRenderErrorMessage,
                uiPopupComponent,
                ...other
            } = this.props as WithForwardedRef<
                WithValidationErrorPopupProps & WithValidationProps
            >;
            const Comp = WrappedComponent as any as ComponentClass<
                WithValidationProps & WithValidationPopupRequiredProps
            >;
            const PopupComponent = uiPopupComponent || Popup;

            const customErrorMessage =
                (this.props.uiValidityMessages &&
                    this.props.uiValidityMessages.patternMismatch) ||
                (this.props.uiValidityMessages &&
                    this.props.uiValidityMessages.valueMissing);

            return (
                <>
                    <Comp
                        // additional reporting props can be overwritten explicitly
                        {...additionalProps}
                        {...other}
                        uiOnValid={this.onValid}
                        uiOnInvalid={this.onInvalid}
                        uiOnStartAsyncValidation={this.onStartAsyncValidation}
                        uiOnFinishAsyncValidation={this.onFinishAsyncValidation}
                        ref={forwardedRef}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                    />

                    {["cost", "msrp"].includes(this.props.id)
                        ? !customErrorMessage &&
                          !this.props.popupString &&
                          this.props.required && (
                              <PopupComponent
                                  uiActive={popupActive}
                                  uiTargetEl={this.props.id}
                                  uiOnRequestClose={noop}
                                  uiAutoPosition
                                  uiPosition={uiPopupPosition}
                                  uiInverted={uiPopupInverted}
                                  uiOffset={uiPopupOffset}
                                  uiDistanceAway={uiPopupDistanceAway}
                              >
                                  {this.state.error &&
                                      uiRenderErrorMessage!(this.state.error)}
                              </PopupComponent>
                          )
                        : !this.props.popupString &&
                          !customErrorMessage && (
                              <PopupComponent
                                  uiActive={popupActive}
                                  uiTargetEl={this.props.id}
                                  uiOnRequestClose={noop}
                                  uiAutoPosition
                                  uiPosition={uiPopupPosition}
                                  uiInverted={uiPopupInverted}
                                  uiOffset={uiPopupOffset}
                                  uiDistanceAway={uiPopupDistanceAway}
                              >
                                  {this.state.error &&
                                      uiRenderErrorMessage!(this.state.error)}
                              </PopupComponent>
                          )}

                    {customErrorMessage &&
                        customErrorMessage.length > 0 &&
                        this.props.required &&
                        !this.props.value &&
                        additionalProps &&
                        additionalProps.uiError && (
                            <PopupComponent
                                uiActive={popupActive}
                                uiTargetEl={this.props.id}
                                uiOnRequestClose={noop}
                                uiAutoPosition
                                uiPosition={uiPopupPosition}
                                uiInverted={uiPopupInverted}
                                uiOffset={uiPopupOffset}
                                uiDistanceAway={uiPopupDistanceAway}
                            >
                                {customErrorMessage &&
                                    uiRenderErrorMessage!(customErrorMessage)}
                            </PopupComponent>
                        )}
                </>
            );
        }

        @Bind()
        private onBlur(e: FocusEvent<any>): void {
            const { onBlur, uiHideErrorOnFocus } = this.props;
            if (
                uiHideErrorOnFocus &&
                this.state.error &&
                !this.state.popupActive
            ) {
                // restore error popup on blur
                this.setState({
                    popupActive: true,
                });
            }
            if (onBlur) {
                onBlur(e);
            }
        }

        @Bind()
        private onFocus(e: FocusEvent<any>): void {
            const { onFocus, uiHideErrorOnFocus } = this.props;

            if (uiHideErrorOnFocus) {
                this.setState({ popupActive: false });
            }
            if (
                [
                    "Markup Cost Trade Input",
                    "Discount MSRP Trade Input",
                    "Rate Trade Input",
                    "Item MSRP",
                    "Item Cost",
                    "Markup Cost Retail Input",
                    "Discount MSRP Retail Input",
                    "Rate Retail Input",
                    "Shipping Cost Input",
                    "Handling Fee MarkUp Product Input",
                    "Handling Fee MarkUp Shipping Cost Input",
                    "Handling Fee Rate Input",
                    "Item Name",
                    "Cost Plus Retail Input",
                ].includes(e.target.name)
            ) {
                this.setState({ popupActive: true });
            }
            if (onFocus) {
                onFocus(e);
            }
        }

        @Bind()
        private onValid(): void {
            if (this.state.error) {
                this.setState({ error: undefined, popupActive: false });
            }
        }

        @Bind()
        private onInvalid(msg: string, el: any, val: any): void {
            if (
                ![
                    "Handling Fee MarkUp Product Input",
                    "Handling Fee MarkUp Shipping Cost Input",
                    "Handling Fee Rate Input",
                    "Item MSRP",
                    "Item Cost",
                    "Shipping Cost Input",
                ].includes(el.name)
            ) {
                el.focus();
            }
            if (
                el.name === "password" &&
                this.props.handleEmptyInputPassword &&
                val === undefined
            ) {
                this.props.handleEmptyInputPassword();
            }
            if (
                (!this.state.error || this.state.error !== msg) &&
                msg !== "Please select billing type for client."
            ) {
                this.setState({ error: msg, popupActive: true });
            } else if (msg === "Please select billing type for client.") {
                this.setState({
                    error: "Please select billing type for client",
                    popupActive: true,
                });
            }
        }

        @Bind()
        private onStartAsyncValidation(): void {
            if (this.statusTimer) {
                clearTimeout(this.statusTimer);
            }
            if (this.props.uiReportAsyncValidationPending) {
                this.setState({ asyncStatus: "pending" });
            }
        }

        @Bind()
        private onFinishAsyncValidation(valid: boolean): void {
            const {
                uiReportAsyncValidationResult,
                uiReportAsyncValidationPending,
                uiReportResultTimeout,
            } = this.props;
            // NOTE: No need to set error message here, WithValidation() will report error byself
            // and uiOnInvalid() will be triggered
            if (
                uiReportAsyncValidationPending ||
                uiReportAsyncValidationResult
            ) {
                this.setState({
                    asyncStatus: !uiReportAsyncValidationResult
                        ? undefined
                        : valid
                        ? "success"
                        : "error",
                });
            }
            if (uiReportResultTimeout && uiReportAsyncValidationResult) {
                this.statusTimer = setTimeout(() => {
                    this.statusTimer = undefined;
                    this.setState({ asyncStatus: undefined });
                }, uiReportResultTimeout) as any;
            }
        }
    }

    return forwardRef(WithErrorMessageComponent as any);
}

export default WithValidationErrorPopup;
