import React from "react";
import {FaArrowDown, FaArrowUp} from "react-icons/fa";
import styled from "../../theme";

interface SortProps {
    direction?: "asc" | "desc";
    name?: string;
}

const SortContainer = styled.div`
    display: inline-flex;
    align-items: center;
    width: 100%;
`;

const ArrowDown = styled(FaArrowDown)`
    margin: 0 0 0 auto;
`;

const ArrowUp = styled(FaArrowUp)`
    margin: 0 0 0 auto;
`;

const Sort: React.FunctionComponent<SortProps> = props => (
    <SortContainer>
        {props.name ? props.name : "NAME"}
        {props.direction === "asc" ? <ArrowDown /> : <ArrowUp />}
    </SortContainer>
);

export default Sort;
