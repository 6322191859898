import Bind from "lodash-decorators/bind";
import React, { MouseEvent, PureComponent } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "../../theme/index";

const Box = styled.span`
    position: relative;
    cursor: pointer;

    .tooltip-box {
        position: absolute;
        bottom: 100%;
        left: 50%;
        padding-bottom: 9px;
        transform: translateX(-50%);
        min-width: 120px;
        max-width: 210px;
        z-index: 10;
    }

    .tooltip-message-box-enter {
      opacity: 0;
      transform: scale(0.9);
    }

    .tooltip-message-box-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 300ms, transform 300ms;
    }

    .tooltip-message-box-exit {
      opacity: 1;
    }

    .tooltip-message-box-exit-active {
      opacity: 0;
      transform: scale(0.9);
      transition: opacity 300ms, transform 300ms;
    }

    .tooltip-message {
        background: rgba(0, 0, 0, 0.7);
        border-radius: 3px;
        color: #fff;
        font-size: 0.75rem;
        line-height: 1.4;
        padding: 0.75em;
        text-align: center;
    }

    .tooltip-bubble {
        min-width: 120px;
        max-width: 210px;
        position: absolute;
        z-index: 10;
        bottom: 100%;
        left: 50%;
        padding-bottom: 9px;
        transform: translateX(-50%);

        &::after {
            content: '';
            position: absolute;
                        border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-top: 9px solid rgba(0, 0, 0, 0.7);
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
`;

const Content = styled.span`
    word-break: break-all;
`;

interface State {
    message: string;
    displayTooltip: boolean;
}

interface CopyToClipboardProps {
    title: string;
    tooltipMessage: string;
    copy: string;
}

export class CopyToClipboard extends PureComponent<CopyToClipboardProps, State> {
    public state: State = {
        message: "",
        displayTooltip: false,
    };

    public render(): JSX.Element {
        return (
            <Box>
                <CSSTransition
                    in={this.state.displayTooltip}
                    timeout={300}
                    classNames="tooltip-message-box"
                    unmountOnExit
                >
                    <div className="tooltip-bubble">
                        <div className="tooltip-message">{this.props.tooltipMessage}</div>
                    </div>
                </CSSTransition>

                <Content onClick={this.handleClick}>
                    {this.props.title}
                </Content>
            </Box>
        );
    }

    @Bind()
    public handleClick(event: MouseEvent<HTMLElement>): void {
        event.preventDefault();
        event.stopPropagation();

        const el = document.createElement("textarea");
        el.value = this.props.copy;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);

        this.showTooltip();

        setTimeout(() => { this.hideTooltip(); }, 2000);
    }

    public hideTooltip(): void {
        this.setState({displayTooltip: false});
    }

    public showTooltip(): void {
        this.setState({displayTooltip: true});
    }

}

export default CopyToClipboard;
