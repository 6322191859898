import React, { ClassAttributes, ComponentType, ForwardRefExoticComponent } from "react";

export type WithForwardedRef<T = {}> =  {
    forwardedRef: any;
} & T;

export function forwardRef<T, P = {}>(Component: ComponentType<P>): ForwardRefExoticComponent<P & ClassAttributes<T>> {
    function passRef(props: any, ref: any) {
        const C = Component as any;
        return (
            <C {...props} forwardedRef={ref} />
        );
    }
    passRef.displayName = `ForwardRef(${Component.displayName || Component.name})`;

    return React.forwardRef(passRef);
}
