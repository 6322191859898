import debounce from "lodash/debounce";
import React, { PureComponent } from "react";
import { connect, DispatchProp } from "react-redux";
import { ContentSection } from "../components/content/content_section";
import { PageContent } from "../components/content/page_content";
import Header, { HeaderProps } from "../components/UI/header_with_search_input/header";
import { AdminModule, RootState } from "../redux";
import { UserSubscriptionInfo } from "../redux/modules/admin";
import { CancelToken } from "../utils/cancel_token";
import { AdminTable } from "./admin_table";

// tslint:disable-next-line:no-empty-interface
interface AdminProps {
    currentUserId: number;
}

interface State {
    sort: "asc" | "desc";
    keyword: string;
    users: AdminModule.UserInfoForAdmin[];
}

export class Admin extends PureComponent<AdminProps & DispatchProp, State> {
    public state: State = {
        sort: "asc",
        keyword: "",
        users: [],
    };

    /**
     * Cancellation token for history load operation
     *
     * @private
     */
    private loadCancelToken?: CancelToken;

    public async componentDidMount(): Promise<void> {
        this.loadUsers();
    }

    public render(): JSX.Element {
        /* tslint:disable:jsx-alignment */
        return (
            <PageContent>
                <Header
                    headingText="Admin"
                    onSearch={this.handleSearch}
                    onKeywordCleared={this.handleKeywordCleared}
                />
                <ContentSection>
                    <AdminTable
                        sort={this.state.sort}
                        users={this.state.users}
                        currentUserId={this.props.currentUserId}
                        onSortingChanged={this.handleSortingChange}
                        onUserRoleChanged={this.onUserRoleChanged}
                        onGetUserSubscriptionHistory={this.onGetUserSubscriptionHistory}
                    />
                </ContentSection>
            </PageContent>
        );
    }

    private handleSearch: HeaderProps["onSearch"] = keyword => {
        this.setState({
            keyword,
        }, () => this.loadUsersDebounced());
    }

    private handleKeywordCleared: HeaderProps["onKeywordCleared"] = () => {
        this.setState({
            keyword: "",
        }, () => this.loadUsers());
    }

    private handleSortingChange = (sort: "asc" | "desc") => {
        this.setState({
            sort,
        }, () => this.loadUsers());
    }

    private onUserRoleChanged = (userId: number, role: "admin" | "office_worker") => {
        this.editUserRole(userId, role);
    }

    /**
     * Debounced version of load users
     */
    // tslint:disable-next-line:member-ordering
    private loadUsersDebounced = debounce(() => {
        this.loadUsers();
    }, 300);

    private async loadUsers(): Promise<void> {
        const { dispatch } = this.props;

        if (this.loadCancelToken) {
            // Cancel previous load operation if needed
            this.loadCancelToken.cancel();
        }

        this.loadCancelToken = new CancelToken();

        const res = await dispatch(AdminModule.searchUsers(this.state.keyword, this.state.sort, this.loadCancelToken));
        if (!res.error) {
            this.setState({
                users: res.payload,
            });
        }
    }

    private async editUserRole(userId: number, role: "admin" | "office_worker"): Promise<void> {
        const { dispatch } = this.props;
        const res = await dispatch(AdminModule.editUserRole(userId, role));
        if (!res.error) {
            this.loadUsers();
        }
    }

    private onGetUserSubscriptionHistory = async (accountId: number): Promise<UserSubscriptionInfo[]> => {
        const { dispatch } = this.props;
        const res = await dispatch(AdminModule.getUserSubscriptionHistory(accountId));
        if (!res.error) {
            return res.payload;
        } else {
            return [];
        }
    }

}

function mapStateToProps(state: RootState): AdminProps {
    return {
        currentUserId: state.user.id,
    };
}

export const AdminContainer = connect(mapStateToProps)(Admin);
