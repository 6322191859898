import React, { FC } from "react";
import { TooltipContainer, TooltipPopup, TooltipText } from "../../styles";

interface RowTitleProps {
    title: string;
    isBottom?: boolean;
    titleName?: string;
    text?: string;
}

const RowTitle: FC<RowTitleProps> = ({
    title,
    text,
    titleName,
    isBottom = false,
}) => {
    return (
        <>
            <p
                className={`${
                    isBottom ? "" : "border-b border-black/25"
                } mb-0 p-2 h-14 flex items-center`}
            >
                {title}
                {title === titleName && (
                    <div>
                        <TooltipPopup
                            text={
                                <>
                                    <TooltipContainer>
                                        <TooltipText>{text}</TooltipText>
                                    </TooltipContainer>
                                </>
                            }
                            position="top right"
                            className="floatRight"
                            size="25px"
                        />
                    </div>
                )}
            </p>
        </>
    );
};

export default RowTitle;
