import React from 'react'
import styled from '../../../theme'

const CustomDivider = styled.div`
    width: 100%;
    background: #ECF6F9;
    margin-bottom: 5px;
`
interface DividerProps {
  height?: number;
}

const Divider = ({ height }: DividerProps) => {
  return (
    <CustomDivider style={{ height: height ? `${height}px` : '11px' }} />
  )
}

export default Divider
