import { ActionDescriptor } from "../../common/realtime_action";
import { BasicAccountInfo } from "../account/interfaces";
import { UserInfo as BasicUserInfo } from "../user/interfaces";

export const SIGNUP = "auth/SIGNUP";

export type SignupAction = ActionDescriptor<
    typeof SIGNUP,
    {
        /**
         * User first name
         */
        firstName: string;
        /**
         * User last name
         */
        lastName: string;
        /**
         * User email
         */
        email: string;
        /**
         * User password
         */
        password: string;
        /**
         * People id
         */
        peopleID: string;
        /**
         * Beta Signup
         */
        isBeta?: boolean;
    },
    {
        /**
         * Generated token for new registered user
         */
        token: string;

        refreshToken: string;
        /**
         * User information
         */
        user: BasicUserInfo;
        /**
         * Account information
         */
        account: BasicAccountInfo;
    }
>;

export const LOGIN = "auth/LOGIN";

export type LoginAction = ActionDescriptor<
    typeof LOGIN,
    | {
        /**
         * JWT token
         */
        token: string;
    }
    | {
        /**
         * Email
         */
        email: string;
        /**
         * Password
         */
        password: string;
    },
    {
        /**
         * New user token
         */
        token: string;

        refreshToken: string;
        /**
         * User information
         */
        user: BasicUserInfo;
        /**
         * Account information
         */
        account: BasicAccountInfo;

        crispAccessCookie: string;
        // /**
        //  * User information with settings
        //  */
        // user: FullUserInformation;
        // /**
        //  * User teams
        //  */
        // teams: PartialTeamInfo[];
        // /**
        //  * User invites
        //  */
        // invites: UserInviteInfo[];
    }
>;

export const VALIDATE_EMAIL = "auth/VALIDATE_EMAIL";
export type ValidateEmailAction = ActionDescriptor<
    typeof VALIDATE_EMAIL,
    {
        /**
         * Email to validate
         */
        email: string;
    },
    {}
>;

export const LOGOUT = "auth/LOGOUT";
export type LogoutAction = ActionDescriptor<typeof LOGOUT, {}, {}>;

export const REQUEST_PASSWORD_RESET = "auth/REQUEST_PASSWORD_RESET";
/**
 * Send the email to reset the password
 */
export type RequestPasswordResetAction = ActionDescriptor<
    typeof REQUEST_PASSWORD_RESET,
    {
        /**
         * Email to send the password reset
         */
        email: string;
    },
    {}
>;

export const PASSWORD_RESET = "auth/PASSWORD_RESET";

/**
 * Reset user password
 */
export type PasswordResetAction = ActionDescriptor<
    typeof PASSWORD_RESET,
    {
        /**
         * Reset code
         */
        code: string;
        /**
         * New password
         */
        password: string;
    },
    {}
>;

export const REFRESH_TOKEN = "auth/REFRESH_TOKEN";

export type RefreshTokenAction = ActionDescriptor<
    typeof REFRESH_TOKEN,
    {
        /**
         * JWT token
         */
        refreshToken: string;
    },
    {
        /**
         * New user token
         */
        token: string;
        refreshToken: string;
        /**
        // /**
        //  * User information with settings
        //  */
        // user: FullUserInformation;
        // /**
        //  * User teams
        //  */
        // teams: PartialTeamInfo[];
        // /**
        //  * User invites
        //  */
        // invites: UserInviteInfo[];
    }
>;

export interface AuthActionsMap {
    [SIGNUP]: SignupAction;
    [LOGIN]: LoginAction;
    [VALIDATE_EMAIL]: ValidateEmailAction;
    [LOGOUT]: LogoutAction;
    [REQUEST_PASSWORD_RESET]: RequestPasswordResetAction;
    [PASSWORD_RESET]: PasswordResetAction;
    [REFRESH_TOKEN]: RefreshTokenAction;
}
