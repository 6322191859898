import React, { ChangeEvent, forwardRef, ReactElement, RefObject } from "react";
import IconButton, { IconButtonProps } from "../button/icon_button";
import styled from "../theme/styled";

export interface ButtonSelectUIProps {
    /**
     * Select icon
     */
    uiIcon?: ReactElement<any>;
    /**
     * Select value
     */
    uiSelectValue?: string;
    /**
     * Button reference
     */
    uiButtonRef?: RefObject<HTMLButtonElement>;
    /**
     * Change callback
     */
    uiOnSelectChange?(e: ChangeEvent<HTMLSelectElement>): void;
}

export type ButtonSelectProps = IconButtonProps & ButtonSelectUIProps;

const ButtonSelectComponent = forwardRef<HTMLSelectElement, ButtonSelectProps>((props, ref) => {
    const { uiIcon, uiOnSelectChange, uiSelectValue, children, uiButtonRef, ...other } = props;
    return (
        <IconButton
            {...other}
            ref={uiButtonRef}
        >
            {uiIcon}
            <select onChange={uiOnSelectChange} value={uiSelectValue} ref={ref}>
                {children}
            </select>
        </IconButton>
    );
});

/**
 * Simple select styled as icon button
 */
const ButtonSelect = styled(ButtonSelectComponent) `
    position: relative;
    cursor: pointer;

    & > select {
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        color: transparent;
        background: transparent;
        /* stylelint-disable */
        -webkit-appearance: none;
        /* stylelint-enable */
        border: none;
        outline: none;
        width: 100%;
        height: 100%;
    }
`;

export default ButtonSelect;
