import {
    Button,
    Modal,
    ModalActions as ModalActionsE,
    ModalHeader as ModalHeaderE,
    ModalProps,
} from "@ramble/ramble-ui";

import {
    CalculatorFieldInput,
    CalculatorFormLabel,
} from "../components/calculator/styles";

import React from "react";
import styled, { css } from "styled-components";
import SearchSelect, {
    SearchSelectProps,
} from "../components/UI/search_select/search_select";
import { breakpoints, theme } from "../theme";

const noop = () => {
    /* ignore */
};

export interface ConfirmationModalProps
    extends Pick<
        ModalProps,
        | "uiActive"
        | "uiOnRequestClose"
        | "className"
        | "uiOnClose"
        | "uiTransitionTimeout"
        | "uiCloseOnEsc"
        | "uiCloseOnOutsideClick"
    > {
    /**
     * Modal header
     */
    uiHeader?: string;
    /**
     * Decline button label
     */
    uiDeclineLabel?: string | false;
    /**
     * Accept button label
     */
    uiAcceptLabel?: string | false;
    /**
     * Close modal when pressing Esc
     * @default true
     */
    uiCloseOnEsc?: boolean;
    /**
     * Close modal on outside click
     * @default true
     */
    uiCloseOnOutsideClick?: boolean;
    /**
     * Class name
     */
    className?: string;
    /**
     * Child
     */
    children?: React.ReactChild;
    /**
     * Confirmation callback
     */

    handleSearchSelectChange(
        id: number,
        name: string,
        pathId: string,
        pathName: string
    ): void;
    handleSearchClient: SearchSelectProps["onSearch"];

    uiOnConfirm(res?: unknown): void;

    handleInputClick?: (val: boolean) => void;

    clientInfo?: { id: number; itemIds: number[] };

    setClientInfo({}): void;
}

const SetupFormLabel = styled(CalculatorFormLabel)`
    width: 100%;
    font-size: 14px;
    margin-right: 0;
    margin-bottom: 5px;
`;

const StyledModal = styled(Modal)`
    max-width: 500px;
    border-radius: 0.5rem;
    // border: solid ${theme.colors.grayRGBA} 1px;
    ${breakpoints.down(
        "sm",
        css`
            max-width: 90%;
        `
    )};
`;
const CustomCalculatorFieldInput = styled(CalculatorFieldInput)`
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
`;

const CustomSearchSelect = styled(SearchSelect)`
    width: 100%;
    border-radius: 10px;
    // margin-bottom: 64px;

    label {
        height: 50px;
        border: 2px solid #3b97b1;
    }
`;
const ModalHeader = styled(ModalHeaderE)`
    color: #353535;
    border-bottom: none;
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 0.25rem;
    padding-top: 22px;
    margin-bottom: 45px;
`;

const ModalActions = styled(ModalActionsE)`
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    // justify-content: space-between;
    background-color: #fff;
    border-top: 0;
    padding: 0.7rem 1.7rem 1.5rem;
`;

const BasicButton = styled(Button)`
    // width: 130px;
    box-shadow: none;
    border: 1px solid #c6c6c6;
    border-radius: 0.5em;
    display: block;
`;

const ConfirmButton = styled(BasicButton)`
    border-radius: 5px;
    height: 30px;
    // width: 110px;
    box-shadow: none;
    line-height: 0.6rem;
    padding: 0;
    border: 2px solid #3b97b1;
    &:hover {
        background: ${(props) => (props.disabled ? "#3b97b1" : "")};
        cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
    }
`;

const CancelButton = styled(BasicButton)`
    border-radius: 5px;
    height: 30px;
    // width: 100px;
    box-shadow: none;
    line-height: 0.6rem;
    background-color: #f6f6f6;
    color: #222222;
    border-color: #9d9a9a;
    border: 1px solid #cdcdcd;
    padding: 0;
    border: none;

    &:hover {
        background: #eeeeee;
    }
    &:not(:last-child) {
        margin-right: 10px;
    }
`;

/**
 * Simple confirmation modal
 */
export const AddToClientModal: React.SFC<ConfirmationModalProps> = (props) => {
    return (
        <StyledModal
            className={props.className}
            uiActive={props.uiActive}
            uiOnRequestClose={noop}
            uiDefaultSizes={false}
            uiOnClose={props.uiOnClose}
            uiTransitionTimeout={props.uiTransitionTimeout}
            uiCloseOnOutsideClick={props.uiCloseOnOutsideClick}
            uiCloseOnEsc={props.uiCloseOnEsc}
        >
            <ModalHeader>{props.uiHeader}</ModalHeader>
            <CustomCalculatorFieldInput>
                <SetupFormLabel>Find existing client:</SetupFormLabel>

                <CustomSearchSelect
                    name="draperyOrder.clientId"
                    searchPage="myshop"
                    searchType="modal"
                    searchItem="client"
                    onItemClick={(id, name) =>
                        props.handleSearchSelectChange(
                            id,
                            name,
                            "draperyOrder.clientId",
                            "draperyOrder.clientName"
                        )
                    }
                    onSearch={props.handleSearchClient}
                    placeholder="Please select client"
                    keepKeyword
                    required={true}
                    setClientInfo={props.setClientInfo}
                />
            </CustomCalculatorFieldInput>
            <ModalActions>
                {props.uiDeclineLabel && (
                    <CancelButton
                        id="decline"
                        uiColor="secondary"
                        onClick={props.uiOnRequestClose}
                    >
                        {props.uiDeclineLabel}
                    </CancelButton>
                )}
                {props.uiAcceptLabel && (
                    <ConfirmButton
                        id="confirm"
                        uiColor="primary"
                        onClick={props.uiOnConfirm}
                        disabled={
                            props.clientInfo &&
                            props.clientInfo.id &&
                            props.clientInfo.itemIds.length > 0
                                ? false
                                : true
                        }
                    >
                        {props.uiAcceptLabel}
                    </ConfirmButton>
                )}
            </ModalActions>
        </StyledModal>
    );
};

AddToClientModal.defaultProps = {
    uiHeader: "Add to Client Project",
    uiAcceptLabel: "Add to client",
    uiDeclineLabel: "Cancel",
};
