import Color from "color";
import React, { ClassAttributes, forwardRef, HTMLAttributes, memo } from "react";
import { breakpoints } from "../theme";
import styled, { css } from "../theme/styled";
import { cleanUiProps } from "../utils/withStyledProps";

export interface PaginatorUIProps {
    /**
     * Total pages
     */
    uiTotalPages: number;
    /**
     * Current active page
     */
    uiCurrentPage: number;
    /**
     * Page was changed
     * @param newPage
     */
    uiOnPageChange(newPage: number): void;
}

export type PaginatorProps = HTMLAttributes<HTMLDivElement> & PaginatorUIProps;

const Container = styled.div<HTMLAttributes<HTMLDivElement> & ClassAttributes<HTMLDivElement>>`
    display: inline-flex;
    font-weight: 400;
    border-radius: 0.28rem;
    min-height: 2.8em;
    border: 1px solid ${({ theme }) => theme.colors.divider};
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => Color(theme.colors.primaryText).alpha(0.85).string()};
    transition: background 0.1s ease, color 0.1s ease;
    ${breakpoints.down("sm", css`
        min-height: 1.8em;
    `)};
    ${breakpoints.down("xs", css`
        min-height: 1.4em;
    `)};
`;

interface PaginationItemProps extends HTMLAttributes<HTMLElement> {
    /**
     * Active item
     */
    uiActive?: boolean;
    /**
     * Selectable item
     */
    uiSelectable?: boolean;
}

const PaginationItem = styled.div<PaginationItemProps> `
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    line-height: 1;
    user-select: none;
    padding: 0.92em 1.14em;
    min-width: 3em;
    text-align: center;
    ${({ theme, uiSelectable }) => uiSelectable && css`
        cursor: pointer;

        &:hover {
            background: rgba(0, 0, 0, 0.05);
            color: ${Color(theme.colors.primaryText).alpha(0.95).string()};
        }
    `};
    ${({ theme, uiActive }) => uiActive && css`
        background: rgba(0, 0, 0, 0.05);
        color: ${Color(theme.colors.primaryText).alpha(0.95).string()};
    `};

    :not(:last-child) {
        border-right: 1px solid ${({ theme }) => theme.colors.divider};
    }

    ${breakpoints.down("sm", css`
        min-width: 2em;
        padding: 0.62em 0.94em;
    `)};
    ${breakpoints.down("xs", css`
        min-width: 1.5em;
        padding: 0.52em 0.75em;
    `)};
`;

const PaginationDots = styled.div<HTMLAttributes<HTMLDivElement>> `
    display: flex;
    justify-content: center;
    flex: 0 0 auto;
    line-height: 1;
    user-select: none;
    min-width: 2em;
    align-items: flex-end;
    padding: 0.82em 0.85em;
    border-right: 1px solid ${({ theme }) => theme.colors.divider};
    ${breakpoints.down("sm", css`
        min-width: 1.5em;
        padding: 0.52em 0.54em;
    `)};
    ${breakpoints.down("xs", css`
        min-width: 1em;
        padding: 0.45em 0.47em;
    `)};
`;

const PaginatorComponent = memo(forwardRef<HTMLDivElement, PaginatorProps>((props, ref) => {
    const { uiCurrentPage, uiOnPageChange, uiTotalPages, ...other } = props;
    cleanUiProps(other);
    if (uiTotalPages <= 1) {
        return null;
    }
    const items: JSX.Element[] = [];
    if (uiTotalPages <= 7) {
        items.push(...(Array.from(Array(uiTotalPages).keys()).map(i => (
            <PaginationItem
                className="item"
                key={i + 1}
                uiSelectable
                uiActive={i + 1 === uiCurrentPage}
                onClick={() => uiOnPageChange(i + 1)}
            >
                {i + 1}
            </PaginationItem>
        ))));
    } else {
        if (uiCurrentPage <= 4) {
            items.push(...(Array.from(Array(4).keys()).map(i => (
                <PaginationItem
                    className="item"
                    key={i + 1}
                    uiSelectable
                    uiActive={i + 1 === uiCurrentPage}
                    onClick={() => uiOnPageChange(i + 1)}
                >
                    {i + 1}
                </PaginationItem>
            ))));
            items.push(<PaginationItem className="item" key={5} uiSelectable onClick={() => uiOnPageChange(5)}>5</PaginationItem>);
            items.push(<PaginationDots className="dots" key="right-dots">...</PaginationDots>);
            items.push(<PaginationItem className="item" key={uiTotalPages} uiSelectable onClick={() => uiOnPageChange(uiTotalPages)}>{uiTotalPages}</PaginationItem>);
        } else if (uiCurrentPage > uiTotalPages - 4) {
            items.push(<PaginationItem className="item" key={1} uiSelectable onClick={() => uiOnPageChange(1)}>1</PaginationItem>);
            items.push(<PaginationDots className="dots" key="left-dots">...</PaginationDots>);
            items.push(<PaginationItem className="item" key={uiTotalPages - 4} uiSelectable onClick={() => uiOnPageChange(uiTotalPages - 4)}>{uiTotalPages - 4}</PaginationItem>);
            items.push(...(Array.from(Array(4).keys()).map(i => (
                <PaginationItem
                    className="item"
                    key={uiTotalPages - 4 + i + 1}
                    uiSelectable
                    uiActive={uiTotalPages - 4 + i + 1 === uiCurrentPage}
                    onClick={() => uiOnPageChange(uiTotalPages - 4 + i + 1)}
                >
                    {uiTotalPages - 4 + i + 1}
                </PaginationItem>))
            ));
        } else {
            items.push(<PaginationItem className="item" key={1} uiSelectable onClick={() => uiOnPageChange(1)}>1</PaginationItem>);
            items.push(<PaginationDots className="dots" key="left-dots">...</PaginationDots>);
            items.push(
                <PaginationItem
                    className="item"
                    key={uiCurrentPage - 1}
                    uiSelectable
                    onClick={() => uiOnPageChange(uiCurrentPage - 1)}
                >
                    {uiCurrentPage - 1}
                </PaginationItem>,
            );
            items.push(<PaginationItem className="item" key={uiCurrentPage} uiSelectable uiActive onClick={() => uiOnPageChange(uiCurrentPage)}>{uiCurrentPage}</PaginationItem>);
            items.push(
                <PaginationItem
                    className="item"
                    key={uiCurrentPage + 1}
                    uiSelectable
                    onClick={() => uiOnPageChange(uiCurrentPage + 1)}
                >
                    {uiCurrentPage + 1}
                </PaginationItem>,
            );
            items.push(<PaginationDots className="dots" key="right-dots">...</PaginationDots>);
            items.push(<PaginationItem className="item" key={uiTotalPages} uiSelectable onClick={() => uiOnPageChange(uiTotalPages)}>{uiTotalPages}</PaginationItem>);
        }
    }
    return (
        <Container
            {...other}
            ref={ref}
        >
            {items}
        </Container>
    );
}));

const Paginator = styled(PaginatorComponent)`
    /* ignore */
`;

export default Paginator;
