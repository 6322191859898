import React, { forwardRef } from "react";
import styled from "../theme/styled";
import Input, { InputProps } from "./input";
import NonNativeDateInput from "./non_native_date_input";
import { hasNativeDatePicker } from "./utils";

const nativeAvailable = hasNativeDatePicker();

export interface DateInputProps extends InputProps {
    /**
     * Id is required for date input
     */
    id: string;
}

const DateInput = forwardRef<HTMLInputElement, DateInputProps>((props, ref) => {
    return (
        nativeAvailable ? <Input {...props} ref={ref} type="date" /> : <NonNativeDateInput {...props} ref={ref} />
    );
});

export default styled(DateInput)`
    /* ignore */
`;
