import React, { forwardRef, memo } from "react";
import { CSSTransition } from "react-transition-group";
import Portal from "../portal/portal";
import styled from "../theme/styled";
import DimmerContent, { DimmerContentProps, DimmerContentUIProps } from "./dimmer_content";

const noop = () => { /* ignore */ };

const DimmerPortal = styled(Portal)`
    z-index: ${props => props.theme.zIndex.pageDimmer};
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export interface PageDimmerUIProps extends DimmerContentUIProps {
    /**
     * Active dimmer
     */
    uiActive: boolean;
}

export type PageDimmerProps = PageDimmerUIProps & DimmerContentProps;

/**
 * @see See also ```<Dimmer />```
 */
const PageDimmerComponent = memo(forwardRef<HTMLDivElement, PageDimmerProps>((props, ref) => {
    const { uiActive, ...other } = props;
    return (
        <CSSTransition
            in={uiActive}
            timeout={200}
            classNames="transition"
            mountOnEnter
            unmountOnExit
            appear
            enter
            exit
        >
            <DimmerPortal
                uiOnRequestClose={noop}
            >
                <DimmerContent {...other} ref={ref} />
            </DimmerPortal>
        </CSSTransition>
    );
}));

const PageDimmer = styled(PageDimmerComponent)`
    &.transition-appear,
    &.transition-enter {
        opacity: 0;
    }

    &.transition-appear-active,
    &.transition-enter-active {
        opacity: 1;
        transition: opacity 0.2s linear;
    }

    &.transition-exit {
        opacity: 1;
    }

    &.transition-exit-active {
        opacity: 0;
        transition: opacity 0.2s linear;
    }
`;

export default PageDimmer;
