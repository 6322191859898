import { ClassAttributes, LiHTMLAttributes } from "react";
import styled, { css } from "../theme/styled";

export interface ListItemUIProps {
    /**
     * Selectable list item
     */
    uiSelectable?: boolean;
    /**
     * Active list item
     */
    uiActive?: boolean;
}

export type ListItemProps = LiHTMLAttributes<HTMLLIElement> & ListItemUIProps;

/**
 * List item
 */
const ListItem = styled.li<ListItemProps & ClassAttributes<HTMLLIElement>> `
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0.15em 0.2em;
    border: 0;
    ${props => props.uiSelectable && css`
        cursor: pointer;
        transition: background-color 0.1s ease;
        will-change: background-color;

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }
    `};
    ${props => props.uiActive && css`
        background-color: rgba(0, 0, 0, 0.05);
    `};
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
`;

export default ListItem;
