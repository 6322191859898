import { History } from "history";
import React from "react";
import ReactDOM from "react-dom";
import Redbox from "redbox-react";
import Root from "./root";
import { getEnv } from "./utils/environment";
import moment from "moment";
/**
 * Set application locale
 */
export function setLocale(locale: string): void {
    moment.locale(locale);
}

/**
 * Render application
 */
export async function render(
    element: HTMLElement | null,
    history: History,
    store: any
): Promise<void> {
    try {
        ReactDOM.render(<Root history={history} store={store} />, element);
    } catch (e) {
        if (getEnv().NODE_ENV !== "production") {
            ReactDOM.render(<Redbox error={e} />, element);
        } else {
            ReactDOM.render(<h1>Error: {e.message}</h1>, element);
        }
    }
}

/**
 * Render error
 */
export async function renderError(
    element: HTMLElement | null,
    e: Error
): Promise<void> {
    ReactDOM.render(<h1>Error at initialization: {e.message}</h1>, element);
}
