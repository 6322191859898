import {ActionDescriptor} from "../../common";
import {DraperyOrderPrice} from "./interfaces";

export const GET_DRAPERY_ORDER_PRICE = "draperyOrderPrice/GET";
export type GetDraperyOrderPriceAction = ActionDescriptor<typeof GET_DRAPERY_ORDER_PRICE, { draperyOrderPriceId: number }, DraperyOrderPrice>;

export const ADD_DRAPERY_ORDER_PRICE = "draperyOrderPrice/ADD";
export type AddDraperyOrderPriceAction = ActionDescriptor<typeof ADD_DRAPERY_ORDER_PRICE,
    DraperyOrderPrice,
    DraperyOrderPrice
>;
export interface DraperyOrderPriceActionsMap {
    [GET_DRAPERY_ORDER_PRICE]: GetDraperyOrderPriceAction;
    [ADD_DRAPERY_ORDER_PRICE]: AddDraperyOrderPriceAction;
}
