import {
    Api,
    isRealtimeRequestAction,
    isServerBroadcastAction,
    REALTIME_ACTION_FAILED,
    REALTIME_ACTION_REQUEST,
    REALTIME_ACTION_SUCCESS,
    RealtimeErrorResponseAction,
    RealtimeRequestAction,
    RealtimeSuccessResponseAction,
    SerializableError,
} from "@quipa/api";
import { Middleware } from "redux";
import { CancelToken } from "../../utils/cancel_token";
import { RealtimeClient } from "../../utils/realtime_client";
import { RootState } from "../modules";

declare module "@quipa/api/build-ts/common/realtime_action" {
    export interface RealtimeActionMetaInformation {
        /**
         * Current logged in user id
         */
        currentUserId?: number;
        /**
         * For broadcasted action will be sender user id
         */
        actionUserId?: number;
        /**
         * True if action should be optimistic
         */
        isOptimistic?: boolean;
        /**
         * Delay request operation for specific amount of miliseconds
         */
        delay?: number;
        /**
         * Cancellation token
         */
        cancelToken?: CancelToken;

        [key: string]: boolean | string | number | undefined | CancelToken;
        // [key: string]: boolean | string | number | undefined;
    }
}

export default function(realtimeApi: RealtimeClient): Middleware<{}, RootState> {
    let resolveAuthPromise: () => void;
    let rejectAuthPromise: () => void;
    // initial auth promise. not necessary because portal will block UI until succesfull auth, but let's make sure
    let authPromise: Promise<any> | undefined = new Promise((res, rej) => {
        resolveAuthPromise = res;
        rejectAuthPromise = rej;
    });
    return store => next => async action => {
        // pass thunks
        if (typeof action === "function") {
            return next(action);
        }

        // if disconnected/paused - create new auth promise
        // TODO
        // if (action.type === ApplicationModule.SET_REALTIME_STATUS && !authPromise && (action.payload === "disconnected" || action.payload === "paused")) {
        //     authPromise = new Promise((resolve, reject) => {
        //         resolveAuthPromise = resolve;
        //         rejectAuthPromise = reject;
        //     });
        // }

        // Store current user id
        // const currentUserId = store.getState().user.id;

        // Catch broadcast action and convert them to request + success response
        // Request action is needed to make optimistic actions work
        if (isServerBroadcastAction(action)) {
            const requestAction: RealtimeRequestAction<any, any, any> = {
                type: action.type,
                flowType: REALTIME_ACTION_REQUEST,
                error: false,
                meta: {
                    realtime: false,
                    actionUserId: action.meta.sentByUserId,
                    // currentUserId,
                    isOptimistic: false,
                },
                payload: action.requestPayload,
            };
            const successAction: RealtimeSuccessResponseAction<any, any, any> = {
                type: action.type,
                flowType: REALTIME_ACTION_SUCCESS,
                error: false,
                meta: {
                    realtime: false,
                    actionUserId: action.meta.sentByUserId,
                    // currentUserId,
                    isOptimistic: false,
                },
                requestPayload: action.requestPayload,
                payload: action.payload,
            };
            store.dispatch(requestAction);
            store.dispatch(successAction);
            return;
        }

        // pass non realtime actions
        if (!isRealtimeRequestAction(action)) {
            return next(action);
        }
        // create new auth promise for auth action if not created yet
        if (action.type === Api.LOGIN && !authPromise) {
            authPromise = new Promise((resolve, reject) => {
                resolveAuthPromise = resolve;
                rejectAuthPromise = reject;
            });
        }
        // action.meta.currentUserId = currentUserId;
        // action.meta.actionUserId = currentUserId;

        // pass request action next
        next(action);

        const cancelToken = action.meta.cancelToken;

        // We don't want to send cancel token over network
        const requestWithoutToken: RealtimeRequestAction<any, any> = {
            ...action,
            meta: {
                ...action.meta,
                cancelToken: undefined,
            },
        };
        try {
            // If authentication required wait for it
            if (action.meta.authenticated && authPromise) {
                await authPromise;
            }

            const request = action.meta.delay
                ? new Promise<any>((resolve, reject) => {
                    setTimeout(() => {
                        realtimeApi.sendAction(requestWithoutToken).then(resolve).catch(reject);
                    }, action.meta.delay);
                })
                : realtimeApi.sendAction(requestWithoutToken);

            // store authentication action
            // if (action.type === PortalApi.LOGIN) {
            //     authPromise = request;
            // }

            const resultAction: RealtimeSuccessResponseAction<any, any, any> | RealtimeErrorResponseAction<any, any> = await request;
            if (action.type === Api.LOGIN && resolveAuthPromise) {
                // unblock waiting auth promise actions, even if auth failed
                resolveAuthPromise();
                authPromise = undefined;
            }
            if (!action.meta.simpleFlow && !(cancelToken && cancelToken.Cancelled)) {
            // if (!action.meta.simpleFlow) {
                return store.dispatch({
                    ...resultAction,
                    meta: {
                        ...resultAction.meta,
                        realtime: false,
                        // actionUserId: currentUserId,
                        // currentUserId,
                    },
                });
            }
        } catch (e) {
            if (action.type === Api.LOGIN && rejectAuthPromise) {
                rejectAuthPromise();
                authPromise = undefined;
            }
            // Network error here likely
            if (action.meta.simpleFlow || (cancelToken && cancelToken.Cancelled)) {
            // if (action.meta.simpleFlow) {
                return;
            }
            const errorAction: RealtimeErrorResponseAction<any, any> = {
                ...action,
                type: action.type,
                flowType: REALTIME_ACTION_FAILED,
                error: true,
                meta: {
                    ...action.meta,
                    realtime: false,
                    authenticated: undefined,
                    noPropagate: undefined,
                    simpleFlow: undefined,
                    cancelToken: undefined,
                },
                payload: new SerializableError(e.message).serialize(),
                requestPayload: action.payload,
            } as any;
            return store.dispatch(errorAction);
        } finally {
            if (action.type === Api.LOGIN) {
                authPromise = undefined;
            }
        }
    };
}
