import Bind from "lodash-decorators/bind";
import React, { PureComponent } from "react";
import { DraperyModule } from "../../redux";
import styled, { css, theme } from "../../theme";
import moment from "moment";

const Container = styled.div`
    width: 100%;
`;

const DisplayName = styled.span`
    width: 100%;
    text-align: center;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    color: ${(props) => (props.className ? "#FF6084" : "inital")};

    &:hover {
        color: #51a6c5;
    }

    & .searchTerm{
        background: #e4e9f1;
    }
`;

const SummaryComponentTable = styled.div`
    position: absolute;
    display: flex;
    z-index: 999;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid lightgray;
    padding: 15px 15px;
    color: #4a4a4a;
    font-size: 13px;
    margin-right: 15px;
    flex-direction: column;
    right: 10px;
    overflow: auto;
    max-height: 170px;
    width: 95%;
    max-width: 700px;
    box-shadow: 0px 5px 7px rgba(0,0,0,.5);
`;

export interface SummaryComponentTableCellProps {
    uiFlex?: number;
    uiWidth?: number;
    uiCenter?: boolean;
    uiRight?: boolean;
}

export const SummaryComponentTableCell = styled.div<SummaryComponentTableCellProps>`
    // border-right: 1px solid ${theme.colors.primary};
    padding: 5px 10px;
    
    &:last-child {
        border-right: none;
        word-break: break-word;
        hyphens: auto;
    }

    ${(props) =>
        props.uiWidth &&
        css`
            flex: ${props.uiWidth};
        `};
    ${(props) =>
        props.uiFlex &&
        css`
            flex: ${props.uiFlex};
        `};
    ${(props) =>
        props.uiCenter &&
        css`
            text-align: center;
        `};
    ${(props) =>
        props.uiRight &&
        css`
            text-align: right;
        `};
`;

export const SummaryComponentTableHeaderCell = styled(
    SummaryComponentTableCell
)`  
    border-bottom: 1px solid lightgray;
    margin: 0px 2px;
    color: ${theme.colors.grayRGBA};
    text-align: center;
    font-weight: 600;
    text-wrap: nowrap;
    padding-bottom: 12px;
`;

export const SummaryComponentTableRow = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr  2fr  2fr  2fr  2fr;
    // border-bottom: 1px solid ${theme.colors.lightGrayText};
    position: relative;

    &>:nth-child(1){
        word-break: break-word;
        word-wrap: break-word;
        hyphens: auto;
    }

    &:last-child {
        border-bottom: none;
    }
`;

export const SummaryCOmponentTableHeaderRow = styled(SummaryComponentTableRow)`
    // border-bottom: 1px solid ${theme.colors.primary};
    display: grid;
    grid-template-columns: 2fr 1fr 2fr 2fr 2fr 2fr;
    &>:nth-child(1){
        text-align: left !important;
    }
`;

interface HoverBoxProps {
    displayText: string;
    components: DraperyModule.DraperyOrderSummaryComponent[];
    componentsPastEta?(): boolean | undefined;
    children?: any;
}

interface State {
    isHovered: boolean;
}

class SummaryComponentHoverBox extends PureComponent<HoverBoxProps, State> {
    public state: State = {
        isHovered: false,
    };

    public render(): JSX.Element {
        const { components, displayText, children } = this.props;
        return (
            <Container onMouseLeave={this.hideBox}>
                <DisplayName
                    onMouseOver={this.showBox}
                    className={
                        (this.props.componentsPastEta &&
                            this.props.componentsPastEta() &&
                            displayText &&
                            displayText.toLowerCase() === "no") ||
                            (!this.props.componentsPastEta &&
                                displayText &&
                                displayText.toLowerCase() === "no")
                            ? "danger"
                            : ""
                    }
                >
                    {children}
                </DisplayName>

                {this.state.isHovered && components.length ? (
                    <SummaryComponentTable>
                        <SummaryCOmponentTableHeaderRow>
                            <SummaryComponentTableHeaderCell uiFlex={2}>
                                ITEM
                            </SummaryComponentTableHeaderCell>
                            <SummaryComponentTableHeaderCell
                                uiFlex={1}
                                uiCenter
                            >
                                PO #
                            </SummaryComponentTableHeaderCell>
                            <SummaryComponentTableHeaderCell
                                uiFlex={2}
                                uiCenter
                            >
                                ORDERED
                            </SummaryComponentTableHeaderCell>
                            <SummaryComponentTableHeaderCell
                                uiFlex={2}
                                uiCenter
                            >
                                ETA
                            </SummaryComponentTableHeaderCell>
                            <SummaryComponentTableHeaderCell
                                uiFlex={2}
                                uiCenter
                            >
                                REC'D
                            </SummaryComponentTableHeaderCell>
                            <SummaryComponentTableHeaderCell uiFlex={3}>
                                NOTES
                            </SummaryComponentTableHeaderCell>
                        </SummaryCOmponentTableHeaderRow>
                        {components.map(this.renderComponentRow)}
                    </SummaryComponentTable>
                ) : (
                    ""
                )}
            </Container>
        );
    }

    public renderComponentRow(
        component: DraperyModule.DraperyOrderSummaryComponent
    ) {
        const componentOrderedDateToShow = !component.orderedDate
            ? null
            : moment(component.orderedDate).isValid()
                ? moment(component.orderedDate).format("MM/DD/YYYY")
                : component.orderedDate;

        const componentETADateToShow = !component.eta
            ? null
            : moment(component.eta).isValid()
                ? moment(component.eta).format("MM/DD/YYYY")
                : component.eta;

        const componentReceivedDateToShow = !component.receivedDate
            ? null
            : moment(component.receivedDate).isValid()
                ? moment(component.receivedDate).format("MM/DD/YYYY")
                : component.receivedDate;
        return (
            <SummaryComponentTableRow key={component.id}>
                <SummaryComponentTableCell uiFlex={2} style={{ textTransform: 'capitalize' }}>
                    {component.item ? component.item.toLowerCase() : '' || ""}
                </SummaryComponentTableCell>
                <SummaryComponentTableCell uiFlex={1} uiRight>
                    {component.po || ""}
                </SummaryComponentTableCell>
                <SummaryComponentTableCell uiFlex={2} uiCenter>
                    {componentOrderedDateToShow}
                </SummaryComponentTableCell>
                <SummaryComponentTableCell uiFlex={2} uiCenter>
                    {componentETADateToShow}
                </SummaryComponentTableCell>
                <SummaryComponentTableCell uiFlex={2} uiCenter>
                    {componentReceivedDateToShow}
                </SummaryComponentTableCell>
                <SummaryComponentTableCell uiFlex={3}>
                    {component.notes || ""}
                </SummaryComponentTableCell>
            </SummaryComponentTableRow>
        );
    }

    @Bind()
    private hideBox(): void {
        this.setState({ isHovered: false });
    }

    @Bind()
    private showBox(): void {
        this.setState({ isHovered: true });
    }
}

export default SummaryComponentHoverBox;
