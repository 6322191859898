import React, { forwardRef, SVGAttributes } from "react";

const SquareIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>(
    (props, ref) => {
        const { style, ...other } = props;
        return (
            <>
                <svg
                    fill="currentColor"
                    {...other}
                    style={{
                        width: "20px",
                        height: "20px",
                        border: "1px solid #CDCDCD",
                        borderRadius: "6px",
                        background: "#fff",
                        ...style,
                    }}
                    ref={ref}
                    xmlns="http://www.w3.org/2000/svg"
                ></svg>
            </>
        );
    }
);

SquareIcon.defaultProps = {
    height: "20px",
    width: "20px",
};

/**
 * Square icon
 */
export default SquareIcon;
