import { Button, dimmable, Dimmer, Header, Loader, Modal, ModalActions, ModalContent, ModalHeader, PageDimmer } from "@ramble/ramble-ui";
import Color from "color";
import React, { PureComponent } from "react";
import styled from "../theme";

export interface QuickbookConnectModalProps {
    /**
     * Modal active
     */
    active: boolean;
    /**
     * Oauth client id
     */
    oauthClientId: string;
    /**
     * Oauth redirect uri
     */
    oauthRedirectUri: string;
    /**
     * Request close callback
     */
    onRequestClose(): void;
    /**
     * Quickbook connected callback
     * @param code OAuth2 Authorization code
     * @param realm Quickbook realm id
     */
    onAccepted(code: string, realm: string): Promise<void>;
}

// const InfoList = styled(List)`
//     margin-bottom: 30px;
//     margin-top: 0;
//     padding: 0;
//     flex: 1 0 nowrap;
// `;

// const InfoListItem = styled(ListItem)`
//     align-items: baseline;
//     padding-left: 1em;
//     position: relative;
//     flex-wrap: wrap;

//     &::before {
//         position: absolute;
//         top: 5px;
//         left: 0;
//         content: "*";
//         user-select: none;
//         pointer-events: none;
//         margin-right: 0.8em;
//         opacity: 0.8;
//     }
// `;

export const FormErrorMessage = styled.div`
    margin-top: 1em;
    font-size: 1em;
    background: transparent;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 1em 1.5em 0;
    color: ${({ theme }) => Color(theme.colors.danger).lighten(0.5).string()};
`;

const Description = styled.div`
    font-size: 1em;
    line-height: 1;
`;

const Content = styled(ModalContent)`
    ${dimmable};
`;

const CancelButton = styled(Button)`
    margin-right: auto !important;
`;

interface State {
    submitting: boolean;
    error?: string;
}

const noop = () => { /* ignore */ };

export class QuickbookConnectModal extends PureComponent<QuickbookConnectModalProps, State> {
    public state: State = {
        submitting: false,
        error: undefined,
    };

    public componentDidMount(): void {
        window.addEventListener("oauthResult", this.onOAuthLogin, false);
    }

    public componentWillUnmount(): void {
        window.removeEventListener("oauthResult", this.onOAuthLogin);
    }

    public render(): JSX.Element {
        const { active } = this.props;
        return (
            <>
                <PageDimmer uiActive={active} />
                <Modal
                    uiActive={active}
                    // disable close on outside click
                    uiOnRequestClose={noop}
                >
                    <ModalHeader>
                        Quickbook connect
                    </ModalHeader>
                    <Content>
                        <Header uiSize="1.2em">Welcome!</Header>
                        <Description>
                            <p>
                                To get the most out of using QUIPA with Quickbooks connect your QUIPA account to your
                                Quickbooks account.
                            </p>
                        </Description>
                        <Dimmer
                            uiColor="white"
                            uiActive={this.state.submitting}
                        >
                            <Loader />
                        </Dimmer>
                        {this.state.error && (
                            <FormErrorMessage>{this.state.error}</FormErrorMessage>
                        )}
                    </Content>
                    <ModalActions>
                        <CancelButton
                            uiColor="secondary"
                            onClick={this.handleClose}
                            uiDisabled={this.state.submitting}
                        >
                            No, Cancel
                        </CancelButton>
                        <Button
                            uiColor="primary"
                            onClick={this.onAcceptClick}
                            uiDisabled={this.state.submitting}
                        >
                            Yes, Connect
                        </Button>
                    </ModalActions>
                </Modal>
            </>
        );
    }

    private handleClose = () => {
        if (this.state.submitting) {
            return;
        }
        this.props.onRequestClose();
    }

    private onAcceptClick = async () => {
        const { oauthClientId, oauthRedirectUri } = this.props;
        const href =
            "https://appcenter.intuit.com/connect/oauth2?" +
            `client_id=${oauthClientId}` +
            `&redirect_uri=${encodeURIComponent(oauthRedirectUri)}&response_type=code` +
            // TODO: state should be CSRF token
            `&scope=com.intuit.quickbooks.accounting&state=${Math.random()}`;
        window.open(href, "Quipa_OAuth");
    }

    private connectToQuickbook = async (code: string, realm: string) => {
        const { onAccepted } = this.props;
        this.setState({
            submitting: true,
            error: undefined,
        });
        let err: string | undefined;
        try {
            await onAccepted(code, realm);
        } catch (e) {
            err = e.message;
        } finally {
            this.setState({
                submitting: false,
                error: err,
            });
        }
    }

    private onOAuthLogin = async (e: Event) => {
        const payload = (e as CustomEvent).detail;
        if (payload.error) {
            this.setState({
                submitting: false,
                error: payload.error_description || "Unknown error",
            });
            return;
        } else {
            const { code, realmid } = payload;
            await this.connectToQuickbook(code, realmid);
        }
    }
}
