import Color from "color";
import { ClassAttributes, TextareaHTMLAttributes } from "react";
import styled, { css } from "../theme/styled";

export interface TextAreaUIProps {
    /**
     * Error textarea
     */
    uiError?: boolean;
    /**
     * Disabled textarea
     */
    uiDisabled?: boolean;
    /**
     * Resizable textarea
     * @default false
     */
    uiResize?: boolean;
}

export type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & TextAreaUIProps;

/**
 * Simple textarea with generic styling
 */
const TextArea = styled.textarea<TextAreaProps & ClassAttributes<HTMLTextAreaElement>>`
    font-weight: 400;
    font-style: normal;
    border-radius: 0.28rem;
    padding: 0.67em 1em;
    outline: none;
    text-align: left;
    box-sizing: border-box;
    transition: border-color, background, color 0.2s ease;
    will-change: color, border-color, background;
    color: ${({ theme }) => Color(theme.colors.primaryText).alpha(0.87).string()};
    border: 1px solid ${({ theme }) => Color(theme.colors.divider).alpha(0.15).string()};
    background: ${({ theme }) => theme.colors.background};

    &:focus {
        border-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => Color(theme.colors.primaryText).alpha(0.8).string()};
    }

    ${({ theme, uiError }) => uiError && css`
        color: ${Color(theme.colors.danger).desaturate(0.2).string()};
        border-color: ${Color(theme.colors.danger).lighten(0.5).string()};
        background: ${Color(theme.colors.danger).lighten(0.9).string()};

        &:focus {
            border-color: ${Color(theme.colors.danger).lighten(0.5).string()};
            color: ${Color(theme.colors.danger).lighten(0.5).string()};
        }
    `};
    ${props => !props.uiResize && css`
        resize: none;
    `};
    ${({ uiDisabled }) => uiDisabled && css`
        pointer-events: none;
        opacity: 0.45;
    `};
`;

TextArea.defaultProps = {
    uiResize: false,
};

export default TextArea;
