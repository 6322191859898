import { ClassAttributes, HTMLAttributes } from "react";
import styled, { PropsWithTheme } from "../theme/styled";

export interface DimmerContentUIProps {
    /**
     * Dimmer color
     */
    uiColor?: "black" | "white" | "transparent" | "inherit";
}

export type DimmerContentProps = HTMLAttributes<HTMLDivElement> & DimmerContentUIProps;

const getTextColor = ({ theme, uiColor }: PropsWithTheme<DimmerContentUIProps>) =>
    uiColor === "transparent" || uiColor === "inherit"
        ? "inherit"
        : uiColor === "white" ? theme.getContrastTextColor(theme.dimmer.white)
            : theme.getContrastTextColor(theme.dimmer.black);

const getColor = ({ theme, uiColor }: PropsWithTheme<DimmerContentUIProps>) =>
    uiColor === "inherit" ? "inherit" :
        uiColor === "transparent" ? "transparent" :
            uiColor === "white" ? theme.dimmer.white :
                theme.dimmer.black;

/**
 * Dimmer element itself
 */
const DimmerContent = styled.div<DimmerContentProps & ClassAttributes<HTMLDivElement>>`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    vertical-align: middle;
    line-height: 1;
    user-select: none;
    will-change: opacity;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    transition: background-color 250ms ease-in-out;
    color: ${props => getTextColor(props)};
    background-color: ${props => getColor(props)};
    z-index: ${({ theme }) => theme.zIndex.dimmer};
`;

DimmerContent.defaultProps = {
    uiColor: "black",
};

export default DimmerContent;
