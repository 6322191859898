import { AnyAction, Middleware } from "redux";
import { SAVE, StorageEngine } from "redux-storage";
import { RootState } from "../modules";

export default function createStorageMiddleware(engine: StorageEngine, whitelistFunc: (action: AnyAction) => boolean): Middleware<{}, RootState> {
    return ({ dispatch, getState }) => next => async action => {
        // tslint:disable:no-string-literal
        const res = next(action);

        if (!action || typeof action !== "object" || typeof action["type"] !== "string") {
            return res;
        }

        if (!whitelistFunc(action)) {
            return res;
        }

        try {
            const saveState = getState();
            await engine.save(saveState);
            dispatch({ type: SAVE, error: false });
        } catch {
            // ignore
        }
        return res;
    };
}
