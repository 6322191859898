import React, {PureComponent} from "react";

export class EmailVerificationIssue extends PureComponent {

    render() {
        return (
            <>
                Problem with email verification
            </>
        )
    }
}