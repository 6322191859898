import { detect } from "detect-browser";
import React, { ChangeEvent, FormEvent } from "react";
import SelectList from "../../components/UI/select-list";
import { SuppliersModule } from "../../redux";
import styled, { theme } from "../../theme";
import {
    LeftCell,
    RightCell,
    RightCellWithBorder,
    SupplierBlock,
    SupplierCheckboxSmall,
    SupplierFormInput,
    SupplierLabel,
    SupplierSubtitle,
    ThreeItemsWrapper,
    TwoItemsWrapper,
} from "./supplier_modal";

const CompanyIformation = styled.div`
    width: 100%;
    border-bottom: 1px solid #338daa;
    padding-bottom: 1rem;

    label {
        color: ${theme.colors.grayRGBA};
    }

    > div {
        display: flex;
        align-items: flex-start;

        > label {
            margin-right: 20px;
        }
    }
`;

interface Props {
    formFields: SuppliersModule.Supplier;
    stateList: string[];
    isSameInfo: boolean;
    onInputChange(e: ChangeEvent<HTMLInputElement>): void;
    onSelectChange(value: string, name: string): void;
    handleChangeSameInformationCheckbox(e: FormEvent<HTMLInputElement>): void;
}

const BillingInfo = (props: Props): JSX.Element => {
    const browser = detect();
    const {
        stateList,
        formFields,
        onInputChange,
        onSelectChange,
        handleChangeSameInformationCheckbox,
        isSameInfo,
    } = props;
    return (
        <SupplierBlock>
            <CompanyIformation>
                <SupplierSubtitle>COMPANY INFORMATION</SupplierSubtitle>
                <div>
                    <SupplierCheckboxSmall
                        name="sameinfo"
                        onChange={(e) => handleChangeSameInformationCheckbox(e)}
                        checked={isSameInfo}
                    >
                        Same as ordering information
                    </SupplierCheckboxSmall>
                </div>
            </CompanyIformation>
            <LeftCell>
                <div className="mb-2">
                    <SupplierLabel htmlFor="billingCompanyName">
                        Company
                    </SupplierLabel>
                    <SupplierFormInput
                        id="billingCompanyName"
                        name="billingAddress.company"
                        className="label"
                        type="text"
                        value={formFields.billingAddress.company || ""}
                        onChange={onInputChange}
                        uiReportOnBlur={false}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
                <TwoItemsWrapper>
                    <div>
                        <SupplierLabel htmlFor="billingMainPhone">
                            Main Phone
                        </SupplierLabel>
                        <SupplierFormInput
                            id="billingMainPhone"
                            name="billingAddress.mainPhone"
                            className="label"
                            type="text"
                            value={formFields.billingAddress.mainPhone || ""}
                            onChange={onInputChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                    <div>
                        <SupplierLabel htmlFor="billingFax">Fax</SupplierLabel>
                        <SupplierFormInput
                            id="billingFax"
                            name="billingAddress.fax"
                            className="label"
                            type="text"
                            value={formFields.billingAddress.fax || ""}
                            onChange={onInputChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                </TwoItemsWrapper>
                <div className="mb-2">
                    <SupplierLabel htmlFor="billingAccount #">
                        Account #
                    </SupplierLabel>
                    <SupplierFormInput
                        id="billingAccount"
                        name="billingAddress.accountNumber"
                        className="label"
                        type="text"
                        value={formFields.billingAddress.accountNumber || ""}
                        onChange={onInputChange}
                        uiReportOnBlur={false}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
            </LeftCell>
            <RightCell>
                <div className="mb-2">
                    <SupplierLabel htmlFor="billingPaymentTerms">
                        Payment Terms
                    </SupplierLabel>
                    <SupplierFormInput
                        id="billingPaymentTerms"
                        name="billingAddress.paymentTerms"
                        className="label"
                        type="text"
                        value={formFields.billingAddress.paymentTerms || ""}
                        onChange={onInputChange}
                        uiReportOnBlur={false}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
            </RightCell>
            <LeftCell>
                <SupplierSubtitle>BILLING CONTACT</SupplierSubtitle>
                <TwoItemsWrapper>
                    <div>
                        <SupplierLabel htmlFor="billingFirstName">
                            First Name
                        </SupplierLabel>
                        <SupplierFormInput
                            id="billingFirstName"
                            name="billingAddress.firstName"
                            className="label"
                            type="text"
                            value={formFields.billingAddress.firstName || ""}
                            onChange={onInputChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                    <div>
                        <SupplierLabel htmlFor="billingLastName">
                            Last Name
                        </SupplierLabel>
                        <SupplierFormInput
                            id="billingLastName"
                            name="billingAddress.lastName"
                            className="label"
                            type="text"
                            value={formFields.billingAddress.lastName || ""}
                            onChange={onInputChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                </TwoItemsWrapper>
                <TwoItemsWrapper>
                    <div>
                        <SupplierLabel htmlFor="billingCellPhone">
                            Cell Phone
                        </SupplierLabel>
                        <SupplierFormInput
                            id="billingCellPhone"
                            name="billingAddress.cellPhone"
                            className="label"
                            type="text"
                            value={formFields.billingAddress.cellPhone || ""}
                            onChange={onInputChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                    <div>
                        <SupplierLabel htmlFor="billingOfficePhone">
                            Office Phone
                        </SupplierLabel>
                        <SupplierFormInput
                            id="billingOfficePhone"
                            name="billingAddress.officePhone"
                            className="label"
                            type="text"
                            value={formFields.billingAddress.officePhone || ""}
                            onChange={onInputChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                </TwoItemsWrapper>
                <div>
                    <SupplierLabel htmlFor="billingEmail">Email</SupplierLabel>
                    <SupplierFormInput
                        id="billingEmail"
                        name="billingAddress.email"
                        className="label"
                        type="text"
                        value={formFields.billingAddress.email || ""}
                        onChange={onInputChange}
                        uiReportOnBlur={false}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
            </LeftCell>
            <RightCellWithBorder>
                <SupplierSubtitle>BILLING ADDRESS</SupplierSubtitle>
                <div className="mb-2">
                    <SupplierLabel htmlFor="billingStreetAddress">
                        Street Address
                    </SupplierLabel>
                    <SupplierFormInput
                        id="billingStreetAddress"
                        name="billingAddress.streetAddress"
                        className="label"
                        type="text"
                        value={formFields.billingAddress.streetAddress || ""}
                        onChange={onInputChange}
                        uiReportOnBlur={false}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
                <div className="mb-2">
                    <SupplierLabel htmlFor="billingSuiteUnit">
                        Suite / Unit
                    </SupplierLabel>
                    <SupplierFormInput
                        id="billingSuiteUnit"
                        name="billingAddress.unitSuite"
                        className="label"
                        type="text"
                        value={formFields.billingAddress.unitSuite || ""}
                        onChange={onInputChange}
                        uiReportOnBlur={false}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
                <ThreeItemsWrapper>
                    <div>
                        <SupplierLabel htmlFor="billingCity">
                            City
                        </SupplierLabel>
                        <SupplierFormInput
                            id="billingCity"
                            name="billingAddress.cityTown"
                            className="label"
                            type="text"
                            value={formFields.billingAddress.cityTown || ""}
                            onChange={onInputChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                    <div>
                        <SelectList
                            label={"State"}
                            selectList={stateList}
                            handleClickSelect={onSelectChange}
                            valueSelect={
                                formFields.billingAddress.stateProvince
                            }
                            flexDirection={"column"}
                            name="billingAddress.stateProvince"
                            smallPadding
                            customValue
                            noAddButton
                            noClearBox
                            uiDropArrow
                        />
                    </div>
                    <div>
                        <SupplierLabel htmlFor="billingZip">Zip</SupplierLabel>
                        <SupplierFormInput
                            id="billingZip"
                            name="billingAddress.zipCode"
                            className="label"
                            type="number"
                            value={formFields.billingAddress.zipCode || ""}
                            onChange={onInputChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                </ThreeItemsWrapper>
                <div className="mb-2">
                    <SupplierLabel htmlFor="billingCountry">
                        Country
                    </SupplierLabel>
                    <SupplierFormInput
                        id="billingCountry"
                        name="billingAddress.country"
                        className="label"
                        type="text"
                        value={formFields.billingAddress.country || ""}
                        onChange={onInputChange}
                        uiReportOnBlur={false}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
            </RightCellWithBorder>
        </SupplierBlock>
    );
};

export default BillingInfo;
