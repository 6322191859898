import {
    ClientBlock,
    ClientCheckbox,
    ClientFields,
    ClientFormInput,
    ClientFormLabel,
    ClientFormSubtitle,
    LeftCell,
    RightCellWithBorder,
    ThreeItemsWrapper,
    TwoItemsWrapper,
} from "./client_modal";
import React, { ChangeEvent } from "react";
import SearchSelect, {
    SearchSelectProps,
} from "../../components/UI/search_select/search_select";

import Avatar from "../../components/UI/avatar";
import DisplayName from "./client-display-name";
import SelectList from "../../components/UI/select-list";
import { detect } from "detect-browser";
import styled from "../../theme";

export interface MainInfoProps {
    ref: React.RefObject<HTMLInputElement>;
    formFields: ClientFields;
    stateList: string[];
    showSubClientMenuList: boolean;
    initialParentClientName?: string;
    handleSearch: SearchSelectProps["onSearch"];
    openAvatarModal(): void;
    deleteAvatarImage(): void;
    handleFieldChange(e: ChangeEvent<HTMLInputElement>): void;
    handleChangeDisplayName(value: string): void;
    handleToggleSubClientMenuList(): void;
    handleFieldState(value: string, name: string): void;
    handleSelectClient(id: number, name: string): void;
    handleClearKeyword(): void;
}

const SubFieldBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 43px;

    label {
        display: flex;
        align-items: center;
        margin-bottom: 3px;
        font-size: 1em;
        margin-right: 15px;

        > input {
            margin-right: 5px;
        }
    }
`;

const MainInfo = React.forwardRef(
    (props: MainInfoProps, ref: React.Ref<HTMLInputElement>): JSX.Element => {
        const browser = detect();
        const {
            formFields,
            openAvatarModal,
            deleteAvatarImage,
            handleFieldChange,
            handleChangeDisplayName,
            handleFieldState,
            stateList,
            handleToggleSubClientMenuList,
            showSubClientMenuList,
            handleSelectClient,
            handleSearch,
            initialParentClientName,
            handleClearKeyword,
        } = props;
        return (
            <ClientBlock>
                <LeftCell>
                    <ClientFormSubtitle>
                        {showSubClientMenuList
                            ? `SUB-CLIENT INFORMATION`
                            : `CLIENT INFORMATION`}
                    </ClientFormSubtitle>
                    <SubFieldBox>
                        <ClientCheckbox
                            onClick={handleToggleSubClientMenuList}
                            defaultChecked={showSubClientMenuList}
                        >
                            Sub-client of
                        </ClientCheckbox>

                        {showSubClientMenuList && (
                            <SearchSelect
                                searchType="modal"
                                onItemClick={handleSelectClient}
                                onSearch={handleSearch}
                                initialValue={initialParentClientName}
                                placeholder="Please select"
                                keepKeyword
                                onKeywordCleared={handleClearKeyword}
                                required={true}
                                searchItem="client"
                                searchPage="clients"
                            />
                        )}
                    </SubFieldBox>
                    <TwoItemsWrapper>
                        <div>
                            <ClientFormLabel htmlFor="firstName">
                                First Name
                            </ClientFormLabel>
                            <ClientFormInput
                                ref={ref}
                                id="firstName"
                                name="firstName"
                                className="label"
                                type="text"
                                value={formFields.firstName || ""}
                                onChange={handleFieldChange}
                                uiReportOnBlur={false}
                                autoComplete={
                                    browser && browser.name === "chrome"
                                        ? "new-password"
                                        : "off"
                                }
                                list="autocompleteOff"
                            />
                        </div>
                        <div>
                            <ClientFormLabel htmlFor="lastName">
                                Last Name
                            </ClientFormLabel>
                            <ClientFormInput
                                id="lastName"
                                name="lastName"
                                className="label"
                                type="text"
                                value={formFields.lastName || ""}
                                onChange={handleFieldChange}
                                uiReportOnBlur={false}
                                autoComplete={
                                    browser && browser.name === "chrome"
                                        ? "new-password"
                                        : "off"
                                }
                                list="autocompleteOff"
                            />
                        </div>
                    </TwoItemsWrapper>

                    <div className="mb-2">
                        <ClientFormLabel htmlFor="company">
                            Company Name
                        </ClientFormLabel>
                        <ClientFormInput
                            id="company"
                            name="company"
                            className="label"
                            type="text"
                            value={formFields.company || ""}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                    <div className="mb-2">
                        <DisplayName
                            value={formFields.displayName}
                            firstName={formFields.firstName}
                            lastName={formFields.lastName}
                            company={formFields.company}
                            handleFieldChange={handleChangeDisplayName}
                        />
                    </div>
                    <div className="mb-2">
                        <ClientFormLabel htmlFor="emailOwner">
                            Email
                        </ClientFormLabel>
                        <ClientFormInput
                            id="emailOwner"
                            name="email"
                            className="label"
                            type="email"
                            value={formFields.email || ""}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            data-index={0}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                    <TwoItemsWrapper>
                        <div>
                            <ClientFormLabel htmlFor="cellPhone">
                                Cell Phone
                            </ClientFormLabel>
                            <ClientFormInput
                                id="cellPhone"
                                name="cellPhone"
                                className="label"
                                type="text"
                                value={formFields.cellPhone || ""}
                                onChange={handleFieldChange}
                                uiReportOnBlur={false}
                                autoComplete={
                                    browser && browser.name === "chrome"
                                        ? "new-password"
                                        : "off"
                                }
                                list="autocompleteOff"
                            />
                        </div>
                        <div>
                            <ClientFormLabel htmlFor="homePhone">
                                Home Phone
                            </ClientFormLabel>
                            <ClientFormInput
                                id="homePhone"
                                name="homePhone"
                                className="label"
                                type="text"
                                value={formFields.homePhone || ""}
                                onChange={handleFieldChange}
                                uiReportOnBlur={false}
                                autoComplete={
                                    browser && browser.name === "chrome"
                                        ? "new-password"
                                        : "off"
                                }
                                list="autocompleteOff"
                            />
                        </div>
                    </TwoItemsWrapper>

                    <TwoItemsWrapper>
                        <div>
                            <ClientFormLabel htmlFor="officePhone">
                                Office Phone
                            </ClientFormLabel>
                            <ClientFormInput
                                id="officePhone"
                                name="officePhone"
                                className="label"
                                type="text"
                                value={formFields.officePhone || ""}
                                onChange={handleFieldChange}
                                uiReportOnBlur={false}
                                autoComplete={
                                    browser && browser.name === "chrome"
                                        ? "new-password"
                                        : "off"
                                }
                                list="autocompleteOff"
                            />
                        </div>

                        <div>
                            <ClientFormLabel htmlFor="fax">Fax</ClientFormLabel>
                            <ClientFormInput
                                id="fax"
                                name="fax"
                                className="label"
                                type="text"
                                value={formFields.fax || ""}
                                onChange={handleFieldChange}
                                uiReportOnBlur={false}
                                autoComplete={
                                    browser && browser.name === "chrome"
                                        ? "new-password"
                                        : "off"
                                }
                                list="autocompleteOff"
                            />
                        </div>
                    </TwoItemsWrapper>
                    <div>
                        <ClientFormLabel htmlFor="website">
                            Website
                        </ClientFormLabel>
                        <ClientFormInput
                            id="website"
                            name="website"
                            className="label"
                            type="text"
                            value={formFields.website || ""}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                </LeftCell>

                <RightCellWithBorder>
                    <Avatar
                        avatarId={formFields.avatarId}
                        openAvatarModal={openAvatarModal}
                        deleteAvatarImage={deleteAvatarImage}
                        firstName={formFields.firstName}
                        lastName={formFields.lastName}
                    />
                    <div className="mt-4">
                        <ClientFormSubtitle>
                            SHIPPING ADDRESS
                        </ClientFormSubtitle>
                    </div>
                    <div className="mb-2">
                        <ClientFormLabel htmlFor="streetAddress">
                            Street Address
                        </ClientFormLabel>
                        <ClientFormInput
                            id="streetAddress"
                            name="streetAddress"
                            className="label"
                            type="text"
                            value={formFields.streetAddress || ""}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                    <div className="mb-2">
                        <ClientFormLabel htmlFor="unitSuite">
                            Suite / Unit
                        </ClientFormLabel>
                        <ClientFormInput
                            id="unitSuite"
                            name="unitSuite"
                            className="label"
                            type="text"
                            value={formFields.unitSuite || ""}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                    <ThreeItemsWrapper>
                        <div>
                            <ClientFormLabel htmlFor="cityTown">
                                City
                            </ClientFormLabel>
                            <ClientFormInput
                                id="cityTown"
                                name="cityTown"
                                className="label"
                                type="text"
                                value={formFields.cityTown || ""}
                                onChange={handleFieldChange}
                                uiReportOnBlur={false}
                                autoComplete={
                                    browser && browser.name === "chrome"
                                        ? "new-password"
                                        : "off"
                                }
                                list="autocompleteOff"
                            />
                        </div>

                        <div>
                            <SelectList
                                label={"State"}
                                selectList={stateList}
                                handleClickSelect={handleFieldState}
                                valueSelect={formFields.stateProvince}
                                flexDirection={"column"}
                                name="stateProvince"
                                smallPadding
                                customValue
                                noAddButton
                                noClearBox
                                uiDropArrow
                            />
                        </div>

                        <div>
                            <ClientFormLabel htmlFor="zipCode">
                                Zip
                            </ClientFormLabel>
                            <ClientFormInput
                                id="zipCode"
                                name="zipCode"
                                className="label"
                                type="number"
                                value={formFields.zipCode || ""}
                                onChange={handleFieldChange}
                                uiReportOnBlur={false}
                                autoComplete={
                                    browser && browser.name === "chrome"
                                        ? "new-password"
                                        : "off"
                                }
                                list="autocompleteOff"
                            />
                        </div>
                    </ThreeItemsWrapper>

                    <div>
                        <ClientFormLabel htmlFor="country">
                            Country
                        </ClientFormLabel>
                        <ClientFormInput
                            id="country"
                            name="country"
                            className="label"
                            type="text"
                            value={formFields.country || ""}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                </RightCellWithBorder>

                {/*<ProjectManagerWrap>
                    <ClientFormLabel htmlFor="projectManager">Project Manager</ClientFormLabel>
                    <ClientFormInput
                        id="projectManager"
                        name="projectManager"
                        className="label"
                        type="text"
                        value={formFields.projectManager}
                        onChange={handleFieldChange}
                        uiReportOnBlur={false}
                        autoComplete={(browser && browser.name === "chrome") ? "new-password" : "off"}
                        list="autocompleteOff"
                    />
                </ProjectManagerWrap>*/}
            </ClientBlock>
        );
    }
);

export default MainInfo;
