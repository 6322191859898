import {
    Button,
    Modal,
    ModalActions as ModalActionsE,
    ModalContent,
    ModalHeader as ModalHeaderE,
    ModalProps,
    PageDimmer,
} from "@ramble/ramble-ui";
import React from "react";
import styled, { css } from "styled-components";
import { breakpoints, theme } from "../theme";

const noop = () => {
    /* ignore */
};

export interface ConfirmationModalProps
    extends Pick<
        ModalProps,
        | "uiActive"
        | "uiOnRequestClose"
        | "className"
        | "uiOnClose"
        | "uiTransitionTimeout"
        | "uiCloseOnEsc"
        | "uiCloseOnOutsideClick"
    > {
    /**
     * Modal header
     */
    uiHeader?: string;
    /**
     * Decline button label
     */
    uiDeclineLabel?: string | false;
    /**
     * Accept button label
     */
    uiAcceptLabel?: string | false;
    /**
     * Close modal when pressing Esc
     * @default true
     */
    uiCloseOnEsc?: boolean;
    /**
     * Close modal on outside click
     * @default true
     */
    uiCloseOnOutsideClick?: boolean;
    /**
     * Class name
     */
    className?: string;
    /**
     * Child
     */
    children?: React.ReactNode;
    /**
     * Confirmation callback
     */
    uiOnConfirm(res?: unknown): void;

    /**
     * Edit Work Order condition
     */
    workOrderEditMode?: boolean;

    /**
     * Cancel editing Work Order
     */
    cancelEditing?(): void;
}

const ConfirmationContent = styled(ModalContent)`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    color: ${theme.colors.grayRGBA};
`;

const StyledModal = styled(Modal)`
    max-width: 500px;
    border-radius: 0.5rem;
    border: solid ${theme.colors.grayRGBA} 1px;
    ${breakpoints.down(
    "sm",
    css`
            max-width: 90%;
        `
)};
`;

const ModalHeader = styled(ModalHeaderE)`
    color: ${theme.colors.primary};
    border-bottom: none;
    padding-bottom: 0.25rem;
`;

const ModalHeaderDivider = styled.div`
    height: 10px;
    width: calc(100% - 40px);
    margin: 0 auto;
    background: ${theme.colors.divider};
`;

const ModalActions = styled(ModalActionsE)`
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    justify-content: space-between;
    padding: 0.7rem 1rem;
`;

const BasicButton = styled(Button)`
    // width: 130px;
    box-shadow: none;
    border: 1px solid #c6c6c6;
    border-radius: 0.5em;
    display: block;
`;

const ConfirmButton = styled(BasicButton)`
    border-radius: 5px;
    // width: 100px;
    box-shadow: none;
    line-height: 0.6rem;
    &:hover {
        background: #c6c6c6;
    }
    margin-left: ${(props) =>
        props.className === "pop-up" ? "auto" : "initial"};
`;

const CancelButton = styled(BasicButton)`
    border-radius: 5px;
    // width: 100px;
    box-shadow: none;
    line-height: 0.6rem;
    background-color: #f6f6f6;
    color: #222222;
    border: none;
    border: 1px solid #cdcdcd;

    &:hover {
        background: #eeeeee;
    }
`;

/**
 * Simple confirmation modal
 */
export const ConfirmationModal: React.SFC<ConfirmationModalProps> = (props) => {
    const leftButtonAction = props.workOrderEditMode ? props.uiOnRequestClose : props.uiOnRequestClose
    const rightButtonAction = props.workOrderEditMode ? props.uiOnConfirm : props.uiOnConfirm
    return (
        <StyledModal
            className={props.className}
            uiActive={props.uiActive}
            uiOnRequestClose={noop}
            uiDefaultSizes={false}
            uiOnClose={props.uiOnClose}
            uiTransitionTimeout={props.uiTransitionTimeout}
            uiCloseOnOutsideClick={props.uiCloseOnOutsideClick}
            uiCloseOnEsc={props.uiCloseOnEsc}
        >
            {props.uiAcceptLabel === "Setup" &&
                props.uiHeader === "Setup Needed" && <PageDimmer uiActive />}
            <ModalHeader>{props.uiHeader}</ModalHeader>
            <ModalHeaderDivider />
            <ConfirmationContent>{props.children}</ConfirmationContent>
            <ModalActions>
                {props.uiDeclineLabel && (
                    <CancelButton
                        id="decline"
                        uiColor="secondary"
                        onClick={leftButtonAction}
                    >
                        {props.uiDeclineLabel}
                    </CancelButton>
                )}
                {props.uiAcceptLabel && (
                    <ConfirmButton
                        id="confirm"
                        uiColor="primary"
                        onClick={rightButtonAction}
                        className={props.className}
                    >
                        {props.uiAcceptLabel}
                    </ConfirmButton>
                )}
            </ModalActions>
        </StyledModal>
    );
};

ConfirmationModal.defaultProps = {
    uiHeader: "Please confirm the action",
    uiAcceptLabel: "Yes",
    uiDeclineLabel: "No",
};
