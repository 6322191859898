import { FormValidationError } from "@ramble/ramble-ui";
import React, { PureComponent } from "react";
import { connect, DispatchProp } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { ContentSection } from "../components/content/content_section";
import { PageContent } from "../components/content/page_content";
import { SortTable, SortTableRowInfo } from "../components/UI/table/sort_table";
import SupplierModal from "../containers/supplier/supplier_modal";
import { RootState, SuppliersModule } from "../redux";
import { SupplierContainer } from "./supplier";
import Header, {
    HeaderProps,
} from "../components/UI/header_with_search_select/header";
import { addSupplier, searchSuppliers } from "../api/supplier";
import { getAll as getAllSuppliers } from "../api/supplier";
import { Supplier } from "../interfaces/supplier";
// tslint:disable-next-line:no-empty-interface
interface SupplierOwnProps extends RouteComponentProps {
    // setFocusFirstField(fn: any): void;
}

// tslint:disable-next-line:no-empty-interface
interface SuppliersProps extends SupplierOwnProps {
    accountId: number;
}
interface SupplierModalProps {
    isOpenModal: boolean;
    handleCloseModal: () => void;
    accountId: number;
}

interface State {
    isAddSupplierModalOpen: boolean;
    suppliers: SortTableRowInfo[];
    nameSort: "asc" | "desc";
    includeInactive: boolean;
    scrollTop?: number;
    isLoading?: boolean;
}

export class Suppliers extends PureComponent<
    SuppliersProps & SupplierModalProps & DispatchProp,
    State
> {
    public state: State = {
        isAddSupplierModalOpen: false,
        includeInactive: false,
        suppliers: [],
        nameSort: "asc",
        isLoading: false,
    };

    public setFocusFirstFieldOnSupplierModal: any;

    public async componentDidMount(): Promise<void> {
        if(!this.props.isOpenModal) this.setState((state) => ({...state, isLoading: true}));
        await this.loadSuppliers();
        this.setState((state) => ({...state, isLoading: false}));
    }

    public render(): JSX.Element {
        /* tslint:disable:jsx-alignment */
        const { accountId } = this.props;
        return (
            <PageContent>
                <ContentSection>
                    {window.location.pathname === '/suppliers' && (
                        <Header
                            headingText="Suppliers"
                            addButtonText="Add New Supplier"
                            inactiveFilter={this.state.includeInactive}
                            onSearch={this.handleSearch}
                            onInactiveFilterChange={this.toggleInactiveFilter}
                            onAddClick={this.showAddSupplierModal}
                            onItemClick={this.selectSupplier}
                        />
                    )}
                    <Switch>
                        <Route
                            path="/suppliers/:id"
                            render={(props) => (
                                <SupplierContainer
                                    supplierId={parseInt(
                                        props.match.params.id,
                                        10
                                    )}
                                    onClose={this.closeSupplier}
                                    onSupplierChanged={this.onSupplierChanged}
                                />
                            )}
                        />
                        <Route
                            path="/suppliers"
                            render={() => (
                                <SortTable
                                    isLoading={this.state.isLoading}
                                    sort={this.state.nameSort}
                                    onSortingChanged={this.handleSortingChange}
                                    list={this.state.suppliers}
                                    onRowChoosed={this.selectSupplier}
                                    scrollTop={this.state.scrollTop}
                                />
                            )}
                        />
                    </Switch>
                    <SupplierModal
                        accountId={accountId}
                        isModalOpen={
                            this.state.isAddSupplierModalOpen ||
                            this.props.isOpenModal
                        }
                        onModalClose={this.closeAddSupplierModal}
                        resetOnClose
                        onSave={this.createSupplier}
                        setFocusFirstField={(fn) =>
                            (this.setFocusFirstFieldOnSupplierModal = fn)
                        }
                    />
                </ContentSection>
            </PageContent>
        );
    }

    private showAddSupplierModal = () => {
        this.setState(
            {
                isAddSupplierModalOpen: true,
                isLoading: false,
            },
            () => {
                if (this.setFocusFirstFieldOnSupplierModal) {
                    this.setFocusFirstFieldOnSupplierModal();
                }
            }
        );
    };

    private handleSearch: HeaderProps["onSearch"] = async (search) => {
        const { accountId } = this.props;
        const res = await searchSuppliers({
            accountId,
            text: search,
            nameSort: "asc",
            typeSort: "asc",
            includeInactive: true,
        });
        
        if (res.error) {
            return [];
        }
        const resFinal = res.filter((sup:any) => sup.active !== false);
        if (resFinal && !this.state.includeInactive) {
            return resFinal.map((s:any) => ({
                id: s.id,
                displayName: s.displayName,
                tags: s.tags,
            }));
        }
        return res.map((s:any) => ({
            id: s.id,
            displayName: s.displayName,
            tags: s.tags,
        }));
    };

    private toggleInactiveFilter = (includeInactive: boolean) => {
        this.setState(
            {
                includeInactive,
            },
            () => {
                this.loadSuppliers();
            }
        );
    };

    private closeSupplier = () => {
        const { history } = this.props;
        history.push("/suppliers");
    };

    private selectSupplier = (id: number, scrollTop?: number) => {
        this.setState({ scrollTop });
        const { history } = this.props;
        history.push(`/suppliers/${id}`);
    };

    private closeAddSupplierModal = () => {
        this.setState({
            isAddSupplierModalOpen: false,
        });
        this.props.handleCloseModal();
    };

    private onSupplierChanged = (supplier: SuppliersModule.BasicSupplier) => {
        this.loadSuppliers();
    };

    private handleSortingChange = (sort: "asc" | "desc") => {
        this.setState(
            {
                nameSort: sort,
            },
            () => this.loadSuppliers()
        );
    };

    private createSupplier = async (supplier: SuppliersModule.Supplier) => {
        const { accountId } = this.props;
        const res = await addSupplier({
            ...supplier,
            accountId})

        if (res.error) {
            if (res.msg === "User name not unique.") {
              let errors ={displayName: "Display Name already in use"} 
                throw FormValidationError.fromErrorDictionary(errors);
            } else {
                throw new Error(res.msg);
            }
        }
        this.loadSuppliers();
        this.props.handleCloseModal();
    };

    private async loadSuppliers(): Promise<void> {
        const { accountId } = this.props;
        const { nameSort, includeInactive } = this.state;
        try {
            const res: Supplier[] = await getAllSuppliers({
                accountId,
                nameSort,
                typeSort: "asc",
                includeInactive,
            });
    
            const convertToSortTableRowInfo = (
                supplier: Supplier
            ): SortTableRowInfo => {
                const rowInfo: SortTableRowInfo = {
                    id: supplier.id,
                    active: supplier.active,
                    avatarId: supplier.avatarId || "",
                    displayName: supplier.displayName,
                    companyName: supplier.companyName,
                    email: supplier.shippingAddress.email,
                    cellPhone: supplier.shippingAddress.cellPhone,
                    homePhone: supplier.shippingAddress.homePhone,
                    officePhone: supplier.shippingAddress.officePhone,
                };
                return rowInfo;
            };
    
            this.setState({
                suppliers: res.map(convertToSortTableRowInfo),
            });
        } catch (error) {
            console.error("Failed to load suppliers:", error);
        }
    }
    
}

function mapStateToProps(
    state: RootState,
    ownProps: SupplierOwnProps
): SuppliersProps {
    return {
        ...ownProps,
        accountId: state.account.id,
        // setFocusFirstField: ownProps.setFocusFirstField,
    };
}

export const SuppliersContainer = connect(mapStateToProps)(Suppliers);
