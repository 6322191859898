import styled, { css, theme } from "../../../theme";

interface IconButtonProps {
    circle?: boolean;
    uiSize?: number;
    uiColor?: "green";
    uiActiveColor?: string;
}

const IconButton = styled.button<IconButtonProps>`
    width: ${(props) => props.uiSize || 24}px;
    height: ${(props) => props.uiSize || 24}px;
    color: ${(props) =>
        props.uiColor === "green" ? theme.colors.info : theme.colors.primary};
    flex: none;
    padding: 0;
    outline: none;
    background: transparent;
    justify-content: center;
    display: flex;
    border: none;
    cursor: pointer;

    > svg {
        width: ${(props) => props.uiSize || 24}px;
        height: ${(props) => props.uiSize || 24}px;
    }

    ${(props) =>
        props.circle &&
        css`
            border-radius: 50%;
            border: ${(props.uiSize || 24) * 0.1}px solid
                ${props.uiColor === "green"
                    ? theme.colors.info
                    : theme.colors.primary};

            > svg {
                width: ${(props.uiSize || 24) * 0.7}px;
                height: ${(props.uiSize || 24) * 0.7}px;
            }

            &:hover {
                background: ${props.uiActiveColor ||
                theme.colors.lightGrayText};
            }
        `}
`;

export default IconButton;
