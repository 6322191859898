import Bind from "lodash-decorators/bind";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import EventListener from "react-event-listener";
import styled from "../theme/styled";

export interface PortalUIProps {
    /**
     * True if portal should request close when pressing esc
     */
    uiCloseOnEsc?: boolean;
    /**
     * True if portal should request close when outside clicking
     */
    uiCloseOnOutsideClick?: boolean;
    /**
     * Class name to assign to node
     */
    className?: string;
    /**
     * Before opening callback
     */
    uiOnOpening?(): void;
    /**
     * After opening callback
     * @param node Portal node
     */
    uiOnOpened?(node: HTMLElement): void;
    /**
     * Before closing callback
     * @param node Portla node
     */
    uiOnClosing?(node: HTMLElement): void;
    /**
     * Closed callback
     */
    uiOnClosed?(): void;
    /**
     * Portal request close callback
     *
     * @param event
     */
    uiOnRequestClose(event: Event): void;
}

export type PortalProps = PortalUIProps;

/**
 * Portal component
 */
class PortalComponent extends Component<PortalProps> {
    public static defaultProps: Partial<PortalProps> = {
        uiCloseOnEsc: true,
        uiCloseOnOutsideClick: true,
    };

    /**
     * Portal node
     */
    private portalNode: HTMLElement;

    public constructor(props: PortalProps) {
        super(props);
        this.portalNode = document.createElement("div");
        this.portalNode.className = props.className || "";
        if (props.uiOnOpening) {
            props.uiOnOpening();
        }
    }

    /**
     * Mount callback
     */
    public componentDidMount(): void {
        document.body.appendChild(this.portalNode);
        const { uiOnOpened } = this.props;
        if (uiOnOpened) {
            uiOnOpened(this.portalNode);
        }
    }

    /**
     * Update callback
     */
    public componentDidUpdate(prevProps: PortalProps): void {
        const { className } = this.props;
        if (className !== prevProps.className) {
            this.portalNode.className = className || "";
        }
    }

    /**
     * Unmount callback
     */
    public componentWillUnmount(): void {
        const { uiOnClosing, uiOnClosed } = this.props;
        if (uiOnClosing) {
            uiOnClosing(this.portalNode);
        }
        document.body.removeChild(this.portalNode);
        if (uiOnClosed) {
            uiOnClosed();
        }
    }

    /**
     * Render
     *
     * @returns
     */
    public render(): JSX.Element | null {
        return ReactDOM.createPortal([
            <EventListener key="keydown-listener" target="document" onKeyDown={this.onDocumentKeyDown} />,
            <EventListener key="click-listener" target="document" onMouseUp={this.onDocumentClick} />,
            <EventListener key="touch-listener" target="document" onTouchEnd={this.onDocumentClick} />,
            this.props.children,
        ], this.portalNode);
    }

    /**
     * Document click callback
     *
     * @protected
     * @param event
     * @returns
     */
    @Bind()
    protected onDocumentClick(event: MouseEvent): void {
        const { uiOnRequestClose } = this.props;
        const root = ReactDOM.findDOMNode(this);
        if (root instanceof Element && root.contains(event.target as Node) || (typeof event.button !== "undefined" && event.button !== 0)) {
            return;
        }
        uiOnRequestClose(event);
    }

    /**
     * Document key down callback
     *
     * @protected
     * @param event
     * @returns
     */
    @Bind()
    protected onDocumentKeyDown(event: KeyboardEvent): void {
        const { uiOnRequestClose } = this.props;
        if (event.keyCode === 27) { // ESC
            uiOnRequestClose(event);
        }
    }
}

const Portal = styled(PortalComponent).attrs({ suppressClassNameWarning: true })`
    /* ignore */
`;
export default Portal;
