/// <reference types="webpack-env" />
//IMPORTANT THAT ||**/// <reference types="webpack-env" />**|| STAYS AT THE TOP OF ALL THE IMPORTS
//OTHERWISE THE "module.hot" used below is referencing "module" of @types/node and there is no 'hot' in @types/node
//but "module" in @types/webpack-env has "hot"
import { Action, applyMiddleware, compose, createStore } from "redux";
import { Api, isFlowTypeAction } from "@quipa/api";
import { StorageEngine, reducer as storageReducer } from "redux-storage";
import { getDeployOptions, getEnv } from "../utils/environment";
import rootReducer, { ApplicationStore } from "./modules/index";

import { History } from "history";
import { RealtimeClient } from "../utils/realtime_client";
import { composeWithDevTools } from "redux-devtools-extension";
import createStorageMiddleware from "./middleware/storage_middleware";
import filter from "redux-storage-decorator-filter";
import notificationMiddleware from "./middleware/notification_middleware";
import optimist from "redux-optimist";
import optimisticMiddleware from "./middleware/optimistic_middleware";
import realtimeMiddleware from "./middleware/realtime_middleware";
import reduxLogger from "../utils/reduxLogger";
import thunkMiddleware from "redux-thunk";

/**
 * Action sanitizer for dev tools
 *
 * @param action
 * @returns
 */
function devToolsActionSanitizer(action: Action): any {
    if (isFlowTypeAction(action)) {
        // Prefix type with (REQUEST/SUCCESS/FAILED)
        const flowType = action.flowType.toUpperCase();
        return {
            ...action,
            type: `${action.type} (${flowType})`,
        };
    }
    return action;
}

/**
 * Configure redux store
 * @param history
 * @param realtimeClient
 * @param storageEngine
 * @param pushApi
 * @param additionalMiddleware
 * @return {*}
 */
export default function configureStore(
    _history: History,
    realtimeClient: RealtimeClient,
    storageEngine: StorageEngine
): ApplicationStore {
    // Only save these paths to storage
    storageEngine = filter(storageEngine, [
        ["user", "token"],
        ["user", "email"],
        ["user", "refreshToken"],
    ]);

    const storageMiddleware = createStorageMiddleware(
        storageEngine,
        (action) => {
            switch (action.type) {
                case Api.LOGOUT:
                case Api.SIGNUP:
                    return true;
                case Api.LOGIN: {
                    // save to store only when rememberme is picked
                    return action.meta && action.meta.remember ? true : false;
                }
                default:
                    return false;
            }
        }
    );

    const middlewares = [
        thunkMiddleware,
        notificationMiddleware as any,
        optimisticMiddleware as any,
        realtimeMiddleware(realtimeClient) as any,
        storageMiddleware,
    ];

    if (getDeployOptions().allowLogger) {
        middlewares.push(reduxLogger);
    }

    const middleware = applyMiddleware(...middlewares);

    let composeEnhancers: typeof compose = compose;

    if (
        getEnv().NODE_ENV !== "production" ||
        getDeployOptions().allowDevTools
    ) {
        composeEnhancers = composeWithDevTools({
            actionSanitizer: devToolsActionSanitizer,
        });
    }

    const store = createStore(
        optimist(storageReducer(rootReducer as any)),
        composeEnhancers(middleware) as any
    );

    if (getEnv().NODE_ENV === "development") {
        if (module.hot) {
            module.hot.accept("./", () => {
                store.replaceReducer(
                    optimist(storageReducer(rootReducer) as any)
                );
            });
        }
    }

    return store as any;
}
