import { Api } from "@quipa/api";
import { flagRoot } from "../../utils";

export type BasicClientInfo = Api.BasicClientInfo;
export type Client = Api.Client;
export type ParentClient = Api.BasicClientInfo;
export type ClientAddressDetails = Api.ClientAddressDetails;

// NOTE: NOT NEEDED

// tslint:disable-next-line:no-empty-interface
export interface State {
    // id: number;
    // firstName: string;
    // lastName: string;
    // displayName: string;
    // email: string;
    // parent: number;
    // level: number;
    // shippingStreetAddress: string;
    // shippingUnitSuite: string;
    // shippingCityTown: string;
    // shippingCountry: string;
    // shippingStateProvince: string;
    // shippingZIPCode: string;
    // cellPhone: string;
    // homePhone: string;
    // fax: string;
    // company: string;
    // officePhone: string;
    // website: string;
    // projectManager: string;
    // subClientOfClientList: number;
    // notes: string;
    // clientBillingContactInformation: {
    //     billToClient: number;
    //     billingFirstName: string;
    //     billingLastName: string;
    //     billingStreetAddress: string;
    //     billingUnitSuite: string;
    //     billingCityTown: string;
    //     billingCountry: string;
    //     billingStateProvince: string;
    //     billingZIPCode: string;
    //     billingCellPhone: string;
    //     billingHomePhone: string;
    //     billingFax: string;
    //     billingCompany: string;
    //     billingOfficePhone: string;
    //     billingEmail: string;
    //     billingWebsite: string;
    //     billingSalesTax: string;
    // };
    // clientAdditionalContact: {
    //     additionalContactRelationToClient: string;
    //     additionalContactFirstName: string;
    //     additionalContactLastName: string;
    //     additionalContactBillingStreetAddress: string;
    //     additionalContactBillingUnitSuite: string;
    //     additionalContactBillingCityTown: string;
    //     additionalContactBillingCountry: string;
    //     additionalContactBillingStateProvince: string;
    //     additionalContactBillingZIPCode: string;
    //     additionalContactCellPhone: string;
    //     additionalContactHomePhone: string;
    //     additionalContactFax: string;
    //     additionalContactCompany: string;
    //     additionalContactOfficePhone: string;
    //     additionalContactEmail: string;
    //     additionalContactWebsite: string;
    // };
    // list: [];
}

export function defaults(): State {
    const st: State = {
        // id: 0,
        // firstName: "",
        // lastName: "",
        // displayName: "",
        // email: "",
        // parent: 0,
        // level: 0,
        // shippingStreetAddress: "",
        // shippingUnitSuite: "",
        // shippingCityTown: "",
        // shippingCountry: "",
        // shippingStateProvince: "",
        // shippingZIPCode: "",
        // cellPhone: "",
        // homePhone: "",
        // fax: "",
        // company: "",
        // officePhone: "",
        // website: "",
        // projectManager: "",
        // subClientOfClientList: 0,
        // notes: "",
        // clientBillingContactInformation: {
        //     billToClient: 0,
        //     billingFirstName: "",
        //     billingLastName: "",
        //     billingStreetAddress: "",
        //     billingUnitSuite: "",
        //     billingCityTown: "",
        //     billingCountry: "",
        //     billingStateProvince: "",
        //     billingZIPCode: "",
        //     billingCellPhone: "",
        //     billingHomePhone: "",
        //     billingFax: "",
        //     billingCompany: "",
        //     billingOfficePhone: "",
        //     billingEmail: "",
        //     billingWebsite: "",
        //     billingSalesTax: "",
        // },
        // clientAdditionalContact: {
        //     additionalContactRelationToClient: "",
        //     additionalContactFirstName: "",
        //     additionalContactLastName: "",
        //     additionalContactBillingStreetAddress: "",
        //     additionalContactBillingUnitSuite: "",
        //     additionalContactBillingCityTown: "",
        //     additionalContactBillingCountry: "",
        //     additionalContactBillingStateProvince: "",
        //     additionalContactBillingZIPCode: "",
        //     additionalContactCellPhone: "",
        //     additionalContactHomePhone: "",
        //     additionalContactFax: "",
        //     additionalContactCompany: "",
        //     additionalContactOfficePhone: "",
        //     additionalContactEmail: "",
        //     additionalContactWebsite: "",
        // },
        // list: [],
    };
    return flagRoot(st);
}
