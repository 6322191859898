import React, { RefObject } from "react";

/**
 * Pure HOC component, similar to pure()
 * @template TProps
 * @param Component
 * @returns Component
 */
export function pure<TProps extends object>(Component: React.SFC<TProps>): React.SFC<TProps> {
    class PureWrapper extends React.PureComponent<object & { forwardedRef: RefObject<any> }, any> {
        public static displayName = `pure(${Component.displayName || (Component as any).name || "Component"})`;

        public render(): JSX.Element {
            const { forwardedRef, ...other } = this.props;
            // assigning ref to
            const Comp = Component as React.SFC<any>;
            return (
                <Comp {...other} ref={forwardedRef} />
            );
        }
    }

    return PureWrapper as any;
}
