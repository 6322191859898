import Color from "color";
import { FlattenInterpolation, InterpolationValue, ThemeProvider } from "styled-components";
import { css } from "./styled";

export const colors = {
    black: "#1B1C1D",
    fullBlack: "#000",
    white: "#fff",
    darkWhite: "#f8f8f8",
    red: "#DB2828",
    orange: "#F2711C",
    olive: "#B5CC18",
    green: "#00897B",
    teal: "#00B5AD",
    blue: "#2185D0",
    violet: "#6435C9",
    purple: "#924c9f",
    pink: "#E03997",
    brown: "#A5673F",
    grey: "#767676",
    yellow: "#FFFF00",
};

export function createTransitions() {
    const transitions = {
        easing: {
            // This is the most common easing curve.
            easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
            // Objects enter the screen at full velocity from off-screen and
            // slowly decelerate to a resting point.
            easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
            // Objects leave the screen at full velocity. They do not decelerate when off-screen.
            easeIn: "cubic-bezier(0.4, 0, 1, 1)",
            // The sharp curve is used by objects that may return to the screen at any time.
            sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
        },
        durations: {
            shortest: 150,
            shorter: 200,
            short: 250,
            // most basic recommended timing
            standard: 300,
            // this is to be used in complex animations
            complex: 375,
            // recommended when something is entering screen
            enteringScreen: 225,
            // recommended when something is leaving screen
            leavingScreen: 195,
        },
    };
    return {
        ...transitions,
        /**
         * Create transition style
         *
         * @param props Transition property(ies)
         * @param options Transition options
         */
        create: (props: string | string[] = ["all"], options: { duration?: number, easing?: string, delay?: number } = {}): string => {
            const {
                duration: durationOption = transitions.durations.standard,
                easing: easingOption = transitions.easing.easeInOut,
                delay = 0,
            } = options;
            return (Array.isArray(props) ? props : [props]).map(prop =>
                `${prop} ${durationOption}ms ${easingOption} ${delay}ms`,
            ).join(",");
        },
    };
}

export const transitions = createTransitions();

export interface BreakpointValues {
    xs: number;
    sm: number;
    tb: number;
    md: number;
    lg: number;
    xl: number;
}

/**
 * Create media query with min-width = given key
 */
function breakpointUp(key: keyof BreakpointValues | number, media?: false): string;
function breakpointUp(key: keyof BreakpointValues | number, media: string | InterpolationValue[] | FlattenInterpolation<any>): FlattenInterpolation<any>;
function breakpointUp(key: keyof BreakpointValues | number, media?: false | string | InterpolationValue[] | FlattenInterpolation<any>): string | FlattenInterpolation<any> {
    let val = 0;
    if (key !== "xs") {
        val = breakpoints.values[key] || key;
    }
    const str = `(min-width: ${val}px)`;
    if (!media) {
        return str;
    }
    return css`
        @media ${str} {
            ${media};
        }
    `;
}
/**
 * Create media query with max-width = given key
 */
function breakpointDown(key: keyof BreakpointValues | number, media?: false): string;
function breakpointDown(key: keyof BreakpointValues | number, media: string | InterpolationValue[] | FlattenInterpolation<any>): FlattenInterpolation<any>;
function breakpointDown(key: keyof BreakpointValues | number, media?: false | string | InterpolationValue[] | FlattenInterpolation<any>): string | FlattenInterpolation<any> {
    const val: number = breakpoints.values[key] || key;
    const str = `(max-width: ${val - 0.01}px)`;
    if (!media) {
        return str;
    }
    return css`
        @media ${str} {
            ${media};
        }
    `;
}
/**
 * Create media query with min-width = start and max-width = end
 */
function breakpointBetween(start: keyof BreakpointValues | number, end: keyof BreakpointValues | number, media?: false): string;
function breakpointBetween(
    start: keyof BreakpointValues | number,
    end: keyof BreakpointValues | number,
    media: string | InterpolationValue[] | FlattenInterpolation<any>,
): FlattenInterpolation<any>;
function breakpointBetween(
    start: keyof BreakpointValues | number,
    end: keyof BreakpointValues | number,
    media?: false | string | InterpolationValue[] | FlattenInterpolation<any>,
): string | FlattenInterpolation<any> {
    const startVal: number = breakpoints.values[start] || start;
    const endVal: number = breakpoints.values[end] || end;
    const str = `(min-width: ${startVal}px) and (max-width: ${endVal - 0.01}px)`;

    if (!media) {
        return str;
    }
    return css`
        @media ${str} {
            ${media};
        }
    `;
}
/**
 * Create media query with min-width = key and max-width = key
 */
// function breakpointOnly(key: BreakpointKey, media: false): string;
// function breakpointOnly(key: BreakpointKey, interpolations: InterpolationValue[]): string;
// function breakpointOnly(key: BreakpointKey, media?: true): InterpolationFunction<any>;
// function breakpointOnly(key: BreakpointKey, media: boolean | undefined | InterpolationValue[] = true): InterpolationFunction<any> | string {
//     if (key === "xl") {
//         return breakpoints.up("xl", media);
//     }
//     return breakpoints.between(key, key, media);
// }

export function createBreakpoints() {
    const breakpoints = {
        values: {
            xs: 360,
            sm: 600,
            tb: 768,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
        /**
         * Create media query with min-width = given key
         */
        up: breakpointUp,
        /**
         * Create media query with max-width = given key
         */
        down: breakpointDown,
        /**
         * Create media query with min-width = start and max-width = end
         */
        between: breakpointBetween,
        /**
         * Create media query with min-width = key and max-width = key
         */
        // only: breakpointOnly
    };
    return breakpoints;
}

export const breakpoints = createBreakpoints();

export interface ThemeInterface {
    colors: {
        primary: string;
        secondary: string;
        danger: string;
        primaryText: string;
        secondaryText: string;
        divider: string;
        darkText: string;
        whiteText: string;
        background: string;
        invertedBackground: string;
    };
    zIndex: {
        dimmer: number;
        defaultPortal: number;
        pageDimmer: number;
        portalModal: number;
        modal: number;
        popover: number;
    };
    dimmer: {
        black: string;
        white: string;
        blurAmount: string;
        grayAmount: number;
    };
    /**
     * Ligten color if the color is dark tone, otherwise darken it
     * @param c Color string
     * @param ratio Ratio to darken/lighten
     * @param darkRatio Ratio to determinate if color is dark or white. Default 0.5
     */
    lightenOrDarken(c: string, ratio: number, darkRatio?: number): string;
    /**
     * Return color from given color string
     * @param color Color string
     * @returns Color
     */
    getColor(color: keyof this["colors"]): string;
    // tslint:disable-next-line:unified-signatures
    getColor(color: string): string;
    getColor(color?: string): string | undefined;
    /**
     * Return contrast color text for given color
     *
     * @param c Color string
     * @returns Contrast color instance for text
     */
    getContrastTextColor(c?: keyof this["colors"] | string): string;
    /**
     * Darken or lighten given color depending on current luminosity
     * @param color Original color
     * @param amount Amount to darken/lighten
     */
    // darkenOrLighten(color: string, amount: number = 0.3): string;
}

/**
 * Color value
 */
export type ColorValue = string | keyof ThemeInterface["colors"];

/**
 * Return theme
 */
export function getTheme(): ThemeInterface {
    const basicTheme: ThemeInterface = {
        colors: {
            primary: colors.purple,
            secondary: colors.teal,
            danger: colors.red,
            primaryText: colors.black,
            secondaryText: colors.grey,
            divider: Color(colors.grey).darken(0.7).alpha(0.2).string(),
            darkText: colors.black,
            whiteText: colors.darkWhite,
            background: colors.white,
            invertedBackground: colors.black,
            // sideElementsBackground: colors.black,
            // sideElementsText: colors.darkWhite,
        },
        zIndex: {
            dimmer: 500,
            defaultPortal: 999,
            pageDimmer: 1000,
            portalModal: 1001,
            modal: 1002,
            popover: 2000,
        },
        dimmer: {
            black: Color(colors.fullBlack).alpha(0.85).string(),
            white: Color(colors.white).alpha(0.85).string(),
            blurAmount: "5px",
            grayAmount: 0.7,
        },
        lightenOrDarken(c, ratio, darkRatio = 0.5) {
            const color = Color(c);
            const rgb = color.rgb().array();
            // 0 - 255
            const yiq = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
            const isDark = yiq < darkRatio * 255;
            return isDark ? color.lighten(ratio).string() : color.darken(ratio).string();
        },
        getColor(color: any) {
            if (!color) {
                return undefined;
            }
            if (typeof color === "function") {
                return color(this.colors);
            }
            if (this.colors[color]) {
                return this.colors[color];
            }
            return color;
        },
        getContrastTextColor(c) {
            if (!c) {
                return this.colors.primaryText;
            }
            try {
                const color = Color(this.getColor(c));
                return color.isDark() ? this.colors.whiteText : this.colors.darkText;
            } catch {
                return this.colors.primaryText;
            }
        },
        // darkenOrLighten(c, amount) {
        //     try {
        //         const color = Color(c);
        //         return color.luminosity() >= 0.6 ? color.darken(amount).string() : color.lighten(amount).string();
        //     } catch {
        //         return c;
        //     }
        // },
    };

    return basicTheme;
}

export const theme = getTheme();

/**
 * Theme provider
 */
export {
    ThemeProvider,
};
