import { Api, Req } from "@quipa/api";

export const getNextWorkOrderNum = (accountId: number) =>
    Api.createAction(Api.GET_NEXT_WORKORDER_NUM, { accountId }, { authenticated: true });

export const createWorkOrder = (workOrder: Api.AddWorkOrderAction[Req]) => Api.createAction(Api.ADD_WORKORDER, workOrder, { authenticated: true });

export const editWorkOrder = (workOrder: Api.EditWorkOrderAction[Req]) => Api.createAction(Api.EDIT_WORKORDER, workOrder, { authenticated: true });

export const searchWorkOrders = (accountId: number) => Api.createAction(Api.SEARCH_WORKORDERS, { accountId }, { authenticated: true });

export const getWorkOrderPdfContent = (draperyOrderId: number) => Api.createAction(Api.GET_WORKORDER_PDF_CONTENT, { draperyOrderId }, { authenticated: true });

export const getWorkOrderRecommendation = (draperyOrderId: number, type: "panel" | "yardage") => Api.createAction(Api.GET_WORKORDER_RECOMMENDATIONS, { draperyOrderId, type }, { authenticated: true });

export const saveWorkOrderOverrides = (draperyOrderId: number, accountId: number, overrides: Pick<Api.SaveWorkOrderOverridesAction["Request"]["payload"], "panelJson" | "yardages">, draperyOrderPriceId?: number) =>
    Api.createAction(Api.SAVE_WORKORDER_OVERRIDES, { draperyOrderId, accountId, panelJson: overrides.panelJson, yardages: overrides.yardages, draperyOrderPriceId });

export const uploadWorkOrderPdf = (data: Blob, accountId: number) => Api.createAction(Api.UPLOAD_WORK_ORDER_PDF, { data, accountId });
