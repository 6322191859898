import { ClassAttributes, HTMLAttributes } from "react";
import styled from "../theme/styled";
import ModalCloseIcon from "./modal_close_icon";

// tslint:disable-next-line:no-empty-interface
export interface ModalHeaderUIProps {}

export type ModalHeaderProps = HTMLAttributes<HTMLElement> & ModalHeaderUIProps;

/**
 * Modal header
 */
const ModalHeader = styled.section<ModalHeaderProps & ClassAttributes<HTMLElement>>`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    border-top-left-radius: 0.28rem;
    border-top-right-radius: 0.28rem;
    color: #353535;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    > svg:first-child:not(${/* sc-selector */ ModalCloseIcon}) {
        margin-right: 0.25em;
        font-size: 1.5em;
    }
`;

export default ModalHeader;