import moment from "moment";
import React, { FC, memo } from "react";
import styled from "../../theme";
import { BillingItem } from "./billing_grid_row";
import {
    Button,
} from "@ramble/ramble-ui";
const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ChangePlanButton = styled(Button)`
    width: 150px;
    box-shadow: none;
    border: 1px solid #c6c6c6;
    border-radius: 0.5em;
    display: block;
    margin-top:10px;
`;

interface BillingTableProps {
    /**
     * Items
     */
    items: BillingItem[];
    /**
     * Loading flag
     */
    loading?: boolean;
    onOpenModal(): void;
}

export const BillingTable: FC<BillingTableProps> = memo(props => (
    <Container>
        {props.items.slice(0,6).map((item, id) => (
            <div key={id}>
                <a href={item.receiptUrl} target="_blank">
                    {moment(item.date).format("DD MMMM YYYY")}
                </a>
            </div>
        ))}
        {props.items.length > 6 && <ChangePlanButton uiColor="primary" onClick={() => props.onOpenModal()}>View more</ChangePlanButton>}
    </Container>
));


