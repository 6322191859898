import { Header, PageDimmer } from "@ramble/ramble-ui";
import Color from "color";
import React, { memo } from "react";
import { FaExclamation, FaExclamationTriangle, FaInfo } from "react-icons/fa";
import styled, { theme } from "../../theme";

const ErrorIcon = styled(FaExclamationTriangle) `
    color: ${Color(theme.colors.danger).lighten(0.2).string()};
`;

const WarningIcon = styled(FaExclamation) `
    color: ${Color(theme.colors.warning).lighten(0.2).string()};
`;

const InfoIcon = styled(FaInfo) `
    color: ${Color(theme.colors.info).lighten(0.2).string()};
`;

const PrimaryMessage = styled(Header) `
    align-items: flex-end;
    font-size: 1.71rem;

    & > svg:first-child {
        font-size: 1.5em;
        margin-right: 0.75rem;
    }
`;

const SecondaryMessage = styled(Header) `
    align-items: flex-end;
    font-size: 1.03rem;
    font-weight: 400;
    margin-top: 0;
`;

/**
 * Application error props
 */
interface ApplicationErrorProps {
    /**
     * Message to as error
     */
    message: string;

    /**
     * Emphasis. Error uses red warning sign icon. Warning uses yellow warning icon
     */
    emphasis?: "warning" | "error" | "info";
    /**
     * Custom icon
     */
    icon?: React.ReactElement<any>;

}

/**
 * Application generic error
 */
const ApplicationErrorE: React.SFC<ApplicationErrorProps> = props => {
    const { message, emphasis, icon } = props;
    const iconElement = icon ||
        emphasis === "error"
        ? <ErrorIcon />
        : emphasis === "warning"
            ? <WarningIcon />
            : <InfoIcon />;
    return (
        <PageDimmer
            uiActive
        >
            <PrimaryMessage>
                {iconElement}
                {emphasis !== "info" && "Uh oh..."}
            </PrimaryMessage>
            <SecondaryMessage>
                {message}
            </SecondaryMessage>
        </PageDimmer>
    );
};

ApplicationErrorE.defaultProps = {
    emphasis: "error",
};

export const ApplicationError = memo(ApplicationErrorE);
