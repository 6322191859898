import Color from "color";
import React, { forwardRef, HTMLAttributes } from "react";
import ListItem, { ListItemProps, ListItemUIProps } from "../list/list_item";
import styled, { css } from "../theme/styled";

export interface MenuItemUIProps extends ListItemUIProps {
    /**
     * Inverted item
     */
    uiInverted?: boolean;
}

export type MenuItemProps = HTMLAttributes<HTMLDivElement> & MenuItemUIProps;

const MenuItemComponent = forwardRef<HTMLDivElement, MenuItemProps>((props, ref) => {
    const { uiInverted, ...other } = props;
    return (
        <ListItem as="div" {...other as ListItemProps} uiSelectable ref={ref as any} />
    );
});

const MenuItem = styled(MenuItemComponent) `
    ${({ uiInverted, theme }) => uiInverted && css`
        background-color: ${theme.colors.invertedBackground};
        color: ${theme.getContrastTextColor(theme.colors.invertedBackground)};

        &:hover {
            background-color: ${Color(theme.colors.invertedBackground).lighten(0.6).alpha(0.85).string()};
        }
    `};
    ${({ theme, uiActive }) => uiActive && css`
        background-color: ${Color(theme.colors.invertedBackground).lighten(0.6).alpha(0.85).string()};
    `};
`;

MenuItem.defaultProps = {
    uiInverted: false,
    uiSelectable: true,
};

/**
 * Menu item is the subset of ListItem but always selectable
 */
export default MenuItem;
