import History from "history";
import createBrowserHistory from "history/createBrowserHistory";
import createMemoryHistory from "history/createMemoryHistory";
import qhistory from "qhistory";
import { parse, stringify } from "qs";
import { createLoader, Loader, StorageEngine } from "redux-storage";
import createEngine from "redux-storage-engine-localstorage";
import Config from "./config";

declare module "history" {
    export interface Location<S = LocationState> {
        query?: any;
    }
    export interface LocationDescriptorObject<S = LocationState> {
        query?: any;
    }
}

/**
 * Create history for given type
 */
export function createHistory(type: "memory" | "browser"): History.History {
    return type === "browser"
        ? qhistory(createBrowserHistory({ basename: "" }), stringify, parse)
        : qhistory(createMemoryHistory(), stringify, parse);
}

/**
 * Create storage engine
 */
export function createStorage(): { engine: StorageEngine, loader: Loader<any> } {
    const engine = createEngine("quipa-store");
    const loader = createLoader(engine);
    return {
        engine,
        loader,
    };
}

export {
    Config,
};
