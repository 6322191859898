import { Link } from "react-router-dom";
import styled, { theme } from "../../theme";

interface ActionLinkProps {
    /**
     * Link color when hovering
     * @default "primary"
     */
    uiHoverColor?: string;
}

export const ActionLink = styled.div<ActionLinkProps>`
    display: inline;
    font-weight: 400;
    opacity: 0.8;
    transition: opacity 0.1s ease, color 0.1s ease;
    color: currentColor;
    cursor: pointer;

    &:hover {
        color: ${props => theme.getColor(props.uiHoverColor || "primary")};
        opacity: 1;
    }
`;
export const RouteLink = ActionLink.withComponent(Link);
