import { ClassAttributes, HTMLAttributes } from "react";
import styled from "../theme/styled";

// tslint:disable-next-line:no-empty-interface
export interface GridNoItemRowUIProps { }

export type GridNoItemRowProps = HTMLAttributes<HTMLDivElement> & GridNoItemRowUIProps;

/**
 * Table row for no items
 */
const GridNoItemRow = styled.div<GridNoItemRowProps & ClassAttributes<HTMLDivElement>> `
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    padding: 15px;
    background: repeating-linear-gradient(-45deg, transparent, transparent 5px, whitesmoke 5px, whitesmoke 6px);
`;

export default GridNoItemRow;
