import React, { forwardRef, SVGAttributes } from "react";

/**
 * Check Square icon
 */
const CheckSquareIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>(
    (props, ref) => {
        return (
            <>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        rx="6"
                        fill="#3B97B1"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.25 9.25L8.75 11.75L13.75 6.75L15.25 8.25L8.75 14.75L5.25 11.25L6.25 10.25L6.25 9.25Z"
                        fill="white"
                    />
                </svg>
            </>
        );
    }
);
CheckSquareIcon.defaultProps = {
    height: "20px",
    width: "20px",
};

export default CheckSquareIcon;
