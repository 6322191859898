import Bind from "lodash-decorators/bind";
import React, { Component, MouseEvent as ReactMouseEvent, TouchEvent as ReactTouchEvent } from "react";
import { DraperyModule } from "../../../../redux";
import { isTouchDevice } from "../../../../utils/is_touch_device";
import { Rect, rgbaToString, toRect } from "./board";
import { ResizePosition } from "./selected-shape";

const pointerStyles = {
    stroke: "none",
    fill: "#000000",
};

interface DrawingShapeProps {
    tool: "line" | "pen" | "arrow" | "text" | "selection" | "rect" | "circle";
    index: number;
    shape: DraperyModule.Shape;
    status?: "selected" | "drawing";
    onShapeMouseEvent?(e: ReactMouseEvent | ReactTouchEvent, shape: DraperyModule.Shape, index: number): void;
    onShapeMouseEventForResize?(e: ReactMouseEvent | ReactTouchEvent, position: ResizePosition): void;
}

class DrawShape extends Component<DrawingShapeProps> {
    public render() {
        if (this.props.shape.type === "pen") {
            return this.drawingPen(this.props.shape, this.props.status === "selected");
        } else if (this.props.shape.type === "line" || this.props.shape.type === "rect" || this.props.shape.type === "circle" || this.props.shape.type === "text") {
            const point1 = this.props.shape.points[0];
            const point2 = this.props.shape.points[1];
            if (point1 && point2) {
                const rect = toRect(point1, point2);
                if (rect.width > 0 && rect.height > 0) {
                    if (this.props.shape.type === "line") {
                        return this.drawingLine(this.props.index, this.props.shape, this.props.status === "selected", point1, point2);
                    } else if (this.props.shape.type === "rect" || this.props.shape.type === "circle") {
                        return this.drawingRectCircle(this.props.shape, this.props.status === "selected", rect);
                    }
                }
                if (this.props.shape.type === "text") {
                    return this.drawingText(this.props.shape, rect, this.props.status);
                }
            }
        }
        return <></>;
    }

    public drawingPen = (shape: DraperyModule.Shape, isSelected: boolean) => {
        const pathData = "M " + shape.points
            .map(p => {
                return `${p.x} ${p.y}`;
            })
            .join(" L ");
        const basicStylePath = {
            fill: "none",
            stroke: rgbaToString(shape.strokeColor),
            strokeWidth: shape.lineWeight,
        };
        const selectedStylePath = {
            outline: "1px",
            outlineStyle: "dashed",
            outlineColor: "white",
        };
        return (
            <path
                style={isSelected ? { ...basicStylePath, ...selectedStylePath} : basicStylePath}
                d={pathData}
                cursor={this.props.tool === "selection" ? "move" : undefined}
                onMouseDown={!isTouchDevice() ? this.onMouseEvent : undefined}
                onMouseMove={!isTouchDevice() ? this.onMouseEvent : undefined}
                onMouseUp={!isTouchDevice() ? this.onMouseEvent : undefined}
                onDoubleClick={!isTouchDevice() ? this.onMouseEvent : undefined}
                onTouchStart={this.onMouseEvent}
                onTouchMove={this.onMouseEvent}
                onTouchEnd={this.onMouseEvent}
            />
        );
    }

    public drawingLine = (index: number, shape: DraperyModule.Shape, isSelected: boolean, point1: DraperyModule.Point, point2: DraperyModule.Point) => {
        const stylesLine = {
            fill: rgbaToString(shape.filledColor),
            stroke: rgbaToString(shape.strokeColor),
            strokeWidth: shape.lineWeight,
            strokeDasharray: shape.lineType === "dashed" ? shape.lineWeight * 2 : "none",
            markerEnd: `url(#arrow${index})`,
        };
        return (
            <>
                {shape.arrow &&
                    <defs>
                        <marker
                            id={"arrow" + index}
                            markerWidth="15"
                            markerHeight="15"
                            refX="0"
                            refY="5"
                            orient="auto"
                            markerUnits="userSpaceOnUse"
                        >
                            <path
                                d="M0,0 L0,10 L15,5 z"
                                fill={rgbaToString(shape.strokeColor)}
                            />
                        </marker>
                    </defs>
                }
                <line
                    style={stylesLine}
                    x1={point1.x}
                    y1={point1.y}
                    x2={point2.x}
                    y2={point2.y}
                    cursor={this.props.tool === "selection" ? "move" : undefined}
                    onMouseDown={!isTouchDevice() ? this.onMouseEvent : undefined}
                    onMouseMove={!isTouchDevice() ? this.onMouseEvent : undefined}
                    onMouseUp={!isTouchDevice() ? this.onMouseEvent : undefined}
                    onTouchStart={this.onMouseEvent}
                    onTouchMove={this.onMouseEvent}
                    onTouchEnd={this.onMouseEvent}
                />
                {isSelected &&
                    <>
                        <circle
                            cx={point1.x}
                            cy={point1.y}
                            r="4"
                            style={pointerStyles}
                            cursor="move"
                            onMouseDown={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.First) : undefined}
                            onMouseMove={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.First) : undefined}
                            onMouseUp={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.First) : undefined}
                            onTouchStart={e => this.onMouseEventForResize(e, ResizePosition.First)}
                            onTouchMove={e => this.onMouseEventForResize(e, ResizePosition.First)}
                            onTouchEnd={e => this.onMouseEventForResize(e, ResizePosition.First)}
                        />
                        <circle
                            cx={point2.x}
                            cy={point2.y}
                            r="4"
                            style={pointerStyles}
                            cursor="move"
                            onMouseDown={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.Second) : undefined}
                            onMouseMove={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.Second) : undefined}
                            onMouseUp={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.Second) : undefined}
                            onTouchStart={e => this.onMouseEventForResize(e, ResizePosition.Second)}
                            onTouchMove={e => this.onMouseEventForResize(e, ResizePosition.Second)}
                            onTouchEnd={e => this.onMouseEventForResize(e, ResizePosition.Second)}
                        />
                    </>
                }
            </>
        );
    }

    public drawingRectCircle = (shape: DraperyModule.Shape, isSelected: boolean, rect: Rect) => {
        const styles = {
            fill: rgbaToString(shape.filledColor),
            stroke: rgbaToString(shape.strokeColor),
            strokeWidth: shape.lineWeight,
            strokeDasharray: shape.lineType === "dashed" ? shape.lineWeight * 2 : "none",
        };
        return (
            <>
                {shape.type === "rect" ?
                    <rect
                        style={styles}
                        x={rect.x}
                        y={rect.y}
                        width={rect.width}
                        height={rect.height}
                        cursor={this.props.tool === "selection" ? "move" : undefined}
                        onMouseDown={!isTouchDevice() ? this.onMouseEvent : undefined}
                        onMouseMove={!isTouchDevice() ? this.onMouseEvent : undefined}
                        onMouseUp={!isTouchDevice() ? this.onMouseEvent : undefined}
                        onTouchStart={this.onMouseEvent}
                        onTouchMove={this.onMouseEvent}
                        onTouchEnd={this.onMouseEvent}
                    />
                    :
                    <ellipse
                        style={styles}
                        cx={rect.x + rect.width / 2}
                        cy={rect.y + rect.height / 2}
                        rx={rect.width / 2}
                        ry={rect.height / 2}
                        cursor={this.props.tool === "selection" ? "move" : undefined}
                        onMouseDown={!isTouchDevice() ? this.onMouseEvent : undefined}
                        onMouseMove={!isTouchDevice() ? this.onMouseEvent : undefined}
                        onMouseUp={!isTouchDevice() ? this.onMouseEvent : undefined}
                        onTouchStart={this.onMouseEvent}
                        onTouchMove={this.onMouseEvent}
                        onTouchEnd={this.onMouseEvent}
                    />
                }
                {isSelected &&
                    <>
                        <circle
                            cx={rect.x}
                            cy={rect.y}
                            r="4"
                            style={pointerStyles}
                            cursor="move"
                            onMouseDown={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.TopLeft) : undefined}
                            onMouseMove={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.TopLeft) : undefined}
                            onMouseUp={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.TopLeft) : undefined}
                            onTouchStart={e => this.onMouseEventForResize(e, ResizePosition.TopLeft)}
                            onTouchMove={e => this.onMouseEventForResize(e, ResizePosition.TopLeft)}
                            onTouchEnd={e => this.onMouseEventForResize(e, ResizePosition.TopLeft)}
                        />
                        <circle
                            cx={rect.x + rect.width}
                            cy={rect.y}
                            r="4"
                            style={pointerStyles}
                            cursor="move"
                            onMouseDown={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.TopRight) : undefined}
                            onMouseMove={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.TopRight) : undefined}
                            onMouseUp={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.TopRight) : undefined}
                            onTouchStart={e => this.onMouseEventForResize(e, ResizePosition.TopRight)}
                            onTouchMove={e => this.onMouseEventForResize(e, ResizePosition.TopRight)}
                            onTouchEnd={e => this.onMouseEventForResize(e, ResizePosition.TopRight)}
                        />
                        <circle
                            cx={rect.x}
                            cy={rect.y + rect.height}
                            r="4"
                            style={pointerStyles}
                            cursor="move"
                            onMouseDown={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.BottomLeft) : undefined}
                            onMouseMove={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.BottomLeft) : undefined}
                            onMouseUp={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.BottomLeft) : undefined}
                            onTouchStart={e => this.onMouseEventForResize(e, ResizePosition.BottomLeft)}
                            onTouchMove={e => this.onMouseEventForResize(e, ResizePosition.BottomLeft)}
                            onTouchEnd={e => this.onMouseEventForResize(e, ResizePosition.BottomLeft)}
                        />
                        <circle
                            cx={rect.x + rect.width}
                            cy={rect.y + rect.height}
                            r="4"
                            style={pointerStyles}
                            cursor="move"
                            onMouseDown={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.BottomRight) : undefined}
                            onMouseMove={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.BottomRight) : undefined}
                            onMouseUp={!isTouchDevice() ? e => this.onMouseEventForResize(e, ResizePosition.BottomRight) : undefined}
                            onTouchStart={e => this.onMouseEventForResize(e, ResizePosition.BottomRight)}
                            onTouchMove={e => this.onMouseEventForResize(e, ResizePosition.BottomRight)}
                            onTouchEnd={e => this.onMouseEventForResize(e, ResizePosition.BottomRight)}
                        />
                    </>
                }
            </>
        );
    }

    public drawingText = (shape: DraperyModule.Shape, rect: Rect, status?: "selected" | "drawing") => {
        if (status === "drawing") {
            if (rect.width > 0 && rect.height > 0) {
                const styleTextRect = {
                    fill: "#0008",
                };
                return (
                    <rect
                        style={styleTextRect}
                        x={rect.x}
                        y={rect.y}
                        width={rect.width}
                        height={rect.height}
                    />
                );
            }
            return <></>;
        }

        const basicStyleText = {
            fill: rgbaToString(shape.strokeColor),
            fontSize: shape.fontSize + "px",
        };
        const selectedStyleText = {
            outline: "1px",
            outlineStyle: "dashed",
            outlineColor: "white",
        };
        const styleTSpan = {
            fontSize: shape.fontSize + "px",
        };
        return (
            <text
                style={status === "selected" ? {...basicStyleText, ...selectedStyleText} : basicStyleText}
                x={rect.x}
                y={rect.y}
                width={rect.width}
                height={rect.height}
                cursor={this.props.tool === "selection" ? "move" : undefined}
                onMouseDown={!isTouchDevice() ? this.onMouseEvent : undefined}
                onMouseMove={!isTouchDevice() ? this.onMouseEvent : undefined}
                onMouseUp={!isTouchDevice() ? this.onMouseEvent : undefined}
                onTouchStart={this.onMouseEvent}
                onTouchMove={this.onMouseEvent}
                onTouchEnd={this.onMouseEvent}
            >
                {shape.text.split("\n").map((line, index) => (
                    <tspan
                        style={styleTSpan}
                        key={index}
                        x={rect.x}
                        dy="1.2em"
                    >
                        {line}
                    </tspan>
                ))}
            </text>
        );
    }

    @Bind()
    private onMouseEvent(e: ReactMouseEvent | ReactTouchEvent) {
        if (this.props.onShapeMouseEvent) {
            this.props.onShapeMouseEvent(e, this.props.shape, this.props.index);
        }
    }

    @Bind()
    private onMouseEventForResize(e: ReactMouseEvent | ReactTouchEvent, position: ResizePosition) {
        if (this.props.onShapeMouseEventForResize) {
            this.props.onShapeMouseEventForResize(e, position);
        }
    }
}

export default DrawShape;
