import Bind from "lodash-decorators/bind";
import { PureComponent } from "react";

export interface ClickOutsideProps {
    /**
     * Root node ID. If click will be given outside of node or node childs,
     * then it will be treated as outside click.
     */
    rootNodeId: string;
    /**
     * Ignore these id(s) if clicking outside but inside these id(s)
     */
    ignoreIds?: string | string[];
    /**
     * Click outside callback
     */
    onOutsideClick(event: Event): void;
}

/**
 * Simple click outside handler
 */
export default class ClickOutside extends PureComponent<ClickOutsideProps> {
    public componentDidMount(): void {
        // Check if we're in not dom environment
        if (typeof document === "undefined" || !document.createElement) {
            return;
        }
        document.addEventListener("mousedown", this.onDocumentClick, true);
        document.addEventListener("touchstart", this.onDocumentClick, true);
    }

    public componentWillUnmount(): void {
        // Check if we're in not dom environment
        if (typeof document === "undefined" || !document.createElement) {
            return;
        }
        document.removeEventListener("mousedown", this.onDocumentClick, true);
        document.removeEventListener("touchstart", this.onDocumentClick, true);
    }

    public render(): JSX.Element | null {
        return null;
    }

    /**
     * Handle document clicks
     */
    @Bind()
    protected onDocumentClick(event: Event): void {
        const { ignoreIds } = this.props;
        if (typeof document === "undefined" || !document.createElement) {
            return;
        }
        if (!(event.target instanceof Element)) {
            return;
        }
        const ignore = [this.props.rootNodeId];
        if (ignoreIds) {
            ignore.push(...Array.isArray(ignoreIds) ? ignoreIds : [ignoreIds]);
        }
        for (const id of ignore) {
            if (event.target.closest("#" + id)) {
                return;
            }
        }
        this.props.onOutsideClick(event);
    }
}
