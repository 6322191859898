import React, { useCallback, useState } from 'react'
import { SortTableRowInfo } from "../table/sort_table";
import SortTableRow from "../table/sort_table_row";

type AccordionProps = {
  items: SortTableRowInfo[];
  handleRowChoosed(id: number): void;
}

const Accordion: React.FC<AccordionProps> = ({ items, handleRowChoosed }) => {
  const [activeIndexes, setActiveIndexes] = useState<any[]>([])

  const collectSubClients = (item: any) => {
    let idsToRemove = [item.id]

    if (item && item.subList && item.subList.length > 0) {
      item.subList.forEach((sub: any) => {
        idsToRemove = idsToRemove.concat(collectSubClients(sub))
      })
    }

    return idsToRemove
  }

  const handleToggle = useCallback((item: any) => {
    setActiveIndexes(prev => {
      let newIndexesArray = [...prev]
      const ids = newIndexesArray.map(i => i.id)

      if (ids.includes(item.id)) {
        const idsToRemove = collectSubClients(item)
        const filteredArray = newIndexesArray.filter(i => !idsToRemove.includes(i.id))
        newIndexesArray = filteredArray
      } else {
        newIndexesArray.push(item)
      }

      return newIndexesArray
    })
  }, [])

  const renderItem = useCallback((
    data: SortTableRowInfo,
    index: number,
    level: number = 0,
  ): JSX.Element => {

    const indexes = activeIndexes && activeIndexes.length > 0 && activeIndexes.map(i => i.id) || []
    const isActiveIndex = indexes && indexes.length > 0 && indexes.includes(data.id)

    return (
      <>
        <SortTableRow
          key={data.id}
          data={data}
          level={level}
          onChoosed={handleRowChoosed}
          handleToggle={handleToggle}
          isActiveIndex={isActiveIndex}
        />

        {isActiveIndex && (
          data.subList && data.subList.length > 0 &&
          data.subList.map((sub) => {
            if (sub.billToParent === false) {
              return renderItem(
                {
                  ...sub,
                },
                index,
                level + 1,
              );
            }
            return renderItem(
              {
                ...sub,
                email: items[index] ? items[index].email : '',
                cellPhone: items[index] ? items[index].cellPhone : '',
              },
              index,
              level + 1,
            );
          })
        )}
      </>
    )
  }, [activeIndexes, handleToggle])


  return (
    <div>
      {items && items.length > 0 && items.map((item, index) => renderItem(item, index))}
    </div>
  )
}

export default Accordion
