import Color from "color";
import { Bind } from "lodash-decorators/bind";
import React, { ChangeEvent, createRef, InputHTMLAttributes, KeyboardEvent, PureComponent, RefObject } from "react";
import { FaCircle } from "react-icons/fa";
import { ColorValue } from "../theme";
import styled, { css } from "../theme/styled";
import { forwardRef, WithForwardedRef } from "../utils/ref";
import { cleanUiProps } from "../utils/withStyledProps";

export interface ToggleUIProps {
    /**
     * Color to use when checked, defaults to primaryColor
     */
    uiColor?: ColorValue;
    /**
     * Disabled checkbox
     */
    uiDisabled?: boolean;
    /**
     * Toggle size
     * @default 1rem
     */
    uiSize?: string;
    /**
     * Width
     */
    uiWidth?: string;
}

export type ToggleProps = InputHTMLAttributes<HTMLInputElement> & ToggleUIProps;

const ToggleIcon = styled.span<{ uiChecked: boolean; uiColor?: ColorValue }>`
    position: relative;
    cursor: pointer;
    user-select: none;
    font-size: 1em;
    padding: 0;
    flex: 0 0 auto;
    min-width: 1.5em;
    min-height: 1em;
    width: 100%;
    height: 100%;
    border-radius: 500rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    will-change: background;
    transition: background 0.2s;
    background: ${props => props.uiChecked ? props.theme.getColor(props.uiColor) : Color(props.theme.colors.background).darken(0.1).string()};

    > .icon-helper {
        flex: 0 1 auto;
        width: ${props => props.uiChecked ? 100 : 0}%;
        transition: width 0.2s linear;
    }

    > .icon {
        flex: 0 0 auto;
        fill: ${props => props.theme.colors.background};
        filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.25));
        font-size: inherit;
        height: 100%;
        width: auto;
    }

    &:hover {
        background: ${props => props.uiChecked ? props.theme.getColor(props.uiColor) : Color(props.theme.colors.background).darken(0.23).string()};
    }
`;

interface ToggleState {
    /**
     * Checked state
     */
    checked?: boolean;
}

class ToggleComponent extends PureComponent<ToggleProps, ToggleState> {
    private rootRef: RefObject<HTMLLabelElement> = createRef();

    public constructor(props: ToggleProps) {
        super(props);

        // set to state only if non-controlled, i.e. checked is undefined
        this.state = {
            checked: props.checked != null ? undefined : props.defaultChecked || false,
        };
    }

    public render(): JSX.Element {
        const {
            uiColor, className, uiDisabled, children, uiSize, uiWidth, forwardedRef, checked, defaultChecked, tabIndex, ...other
        } = this.props as WithForwardedRef<ToggleProps>;
        cleanUiProps(other);
        return (
            <label
                className={className}
                tabIndex={tabIndex}
                onKeyDown={this.onKeyDown}
            >
                {/* YES!!! You see this. Actually if you have a better idea - let me know (asvetl) */}
                <span className="baseline-helper" />
                <input
                    {...other}
                    tabIndex={tabIndex}
                    ref={forwardedRef}
                    type="checkbox"
                    checked={checked != null ? checked : this.state.checked}
                    className="input"
                    onChange={this.onChange}
                />
                <ToggleIcon uiChecked={checked || this.state.checked || false} uiColor={uiColor}>
                    <div className="icon-helper" />
                    <FaCircle className="icon" />
                </ToggleIcon>
            </label>
        );
    }

    /**
     * Handle checkbox change
     */
    @Bind()
    private onChange(e: ChangeEvent<HTMLInputElement>): void {
        this.handleChange(e);
    }

    @Bind()
    private onKeyDown(e: KeyboardEvent<HTMLElement>): void {
        // space
        if (e.keyCode !== 32 || !this.rootRef.current) {
            return;
        }
        const input = this.rootRef.current.children[1] as HTMLInputElement;
        if (!input || !(input instanceof HTMLInputElement)) {
            return;
        }
        this.handleChange({
            ...e,
            currentTarget: input,
            target: input,
        });
    }

    private handleChange(e: ChangeEvent<HTMLInputElement>): void {
        const { onChange, checked } = this.props;
        if (onChange) {
            onChange(e);
        }
        if (checked == null) {
            this.setState({
                checked: !this.state.checked,
            });
        }
    }
}

const ForwardRefToggle = forwardRef<HTMLInputElement, ToggleProps>(ToggleComponent);

const Toggle = styled(ForwardRefToggle)`
    font-style: normal;
    font-size: ${props => props.uiSize};
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    backface-visibility: hidden;
    outline: none;
    cursor: pointer;
    min-width: 1.5em;
    min-height: 1em;

    .baseline-helper {
        opacity: 0;
        width: 0;
        visibility: hidden;

        &::after {
            content: ".";
        }
    }

    .input {
        opacity: 0;
        cursor: pointer;
        width: 0;
        height: 0;
        visibility: hidden;
        outline: 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    ${props => props.uiWidth && css`
        width: ${props.uiWidth};
    `};
    ${props => props.uiDisabled && css`
        pointer-events: none;
        opacity: 0.45;
    `};
`;
Toggle.defaultProps = {
    uiSize: "1rem",
    uiColor: "primary",
};

export default Toggle;
