import React, { Component, KeyboardEvent, MouseEvent as ReactMouseEvent, TouchEvent as ReactTouchEvent } from "react";
import { DraperyModule } from "../../../../redux";
import styled from "../../../../theme";
import { viewBoxStr } from "./board";
import DrawShape from "./shape";

const Cover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const Svg = styled.svg`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`;

const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
`;

interface Props {
    tool: "line" | "pen" | "arrow" | "text" | "selection" | "rect" | "circle";
    newShape: DraperyModule.Shape;
    newShapeIndex: number;
    onFinished(shape?: DraperyModule.Shape, textInputed?: boolean): void;
    relativeCoordinatesForEvent(event: ReactMouseEvent | ReactTouchEvent): DraperyModule.Point | undefined;
}

interface State {
    shape: DraperyModule.Shape;
}

class NewShape extends Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            shape: {...props.newShape},
        };
    }

    public componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress, false);
    }

    public componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress, false);
    }

    public render() {
        return (
            <Container>
                <Cover/>
                <Svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={viewBoxStr}
                >
                    <DrawShape
                        tool={this.props.tool}
                        shape={this.state.shape}
                        index={this.props.newShapeIndex}
                        status="drawing"
                    />
                </Svg>
            </Container>
        );
    }

    public handleMouseEvent = (event: ReactMouseEvent | ReactTouchEvent): void => {
        const point = this.props.relativeCoordinatesForEvent(event);
        if (point) {
            if (event.type === "mousemove" || event.type === "touchmove") {
                this.updateShape(point);
            } else if (event.type === "mouseup" || event.type === "touchend") {
                this.updateShape(point);
                if (this.state.shape.type !== "pen" && this.state.shape.type !== "text" && this.state.shape.points[0].x === point.x && this.state.shape.points[0].y === point.y) {
                    this.props.onFinished();
                } else {
                    this.props.onFinished(this.state.shape, false);
                }
            }
        }
    }

    private handleKeyPress = (e: Event) => {
        const event: KeyboardEvent<Element> = e as unknown as KeyboardEvent<Element>;
        if (event.key === "Escape") {
            this.props.onFinished();
        }
    }

    private updateShape = (point: DraperyModule.Point): void => {
        const { shape } = this.state;
        if (shape.type === "pen") {
            shape.points.push({...point});
        } else {
            if (shape.points.length === 1) {
                shape.points.push({...point});
            } else if (shape.points.length === 2) {
                shape.points[1] = {...point};
            }
        }
        this.setState({
            shape,
        });
    }
}

export default NewShape;
