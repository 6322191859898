import { ActionDescriptor } from "../../common";

export const SEND_FEEDBACK = "feedback/SEND";

export type SendFeedbackAction = ActionDescriptor<typeof SEND_FEEDBACK, {
    /**
     * account id
     */
    accountId: number,
    /**
     * content
     */
    content: string;
}, {}>;

export interface FeedbackActionsMap {
    [SEND_FEEDBACK]: SendFeedbackAction;
}
