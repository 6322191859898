import React, { FC } from "react";
import styled, { theme } from "../../theme";

import Color from "color";
import GenericTooltip from "../content/GenericTooltip";
import LockIcon from "../../assets/icons/icons8-lock.svg";

interface LockedInputProps {
    id: string;
    text: string;
    toolTipText: string;
}

const Container = styled.div`
    border: solid 1px
        ${({ theme }) => Color(theme.colors.divider).alpha(0.15).string()};
    border-radius: 0.28rem;
    padding: 0.45em 0.5em;
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const LockedInput: FC<LockedInputProps> = ({ id, text, toolTipText }) => {
    return (
        <Container id={id}>
            <span>{text}</span>
            <div>
                <GenericTooltip
                    text={
                        <div className="pt-4 px-2">
                            <p>{toolTipText}</p>
                        </div>
                    }
                    position={"top right"}
                >
                    <LockIcon className="h-4" />
                </GenericTooltip>
            </div>
        </Container>
    );
};

export default LockedInput;
