/**
 * Simple cancellation token
 *
 * @export
 * @class CancelToken
 */
export class CancelToken {
    private canceled = false;

    /**
     * Return cancel status
     *
     * @readonly
     */
    public get Cancelled(): boolean {
        return this.canceled;
    }

    /**
     * Cancel token
     */
    public cancel(): void {
        this.canceled = true;
    }
}
