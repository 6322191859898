import React, { MouseEvent, ReactChild, SFC, SVGAttributes } from "react";
import { FaTimes } from "react-icons/fa";
import FaExclamationCircle from "../../assets/icons/error.svg";
import FaQuestionCircle from "../../assets/icons/icons8-help.svg";
import styled, { css } from "../../theme";
import { WithSimpleInfoPopup, WithSimpleInfoPopupProps } from "./with_simple_info_popup";

interface HelpIconUIProps extends SVGAttributes<SVGElement> {
    uiSize?: string;
    errorIcon?: boolean;
}

const HelpIconE: SFC<HelpIconUIProps> = ({ uiSize, errorIcon, ...other }) => errorIcon ? <FaExclamationCircle {...other}/> : < FaQuestionCircle {...other}/>;
/**
 * Help icon
 */

const HelpIcon = styled(HelpIconE)`
    color: #f4d9e0;
    border-radius: ${props => props.uiSize};
    transition: color 0.2s ease;
    cursor: pointer;
    height: 1.4em;
    margin: 0 6px;
    flex: 0 0 auto;
    margin-top:-2px;

    &:hover {
        color: #f4d9e0;
    }
`;
HelpIcon.defaultProps = {
    uiSize: "1em",
};

const CloseButton = styled.div`
    color: black;
    position: absolute;
    right: 10px;
`;

const StyledPopup = styled(WithSimpleInfoPopup)`
    background-color: rgba(59, 151, 177, 1);
    border-radius: 12px;
    color: #111;
    border: solid #8a5c63 1px;
    white-space: pre-wrap;
    max-width: 250px;
    display: flex;
    flex-direction: column;

    &::before {
        background-color: #3B97B1;
        ${props => (props.popupPosition  === "top left" || props.popupPosition === "top right" || props.popupPosition === "top center") && css`
            bottom: -0.4em;
            border-bottom: solid #8a5c63 1px;
            border-right: solid #8a5c63 1px;
            margin-left: 8px;
        `};
        ${props => (props.popupPosition  === "bottom right" || props.popupPosition === "bottom center" || props.popupPosition === "bottom left") && css`
            top: -0.4em;
            border-top: solid #8a5c63 1px;
            border-left: solid #8a5c63 1px;
            margin-right: 8px;
        `};
        ${props => props.popupPosition  === "left center" && css`
            right: -0.4em;
            border-right: solid #8a5c63 1px;
            border-top: solid #8a5c63 1px;
            margin-bottom: 8px;
        `};
        ${props => props.popupPosition  === "right center" && css`
            left: -0.4em;
            border-left: solid #8a5c63 1px;
            border-bottom: solid #8a5c63 1px;
            margin-top: 8px;
        `};
    }
`;

export interface HelpPopupProps {
    /**
     * Help text
     */
    text: ReactChild;
    /**
     * Help text
     */
    className?: string;
    /**
     * Id of help icon
     */
    id?: string;
    /**
     * Touch device behavior
     * @default "click"
     */
    touchDeviceBehavior?: WithSimpleInfoPopupProps["touchDeviceBehavior"];
    /**
     * Popup position
     */
    position?: WithSimpleInfoPopupProps["popupPosition"];
    /**
     * Icon size
     * @default 1em
     */
    size?: string;
    /**
     * Show error icon
     * @default undefined
     */
    errorIcon?: boolean;
    /**
     * Close popup on click
     * @default false
     */
    closeOnClick?: boolean;
    /**
     * Icon click handler
     * @param e
     */
    onClick?(e: MouseEvent<SVGElement>): void;
}

/**
 * Simple help popup
 */
export const HelpPopup: React.SFC<HelpPopupProps> = ({ text, className, touchDeviceBehavior, position, size, onClick, id, closeOnClick, errorIcon }) => (
    <StyledPopup
        popupPosition={position}
        popupContent={text}
        touchDeviceBehavior={touchDeviceBehavior}
        closeOnClick={closeOnClick}
        className={className}
        popupOn="click"
        closeButton={
            <CloseButton><FaTimes /></CloseButton>
        }
    >
        <HelpIcon
            id={id}
            uiSize={size}
            errorIcon={errorIcon}
            onClick={onClick}
        />
    </StyledPopup>
);

HelpPopup.defaultProps = {
    touchDeviceBehavior: "click",
    closeOnClick: false,
};

export default HelpPopup;