import {
    GridTableBody as GridTableBodyE,
    GridTableCell as GridTableCellE,
    GridTable as GridTableE,
    GridTableRow as GridTableRowE,
} from "@ramble/ramble-ui";
import React, { PureComponent } from "react";
import styled from "../../../theme";

import Sort from "../sort";
import SortTableRow from "./sort_table_row";
import Accordion from "../accordion/Accordion";
import { FadeLoader } from "react-spinners";

const Container = styled.div`
    /* ignore */

    
`;

const GridTable = styled(GridTableE)`
    border: 0;
`;

const GridTableBody = styled(GridTableBodyE)`
    height: calc(100vh - 240px);
    overflow: auto;

`;

interface GridTableRowProps {
    scrollWidth: number;
}

const GridTableRow = styled(GridTableRowE) <GridTableRowProps>`
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "displayName email phone";
    grid-column-gap: 0;
    font-weight: 600;
    color: #4a4a4a;
    text-align: left;
    background-color: #ecf6f9;
    padding: 0;
    padding-right: ${(props) => props.scrollWidth}px;
   

    // &:hover {
    //     background-color: #ecf6f9;
    // }
`;

const GridTableCell = styled(GridTableCellE)`
    padding: 20px 10px;
    border-left: 1px solid #e2e2e2;
    color: #222222;
    font-weight: 500;
    font-size: 14px;

    &:first-child {
        border-left: none;
    }
`;

export interface SortTableRowInfo {
    id: number;
    active: boolean;
    avatarId?: string;
    displayName: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    email?: string;
    cellPhone?: string;
    homePhone?: string;
    officePhone?: string;
    subList?: SortTableRowInfo[];
    billToParent?: boolean;
    parent?: any;
}

export interface SortTableProps {
    sort: "asc" | "desc";
    list: SortTableRowInfo[];
    scrollTop?: number;
    onSortingChanged(sort: "asc" | "desc"): void;
    onRowChoosed(id: number, scrollTop?: number): void;
    isLoading?: boolean;
}

interface SortTableState {
    scrollWidth: number;
}

export class SortTable extends PureComponent<SortTableProps, SortTableState> {
    public state = {
        scrollWidth: 0,
    };

    public componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        const e = document.getElementById("grid-table-body");
        if (e && this.props.scrollTop) {
            e.scrollTop = this.props.scrollTop;
        }
        this.handleResize();
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    public componentDidUpdate() {
        this.handleResize();
    }

    public render(): JSX.Element {
        const { list, sort } = this.props;
        return (
            <Container>
                <FadeLoader
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(50%,-50%)",
                        zIndex: 9999,
                        display: !this.props.isLoading ? "none" : "block",
                    }}
                    color="#3B97B1"
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                <GridTable>
                    <GridTableRow scrollWidth={this.state.scrollWidth}>
                        <GridTableCell
                            uiGridArea="displayName"
                            onClick={this.changeSorting}
                        >
                            <Sort direction={sort} />
                        </GridTableCell>
                        <GridTableCell uiGridArea="email">EMAIL</GridTableCell>
                        <GridTableCell uiGridArea="phone">PHONE</GridTableCell>
                    </GridTableRow>
                    <GridTableBody id="grid-table-body">
                        <Accordion
                            handleRowChoosed={this.handleRowChoosed}
                            items={list} />
                    </GridTableBody>
                </GridTable>
            </Container>
        );
    }

    public renderRow(
        data: SortTableRowInfo,
        index: number,
        level: number = 0
    ): JSX.Element {
        return (
            <>
                <SortTableRow
                    key={data.id}
                    data={data}
                    level={level}
                    onChoosed={this.handleRowChoosed}
                />
                {data.subList &&
                    data.subList.map((sub) => {
                        if (sub.billToParent === false) {
                            return this.renderRow(
                                {
                                    ...sub,
                                },
                                index,
                                level + 1
                            );
                        }
                        return this.renderRow(
                            {
                                ...sub,
                                email: this.props.list[index].email,
                                cellPhone: this.props.list[index].cellPhone,
                            },
                            index,
                            level + 1
                        );
                    })}
            </>
        );
    }

    private changeSorting = () => {
        const { onSortingChanged, sort } = this.props;
        onSortingChanged(sort === "asc" ? "desc" : "asc");
    };

    private handleResize = () => {
        const e = document.getElementById("grid-table-body");
        const e1: HTMLElement | null = document.getElementsByClassName(
            "grid-table-row"
        )[0] as any;
        let scrollWidth = 0;
        if (e && e1) {
            scrollWidth = e.offsetWidth - e1.offsetWidth;
        }
        this.setState({
            scrollWidth,
        });
    };

    private handleRowChoosed = (id: number) => {
        const e = document.getElementById("grid-table-body");
        const scrollTop = e ? e.scrollTop : undefined;
        this.props.onRowChoosed(id, scrollTop);
    };
}
