import Bind from "lodash-decorators/bind";
import React, { MouseEvent as ReactMouseEvent, PureComponent } from "react";
import styled, { css } from "../../../../theme";
import { MenuButton, MenuButtonProps } from "./toolbar";

import LineIconE from "../../../../assets/icons/drawing/line_weight_tool_line.svg";

const Popover = styled.div`
    position: absolute;
    z-index: 2;
`;

const Cover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const Menu = styled.div<MenuButtonProps>`
    width: ${props => props.uiWidth ? `${props.uiWidth}px` : `6vh`};
    bottom: ${props => props.uiWidth ? `${props.uiWidth * 1.13}px` : `6.78vh`};
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    background-color: white;
`;

const MenuItem = styled.div<MenuButtonProps>`
    width: 100%;
    height: ${props => props.uiWidth ? `${props.uiWidth * 0.6}px` : `3.6vh`};

    &:not(:last-child) {
        border-bottom: 1px solid #b3b3b3;
    }
`;

const LineIcon = styled(LineIconE)<LineWeightProps>`
    stroke-width: ${props => props.uiwidth};
    ${props => props.uidashline && css`
        stroke-dasharray: 5 5 5 5 5 5;
    `}
    width: 100%;
    height: 100%;
`;

interface LineWeightProps {
    uiwidth: number;
    uidashline?: string;
}

interface Props {
    className?: string;
    uiWidth: number;
    onClicked(width: number, lineType: "solid" | "dashed"): void;
}

interface State {
    displayMenu: boolean;
}

class LineWeightMenuButton extends PureComponent<Props, State> {
    public state: State = {
        displayMenu: false,
    };

    public render() {
        return (
            <MenuButton uiWidth={this.props.uiWidth} className={this.props.className}>
                <div>
                    <div title="" onClick={this.toggleMenu}>
                        {this.props.children}
                    </div>
                    {this.state.displayMenu &&
                        <Popover>
                            <Cover onClick={this.closeMenu}/>
                            <Menu uiWidth={this.props.uiWidth}>
                                <MenuItem uiWidth={this.props.uiWidth} id="solid_0.5" title="" onClick={this.handleClickItem}><LineIcon uiwidth={0.5}/></MenuItem>
                                <MenuItem uiWidth={this.props.uiWidth} id="solid_1" title="" onClick={this.handleClickItem}><LineIcon uiwidth={1}/></MenuItem>
                                <MenuItem uiWidth={this.props.uiWidth} id="solid_3" title="" onClick={this.handleClickItem}><LineIcon uiwidth={3}/></MenuItem>
                                <MenuItem uiWidth={this.props.uiWidth} id="solid_5" title="" onClick={this.handleClickItem}><LineIcon uiwidth={5}/></MenuItem>
                                <MenuItem uiWidth={this.props.uiWidth} id="dashed_0.5" title="" onClick={this.handleClickItem}><LineIcon uiwidth={0.5} uidashline="true"/></MenuItem>
                                <MenuItem uiWidth={this.props.uiWidth} id="dashed_1" title="" onClick={this.handleClickItem}><LineIcon uiwidth={1} uidashline="true"/></MenuItem>
                                <MenuItem uiWidth={this.props.uiWidth} id="dashed_3" title="" onClick={this.handleClickItem}><LineIcon uiwidth={3} uidashline="true"/></MenuItem>
                                <MenuItem uiWidth={this.props.uiWidth} id="dashed_5" title="" onClick={this.handleClickItem}><LineIcon uiwidth={5} uidashline="true"/></MenuItem>
                            </Menu>
                        </Popover>
                    }
                </div>
            </MenuButton>
        );
    }

    @Bind()
    private toggleMenu() {
        this.setState({ displayMenu: !this.state.displayMenu });
    }

    @Bind()
    private closeMenu() {
      this.setState({ displayMenu: false });
    }

    @Bind()
    private handleClickItem(e: ReactMouseEvent) {
        const type = e.currentTarget.id.split("_").shift();
        const width = Number(e.currentTarget.id.split("_").pop());

        if (type === "solid" || type === "dashed") {
            this.props.onClicked(width, type);
        }
        this.closeMenu();
    }

}

export default LineWeightMenuButton;
