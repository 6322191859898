import React, { forwardRef } from "react";
import { CSSTransition } from "react-transition-group";
import { css } from "../theme/styled";
import Popover, { PopoverUIProps } from "./popover";

export interface TransitionPopoverUIProps extends PopoverUIProps {
    /**
     * Active
     */
    uiActive?: boolean;
    /**
     * Transition class names suffix
     * @default "transition"
     */
    uiTransitionSuffix?: string;
    /**
     * Transition timeouts
     * @default 200
     */
    uiTransitionTimeouts?: number | { enter?: number; exit?: number };
}

export type TransitionPopoverProps = TransitionPopoverUIProps;

const TransitionPopover = forwardRef<Popover, TransitionPopoverProps>(({ uiActive, uiTransitionSuffix, uiTransitionTimeouts, ...other }, ref) => (
    <CSSTransition
        in={uiActive}
        appear
        enter
        exit
        mountOnEnter
        unmountOnExit
        classNames={uiTransitionSuffix!}
        timeout={uiTransitionTimeouts!}
    >
        <Popover
            {...other}
            ref={ref}
        />
    </CSSTransition>
));
TransitionPopover.defaultProps = {
    uiTransitionSuffix: "transition",
    uiTransitionTimeouts: 200,
};

export const defaultPopoverTransition: any = css`
    &.transition-enter,
    &.transition-appear {
        opacity: 0.1;
        transform: scale(0.2);
    }

    &.transition-enter-active,
    &.transition-appear-active {
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.1s ease, transform 0.2s ease;
    }

    &.transition-exit {
        opacity: 1;
        transform: scale(1);
    }

    &.transition-exit-active {
        opacity: 0.1;
        transform: scale(0.4);
        transition: opacity 0.2s ease, transform 0.2s ease;
    }
`;

export default TransitionPopover;
