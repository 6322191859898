import { Bind } from "lodash-decorators/bind";
import React, { PureComponent } from "react";
import { findDOMNode } from "react-dom";
import EventListener from "react-event-listener";
import ResizeObserver from "resize-observer-polyfill";
import Portal from "../portal/portal";
import { getElementOffsetDimensions } from "../utils/dimensions";

export interface BaseModalUIProps {
    /**
     * Modal position on the screen:
     *  center - try to center content on the screen
     *  top - place modal at the top of the screen
     *
     * @default "center"
     */
    uiPosition?: "center" | "top";
    /**
     * Offset from the left position
     * @default 0
     */
    uiOffsetLeft?: number;
    /**
     * Offset from the top position
     * @default 0
     */
    uiOffsetTop?: number;
    /**
     * Single child is required
     */
    // children?: React.ReactChild;
    /**
     * Class name for portal node
     */
    className?: string;
    /**
     * Close modal when pressing Esc
     * @default true
     */
    uiCloseOnEsc?: boolean;
    /**
     * Close modal on outside click
     * @default true
     */
    uiCloseOnOutsideClick?: boolean;
    /**
     * On modal request close by pressing esc/clicking outside
     */
    uiOnRequestClose(event: Event | React.SyntheticEvent<any>): void;
    /**
     * Callback for modal opening
     */
    uiOnOpen?(): void;
    /**
     * Callback for modal closing
     */
    uiOnClose?(): void;
}

export type BaseModalProps = BaseModalUIProps;

class BaseModal extends PureComponent<BaseModalProps> {
    public static defaultProps: Partial<BaseModalProps> = {
        uiOffsetLeft: 0,
        uiOffsetTop: 0,
        uiPosition: "center",
        uiCloseOnEsc: true,
        uiCloseOnOutsideClick: true,
    };

    /**
     * Resize observer
     */
    private resizeObserver: ResizeObserver = new ResizeObserver(() => this.updatePosition());

    /**
     * Modal body
     */
    private modalBodyElement: HTMLElement | undefined = undefined;

    public componentDidMount(): void {
        this.modalBodyElement = findDOMNode(this) as HTMLElement;
        if (this.modalBodyElement) {
            this.resizeObserver.observe(this.modalBodyElement);
        }
        this.updatePosition(true);
    }

    public componentDidUpdate(prevProps: BaseModalProps): void {
        // Update modal top and left when position changes
        const { uiPosition } = this.props;
        if (prevProps.uiPosition !== uiPosition) {
            this.updatePosition();
        }
    }

    public componentWillUnmount(): void {
        if (this.modalBodyElement) {
            this.resizeObserver.unobserve(this.modalBodyElement);
            this.modalBodyElement = undefined;
        }
    }

    public render(): JSX.Element | null {
        const { uiCloseOnEsc, uiCloseOnOutsideClick, uiOnClose, uiOnOpen, className, children, uiOnRequestClose } = this.props;

        return (
            <Portal
                uiCloseOnEsc={uiCloseOnEsc}
                uiCloseOnOutsideClick={uiCloseOnOutsideClick}
                uiOnOpened={uiOnOpen}
                uiOnClosed={uiOnClose}
                uiOnRequestClose={uiOnRequestClose}
                className={className}
            >
                {children}
                <EventListener key="resize-listener" target="window" onResize={this.onResize} />
            </Portal>
        );
    }

    /**
     * Update modal placement
     * @param initial
     */
    @Bind()
    public updatePosition(initial: boolean = false): void {
        if (!this.modalBodyElement) {
            return;
        }
        if (initial) {
            this.modalBodyElement.style.position = "absolute";
            this.modalBodyElement.style.visibility = "hidden";
            this.modalBodyElement.style.top = "0px";
            this.modalBodyElement.style.left = "0px";
        }
        const { height: modalHeight, width: modalWidth } = getElementOffsetDimensions(this.modalBodyElement);
        const style = getComputedStyle(this.modalBodyElement);
        const marginTop = parseInt(style.marginTop!, 10) || 0;
        const marginBottom = parseInt(style.marginBottom!, 10) || 0;
        const marginLeft = parseInt(style.marginLeft!, 10) || 0;
        const marginRight = parseInt(style.marginRight!, 10) || 0;

        const { uiPosition, uiOffsetLeft, uiOffsetTop } = this.props;
        let top = 0;
        // calculate left position
        let left = (window.innerWidth / 2) - ((modalWidth + marginLeft + marginRight) / 2) + uiOffsetLeft!;
        if (left <= 0) {
            left = uiOffsetLeft!;
        }
        if (uiPosition === "center") {
            top = (window.innerHeight / 2) - ((modalHeight + marginTop + marginBottom) / 2) + uiOffsetTop!;
        }
        if (top <= 0) {
            top = uiOffsetTop!;
        }

        this.modalBodyElement.style.visibility = "visible";
        this.modalBodyElement.style.position = "absolute";
        this.modalBodyElement.style.top = `${top}px`;
        this.modalBodyElement.style.left = `${left}px`;
    }

    /**
     * Window resize handler
     */
    @Bind()
    protected onResize() {
        this.updatePosition();
    }
}

export default BaseModal;
