import React, { FC, memo } from "react";
import { ClientModule } from "../../redux";
import styled from "../../theme";
import CopyToClipboard from "../UI/copy-to-clipboard";
import {
    Container,
    HContainer,
    Separator,
    VContainer,
} from "../UI/more_detail/container";
import DetailValue from "../UI/more_detail/detail_value";
import Section, { SubSection } from "../UI/more_detail/section";
import ClientHoverBox from "./client_hover_box";
import { ValidationErrorInfo } from "../calculator/interfaces-common";

const NotesContent = styled.div`
    width: 100%;
    white-space: pre-line;
    word-break: break-word;
`;

const WrapperInfo = styled.div`
    width: 100%;
    position: relative;
`;

const RelationName = styled.span`
    color: #41a1dc;
    margin-right: 3px;
    align-self: center;
`;

const Space = styled.div<SpaceProps>`
    width: ${(props) => props.uiWidth}px;
`;

export const validateClientBillingType = (
    tabIndex: number,
    billingType: string | undefined
): ValidationErrorInfo[] => {
    let errors: ValidationErrorInfo[] = [];
    if (!billingType) {
        errors.push({
            tabIndex,
            message: `Please specify the billing type for client`,
            name: ["billingType"],
        });
    }

    return errors;
};
interface SpaceProps {
    uiWidth: number;
}

interface ClientMoreDetailsProps {
    className?: string;
    /**
     * Client info to display
     */
    client: ClientModule.Client;
    /**
     * Client click callback
     * @param id
     */
    /**
     * Sub client click callback
     * @param id
     */
    onSubClientClick(id: number): void;
    createSubClient(): void
}

const ClientMoreDetails: FC<ClientMoreDetailsProps> = memo((props) => {
    const { client } = props;

    const renderSubClient = (
        c: ClientModule.Client,
        level = 0
    ): JSX.Element => {
        return (
            <>
                <HContainer>
                    <Space uiWidth={level * 15} />
                    <ClientHoverBox
                        key={c.id}
                        client={c}
                        type="child"
                        onClick={props.onSubClientClick}
                    />
                </HContainer>
                {c.subClients &&
                    c.subClients.map((sub: ClientModule.Client) =>
                        renderSubClient(sub, level + 1)
                    )}
            </>
        );
    };

    const renderFirstVContainer = () => {
        return (
            <>
                <HContainer>
                    <Section title="CLIENT INFORMATION" rightBorder>
                        {(client.firstName || client.lastName) && (
                            <DetailValue
                                label="Contact"
                                value={`${client.firstName || ""} ${client.lastName || ""
                                    }`}
                            />
                        )}
                        {client.cellPhone && (
                            <DetailValue
                                label="Mobile"
                                value={client.cellPhone}
                            />
                        )}
                        {client.homePhone && (
                            <DetailValue
                                label="Home"
                                value={client.homePhone}
                            />
                        )}
                        {client.email && (
                            <DetailValue
                                label="Email"
                                value={
                                    <CopyToClipboard
                                        title={client.email}
                                        copy={client.email}
                                        tooltipMessage="Email Copied"
                                    />
                                }
                            />
                        )}
                        {(client.firstName ||
                            client.lastName ||
                            client.cellPhone ||
                            client.homePhone ||
                            client.email) && <Separator />}
                        {client.company && (
                            <DetailValue
                                label="Company"
                                value={client.company}
                            />
                        )}
                        {client.officePhone && (
                            <DetailValue
                                label="Office Phone"
                                value={client.officePhone}
                            />
                        )}
                        {client.fax && (
                            <DetailValue label="Fax" value={client.fax} />
                        )}
                        {client.website && (
                            <DetailValue
                                label="Website"
                                value={client.website}
                                clickable
                                href={`http://${client.website}`}
                                target="_blank"
                            />
                        )}
                        <Separator />
                    </Section>
                    <Section title="BILLING INFORMATION" rightBorder>
                        {client.billingDetails &&
                            (client.billingDetails.firstName ||
                                client.billingDetails.lastName) && (
                                <DetailValue
                                    label="Contact"
                                    value={`${client.billingDetails.firstName || ""
                                        } ${client.billingDetails.lastName || ""}`}
                                />
                            )}
                        {client.billingDetails &&
                            client.billingDetails.cellPhone && (
                                <DetailValue
                                    label="Mobile"
                                    value={client.billingDetails.cellPhone}
                                />
                            )}
                        {client.billingDetails &&
                            client.billingDetails.homePhone && (
                                <DetailValue
                                    label="Home"
                                    value={client.billingDetails.homePhone}
                                />
                            )}
                        {client.billingDetails &&
                            client.billingDetails.email && (
                                <DetailValue
                                    label="Email"
                                    value={
                                        <CopyToClipboard
                                            title={client.billingDetails.email}
                                            copy={client.billingDetails.email}
                                            tooltipMessage="Email Copied"
                                        />
                                    }
                                />
                            )}
                        {client.billingDetails &&
                            (client.billingDetails.firstName ||
                                client.billingDetails.lastName ||
                                client.billingDetails.cellPhone ||
                                client.billingDetails.homePhone ||
                                client.billingDetails.email) && <Separator />}
                        {client.billingDetails &&
                            client.billingDetails.company && (
                                <DetailValue
                                    label="Company"
                                    value={client.billingDetails.company}
                                />
                            )}
                        {client.billingDetails &&
                            client.billingDetails.officePhone && (
                                <DetailValue
                                    label="Office Phone"
                                    value={client.billingDetails.officePhone}
                                />
                            )}
                        {client.billingDetails && client.billingDetails.fax && (
                            <DetailValue
                                label="Fax"
                                value={client.billingDetails.fax}
                            />
                        )}
                        {client.billingDetails &&
                            client.billingDetails.website && (
                                <DetailValue
                                    label="Website"
                                    value={client.billingDetails.website}
                                    clickable
                                    href={`http://${client.billingDetails.website}`}
                                    target="_blank"
                                />
                            )}
                        <Separator />
                        {client && client.billingDetails && (
                            <DetailValue
                                label="Billing Type"
                                value={client.billingDetails.billingType || ""}
                            />
                        )}
                        {client && client.billingSalesTax && (
                            <DetailValue
                                label="Sales Tax"
                                value={client.billingSalesTax}
                            />
                        )}
                        <Separator />
                    </Section>
                </HContainer>
                <HContainer uiFlex="1">
                    <Section title="SHIPPING ADDRESS" rightBorder>
                        {client.streetAddress && (
                            <DetailValue
                                clickable
                                value={client.streetAddress}
                                href={`http://maps.google.com/?q=${client.streetAddress},${client.cityTown},${client.zipCode} ${client.country}`}
                                target="_blank"
                            />
                        )}
                        {client.unitSuite && (
                            <DetailValue value={client.unitSuite} />
                        )}
                        {client.cityTown && !client.zipCode && (
                            <DetailValue value={`${client.cityTown}`} />
                        )}
                        {!client.cityTown && client.zipCode && (
                            <DetailValue value={`${client.zipCode}`} />
                        )}
                        {client.cityTown &&
                            client.zipCode &&
                            !client.stateProvince && (
                                <DetailValue
                                    value={`${client.cityTown}, ${client.zipCode}`}
                                />
                            )}
                        {client.cityTown &&
                            client.zipCode &&
                            client.stateProvince && (
                                <DetailValue
                                    value={`${client.cityTown}, ${client.stateProvince} ${client.zipCode}`}
                                />
                            )}
                        {client.country && (
                            <DetailValue value={client.country} />
                        )}
                        <Separator />
                    </Section>
                    <Section title="BILLING ADDRESS" rightBorder>
                        {client.billingDetails &&
                            client.billingDetails.streetAddress && (
                                <DetailValue
                                    clickable
                                    value={client.billingDetails.streetAddress}
                                    href={`http://maps.google.com/?q=${client.billingDetails.streetAddress}`}
                                    target="_blank"
                                />
                            )}
                        {client.billingDetails &&
                            client.billingDetails.unitSuite && (
                                <DetailValue
                                    value={client.billingDetails.unitSuite}
                                />
                            )}
                        {client.billingDetails &&
                            client.billingDetails.cityTown &&
                            !client.billingDetails.zipCode && (
                                <DetailValue
                                    value={`${client.billingDetails.cityTown}`}
                                />
                            )}
                        {client.billingDetails &&
                            !client.billingDetails.cityTown &&
                            client.billingDetails.zipCode && (
                                <DetailValue
                                    value={`${client.billingDetails.zipCode}`}
                                />
                            )}
                        {client.billingDetails &&
                            client.billingDetails.cityTown &&
                            client.billingDetails.zipCode &&
                            !client.billingDetails.stateProvince && (
                                <DetailValue
                                    value={`${client.billingDetails.cityTown}, ${client.billingDetails.zipCode}`}
                                />
                            )}
                        {client.billingDetails &&
                            client.billingDetails.cityTown &&
                            client.billingDetails.zipCode &&
                            client.billingDetails.stateProvince && (
                                <DetailValue
                                    value={`${client.billingDetails.cityTown}, ${client.billingDetails.stateProvince} ${client.billingDetails.zipCode}`}
                                />
                            )}
                        {client.billingDetails &&
                            client.billingDetails.country && (
                                <DetailValue
                                    value={client.billingDetails.country}
                                />
                            )}
                        <Separator />
                    </Section>
                </HContainer>
            </>
        );
    };

    const renderSecondVContainer = (rightBorder: boolean) => {
        return (
            <>
                {/*<Section title="PROJECT MANAGER" rightBorder>
                {(client.projectManager && client.projectManager) && (
                    <DetailValue value={client.projectManager} />
                )}
                </Section>*/}
                <Section title="ADDITIONAL CONTACTS" rightBorder={rightBorder}>
                    {/*{client.additionalContactDetails && client.additionalContactDetails.map((cl: ClientModule.ClientAddressDetails) => (
                        <>
                            {cl && cl.relation && (cl.firstName && cl.lastName) && (
                                <DetailValue label={`${cl.relation}: `} value={`${cl.firstName} ${cl.lastName}`} />
                            )}
                        </>
                            ))}*/}
                    {client.additionalContactDetails &&
                        client.additionalContactDetails.map(
                            (cl: ClientModule.ClientAddressDetails) => {
                                return (
                                    <>
                                        {cl &&
                                            cl.relation &&
                                            (cl.firstName || cl.lastName) && (
                                                <HContainer>
                                                    <RelationName>
                                                        {cl.relation}
                                                    </RelationName>
                                                    <ClientHoverBox
                                                        key={cl.id}
                                                        additionalContact={cl}
                                                        type="child"
                                                    />
                                                </HContainer>
                                            )}
                                    </>
                                );
                            }
                        )}
                </Section>
                <Section title="NOTES" rightBorder={rightBorder}>
                    {client.notes && (
                        <NotesContent>{client.notes}</NotesContent>
                    )}
                </Section>
            </>
        );
    };

    return (
        <WrapperInfo>
            <Container style={{ border: 'none' }}>
                <>
                    <VContainer uiWidth="50%">
                        {renderFirstVContainer()}
                    </VContainer>
                    <VContainer uiWidth="25%">
                        {renderSecondVContainer(true)}
                    </VContainer>
                    <VContainer uiWidth="25%">
                        <Section
                            title="SUB-CLIENTS"
                            className={props.className}
                            addSubClient={true}
                            createSubClient={props.createSubClient}
                        >
                            <SubSection>
                                {client.subClients.map(
                                    (c: ClientModule.Client) =>
                                        renderSubClient(c)
                                )}
                            </SubSection>
                        </Section>
                    </VContainer>
                </>
            </Container>
        </WrapperInfo>
    );
});

export default ClientMoreDetails;
