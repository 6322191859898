import { Omit } from "../../../../types/index";
import { ActionDescriptor } from "../../common";
import {
    ChangeableDraperyOrderSummary,
    DraperyOrderSummary,
} from "./interfaces";

export const ADD_DRAPERY_ORDER_SUMMARY = "draperyOrderSummary/ADD";
export type AddDraperyOrderSummaryAction = ActionDescriptor<
    typeof ADD_DRAPERY_ORDER_SUMMARY,
    Omit<DraperyOrderSummary, "id"> & { accountId: number },
    DraperyOrderSummary
>;

export const EDIT_DRAPERY_ORDER_SUMMARY = "draperyOrderSummary/EDIT";
export type EditDraperyOrderSummaryAction = ActionDescriptor<
    typeof EDIT_DRAPERY_ORDER_SUMMARY,
    ChangeableDraperyOrderSummary & { id: number },
    {}
>;

export const GET_DRAPERY_ORDER_SUMMARY = "draperyOrderSummary/GET";
export type GetDraperyOrderSummaryAction = ActionDescriptor<
    typeof GET_DRAPERY_ORDER_SUMMARY,
    { id: number },
    DraperyOrderSummary
>;

export const DELETE_DRAPERY_ORDER_SUMMARY = "draperyOrderSummary/DELETE";
export type DeleteDraperyOrderSummaryAction = ActionDescriptor<
    typeof DELETE_DRAPERY_ORDER_SUMMARY,
    { id: number },
    {}
>;

export interface DraperyOrderSummaryActionsMap {
    [ADD_DRAPERY_ORDER_SUMMARY]: AddDraperyOrderSummaryAction;
    [EDIT_DRAPERY_ORDER_SUMMARY]: EditDraperyOrderSummaryAction;
    [GET_DRAPERY_ORDER_SUMMARY]: GetDraperyOrderSummaryAction;
    [DELETE_DRAPERY_ORDER_SUMMARY]: DeleteDraperyOrderSummaryAction;
}
