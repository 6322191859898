import { FormInput, FormLabel } from "./styles";
import {
    DraperyItemDetailsFields,
    PricingGroupItem,
    PricingGroupOrSimpleItem,
    PricingSimpleItem,
} from "../../redux/modules/my-shop/drapery-item-details-fields-reducer";
import { deletePleat } from "../../api/item";
import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { checkIfGroupItem, checkIfSimpleItem } from "./helpers";
import { FaEllipsisV } from "react-icons/fa";
// import { TransitionPopover } from "@ramble/ramble-ui";
import Pencil from "../../../src/assets/icons/pencil.svg";
import TrashCan from "../../../src/assets/icons/trash1.svg";
import styled from "../../theme";
import { FormFields, FormFieldsAction } from "../../redux/modules/my-shop/form-fields-reducer";
import PleatModal from "./pleat-modal/pleat-modal";
import { Checkbox } from "@ramble/ramble-ui";
import { NotificationsModule } from "../../redux";
import { useDispatch } from "react-redux";

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    padding-left: 1em;
    position: relative;

    > *:not(:last-child) {
        margin-left: 10px;
    }
`;

const AddButton = styled.div`
    position: absolute;
    // border-radius: 20px;
    box-shadow: 0px 10px 14px 8px #00000026;
    overflow: hidden;
    top: 20px;
    right: 20px;
    animation: slidein 0.2s ease-in;
    width: 150px;
    border-radius: 12px;
    ul {
        position: relative;
        z-index: 999999;
    }
    li {
        color: #6b6b6b;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        display: flex;
        width: 205px;
        padding: var(--8px, 8px);
        // border-top: 1px solid #eee;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        background-color: #fff;
        height: 49px;

        &:hover {
            background-color: rgb(238, 238, 238);
        }
    }
    &.transition-enter,
    &.transition-appear {
        opacity: 1;
        transform: scaleY(0.1);
        transform-origin: 100% 0;
    }

    &.transition-enter-active,
    &.transition-appear-active {
        transform: scaleY(1);
        transition: transform 0.1s ease;
    }

    &.transition-exit {
        opacity: 1;
        transform: scaleY(1);
        transform-origin: 100% 0;
    }

    &.transition-exit-active {
        opacity: 0.4;
        transform: scaleY(0.1);
        transition: transform 0.1s ease, opacity 0.1s ease;
    }


`;

interface Header {
    title: string;
    subTitle?: string;
}
interface HeaderCompProps {
    headers: Header[];
}
interface PricingGroupGridProps {
    headers: Header[];
    rows: PricingGroupOrSimpleItem[];
    handleInputChange(e: ChangeEvent<HTMLInputElement>): void;
    inputRequired?: boolean;
    formFields?: FormFields;
    itemId?: number | null;
    draperyItemDetailsFields?: DraperyItemDetailsFields;
    highLightInput?: string[];
}
interface RowsCompProps {
    rows: PricingGroupOrSimpleItem[];
    handleInputChange(e: ChangeEvent<HTMLInputElement>): void;
    inputRequired?: boolean;
    formFields?: FormFields;
    itemId?: number | null;
    draperyItemDetailsFields?: DraperyItemDetailsFields;
    highLightInput?: string[]
}
interface RowSimpleItemCompProps {
    item: PricingSimpleItem;
    handleInputChange(e: ChangeEvent<HTMLInputElement>): void;
    inputRequired?: boolean;
    formFields?: FormFields;
    itemId?: number | null;
    pleatChecked?: boolean;
    draperyItemDetailsFields?: DraperyItemDetailsFields;
    highLightInput?: string[];
}
interface RowGroupItemCompProps {
    item: PricingGroupItem;
    handleInputChange(e: ChangeEvent<HTMLInputElement>): void;
    inputRequired?: boolean;
    pleatChecked?: boolean;
}

const RowSimpleItemComp: FC<RowSimpleItemCompProps> = ({
    item,
    inputRequired,
    handleInputChange,
    formFields,
    itemId,
    draperyItemDetailsFields,
    highLightInput,
}) => {
    const { id, name, price } = item;
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [isPleatModalOpen, setIsPleatModalOpen] = useState(false);

    const handleNewPleatClick = () => {
        setIsPopoverOpen(true);

        // e.stopPropagation();
    };

    const dispatchRedux = useDispatch();
    const newPleatId = item.newPleatId;
    const handleDeletePleat = async (itemId: number | null, newPleatId: any, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            await deletePleat(itemId, newPleatId);
            const notification: NotificationsModule.Notification = {
                id: 0,
                message: "Delete Success!",
                subMessage: "Pleat has been successfully deleted.",
                type: "error",
            };
            dispatchRedux(NotificationsModule.addNotification(notification));
            window.location.reload();

        } catch (error) {
            console.error('Error deleting pleat:', error);
        }
    };

    const openPleatModal = (newPleatId: any, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setIsPleatModalOpen(true);
    };

    const closePleatModal = () => {
        setIsPleatModalOpen(false);
    };

    const [hasErrorFields, setHasErrorFields] = useState<string[]>([]);
    const errorInputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});

    useEffect(() => {
        if (draperyItemDetailsFields) {
            const errorFields = draperyItemDetailsFields.pleatTypesWithPricing
                .filter((pleat) => pleat.price === '')
                .map((pleat) => pleat.id);
            const currentUrl = window.location.href;
            if (!currentUrl.includes("/myshop")) {
                setHasErrorFields(errorFields);
                if (errorFields.length > 0) {
                    const firstErrorId = errorFields[0];
                    const firstErrorRef = errorInputRefs.current[firstErrorId];
                    if (firstErrorRef) {
                        firstErrorRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }
            }
        }
    }, [draperyItemDetailsFields]);

    function dispatch(value: FormFieldsAction): void {
        throw new Error("Function not implemented.");
    }
    return (
        <div key={id} className="flex border items-center">
            <div className="w-1/2 p-2" style={item.isActive === false ? { fontStyle: 'italic', color: '#AAAAAA', paddingLeft: '10px' } : {}}>
                {name}
                {item.isActive === false && <span style={{ marginLeft: '5px', color: '#AAAAAA' }}>(Inactive)</span>}
            </div>
            <div className="w-1/2 p-2">
                <FormLabel htmlFor={id}>$</FormLabel>
                <FormInput
                    id={id}
                    name={name}
                    className={`p-2 ml-2 max-w-[80px] ${(hasErrorFields.includes(item.id) || (highLightInput && highLightInput.length > 0 && highLightInput.includes(item.id))) ? 'error-pleatType' : ''}`}
                    type="text"
                    required={inputRequired}
                    value={price}
                    onChange={handleInputChange}
                    uiReportOnBlur={false}
                    uiDisabled={false}
                    list="autocompleteOff"
                    disabled={item.isActive === false}
                    ref={(el) => (errorInputRefs.current[item.id] = el)}
                />
            </div>
            {newPleatId !== undefined ? (
                <>
                    {/* <Container>
                        <div
                            style={{ fontSize: "20px" }}
                            onClick={handleNewPleatClick}
                        >
                            <FaEllipsisV />
                        </div>
                        <TransitionPopover
                            uiActive={isPopoverOpen}
                            uiDistanceAway={20}
                            uiOffset={-198}
                            uiAutoPosition={false}
                            uiTargetEl={null}
                            uiOnRequestClose={() => setIsPopoverOpen(false)}
                            uiTransitionTimeouts={100}
                            uiPosition="bottom center"
                        >
                            <div>
                                <div>
                                    <img src="" alt="" />
                                    <p>edit</p>
                                </div>
                                <div>
                                    <img src="" alt="" />
                                    <p>edit</p>
                                </div>
                            </div>
                        </TransitionPopover>
                    </Container> */}
                    <Container>
                        <div
                            className="p-2 cursor-pointer"
                            onMouseEnter={handleNewPleatClick}
                            onMouseLeave={() => setIsPopoverOpen(false)}
                        >
                            <FaEllipsisV />
                            {/* <TransitionPopover
                                uiActive={isPopoverOpen}
                                uiDistanceAway={450}
                                uiOffset={-300}
                                uiAutoPosition={false}
                                uiTargetEl="user-menu"
                                uiOnRequestClose={() => {}}
                                uiTransitionTimeouts={100}
                                uiPosition="bottom right"
                            > */}
                            {isPopoverOpen && (
                                <AddButton
                                    onMouseLeave={() => console.log("otso")}
                                >
                                    <>
                                        <ul>
                                            <li
                                                data-num="editPleat"
                                                style={{ display: "flex", position: "relative", alignItems: "center", listStyleType: "none" }}
                                            >
                                                <button
                                                    style={{
                                                        fontSize: "16px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        backgroundColor: "transparent",
                                                        border: "none",
                                                        cursor: "pointer",
                                                        marginRight: "5px",
                                                        top: "0",
                                                        left: "2%",
                                                        width: "100%",
                                                        height: "100%"
                                                    }}
                                                    onClick={(e) => {
                                                        if (item.newPleatId)
                                                            openPleatModal(item.newPleatId, e)
                                                    }}
                                                >
                                                    <Pencil style={{ marginRight: "5px" }} />
                                                    <span>Edit</span>
                                                </button>
                                            </li>

                                            <li
                                                data-num="deletePleat"
                                                style={{ display: "flex", position: "relative", alignItems: "center", listStyleType: "none" }}
                                            >
                                                <button
                                                    style={{
                                                        fontSize: "16px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        backgroundColor: "transparent",
                                                        border: "none",
                                                        cursor: "pointer",
                                                        marginRight: "5px",
                                                        position: "absolute",
                                                        top: "0",
                                                        left: "5%",
                                                        width: "100%",
                                                        height: "100%"
                                                    }}
                                                    onClick={(e) => {
                                                        if (item.newPleatId && itemId !== null && itemId !== undefined) {
                                                            handleDeletePleat(itemId, item.newPleatId, e)
                                                        }
                                                    }}                                                                                                    >
                                                    <TrashCan style={{ marginRight: "5px" }} />
                                                    <span>Delete</span>
                                                </button>
                                            </li>

                                        </ul>
                                    </>
                                </AddButton>
                            )}

                            {/* </TransitionPopover>{" "} */}
                        </div>
                    </Container>
                </>
            ) : (
                <div
                    style={{ marginLeft: "48px" }}
                >
                </div>
            )}
            {isPleatModalOpen && (
                <PleatModal
                    isPleatModalOpen={isPleatModalOpen}
                    onPleatModalClose={closePleatModal}
                    dispatch={dispatch}
                    formFields={formFields!}
                    draperyItemDetailsFields={draperyItemDetailsFields}
                    itemId={itemId}
                    newPleatId={newPleatId}
                />
            )}
        </div>
    );
}
const RowGroupItemComp: FC<RowGroupItemCompProps> = ({
    item,
    inputRequired,
    handleInputChange,
}) => {
    const {
        name: groupName,
        nameSubText: groupNameSubtext,
        items,
    } = item.group;

    return (
        <div key={groupName} className="flex border flex-col">
            <div className="w-1/2 p-2">
                {groupName} {groupNameSubtext}
            </div>
            <div className="px-6">
                {items && // NOSONAR
                    items.length && // NOSONAR
                    items.map((item) => {
                        const { id, name, nameSubText, price } = item;
                        return (
                            <div
                                key={id}
                                className="flex flex-row items-center"
                            >
                                <div className="w-1/2 p-2">
                                    {name} {nameSubText}
                                </div>

                                <div className="w-1/2 p-2">
                                    <FormLabel htmlFor={id}>$</FormLabel>
                                    <FormInput
                                        id={id}
                                        name={name}
                                        className="p-2 ml-2 max-w-[80px]"
                                        type="text"
                                        required={inputRequired}
                                        value={price}
                                        onChange={handleInputChange}
                                        uiReportOnBlur={false}
                                        disabled={false}
                                        uiDisabled={false}
                                        list="autocompleteOff"
                                    />
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

const RowsComp: FC<RowsCompProps> = ({
    rows,
    handleInputChange,
    inputRequired,
    formFields,
    itemId,
    draperyItemDetailsFields,
    highLightInput,
}) => {
    const [pleatChecked, setPleatChecked] = useState<boolean>(false);
    const handleInactiveOnly = () => {
        setPleatChecked((prevChecked) => !prevChecked);
    };

    if (!rows || !rows.length) return null; // NOSONAR
    //typescript version doesnt supprt optional chaining, so NOSONAR
    return (
        <>

            {rows.map(row => (
                'id' in row && row.id === "noneflat" && (
                    <div className="flex items-center justify-end" style={{ marginTop: '-25px' }}>
                        <Checkbox
                            onChange={() => handleInactiveOnly()}
                        />
                        <p className="mb-0" style={{ marginRight: '10px', fontSize: '15px' }}>
                            Include Inactive
                        </p>

                    </div>)
            ))}

            {rows.map((r) => {
                let rItem;
                const isSimpleItem = checkIfSimpleItem(r);
                const isGroupItem = checkIfGroupItem(r);
                if (!isSimpleItem && !isGroupItem) return;
                if (isGroupItem) {
                    rItem = r as PricingGroupItem;
                    return (
                        <RowGroupItemComp
                            key={rItem.group.name}
                            item={rItem}
                            inputRequired={inputRequired}
                            handleInputChange={handleInputChange}
                            pleatChecked={pleatChecked}
                        />
                    );
                }

                //simple Item
                rItem = r as PricingSimpleItem;
                if (!rItem.isDeleted && (pleatChecked || rItem.isActive === true || rItem.isActive === undefined)) {
                    return (
                        <RowSimpleItemComp
                            key={rItem.id}
                            item={rItem}
                            inputRequired={inputRequired}
                            handleInputChange={handleInputChange}
                            formFields={formFields}
                            draperyItemDetailsFields={draperyItemDetailsFields}
                            itemId={itemId}
                            pleatChecked={pleatChecked}
                            highLightInput={highLightInput}
                        />
                    );
                } else {
                    if (rItem.isActive === true || rItem.isActive === undefined) {
                        return (
                            <RowSimpleItemComp
                                key={rItem.id}
                                item={rItem}
                                inputRequired={inputRequired}
                                handleInputChange={handleInputChange}
                                formFields={formFields}
                                draperyItemDetailsFields={draperyItemDetailsFields}
                                itemId={itemId}
                                pleatChecked={pleatChecked}
                            />
                        );
                    } else {
                        return null;
                    }
                }
            })}
        </>
    );
};

const HeaderComp: FC<Partial<HeaderCompProps>> = ({ headers }) => {
    if (!headers || !headers.length) return null; // NOSONAR
    //typescript version doesnt supprt optional chaining, so NOSONAR
    return (
        <div className="flex border bg-twBackDropColor">
            {headers.map((h: Header) => {
                const { title, subTitle } = h;

                return (
                    <div
                        key={`${title}`}
                        className="w-1/2 p-2 flex flex-col justify-center"
                    >
                        {title}
                        {subTitle && (
                            <span className="text-sm">{subTitle}</span>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

const PricingGroupGrid: FC<PricingGroupGridProps> = ({
    headers,
    rows,
    handleInputChange,
    inputRequired = true,
    formFields,
    itemId,
    draperyItemDetailsFields,
    highLightInput,
}) => {
    return (
        <div>
            <HeaderComp headers={headers} />
            <RowsComp
                rows={rows}
                handleInputChange={handleInputChange}
                inputRequired={inputRequired}
                formFields={formFields}
                draperyItemDetailsFields={draperyItemDetailsFields}
                itemId={itemId}
                highLightInput={highLightInput}
            />
        </div>
    );
};

export default PricingGroupGrid;