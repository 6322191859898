import Bind from "lodash-decorators/bind";
import React, { PureComponent } from "react";
import styled from "../../../../theme";
import { MenuButton, MenuButtonProps } from "./toolbar";

import ArrowIcon from "../../../../assets/icons/drawing/pencil_tool_arrow.svg";
import CircleIcon from "../../../../assets/icons/drawing/pencil_tool_circle.svg";
import LineIcon from "../../../../assets/icons/drawing/pencil_tool_line.svg";
import RectIcon from "../../../../assets/icons/drawing/pencil_tool_square.svg";
import SquiggleIcon from "../../../../assets/icons/drawing/pencil_tool_squiggle.svg";

const Popover = styled.div`
    position: absolute;
    z-index: 2;
`;

const Cover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const Menu = styled.div<MenuButtonProps>`
    width: ${props => props.uiWidth ? `${props.uiWidth}px` : `6vh`};
    bottom: ${props => props.uiWidth ? `${props.uiWidth * 1.03}px` : `6.18vh`};
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    background-color: white;
`;

const MenuItem = styled.div<MenuButtonProps>`
    width: 100%;
    height: ${props => props.uiWidth ? `${props.uiWidth * 0.6}px` : `3.6vh`};
    padding: ${props => props.uiWidth ? `${props.uiWidth * 0.1}px` : `0.6vh`};

    &:not(:last-child) {
        border-bottom: 1px solid #b3b3b3;
    }
`;

const ChildrenContainer = styled.div`
    width: 100%;
    height: 100%;
`;

interface Props {
    className?: string;
    uiWidth: number;
    selected: boolean;
    onClicked(type: "line" | "arrow" | "pen" | "rect" | "circle"): void;
}

interface State {
    displayMenu: boolean;
}

class PencilMenuButton extends PureComponent<Props, State> {
    public state: State = {
        displayMenu: false,
    };

    public render() {
        return (
            <MenuButton uiWidth={this.props.uiWidth} className={this.props.className}>
                <ChildrenContainer title="" onClick={this.toggleMenu}>
                    {this.props.children}
                </ChildrenContainer>
                {this.state.displayMenu &&
                    <Popover>
                        <Cover onClick={this.closeMenu}/>
                        <Menu uiWidth={this.props.uiWidth}>
                            <MenuItem uiWidth={this.props.uiWidth} title="" onClick={this.handleLine}><LineIcon width="100%" height="100%"/></MenuItem>
                            <MenuItem uiWidth={this.props.uiWidth} title="" onClick={this.handleArrow}><ArrowIcon width="100%" height="100%"/></MenuItem>
                            <MenuItem uiWidth={this.props.uiWidth} title="" onClick={this.handleSquiggle}><SquiggleIcon width="100%" height="100%"/></MenuItem>
                            <MenuItem uiWidth={this.props.uiWidth} title="" onClick={this.handleRect}><RectIcon width="100%" height="100%"/></MenuItem>
                            <MenuItem uiWidth={this.props.uiWidth} title="" onClick={this.handleCircle}><CircleIcon width="100%" height="100%"/></MenuItem>
                        </Menu>
                    </Popover>
                }
            </MenuButton>
        );
    }

    @Bind()
    private toggleMenu() {
        if (!this.props.selected) {
            this.setState({ displayMenu: !this.state.displayMenu });
        }
    }

    @Bind()
    private closeMenu() {
        this.setState({ displayMenu: false });
    }

    @Bind()
    private handleLine() {
        this.props.onClicked("line");
        this.closeMenu();
    }

    @Bind()
    private handleArrow() {
        this.props.onClicked("arrow");
        this.closeMenu();
    }

    @Bind()
    private handleSquiggle() {
        this.props.onClicked("pen");
        this.closeMenu();
    }

    @Bind()
    private handleRect() {
        this.props.onClicked("rect");
        this.closeMenu();
    }

    @Bind()
    private handleCircle() {
        this.props.onClicked("circle");
        this.closeMenu();
    }
}

export default PencilMenuButton;
