
/**
 * Return element's offset width and height
 *
 * @export
 * @param element
 * @returns
 */
export function getElementOffsetDimensions(element: HTMLElement): { width: number, height: number } {
    return {
        width: element.offsetWidth,
        height: element.offsetHeight,
    };
}
