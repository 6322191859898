import { Omit } from "../../../../types/index";
import { ActionDescriptor } from "../../common";
import { DraperyOrder, DraperyOrderAllInfo } from "./interfaces";

export const ADD_DRAPERY_ORDER = "draperyOrder/ADD";
export type AddDraperyOrderAction = ActionDescriptor<
    typeof ADD_DRAPERY_ORDER,
    Omit<DraperyOrder, "id" | "workOrderId"> & { accountId: number } & {
        clientId?: number;
    } & { draperyOrderDesignId?: number } & {
        draperyOrderFabricIds?: number[];
    } & { draperyOrderSummaryId?: number } & { roomId?: number } & {
        workOrderId?: number;
    },
    DraperyOrder
>;

export const EDIT_DRAPERY_ORDER = "draperyOrder/EDIT";
export type EditDraperyOrderAction = ActionDescriptor<
    typeof EDIT_DRAPERY_ORDER,
    DraperyOrder,
    {}
>;

export const GET_DRAPERY_ORDER = "draperyOrder/GET";
export type GetDraperyOrderAction = ActionDescriptor<
    typeof GET_DRAPERY_ORDER,
    { id: number },
    DraperyOrder
>;

export const GET_DRAPERY_ORDER_ALL_INFO = "draperyOrderAllInfo/GET";
export type GetDraperyOrderAllInfoAction = ActionDescriptor<
    typeof GET_DRAPERY_ORDER_ALL_INFO,
    { id: number },
    DraperyOrderAllInfo
>;

export const DELETE_DRAPERY_ORDER = "draperyOrder/DELETE";
export type DeleteDraperyOrderAction = ActionDescriptor<
    typeof DELETE_DRAPERY_ORDER,
    { id: number },
    {}
>;

export interface DraperyOrderActionsMap {
    [ADD_DRAPERY_ORDER]: AddDraperyOrderAction;
    [EDIT_DRAPERY_ORDER]: EditDraperyOrderAction;
    [GET_DRAPERY_ORDER]: GetDraperyOrderAction;
    [GET_DRAPERY_ORDER_ALL_INFO]: GetDraperyOrderAllInfoAction;
    [DELETE_DRAPERY_ORDER]: DeleteDraperyOrderAction;
}
