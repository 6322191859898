import { Bind } from "lodash-decorators/bind";
import React, { PureComponent } from "react";
import { findDOMNode } from "react-dom";
import EventListener from "react-event-listener";
import ResizeObserver from "resize-observer-polyfill";
import Portal from "../portal/portal";
import { getElementOffsetDimensions } from "../utils/dimensions";

export type Position = "top left" | "top center" | "top right" | "right center" | "left center" | "bottom right" | "bottom center" | "bottom left";

const POSITIONS: Position[] = ["top center", "left center", "right center", "bottom center", "top left", "top right", "bottom right", "bottom left"];

export interface CalculatedPosition {
    top: number | "auto";
    left: number | "auto";
    right: number | "auto";
    bottom: number | "auto";
}

export interface PopoverScrollContextInterface {
    scrollX: number;
    scrollY: number;
}

// Although it's not being used directly, it's needed to force update after scrolling in some inner scrollable content,
// otherwise the popup position may not be updated correctly
export const PopoverScrollContext = React.createContext<PopoverScrollContextInterface>({ scrollX: 0, scrollY: 0});

export interface PopoverUIProps {
    /**
     * Target element to attach the popup
     */
    uiTargetEl: React.ReactInstance | Element | null | string;
    /**
     * Default position
     * @default "top center"
     */
    uiPosition?: Position;
    /**
     * Auto position popup if it can't fit in the screen
     * @default true
     */
    uiAutoPosition?: boolean;
    /**
     * Offset in px to add to popup
     * @default 0
     */
    uiOffset?: number;
    /**
     * Distance away in px from target
     * @default 0
     */
    uiDistanceAway?: number;
    /**
     * If true when clicking on uiTargetEl won't trigger uiOnRequestClose() event
     * @default true
     */
    uiNoCloseOnTargetClick?: boolean;
    /**
     * Portal class name
     */
    className?: string;
    /**
     * Use intersection observer to watch target changes
     */
    uiUseIntersectionObserver?: boolean;
    /**
     * Change position due to popup auto-positioning callback
     *
     * @param newPosition
     * @param oldPosition
     */
    uiOnChangePosition?(newPosition: Position, oldPosition: Position): void;
    /**
     * Popup close callback
     * @param event Event caused popup closing. May be undefined
     */
    uiOnRequestClose(event?: Event | undefined): void;
    /**
     * Callback for popover opening
     */
    uiOnOpen?(): void;
    /**
     * Callback for popover closing
     */
    uiOnClose?(): void;
}

export type PopoverProps = PopoverUIProps;

/**
 * Just a popover
 */
class Popover extends PureComponent<PopoverProps, {}, PopoverScrollContextInterface> {
    public static defaultProps: Partial<PopoverProps> = {
        uiPosition: "top center",
        uiAutoPosition: true,
        uiOffset: 0,
        uiDistanceAway: 0,
        uiNoCloseOnTargetClick: true,
        uiUseIntersectionObserver: true,
    };

    public static contextType = PopoverScrollContext;

    /**
     * Popover body node
     */
    private popoverBodyElement?: HTMLElement;

    /**
     * Target element node
     */
    private targetElement?: HTMLElement;

    /**
     * Resize observer
     */
    private resizeObserver: ResizeObserver = new ResizeObserver(this.updatePlacement);
    private intersectionObserver: IntersectionObserver = new IntersectionObserver(entries => {
        const lastEntry = entries.slice(-1)[0];
        if (!lastEntry || !this.popoverBodyElement || !this.props.uiUseIntersectionObserver) {
            return;
        }
        const isOut = lastEntry.intersectionRatio < 0.9;
        this.popoverBodyElement.style.display = isOut ? "none" : "block";

    }, {
            root: null,
            threshold: [0.9, 1],
        },
    );

    public componentDidMount(): void {
        this.popoverBodyElement = findDOMNode(this) as HTMLElement;
        if (this.popoverBodyElement) {
            this.resizeObserver.observe(this.popoverBodyElement);
        }
        this.targetElement = this.findElement(this.props.uiTargetEl);
        if (this.targetElement) {
            this.intersectionObserver.observe(this.targetElement);
        }
        this.setPlacement(true);
    }

    public componentDidUpdate(prevProps: PopoverProps): void {
        const { uiPosition, uiTargetEl } = this.props;
        if (prevProps.uiPosition !== uiPosition || uiTargetEl !== prevProps.uiTargetEl) {
            if (uiTargetEl !== prevProps.uiTargetEl) {
                if (this.targetElement) {
                    this.intersectionObserver.unobserve(this.targetElement);
                }
                this.targetElement = this.findElement(uiTargetEl);
                if (this.targetElement) {
                    this.intersectionObserver.observe(this.targetElement);
                }
            }
        }
        this.setPlacement();
    }

    public componentWillUnmount(): void {
        if (this.popoverBodyElement) {
            this.resizeObserver.unobserve(this.popoverBodyElement);
            this.popoverBodyElement = undefined;
        }
        this.intersectionObserver.disconnect();
    }

    /**
     * Render
     *
     * @returns
     */
    public render(): JSX.Element {
        const { children, uiOnOpen, uiOnClose, className } = this.props;
        return (
            <Portal
                uiCloseOnEsc={false}
                uiCloseOnOutsideClick
                uiOnRequestClose={this.requestClose}
                uiOnOpened={uiOnOpen}
                uiOnClosed={uiOnClose}
                className={className}
            >
                {children}
                <EventListener key="resize-listener" target="window" onResize={this.updatePlacement} />
            </Portal>
        );
    }

    /**
     * Set popup placement
     *
     * @param initial Initial placement
     */
    public setPlacement(initial: boolean = false): void {
        const { uiPosition, uiOnRequestClose, uiAutoPosition, uiOnChangePosition } = this.props;
        if (!this.targetElement || !this.popoverBodyElement) {
            return;
        }
        if (this.targetElement.offsetHeight === 0) {
            return;
        }

        // Set initial position to 0:0 to calculate proper dimensions. it'll be changed immediately
        if (initial) {
            this.popoverBodyElement.style.position = "fixed";
            this.popoverBodyElement.style.top = `0px`;
            this.popoverBodyElement.style.left = `0px`;
            this.popoverBodyElement.style.visibility = "hidden";
        }
        let { width: popupWidth, height: popupHeight } = getElementOffsetDimensions(this.popoverBodyElement);
        const style = getComputedStyle(this.popoverBodyElement);
        const marginTop = parseInt(style.marginTop!, 10) || 0;
        const marginBottom = parseInt(style.marginBottom!, 10) || 0;
        const marginLeft = parseInt(style.marginLeft!, 10) || 0;
        const marginRight = parseInt(style.marginRight!, 10) || 0;
        popupWidth += marginLeft + marginRight;
        popupHeight += marginTop + marginBottom;

        let popoverPosition = this.getPopoverPosition(uiPosition!, popupWidth, popupHeight, this.targetElement);
        // Given position doesn't fit to the screen, try auto-positioning
        if (!this.isPopoverFitsToScreen(popoverPosition, popupWidth, popupHeight) && uiAutoPosition) {
            let newPopoverPosition;
            let newPositionStr: Position | undefined;
            for (const pos of POSITIONS) {
                const newPos = this.getPopoverPosition(pos, popupWidth, popupHeight, this.targetElement);
                if (this.isPopoverFitsToScreen(newPos, popupWidth, popupHeight)) {
                    newPositionStr = pos;
                    newPopoverPosition = newPos;
                    break;
                }
            }
            if (newPopoverPosition) {
                if (uiOnChangePosition) {
                    uiOnChangePosition(newPositionStr!, uiPosition!);
                }
                popoverPosition = newPopoverPosition;
            } else {
                // No suitable popup position found, close
                uiOnRequestClose();
            }
        }
        this.popoverBodyElement.style.visibility = "visible";
        // Set the style even if we didn't find the correct position
        this.popoverBodyElement.style.position = "absolute";
        this.popoverBodyElement.style.right = popoverPosition.right === "auto" ? "auto" : `${popoverPosition.right}px`;
        this.popoverBodyElement.style.bottom = popoverPosition.bottom === "auto" ? "auto" : `${popoverPosition.bottom}px`;
        this.popoverBodyElement.style.top = popoverPosition.top === "auto" ? "auto" : `${popoverPosition.top}px`;
        this.popoverBodyElement.style.left = popoverPosition.left === "auto" ? "auto" : `${popoverPosition.left}px`;
    }

    /**
     * Get popover top left position for given position
     *
     * @param position
     * @param popupWidth
     * @param popupHeight
     * @param targetDimensions
     * @returns Calculated popover position
     */
    protected getPopoverPosition(position: Position, popupWidth: number, popupHeight: number, target: HTMLElement): CalculatedPosition {
        const { uiOffset, uiDistanceAway } = this.props;
        const rect = target.getBoundingClientRect();
        switch (position) {
            case "top left":
                return {
                    top: rect.top - popupHeight + window.scrollY - uiDistanceAway!,
                    left: rect.left + uiOffset! + window.scrollX,
                    bottom: "auto",
                    right: "auto",
                };
            case "top center":
                return {
                    top: rect.top - popupHeight + window.scrollY - uiDistanceAway!,
                    left: rect.left + rect.width / 2 - popupWidth / 2 + uiOffset! + window.scrollX,
                    bottom: "auto",
                    right: "auto",
                };
            case "top right":
                return {
                    top: rect.top - popupHeight + window.scrollY - uiDistanceAway!,
                    left: rect.right - popupWidth - uiOffset! + window.scrollX,
                    bottom: "auto",
                    right: "auto",
                };
            case "right center":
                return {
                    top: rect.top + rect.height / 2 - popupHeight / 2 + uiOffset! + window.scrollY,
                    left: rect.right + uiDistanceAway! + window.scrollX,
                    bottom: "auto",
                    right: "auto",
                };
            case "bottom right":
                return {
                    top: rect.bottom + uiDistanceAway! + window.scrollY,
                    left: rect.right - popupWidth + uiOffset! + window.scrollX,
                    bottom: "auto",
                    right: "auto",
                };
            case "bottom center":
                return {
                    top: rect.bottom + uiDistanceAway! + window.scrollY,
                    left: rect.left + rect.width / 2 - popupWidth / 2 + uiOffset! + window.scrollX,
                    bottom: "auto",
                    right: "auto",
                };
            case "bottom left":
                return {
                    top: rect.bottom + uiDistanceAway! + window.scrollY,
                    left: rect.left + uiOffset! + window.scrollX,
                    bottom: "auto",
                    right: "auto",
                };

            case "left center":
                return {
                    top: rect.top + rect.height / 2 - popupHeight / 2 + uiOffset! + window.scrollY,
                    left: rect.left - popupWidth - uiDistanceAway! + window.scrollX,
                    bottom: "auto",
                    right: "auto",
                };
        }
    }

    /**
     * Check if popover with given top left position fits the client screen
     *
     * @param calculatedPos
     * @param popupWidth
     * @param popupHeight
     * @returns
     */
    protected isPopoverFitsToScreen(calculatedPos: CalculatedPosition, popupWidth: number, popupHeight: number): boolean {
        const windowWidth = document.documentElement.scrollWidth;
        const windowHeight = document.documentElement.scrollHeight;
        return (
            ((calculatedPos.left !== "auto" && calculatedPos.left >= 0 && calculatedPos.left + popupWidth < windowWidth) ||
            (calculatedPos.right !== "auto" && calculatedPos.right < windowWidth && popupWidth + calculatedPos.right < windowWidth)) &&

            ((calculatedPos.top !== "auto" && calculatedPos.top >= 0 && calculatedPos.top + popupHeight < windowHeight) ||
            (calculatedPos.bottom !== "auto" && calculatedPos.bottom < windowWidth && popupHeight + calculatedPos.bottom < windowHeight))
        );
    }

    /**
     * Popover changes size somehow dynamically
     */
    @Bind()
    protected updatePlacement(): void {
        this.setPlacement();
    }

    /**
     * Request popover close
     * @param e Portal close event
     */
    @Bind()
    protected requestClose(e: Event): void {
        const { uiOnRequestClose, uiNoCloseOnTargetClick, uiTargetEl } = this.props;
        const targetEl = this.findElement(uiTargetEl);
        if (uiNoCloseOnTargetClick && e && e.target instanceof Element && targetEl && targetEl.contains(e.target)) {
            return;
        }
        uiOnRequestClose(e);
    }

    /**
     * Find target element
     */
    private findElement(elem?: PopoverProps["uiTargetEl"]): HTMLElement | undefined {
        if (!elem) {
            return;
        }
        const target: HTMLElement | null = elem instanceof Element
            ? elem as HTMLElement
            : typeof elem === "string"
                ? document.getElementById(elem)
                : findDOMNode(elem) as HTMLElement;

        return target || undefined;
    }
}

export default Popover;
