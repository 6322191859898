import React, { ComponentType } from "react";
import styled, { keyframes } from "../theme/styled";

interface AnimatedIconProps {
    /**
     * Icon children
     */
    children: React.ReactElement<any>;
    className?: string;
}

const rotate360 = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const AnimatedIconComponent: React.SFC<AnimatedIconProps> = props => {
    const { children } = props;
    const child = React.Children.only(children);
    return React.cloneElement(child, {
        ...child.props,
        className: child.props.className ? `${child.props.className} ${props.className}` : props.className,
    });
};

const AnimatedIcon = styled(AnimatedIconComponent)`
    animation: ${rotate360} 2s linear infinite;
`;

/**
 * Spin animation icon
 */
export default AnimatedIcon;

/**
 * Make a new animation icon component
 */
export function createAnimatedIcon<T extends ComponentType<any>>(component: T): T {
    return styled(component)`
        animation: ${rotate360} 2s linear infinite;
    ` as any;
}
