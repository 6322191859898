import { Button, Modal, ModalActions, ModalCloseIcon, ModalContent, ModalHeader, PageDimmer } from "@ramble/ramble-ui";
import Bind from "lodash-decorators/bind";
import React, { PureComponent } from "react";
import { FaCreditCard } from "react-icons/fa";
import { CreditCardForm, CreditCardFormProps } from "../components/stripe/credit_card_form";
import styled from "../theme";

const CancelButton = styled(Button)`
    margin-right: auto !important;
`;

const UpdateCardErrorStyle = styled.div`
    color: #ff6084;
    font-style: italic;
    margin: auto;
    margin-bottom: 20px
`;

export interface UpdateCreditCardModalProps extends CreditCardFormProps {
    /**
     * Modal is active
     */
    active: boolean;
    /**
     * Request close callback
     */
    onRequestClose(): void;
    onUpdateCardError(error: string): void;
    updateCardError: string;
}

interface State {
    updating: boolean;
}

export class UpdateCreditCardModal extends PureComponent<UpdateCreditCardModalProps, State> {
    public state: State = {
        updating: false,
    };

    public render(): JSX.Element {
        const { active, configKey, updateCardError, onUpdateCardError } = this.props;
        return (
            <>
                <PageDimmer uiActive={active}/>
                <Modal
                    uiActive={active}
                    uiOnRequestClose={this.handleClose}
                >
                    <ModalHeader>
                        <FaCreditCard />
                        Update Card
                        <ModalCloseIcon onClick={this.handleClose} />
                    </ModalHeader>
                    <ModalContent>
                        <CreditCardForm
                            configKey={configKey}
                            onAddCard={this.handleCardAdding}
                            onUpdateCardError={onUpdateCardError}
                        />
                    </ModalContent>
                    {updateCardError && <UpdateCardErrorStyle>{updateCardError}</UpdateCardErrorStyle>}
                    <ModalActions>
                        <CancelButton
                            uiColor="secondary"
                            uiDisabled={this.state.updating}
                            onClick={this.handleClose}
                        >
                            Cancel
                        </CancelButton>
                        <Button
                            type="submit"
                            form="cardform"
                            uiColor="primary"
                            uiDisabled={this.state.updating}
                        >
                            Update
                        </Button>
                    </ModalActions>
                </Modal>
            </>
        );
    }

    @Bind()
    private handleClose(): void {
        if (this.state.updating) {
            return;
        }
        this.props.onRequestClose();
    }

    @Bind()
    private async handleCardAdding(id: string): Promise<void> {
        if (this.state.updating) {
            return;
        }

        this.setState({
            updating: true,
        });

        try {
            await this.props.onAddCard(id);
        } finally {
            this.setState({
                updating: false,
            });
        }
    }
}
