/**
 * Return true if DOM supports native datepicker
 */
export function hasNativeDatePicker(): boolean {
    const input = document.createElement("input");
    input.type = "date";
    // if not available it will be reset to "text"
    return input.type === "date";
}

/**
 * Return true if DOM supports native timepicker
 */
export function hasNativeTimePicker() {
    const input = document.createElement("input");
    input.type = "time";
    // if not available it will be reset to "text"
    return input.type === "time";
}
