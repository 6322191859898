import { ClassAttributes, HTMLAttributes } from "react";
import styled from "../theme/styled";

// tslint:disable-next-line:no-empty-interface
export interface ListUIProps { }

export type ListProps = HTMLAttributes<HTMLUListElement> & ListUIProps;

/**
 * Simple list with basic formatting
 */
const List = styled.ul<ListProps & ClassAttributes<HTMLUListElement>> `
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    -webkit-tap-highlight-color: transparent;
    padding: 8px 0;
    list-style: none;
`;

export default List;
