import Bind from "lodash-decorators/bind";
import React, { ComponentClass, createRef, PureComponent, RefObject } from "react";
import DatePicker, { DatePickerProps } from "react-date-picker";
import { CSSTransition } from "react-transition-group";
import Popover from "../popover/popover";
import styled from "../theme/styled";
import { forwardRef, WithForwardedRef } from "../utils/ref";
import Input, { InputProps } from "./input";

const PortalStyledPopover = styled(Popover)`
    > .react-date-picker__calendar {
        z-index: ${props => props.theme.zIndex.popover};

        &.transition-enter {
            transform: scaleY(0);
            opacity: 0;
            transform-origin: top center;
        }

        &.transition-enter-active {
            opacity: 1;
            transform: scaleY(1);
            transition: opacity 0.2s ease transform 0.2s ease;
        }
    }
`;

// Hacky workaround to render calendar through portal
// see https://github.com/wojtekmaj/react-date-picker/issues/91
export class FixedReactDatePicker extends (DatePicker as any as ComponentClass<DatePickerProps & { containerId: string }, { isOpen: boolean }>) {
    public componentDidMount(): void {
        // datepicker binds global listener to close calendar on outside click
        // since we have uiOnRequestClose we don't need it
        // ignore
    }

    public componentWillUnmount(): void {
        // same as in componentDidMount()
    }

    public renderCalendar(): JSX.Element | null {
        const { containerId } = this.props;
        // tslint:disable-next-line:no-string-literal
        const calendarElem: JSX.Element | null = super["renderCalendar"]();
        if (!calendarElem) {
            return null;
        }
        // ac
        return (
            <CSSTransition
                timeout={200}
                exit={false}
                classNames="transition"
                unmountOnExit
                in={this.state.isOpen}
            >
                <PortalStyledPopover
                    uiTargetEl={containerId}
                    uiPosition="bottom left"
                    uiOnRequestClose={(this as any).closeCalendar}
                >
                    {calendarElem}
                </PortalStyledPopover>
            </CSSTransition>
        );
    }
}

const ReactDatePickerContainer = styled(Input)`
    * {
        outline: none;
    }

    .react-date-picker__inputGroup {
        padding-left: 0;

        > :first-child {
            visibility: visible !important;
            position: absolute !important;
            top: 0 !important;
            left: 0 !important;
            bottom: 0 !important;
            right: 0 !important;
            padding: 0 !important;
            z-index: -1 !important;
            width: 0 !important;
        }
    }

    .react-date-picker__inputGroup__input:invalid {
        background: initial;
    }

    .react-date-picker__clear-button {
        padding-top: 0;
        padding-bottom: 0;
    }

    .react-date-picker__calendar-button {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
    }

    .react-date-picker__wrapper {
        border: none;
        padding: 0;
        margin: 0;
    }

    .react-date-picker__button {
        border: none;
    }

    .react-date-picker__button__icon {
        padding: 0 4px;
    }
`;

interface PickerState {
    currentValue?: string;
}

const noop = () => { /* ignore */ };

/**
 * Date input wrapper for environments which don't supports native <input type="date" /> (Desktop Safari)
 */
class NonNativeDateInputCompopnent extends PureComponent<InputProps, PickerState> {
    private inputElem?: HTMLInputElement;

    private containerRef: RefObject<HTMLElement> = createRef();

    public constructor(props: InputProps) {
        super(props);
        this.state = {
            currentValue: props.value != null ? props.value as string : props.defaultValue as string || "",
        };
    }

    public componentDidMount(): void {
        if (!this.containerRef.current) {
            return;
        }
        const { forwardedRef } = this.props as WithForwardedRef<InputProps>;
        const inputGroup = this.containerRef.current.getElementsByClassName("react-date-picker__inputGroup")[0];
        if (inputGroup && inputGroup.children[0]) {
            this.inputElem = inputGroup.children[0] as HTMLInputElement;
            this.inputElem.oninvalid = this.handleInvalid;
            if (typeof forwardedRef === "function") {
                forwardedRef(this.inputElem);
            } else if (typeof forwardedRef === "object" && forwardedRef) {
                (forwardedRef as any).current = this.inputElem;
            }
        }
    }

    public componentWillUnmount(): void {
        const { forwardedRef } = this.props as WithForwardedRef<InputProps>;

        if (this.inputElem && forwardedRef) {
            if (typeof forwardedRef === "function") {
                forwardedRef(null);
            } else if (typeof forwardedRef === "object") {
                (forwardedRef as any).current = null;
            }
        }
    }

    public render(): JSX.Element {
        const { min, max, required, name, id, className, uiDisabled, uiError, value } = this.props;

        return (
            <ReactDatePickerContainer
                as="div"
                id={id}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                className={className}
                uiDisabled={uiDisabled}
                uiError={uiError}
                ref={this.containerRef as any}
            >
                <FixedReactDatePicker
                    value={value != null
                        ? value ? new Date(value as string) : undefined
                        : this.state.currentValue ? new Date(this.state.currentValue) : undefined
                    }
                    minDate={min ? new Date(min) : undefined}
                    maxDate={max ? new Date(max) : undefined}
                    containerId={id!}
                    name={name}
                    required={required}
                    onChange={this.handleDateChange}
                />
            </ReactDatePickerContainer>
        );
    }

    /**
     * Handle invalid event on hidden input with date value
     * @param e Native event
     */
    @Bind()
    private handleInvalid(e: Event): void {
        if (!this.inputElem) {
            return;
        }
        const { onInvalid } = this.props;
        if (onInvalid) {
            // create react-compatible event
            onInvalid({
                bubbles: e.bubbles,
                cancelable: e.cancelable,
                currentTarget: this.inputElem,
                defaultPrevented: e.defaultPrevented,
                eventPhase: e.eventPhase,
                isTrusted: e.isTrusted,
                nativeEvent: e,
                target: this.inputElem,
                timeStamp: e.timeStamp,
                type: e.type,
                preventDefault: e.preventDefault,
                persist: () => { /* ignore */},
                stopPropagation: e.stopImmediatePropagation,
                isDefaultPrevented: () => e.defaultPrevented,
                isPropagationStopped: () => e.bubbles,
            });
        }
    }

    @Bind()
    private handleFocus(e: React.FocusEvent<HTMLDivElement>): void {
        if (!this.inputElem) {
            return;
        }
        const { onFocus } = this.props;
        if (onFocus) {
            onFocus({
                ...e,
                // persist not copied
                persist: noop,
                target: this.inputElem,
                currentTarget: this.inputElem,
            });
        }
    }

    @Bind()
    private handleBlur(e: React.FocusEvent<HTMLDivElement>): void {
        if (!this.inputElem) {
            return;
        }
        if (e.target.closest(".react-calendar")) {
            // clicked on calendar, don't send blur
            return;
        }
        const { onBlur } = this.props;
        if (onBlur) {
            onBlur({
                ...e,
                // persist not copied
                persist: noop,
                target: this.inputElem,
                currentTarget: this.inputElem,
            });
        }
    }

    @Bind()
    private handleDateChange(date: Date | Date[]): void {
        const dateVal = Array.isArray(date) ? date[0] : date;
        const dateStr = dateVal ? `${dateVal.getFullYear()}-${dateVal.getMonth() + 1}-${dateVal.getDate()}` : "";
        const { value, onChange } = this.props;
        // noncontrolled input
        if (value == null) {
            this.setState({ currentValue: dateStr });
        }
        if (onChange && this.inputElem) {
            // create react compatible change event
            onChange({
                bubbles: false,
                cancelable: false,
                currentTarget: this.inputElem,
                defaultPrevented: true,
                eventPhase: 3,
                type: "change",
                timeStamp: new Date().getTime(),
                target: this.inputElem,
                isTrusted: true,
                nativeEvent: null as any,
                persist: noop,
                isDefaultPrevented: () => true,
                isPropagationStopped: () => true,
                preventDefault: noop,
                stopPropagation: noop,
            });
        }
    }

}

const NonNativeDateInput = forwardRef<HTMLInputElement, InputProps>(NonNativeDateInputCompopnent);

export default NonNativeDateInput;
