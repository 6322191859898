import Color from "color";
import React, { forwardRef, HTMLAttributes, memo, ReactElement } from "react";
import styled, { css } from "../theme/styled";
import { cleanUiProps } from "../utils/withStyledProps";

export interface FormFieldUIProps {
    /**
     * Label located on same line with control
     * @default false
     */
    uiInline?: boolean;
    /**
     * Don't show bottom form field margin
     * @default false
     */
    uiNoMargin?: boolean;
    /**
     * Label text
     */
    uiLabel?: string | ReactElement<any>;
    /**
     * Label alignment
     */
    uiLabelAlignment?: "left" | "center";
    /**
     * Don't render div for control
     * @default false
     */
    uiNoControlWrap?: boolean;
    /**
     * Set label for
     */
    uiLabelFor?: string;
    /**
     * Mark field as required
     */
    uiLabelFontSize?: string;
    /**
     * Mark field as required
     */
    uiRequired?: boolean;
    /**
     * Elements to put after control
     */
    uiAfterControl?: React.ReactNode;
    /**
     * Alignment of error messages if present
     */
    uiErrorAlignment?: "left" | "center" | "right";
    /**
     * Label flex
     */
    uiLabelFlex?: string;
}
export type FormFieldProps = HTMLAttributes<HTMLDivElement> & FormFieldUIProps;

const FormFieldComponent = memo(
    forwardRef<HTMLDivElement, FormFieldProps>((props, ref) => {
        const {
            uiInline,
            uiNoMargin,
            uiLabel,
            uiLabelFontSize,
            uiErrorAlignment,
            uiNoControlWrap,
            children,
            uiLabelFor,
            uiRequired,
            uiAfterControl,
            className,
            ...other
        } = props;
        cleanUiProps(other);
        return (
            <div {...other} className={className} ref={ref}>
                {uiLabel && (
                    <label className="label" htmlFor={uiLabelFor}>
                        {uiLabel}
                    </label>
                )}
                {uiNoControlWrap && children}
                {!uiNoControlWrap && <div className="control">{children}</div>}
                {uiAfterControl && uiAfterControl}
            </div>
        );
    })
);

/**
 * Generic form field. Supports both inline / column controls and label
 */
const FormField = styled(FormFieldComponent)`
    display: flex;
    align-items: ${(props) => (props.uiInline ? "baseline" : "stretch")};
    justify-content: flex-start;
    flex-flow: column nowrap;
    ${(props) =>
        !props.uiNoMargin &&
        css`
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        `};

    > .label {
        font-weight: 700;
        font-size: ${(props) => props.uiLabelFontSize};
        text-transform: none;
        margin-bottom: 0.28rem;
        margin-right: 0;
        text-align: ${(props) => props.uiLabelAlignment};
        ${(props) =>
            props.uiLabelFlex &&
            css`
                flex: ${props.uiLabelFlex};
            `};
    }

    > .control {
        position: relative;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }

    .error-msg {
        font-size: 0.8em;
        line-height: 24px;
        text-align: ${(props) => props.uiErrorAlignment || "center"};
        color: ${(props) =>
            Color(props.theme.colors.danger).lighten(0.5).string()};
    }

    ${({ uiRequired, theme }) =>
        uiRequired &&
        css`
            > .label {
                &::after {
                    display: inline-block;
                    vertical-align: top;
                    margin: -0.2em 0 0 0.2em;
                    content: "*";
                    color: ${theme.colors.primary};
                }
            }
        `};
    ${(props) =>
        props.uiInline &&
        css`
            flex-flow: row wrap;

            > .label {
                margin-bottom: 0;
                margin-right: 0.85em;
            }
        `};
`;
FormField.defaultProps = {
    uiErrorAlignment: "center",
    uiLabelAlignment: "left",
    uiLabelFontSize: ".92em",
};

export default FormField;
