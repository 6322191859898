import { ActionDescriptor } from "../../common";

export const CONNECT_TO_QUICKBOOK = "quickbook/connect";

export type ConnectToQuickbookAction = ActionDescriptor<typeof CONNECT_TO_QUICKBOOK,
    {
        /**
         * Account id
         */
        accountId: number;
        /**
         * OAuth code
         */
        code: string;
        /**
         * QB Realm id
         */
        realmId: string;
    }, {}>;

export interface QuickbookActionsMap {
    [CONNECT_TO_QUICKBOOK]: ConnectToQuickbookAction;
}
