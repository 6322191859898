import { Api, Req } from "@quipa/api";

export const getClients = (accountId: number, sort: "asc" | "desc", includeInactive?: boolean) =>
    Api.createAction(Api.GET_CLIENTS, { accountId, sort, includeInactive }, { authenticated: true });

export const getClient = (clientId: number) => Api.createAction(Api.GET_CLIENT, { clientId }, { authenticated: true });

export const addClient = (client: Api.AddClientAction[Req]) => Api.createAction(Api.ADD_CLIENT, client, { authenticated: true });

export const editClient = (client: Api.EditClientAction[Req]) => Api.createAction(Api.EDIT_CLIENT, client, { authenticated: true });

export const searchClient = (text: string, accountId: number, excludeId?: number, includeInactive?: boolean) =>
    Api.createAction(Api.SEARCH_CLIENTS, { text, accountId, excludeId, includeInactive }, { authenticated: true });
