import { BotInfo, BrowserInfo, NodeInfo } from "detect-browser";
import {
    FormFields,
    FormFieldsAction,
} from "../../../redux/modules/my-shop/form-fields-reducer";
import {
    FormInput,
    FormLabel,
    RadioInput,
    RadioLabel,
    RightCellWithBorder,
    TooltipPopup,
} from "../styles";
import React, { ChangeEvent, Dispatch, FC } from "react";
import {
    formatPrice,
    formatSectionTotalPrice,
    validatePricingInput,
} from "../helpers";
import { Checkbox } from "@ramble/ramble-ui";
import { useState } from "react";

interface PricingProps {
    browser: BrowserInfo | BotInfo | NodeInfo | null;
    formFields: FormFields;
    dispatch: Dispatch<FormFieldsAction>;
}

enum PricingSection {
    Retail = "Retail",
    Trade = "Trade",
}

const Pricing: FC<PricingProps> = ({ browser, formFields, dispatch }) => {
    const isItemTypeService = formFields.itemTypeId === 2;

    const formInputStyleClass = "px-1.5 py-2 ml-2 max-w-[80px]";
    const getIsMsrpRequired = () => {
        return ["discountMsrpRetail", "discountMsrpTrade"].includes(
            formFields.retailPricingMethod
        );
    };
    const [isShippingCostChecked, setIsShippingCostChecked] =
        useState<boolean>(false);
    const [isHandlingFeeChecked, setIsHandlingFeeChecked] =
        useState<boolean>(false);

    const handlePricingMethodRadioChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        keyParam?: string
    ) => {
        const pricingMethodKey = keyParam || e.currentTarget.id;
        const pricingMethodValue: string | number = e.currentTarget.value;

        const relatedPricingValueKey = pricingMethodKey.includes(
            PricingSection.Retail.toLowerCase()
        )
            ? "retailPricingValue"
            : "tradePricingValue";

        const updatedForm = {
            [pricingMethodKey]: pricingMethodValue,
            [relatedPricingValueKey]: "",
        };

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updatedForm,
        });
    };

    const toggleShippingCost = () => {
        setIsShippingCostChecked(!isShippingCostChecked);
    };
    const getIsShippingCostActive = (): boolean => {
        //for typescript
        return isShippingCostChecked || !!formFields.shippingCost;
    };
    const getIsHandlingFeeActive = (): boolean => {
        return isHandlingFeeChecked || !!formFields.handlingFeeMethod;
    };
    const isShippingCostActive = getIsShippingCostActive();
    const isHandlingFeeActive = getIsHandlingFeeActive();

    const toggleHandlingFee = () => {
        setIsHandlingFeeChecked(!isHandlingFeeChecked);
    };
    const handlePricingInputChange = (
        e: ChangeEvent<HTMLInputElement>,
        keyParam?: string,
        type: string = "dollarAmount"
    ) => {
        const updates = validatePricingInput({
            event: e,
            keyParam,
            type,
            dollarMin: 0,
            dollarMax: 99999,
        });
        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updates,
        });
    };

    const getPricingSectionPrice = (pricingSection: string) => {
        const pricingSectionLower = pricingSection.toLowerCase();

        const pricingMethodTextKey = `${pricingSectionLower}PricingMethod`;
        const pricingMethodValueKey = `${pricingSectionLower}PricingValue`;

        const pricingMethodText = formFields[pricingMethodTextKey];
        let pricingMethodValue = formFields[pricingMethodValueKey];

        let msrp = formFields.msrp;
        let cost = formFields.cost;

        if (
            ["discountMsrpRetail", "discountMsrpTrade"].includes(
                pricingMethodText
            )
        ) {
            if (msrp && pricingMethodValue) {
                msrp = +msrp;
                pricingMethodValue = +pricingMethodValue;
                const discountedVal = (pricingMethodValue / 100) * msrp;
                return formatSectionTotalPrice(msrp - discountedVal);
            } else {
                return "TBD";
            }
        }

        if (
            ["markUpCostRetail", "markUpCostTrade"].includes(pricingMethodText)
        ) {
            if (cost && pricingMethodValue) {
                cost = +cost;
                pricingMethodValue = +pricingMethodValue;
                const markUpVal = (pricingMethodValue / 100) * cost;
                return formatSectionTotalPrice(cost + markUpVal);
            } else {
                return "TBD";
            }
        }

        if (["costPlusRetail", "costPlusTrade"].includes(pricingMethodText)) {
            if (cost && pricingMethodValue) {
                cost = +cost;
                pricingMethodValue = +pricingMethodValue;
                return formatSectionTotalPrice(cost + pricingMethodValue);
            } else {
                return "TBD";
            }
        }
        return "TBD";
    };
    const handleHandleFeeRadioChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        keyParam: string,
        relatedValueKey: string
    ) => {
        const updatedForm = {
            [keyParam]: e.currentTarget.value,
            [relatedValueKey]: "",
        };

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updatedForm,
        });
    };
    const getHandlingFeeSection = () => {
        const radioGroupName = "handlingFee";
        const handlingFeeMethod = "handlingFeeMethod";
        const handlingFeeValue = "handlingFeeValue";

        const rate = "rate";
        const markUpShippingCost = "markUpShippingCost";
        const markupProductCost = "markUpProductCost";

        const isRateChecked = formFields.handlingFeeMethod === rate;
        const isMarkUpShippingChecked =
            formFields.handlingFeeMethod === markUpShippingCost;
        const isMarkUpProductChecked =
            formFields.handlingFeeMethod === markupProductCost;

        //if corresponding radios are selected, then keep the text value, else clear it
        const rateValue = isRateChecked ? formFields.handlingFeeValue : "";
        const markUpShippingValue = isMarkUpShippingChecked
            ? formFields.handlingFeeValue
            : "";
        const markUpProductValue = isMarkUpProductChecked
            ? formFields.handlingFeeValue
            : "";

        return (
            <div className="flex flex-col mt-4 ml-4">
                <div className="flex flex-row items-center mb-2">
                    <RadioLabel className="mb-2">
                        <RadioInput
                            id="handlingFeeRateRadio"
                            name={radioGroupName}
                            type="radio"
                            checked={formFields.handlingFeeMethod === rate}
                            value={rate}
                            onChange={(e) =>
                                handleHandleFeeRadioChange(
                                    e,
                                    handlingFeeMethod,
                                    handlingFeeValue
                                )
                            }
                            uiReportOnBlur={false}
                            disabled={!isHandlingFeeChecked}
                            required={isHandlingFeeChecked}
                        />
                        Rate
                    </RadioLabel>
                    <div>
                        <FormLabel htmlFor="handlingFeeRateInput">$</FormLabel>
                        <FormInput
                            id="handlingFeeRateInput"
                            name={`Handling Fee Rate Input`}
                            className={formInputStyleClass}
                            type="text"
                            value={rateValue}
                            onChange={(e) =>
                                handlePricingInputChange(e, handlingFeeValue)
                            }
                            uiReportOnBlur={false}
                            disabled={!isRateChecked}
                            required={isRateChecked}
                            uiDisabled={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                </div>
                <div className="flex flex-row items-center mb-2">
                    <RadioLabel className="mb-2">
                        <RadioInput
                            id="handlingFeeMarkUpShippingRadio"
                            name={radioGroupName}
                            type="radio"
                            checked={
                                formFields.handlingFeeMethod ===
                                markUpShippingCost
                            }
                            value={markUpShippingCost}
                            onChange={(e) =>
                                handleHandleFeeRadioChange(
                                    e,
                                    handlingFeeMethod,
                                    handlingFeeValue
                                )
                            }
                            uiReportOnBlur={false}
                            disabled={!isHandlingFeeChecked}
                            required={isHandlingFeeChecked}
                        />
                        Mark-up Shipping Cost
                    </RadioLabel>
                    <div>
                        <FormInput
                            id="handlingFeeMarkUpShippingInput"
                            name={`Handling Fee MarkUp Shipping Cost Input`}
                            className={formInputStyleClass}
                            type="text"
                            value={markUpShippingValue}
                            onChange={(e) =>
                                handlePricingInputChange(
                                    e,
                                    handlingFeeValue,
                                    "percentage"
                                )
                            }
                            uiReportOnBlur={false}
                            disabled={!isMarkUpShippingChecked}
                            required={isMarkUpShippingChecked}
                            uiDisabled={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                        <FormLabel htmlFor="handlingFeeMarkUpShippingInput">
                            %
                        </FormLabel>
                    </div>
                </div>
                <div className="flex flex-row items-center mb-2">
                    <RadioLabel className="mb-2">
                        <RadioInput
                            id="handlingFeeMarkUpProductRadio"
                            name={radioGroupName}
                            type="radio"
                            checked={
                                formFields.handlingFeeMethod ===
                                markupProductCost
                            }
                            value={markupProductCost}
                            onChange={(e) =>
                                handleHandleFeeRadioChange(
                                    e,
                                    handlingFeeMethod,
                                    handlingFeeValue
                                )
                            }
                            uiReportOnBlur={false}
                            disabled={!isHandlingFeeChecked}
                            required={isHandlingFeeChecked}
                        />
                        Mark-up Product Cost
                    </RadioLabel>
                    <div>
                        <FormInput
                            id="handlingFeeMarkUpProductInput"
                            name={`Handling Fee MarkUp Product Input`}
                            className={formInputStyleClass}
                            type="text"
                            value={markUpProductValue}
                            onChange={(e) =>
                                handlePricingInputChange(
                                    e,
                                    handlingFeeValue,
                                    "percentage"
                                )
                            }
                            uiReportOnBlur={false}
                            disabled={!isMarkUpProductChecked}
                            required={isMarkUpProductChecked}
                            uiDisabled={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                        <FormLabel htmlFor="handlingFeeMarkUpProductInput">
                            %
                        </FormLabel>
                    </div>
                </div>
            </div>
        );
    };

    const getPricingMethodSection = (pricingSection: PricingSection) => {
        const radioGroupName = `${pricingSection}Radio`;
        const pricingMethodKey = `${pricingSection.toLowerCase()}PricingMethod`; //Eg: retailPricingMethod => db field name
        const pricingValueKey = `${pricingSection.toLowerCase()}PricingValue`; //Eg: tradePricingValue => db field name

        const markUpCost = `markUpCost${pricingSection}`;
        const discountMsrp = `discountMsrp${pricingSection}`;
        const rate = `rate${pricingSection}`;
        const costPlus = `costPlus${pricingSection}`;

        const isMarkUpChecked = formFields[pricingMethodKey] === markUpCost; //Eg: formFields["retailPricingMethod"]="markUpCostRetail"
        const isDiscountMsrpChecked =
            formFields[pricingMethodKey] === discountMsrp; //Eg: formFields["tradePricingMethod"]="discountMsrpTrade"
        const isRateChecked = formFields[pricingMethodKey] === rate;
        const isCostPlusChecked = formFields[pricingMethodKey] === costPlus;

        //if corresponding radios are selected, then keep the text value, else clear it
        const markUpValue = isMarkUpChecked ? formFields[pricingValueKey] : "";
        const discountMsrpValue = isDiscountMsrpChecked
            ? formFields[pricingValueKey]
            : "";
        const rateValue = isRateChecked ? formFields[pricingValueKey] : "";
        const costPlusValue = isCostPlusChecked
            ? formFields[pricingValueKey]
            : "";

        return (
            <>
                <div>
                    <span>{pricingSection}</span>
                    <div className="flex flex-col mt-4 mb-2">
                        <div className="flex flex-row items-center mb-2">
                            <RadioLabel className="mb-2">
                                <RadioInput
                                    id={`${markUpCost}Radio`}
                                    name={radioGroupName}
                                    type="radio"
                                    checked={isMarkUpChecked}
                                    value={markUpCost}
                                    onChange={(e) =>
                                        handlePricingMethodRadioChange(
                                            e,
                                            pricingMethodKey
                                        )
                                    }
                                    uiReportOnBlur={false}
                                    disabled={false}
                                />
                                Mark-up cost
                                <div className="flex flex-row items-center">
                                    <FormInput
                                        id={`${markUpCost}Input`}
                                        name={`Markup Cost ${pricingSection} Input`}
                                        className={formInputStyleClass}
                                        type="text"
                                        value={markUpValue}
                                        onChange={(e) =>
                                            handlePricingInputChange(
                                                e,
                                                pricingValueKey,
                                                "percentage"
                                            )
                                        }
                                        uiReportOnBlur={false}
                                        disabled={!isMarkUpChecked}
                                        required={isMarkUpChecked}
                                        uiDisabled={false}
                                        autoComplete={
                                            browser && browser.name === "chrome"
                                                ? "new-password"
                                                : "off"
                                        }
                                        list="autocompleteOff"
                                    />
                                    <span>%</span>
                                </div>
                            </RadioLabel>
                        </div>
                        {isItemTypeService ? (
                            <div className="flex flex-row items-center mb-2">
                                <RadioLabel className="mb-2">
                                    <RadioInput
                                        id={`${costPlus}Radio`}
                                        name={radioGroupName}
                                        type="radio"
                                        checked={isCostPlusChecked}
                                        value={costPlus}
                                        onChange={(e) =>
                                            handlePricingMethodRadioChange(
                                                e,
                                                pricingMethodKey
                                            )
                                        }
                                        uiReportOnBlur={false}
                                        disabled={false}
                                    />
                                    Cost+
                                    <div className="flex flex-row items-center ml-2">
                                        <FormLabel htmlFor={`${costPlus}Input`}>
                                            $
                                        </FormLabel>
                                        <FormInput
                                            id={`${costPlus}Input`}
                                            name={`Cost Plus ${pricingSection} Input`}
                                            className={formInputStyleClass}
                                            type="text"
                                            value={costPlusValue}
                                            onChange={(e) =>
                                                handlePricingInputChange(
                                                    e,
                                                    pricingValueKey
                                                )
                                            }
                                            uiReportOnBlur={false}
                                            disabled={!isCostPlusChecked}
                                            required={isCostPlusChecked}
                                            uiDisabled={false}
                                            autoComplete={
                                                browser &&
                                                browser.name === "chrome"
                                                    ? "new-password"
                                                    : "off"
                                            }
                                            list="autocompleteOff"
                                        />
                                    </div>
                                </RadioLabel>
                            </div>
                        ) : (
                            <div className="flex flex-row items-center mb-2">
                                <RadioLabel className="mb-2">
                                    <RadioInput
                                        id={`${discountMsrp}Radio`}
                                        name={radioGroupName}
                                        type="radio"
                                        checked={isDiscountMsrpChecked}
                                        value={discountMsrp}
                                        onChange={(e) =>
                                            handlePricingMethodRadioChange(
                                                e,
                                                pricingMethodKey
                                            )
                                        }
                                        uiReportOnBlur={false}
                                        disabled={false}
                                    />
                                    Discount MSRP
                                    <div className="flex flex-row items-center">
                                        <FormInput
                                            id={`${discountMsrp}Input`}
                                            name={`Discount MSRP ${pricingSection} Input`}
                                            className={formInputStyleClass}
                                            type="text"
                                            value={discountMsrpValue}
                                            onChange={(e) =>
                                                handlePricingInputChange(
                                                    e,
                                                    pricingValueKey,
                                                    "percentage"
                                                )
                                            }
                                            uiReportOnBlur={false}
                                            disabled={!isDiscountMsrpChecked}
                                            required={isDiscountMsrpChecked}
                                            uiDisabled={false}
                                            autoComplete={
                                                browser &&
                                                browser.name === "chrome"
                                                    ? "new-password"
                                                    : "off"
                                            }
                                            list="autocompleteOff"
                                        />
                                        <span>%</span>
                                    </div>
                                </RadioLabel>
                            </div>
                        )}
                        <div className="flex flex-row items-center mb-2">
                            <RadioLabel className="mb-2">
                                <RadioInput
                                    id={`${rate}Radio`}
                                    name={radioGroupName}
                                    type="radio"
                                    checked={isRateChecked}
                                    value={rate}
                                    onChange={(e) =>
                                        handlePricingMethodRadioChange(
                                            e,
                                            pricingMethodKey
                                        )
                                    }
                                    uiReportOnBlur={false}
                                    disabled={false}
                                />
                                Rate
                            </RadioLabel>
                            <div>
                                <FormLabel htmlFor={`${rate}Input`}>
                                    $
                                </FormLabel>
                                <FormInput
                                    id={`${rate}Input`}
                                    name={`Rate ${pricingSection} Input`}
                                    className={formInputStyleClass}
                                    type="text"
                                    value={rateValue}
                                    onChange={(e) =>
                                        handlePricingInputChange(
                                            e,
                                            pricingValueKey
                                        )
                                    }
                                    uiReportOnBlur={false}
                                    disabled={!isRateChecked}
                                    required={isRateChecked}
                                    uiDisabled={false}
                                    autoComplete={
                                        browser && browser.name === "chrome"
                                            ? "new-password"
                                            : "off"
                                    }
                                    list="autocompleteOff"
                                />
                            </div>
                        </div>

                        <div className="flex flex-row mt-5 bg-twBackDropColor justify-center items-center w-[90%]">
                            <span className="mr-4 text-lg">
                                {`${pricingSection} Price`}{" "}
                            </span>
                            <span className="text-lg">
                                {getPricingSectionPrice(pricingSection)}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="flex flex-wrap w-full items-start">
            <div className="lg:w-2/4 mb-6 pr-6">
                <div className="flex items-center font-bold tracking-wider place-content-between mt-4 mb-4 text-twBlue1">
                    PRICING INFORMATION
                </div>
                <div className="flex flex-row items-center mb-2">
                    <FormLabel className="mr-3" htmlFor="cost">
                        Cost$
                    </FormLabel>
                    <FormInput
                        id="cost"
                        name="Item Cost"
                        className="px-1.5 py-2"
                        type="text"
                        value={formatPrice(formFields.cost)}
                        onChange={handlePricingInputChange}
                        uiReportOnBlur={false}
                        disabled={false}
                        uiDisabled={false}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                    <TooltipPopup
                        text={
                            <p className="font-normal">
                                If the cost varies, leave this field blank.
                                <br />
                                You will be prompted to input the cost for each
                                project when selecting this product.
                            </p>
                        }
                        position="top right"
                        className="floatRight"
                        size="250"
                    />
                </div>
                {!isItemTypeService && (
                    <div className="flex flex-row items-center mb-2">
                        <FormLabel className="mr-2" htmlFor="itemNumber">
                            MSRP$
                        </FormLabel>
                        <FormInput
                            id="msrp"
                            name="Item MSRP"
                            className="px-1.5 py-2"
                            type="text"
                            value={formatPrice(formFields.msrp)}
                            onChange={handlePricingInputChange}
                            uiReportOnBlur={false}
                            disabled={false}
                            uiDisabled={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            required={getIsMsrpRequired()}
                            list="autocompleteOff"
                        />
                    </div>
                )}

                <div className="mt-4">
                    <span className="m-0">
                        How would you like to price this item?
                    </span>
                    <div className="mt-5 flex flex-row">
                        <div className="w-2/4 mb-6 pr-6">
                            {getPricingMethodSection(PricingSection.Retail)}
                        </div>
                        <RightCellWithBorder>
                            {getPricingMethodSection(PricingSection.Trade)}
                        </RightCellWithBorder>
                    </div>
                    {formFields.itemTypeId === 1 && (
                        <div className="mt-4">
                            <div className="flex flex-col">
                                <span>Shipping & Handling</span>
                                <span className="mt-4">Bill to Client:</span>
                            </div>
                            <div className="flex flex-row mt-2">
                                <Checkbox
                                    checked={isShippingCostActive}
                                    onClick={toggleShippingCost}
                                    className="font-normal text-base mr-5"
                                >
                                    Shipping Cost
                                </Checkbox>
                                <div>
                                    <FormLabel htmlFor="shippingCostInput">
                                        $
                                    </FormLabel>
                                    <FormInput
                                        id="shippingCostInput"
                                        name="Shipping Cost Input"
                                        type="text"
                                        value={formFields.shippingCost}
                                        onChange={(e) =>
                                            handlePricingInputChange(
                                                e,
                                                "shippingCost"
                                            )
                                        }
                                        uiReportOnBlur={false}
                                        disabled={!isShippingCostActive}
                                        required={isShippingCostActive}
                                        uiDisabled={false}
                                        autoComplete={
                                            browser && browser.name === "chrome"
                                                ? "new-password"
                                                : "off"
                                        }
                                        list="autocompleteOff"
                                        className="max-w-[80px]"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col mt-2">
                                <Checkbox
                                    checked={isHandlingFeeActive}
                                    onClick={toggleHandlingFee}
                                    className="font-normal text-base mr-5"
                                >
                                    Handling Fee
                                </Checkbox>
                                {getHandlingFeeSection()}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Pricing;
