import React, { PureComponent } from "react";
import styled, { css, theme } from "../../../theme";

import { ActionMenuItem } from "../../clients/action_menu_item";
import Bind from "lodash-decorators/bind";
import { FaChevronDown } from "react-icons/fa";
import { MyShopItem } from "../my-shop";
import { TransitionPopover } from "@ramble/ramble-ui";

interface ContainerUIProps {
    uiActive?: boolean;
}

const Container = styled.div<ContainerUIProps>`
    flex: 0 0 auto;
    font-size: 14px;
    min-width: 100px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    border: 1px solid #3b97b1;
    border-radius: 8px;
    z-index: 10;
    color: #3b97b1;
    height: 40px;
    position: relative;
    ${(props) =>
        props.uiActive &&
        css`
            background: #e2edf3;
        `};

    &:hover {
        background: #e2edf3;
    }
`;

const ActionPopover = styled(TransitionPopover)`
    z-index: 9;
`;

const DropdownContainer = styled.div`
    background: white;
    font-weight: 200;

    z-index: 1;
    border: 1px solid #969696;
    padding: 5px 0 15px;

    &.transition-enter,
    &.transition-appear {
        opacity: 1;
        transform: scaleY(0.1);
        transform-origin: 100% 0;
    }

    &.transition-enter-active,
    &.transition-appear-active {
        transform: scaleY(1);
        transition: transform 0.1s ease;
    }

    &.transition-exit {
        opacity: 1;
        transform: scaleY(1);
        transform-origin: 100% 0;
    }

    &.transition-exit-active {
        opacity: 0.4;
        transform: scaleY(0.1);
        transition: transform 0.1s ease, opacity 0.1s ease;
    }

    > div:last-child {
        border-bottom: none;
    }
`;

const Label = styled.div`
    padding: 0 0.5em 0 1em;
    height: 100%;
    display: flex;
    align-items: center;

    > svg {
        fill: currentColor;
        margin-left: 0.4em;
        font-size: 0.9em;
    }
`;

const GuideHint = styled.span`
    margin: 0 20px;
    display: flex;
    align-items: center;
    padding: 3px;
    transition: background 0.1s ease;
    cursor: default;
    text-align: left;
    margin-top: 8px;
    font-style: italic;
    color: ${theme.colors.primary};
`;

export interface ActionMenuProps {
    className?: string;
    label: string;
    isProjectsChecked: boolean;
    showHint: boolean;
    showArchived: boolean;
    showUnarchived: boolean;
    onItemClick(val: string): void;
    setClientProjectModal(val: boolean): void;
    checkedItems: MyShopItem[];
}

interface State {
    active: boolean;
    modalOpen: boolean;
}

export class ActionButton extends PureComponent<ActionMenuProps, State> {
    public state: State = {
        active: false,
        modalOpen: false,
    };

    private closeTimer?: any;

    public render(): JSX.Element {
        const { label } = this.props;
        const { active } = this.state;
        return (
            <Container
                className={this.props.className}
                id="action-menu"
                onMouseLeave={this.requestCloseMenu}
                onClick={this.openMenu}
                uiActive={active}
            >
                <Label>
                    {label}
                    <FaChevronDown />
                </Label>
                <ActionPopover
                    uiActive={active}
                    uiDistanceAway={-10}
                    uiOffset={18}
                    uiAutoPosition={false}
                    uiTargetEl="action-menu"
                    uiOnRequestClose={this.toggleMenu}
                    uiTransitionTimeouts={100}
                    uiPosition="bottom right"
                >
                    <DropdownContainer onMouseEnter={this.cancelCloseMenu}>
                        {this.props.showHint && (
                            <GuideHint>
                                Select row(s) to <br />
                                perform action(s)
                            </GuideHint>
                        )}
                        {this.props.isProjectsChecked && (
                            <>
                                <ActionMenuItem
                                    disabled={
                                        this.props.checkedItems.length === 0 ||
                                        this.props.checkedItems.some(
                                            (checkedItem) =>
                                                checkedItem.name === "Drapery"
                                                    ? true
                                                    : false
                                        )
                                    }
                                    label="Make Inactive"
                                    value="Make Inactive"
                                    topMargin="8px"
                                    onClick={() =>
                                        this.menuItemClick("makeInactive")
                                    }
                                />
                                <ActionMenuItem
                                    disabled={
                                        this.props.checkedItems.length === 0 ||
                                        this.props.checkedItems.length > 1
                                    }
                                    label="Edit"
                                    value="Edit"
                                    topMargin="8px"
                                    onClick={() => this.menuItemClick("edit")}
                                />
                                <ActionMenuItem
                                    disabled={
                                        this.props.checkedItems.length === 0
                                    }
                                    label="Add to Client Project"
                                    value="Add to Client Project"
                                    topMargin="8px"
                                    onClick={() => {
                                        this.props.setClientProjectModal(true);
                                        this.menuItemClick("addToClient");
                                    }}
                                />
                                {/* <ActionMenuItem
                                    label="Manage Categories"
                                    value="Manage Categories"
                                    topMargin="8px"
                                    onClick={() =>
                                        this.menuItemClick("manageCategories")
                                    }
                                /> */}
                            </>
                        )}
                    </DropdownContainer>
                </ActionPopover>
            </Container>
        );
    }

    public componentWillUnmount(): void {
        if (this.closeTimer) {
            clearTimeout(this.closeTimer);
        }
    }

    @Bind()
    private openMenu() {
        this.setState({ active: true });
        this.cancelCloseMenu();
    }

    @Bind()
    private requestCloseMenu() {
        if (this.closeTimer) {
            clearTimeout(this.closeTimer);
        }
        this.closeTimer = setTimeout(this.closeMenu, 1000);
    }

    @Bind()
    private cancelCloseMenu() {
        if (this.closeTimer) {
            clearTimeout(this.closeTimer);
            this.closeTimer = undefined;
        }
    }

    @Bind()
    private closeMenu() {
        this.setState({ active: false });
        this.closeTimer = undefined;
    }

    @Bind()
    private toggleMenu(): void {
        if (!this.state.active) {
            this.setState({
                active: !this.state.active,
            });
        }
    }

    @Bind()
    private menuItemClick(val: string): void {
        const { onItemClick } = this.props;
        this.setState({
            active: false,
        });
        onItemClick(val);
    }
}
