import logoImg from "../../assets/img/QUIPA_Custom_Design_Hub_Logo_Cropped.png";
import styled from "../../theme";

export const Logo = styled.img`
    width: 100%;
    max-width: 425px;
    height: auto;
    flex: 1 0 auto;
    margin: 0 auto;

    @media screen and (max-height: 800px) {
        width: auto;
        height: 150px;
    }
`;

Logo.defaultProps = {
    src: logoImg,
};
