import React, { PureComponent } from "react";
// import { Client, ClientAddressDetails} from "../../redux/modules/client";
import styled, { css, theme } from "../../theme";

import Bind from "lodash-decorators/bind";
import { ClientModule } from "../../redux";
// import { AvatarImage } from "../UI/avatar";

export const getParentClients = (client?: ClientModule.ParentClient): ClientModule.ParentClient[] =>
    !client
       ? []
       : ([] as ClientModule.ParentClient[]).concat(getParentClients(client.parent)).concat(client);

const ClientBox = styled.div<UiClientHoverBoxProps>`
    position: relative;
    cursor: pointer;
    display: inline;

    &:hover {
        color: #41a1dc;
    }

    ${props => (props.type === "child" || props.type === "contact") && css`
        padding: 3px 9px 3px 0;
        flex: 1;
    `};
`;

const DisplayName = styled.span<UiClientHoverBoxProps>`
    font-size: 20px;
    font-weight: 400;
    color: #9e999f;
    word-break: break-all;
    ${props => props.type === "child" && css`
        font-size: 15px;
        color: rgba(0,0,0,0.87);
    `};
    ${props => props.type === "contact" && css`
        font-size: 15px;
    `};
    ${props => props.type === "draperyOrder" && css`
        font-size: 16pt;
        color: ${theme.colors.primary};
    `};
`;

// const TooltipBox = styled.div`
//     position: fixed;
//     display: flex;
//     z-index: 999;
//     background-color: #fff;
//     box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
//     border-radius: 10px;
//     padding: 14px 21px;
//     color: #4a4a4a;
//     font-size: 1rem;
// `;

// const InfoBox = styled.div`
//     margin-left: 18px;
// `;

// const InfoItem = styled.div`
//     margin: 3px 0;
// `;

interface UiClientHoverBoxProps {
    type?: "parent" | "child" | "contact" | "draperyOrder";
}

interface ClientHoverBoxProps extends UiClientHoverBoxProps {
    client?: ClientModule.BasicClientInfo;
    additionalContact?: ClientModule.ClientAddressDetails;
    onClick?(id: number): void;
}

interface State {
    isClientHovered: boolean;
}

export class ClientHoverBox extends PureComponent<ClientHoverBoxProps, State> {
    public state: State = {
        isClientHovered: false,
    };

    public render(): JSX.Element {
        const { additionalContact, client } = this.props;
        return (
            <ClientBox
                type={this.props.type}
                onMouseLeave={this.hideBox}
                onClick={this.handleClick}
            >
                <DisplayName
                    type={this.props.type}
                    onMouseOver={this.showBox}
                >
                    {client && client.displayName}
                    {additionalContact && `${additionalContact.firstName} ${additionalContact.lastName}`}
                </DisplayName>

                {/*this.state.isClientHovered && (
                    <TooltipBox>
                        <AvatarImage
                            avatarId={client && client.avatarId}
                            firstName={client && client.firstName || additionalContact && additionalContact.firstName}
                            lastName={client && client.lastName || additionalContact && additionalContact.lastName}
                            width={69}
                            height={69}
                            fontSize={38}
                        />

                        <InfoBox>
                            <InfoItem>
                                {client && client.displayName}
                                {additionalContact && `${additionalContact.firstName} ${additionalContact.lastName}`}
                            </InfoItem>
                            <InfoItem>
                                {client && client.email || ""}
                                {additionalContact && additionalContact.email || ""}
                            </InfoItem>
                            <InfoItem>
                                {client && (client.cellPhone || client.officePhone || client.homePhone || "")}
                                {additionalContact && (additionalContact.cellPhone || additionalContact.officePhone || additionalContact.homePhone || "")}
                            </InfoItem>
                        </InfoBox>
                    </TooltipBox>
                )*/}
            </ClientBox>
        );
    }

    @Bind()
    private hideBox(): void {
        this.setState({isClientHovered: false});
    }

    @Bind()
    private showBox(): void {
        this.setState({isClientHovered: true});
    }

    @Bind()
    private handleClick(): void {
        if (this.props.onClick && this.props.client) {
            this.props.onClick(this.props.client.id);
        }
        if (this.props.onClick && this.props.additionalContact) {
            this.props.onClick(0);
        }
    }
}

export default ClientHoverBox;
