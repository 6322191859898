import Bind from "lodash-decorators/bind";
import React, { PureComponent } from "react";
import styled, { theme } from "../../theme";

const AdminWOBox = styled.div`
    position: relative;
    cursor: pointer;
    display: inline;
`;

const WOLimitBox = styled.span`
    color: ${theme.colors.primary};
    word-break: break-all;
`;

const TooltipBox = styled.div`
    position: fixed;
    display: flex;
    z-index: 999;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 15px 15px;
    color: #000;
`;

const InfinSymbolText = styled.span`
    font-size: 20px;
`;

const InfoBox = styled.div`
    margin: 5px;
`;

const InfoItem = styled.div`
    text-align: center;
    margin: 0;
`;

interface AdminWOHoverBoxProps {
    monthlyCount: number;
    planLimit: number;
    lifetimeCount: number;
}

interface State {
    isHovered: boolean;
}

export class AdminWOHoverBox extends PureComponent<AdminWOHoverBoxProps, State> {
    public state: State = {
        isHovered: false,
    };

    public render(): JSX.Element {
        const { monthlyCount, planLimit, lifetimeCount } = this.props;
        const planLimitContent = planLimit !== -1 ? planLimit : <InfinSymbolText>&infin;</InfinSymbolText>;
        return (
            <AdminWOBox
                onMouseLeave={this.hideBox}
            >
                <WOLimitBox
                    onMouseOver={this.showBox}
                >
                    {lifetimeCount}
                </WOLimitBox>

                {this.state.isHovered && (
                    <TooltipBox>
                        <InfoBox>
                            <InfoItem>
                                {monthlyCount + " / "}{planLimitContent}
                            </InfoItem>
                            <InfoItem>
                                {lifetimeCount}
                            </InfoItem>
                        </InfoBox>
                    </TooltipBox>
                )}
            </AdminWOBox>
        );
    }

    @Bind()
    private hideBox(): void {
        this.setState({isHovered: false});
    }

    @Bind()
    private showBox(): void {
        this.setState({isHovered: true});
    }
}

export default AdminWOHoverBox;
