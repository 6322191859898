import { ActionDescriptor } from "../../common";
import { UserInfoForAdmin, UserSubscriptionInfo } from "./interfaces";

export const SEARCH_USERS_FOR_ADMIN = "admin/SEARCH_USERS";

export type SearchUsersAction = ActionDescriptor<typeof SEARCH_USERS_FOR_ADMIN, {
    /**
     * Search text
     */
    text: string;
    /**
     * USer sorting order. Default to asc if not set
     */
    sort: "asc" | "desc";
}, UserInfoForAdmin[]>;

export const GET_USER_SUBSCRIPTION_HISTORY = "admin/GET_USER_SUBSCRIPTION_HISTORY";

export type GetUserSubscriptionHistoryAction = ActionDescriptor<typeof GET_USER_SUBSCRIPTION_HISTORY, {
    /**
     * Account Id
     */
    accountId: number;
}, UserSubscriptionInfo[]>;

export const EDIT_USER_ROLE = "admin/EDIT_USER_ROLE";
export type EditUserRoleAction = ActionDescriptor<typeof EDIT_USER_ROLE, {
    userId: number;
    role: "admin" | "office_worker";
}, {}>;

export interface AdminActionsMap {
    [SEARCH_USERS_FOR_ADMIN]: SearchUsersAction;
    [GET_USER_SUBSCRIPTION_HISTORY]: GetUserSubscriptionHistoryAction;
    [EDIT_USER_ROLE]: EditUserRoleAction;
}
