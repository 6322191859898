import * as styledComponents from "styled-components";
import { ThemeInterface } from "./theme";

export type PropsWithTheme<P extends object> = P & { theme: ThemeInterface };

const {
    default: styled,
    createGlobalStyle,
    css,
    keyframes,
    withTheme,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<ThemeInterface>;

export { css, createGlobalStyle, keyframes, withTheme };

export default styled;
