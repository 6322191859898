import Bind from "lodash-decorators/bind";
import React, { PureComponent, RefObject } from "react";
import { CSSTransition } from "react-transition-group";
import { CSSTransitionProps } from "react-transition-group/CSSTransition";
import Popover, { PopoverUIProps, Position } from "../popover/popover";
import styled, { css } from "../theme/styled";
import { forwardRef, WithForwardedRef } from "../utils/ref";
import PopupElement, {
    PopupElementProps,
    PopupElementUIProps,
} from "./popup_element";

export interface PopupUIProps extends PopoverUIProps, PopupElementUIProps {
    /**
     * Popup is active
     */
    uiActive: boolean;
    /**
     * Transition timeouts
     */
    uiTransitionTimeouts?: CSSTransitionProps["timeout"];
    /**
     * Popup element reference
     */
    uiElementRef?: RefObject<HTMLDivElement>;
}

export type PopupProps = PopupElementProps & PopupUIProps;

interface State {
    /**
     * Current popup position.
     * This may differ with given position since the popup can be auto-positioned
     */
    currentPosition: Position;
}

class PopupComponent extends PureComponent<PopupProps, State> {
    public static defaultProps: Partial<PopupProps> = {
        uiPosition: "top left",
        uiAutoPosition: true,
        uiInverted: false,
        uiTransitionTimeouts: {
            enter: 200,
            exit: 100,
        },
    };

    /**
     * Creates an instance of InfoPopup.
     * @param props
     */
    public constructor(props: PopupProps) {
        super(props);
        this.state = {
            currentPosition: props.uiPosition!,
        };
    }

    public componentWillReceiveProps(nextProps: PopupProps): void {
        if (nextProps.uiPosition) {
            this.setState({ currentPosition: nextProps.uiPosition });
        }
    }

    /**
     * Render
     *
     * @returns
     */
    public render(): JSX.Element | null {
        const {
            uiActive,
            children,
            uiTransitionTimeouts,
            uiTargetEl,
            uiPosition,
            uiAutoPosition,
            uiOffset,
            uiDistanceAway,
            uiOnRequestClose,
            uiOnClose,
            uiOnOpen,
            uiElementRef,
            forwardedRef,
            ...other
        } = this.props as WithForwardedRef<PopupProps>;
        if (!uiTargetEl) {
            // tslint:disable-next-line:no-null-keyword
            return null;
        }
        return (
            <CSSTransition
                timeout={uiTransitionTimeouts!}
                appear
                mountOnEnter
                unmountOnExit
                in={uiActive}
                classNames="transition"
            >
                <Popover
                    uiTargetEl={uiTargetEl!}
                    uiDistanceAway={uiDistanceAway}
                    uiOffset={uiOffset}
                    uiPosition={
                        window.location.pathname.includes("/suppliers") ||
                        window.location.pathname.includes("/calculator")
                            ? "top center"
                            : this.state.currentPosition
                    }
                    uiAutoPosition={uiAutoPosition}
                    uiOnChangePosition={this.onChangePosition}
                    uiOnClose={uiOnClose}
                    uiOnOpen={uiOnOpen}
                    uiOnRequestClose={uiOnRequestClose}
                    ref={forwardedRef}
                >
                    <PopupElement
                        {...other}
                        uiPosition={
                            window.location.pathname.includes("/suppliers") ||
                            window.location.pathname.includes("/calculator")
                                ? "top center"
                                : this.state.currentPosition
                        }
                        ref={uiElementRef}
                    >
                        {children}
                    </PopupElement>
                </Popover>
            </CSSTransition>
        );
    }

    /**
     * Popup was auto-positioned
     *
     * @protected
     * @param newPos
     */
    @Bind()
    protected onChangePosition(newPos: Position): void {
        this.setState({ currentPosition: newPos });
    }
}

const ForwardPopup = forwardRef<Popover, PopupProps>(PopupComponent);

export const defaultPopupTransition: any = css`
    &.transition-enter,
    &.transition-appear {
        opacity: 0.1;
        transform: scale(0.2);
        /* abc */
    }

    &.transition-enter-active,
    &.transition-appear-active {
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.1s ease, transform 0.2s ease;
    }

    &.transition-exit {
        opacity: 1;
        transform: scale(1);
    }

    &.transition-exit-active {
        opacity: 0.1;
        transform: scale(0.4);
        transition: opacity 0.1s ease, transform 0.1s ease;
    }
`;

/**
 * A Popup
 */
const Popup = styled(ForwardPopup)`
    ${defaultPopupTransition};
`;

export default Popup;
