import React from "react";

interface TitleInterface {
    title: string;
    isActive?: string;
}
const rowHeight = 14;

const SubtitleSection = ({ title, isActive }: TitleInterface) => {
    return (
        <div
            className={`grid grid-cols-12 h-${rowHeight} border-t-0 border border-solid border-[#e2e2e2] bg-[#F6F6F6] divide-x divide-black/5 ${
                !isActive && "text-twGrey italic"
            }`}
        >
            <div className="col-span-1 flex"></div> 
            <div className="self-center items-center col-span-3 flex h-full">
                <p className="self-center font-medium pl-8 text-md text-[#545454] text-[18px] font-medium" style={{fontWeight:'500'}}>{title || ""} {!isActive && "(inactive)"}</p>
            </div>
            <div className="col-span-1 flex"></div>
            <div className="col-span-2 flex"></div>
            <div className="col-span-3 flex"></div>
            <div className="col-span-2 flex"></div>
        </div>
    );
};

export default SubtitleSection;
