import React from "react";
import ReactDOM from "react-dom";
/**
 * Return true if given node is in given root node
 * @param node
 * @param root
 * @returns
 */
export function isNodeInRoot(node: Node | null, root: Element): boolean {
    while (node) {
        if (node === root) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}

/**
 * Check if root element contains related element
 *
 * @export
 * @param rootElement HTML element or React instance
 * @param relatedElement Related element
 * @returns
 */
export function isElementContainsAnother(rootElement: React.ReactInstance | undefined | null, relatedElement: Element): boolean {
    if (rootElement) {
        const el = rootElement instanceof Element ? rootElement : ReactDOM.findDOMNode(rootElement);
        if (el instanceof Element && el.contains(relatedElement)) {
            return true;
        }
    }
    return false;
}

/**
 * Return true if given event target is related to given id or any descendants
 */
export function eventRelatedToId(id: string, event?: Event | React.SyntheticEvent<any>): boolean {
    if (!event || !(event.target instanceof Element)) {
        return false;
    }
    const el = event.target.closest("#" + id);
    return !!el;
}

export function downloadBlob(blob: Blob, name: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}
