import { Button, TextArea } from "@ramble/ramble-ui";
import {
    CalculatorCheckbox,
    CalculatorFieldInput,
    CalculatorFormLabel,
    CalculatorInput,
    CalculatorRadio,
    CalculatorRadioGroup,
    CalculatorRadioWrapper,
    HContainer,
    PrependAppendInput,
    PrependAppendInputGroup,
    PrependAppendInputGroupLabel,
    StyleSelectContainer,
    TextAreaContainer,
    TooltipContainer,
    TooltipHeader,
    TooltipImgContainer,
    TooltipPopup,
    TooltipText,
    TooltipVertStrips,
    VContainer,
} from "./styles";
import { DraperyOrderDesignEmbellishment, highlightStyle } from "./calculator";
import React, { ChangeEvent, Component } from "react";
import styled, { css } from "../../theme";

import SelectList from "../UI/select-list";

const numberToString = (value: string | number | undefined): string =>
    value === undefined ? "" : `${value}`;
const embellishmentTitle = (stateName: string): string => {
    if (stateName === "bandings") {
        return "banding";
    }
    if (stateName === "cordings") {
        return "cording";
    }
    if (stateName === "flanges") {
        return "flange";
    }
    if (stateName === "trims") {
        return "trim";
    }
    if (stateName === "othersEmbellishments") {
        return "Other Embellishments";
    }
    return "";
};
const embellishmentTypeTitle = (stateName: string): string[] => {
    if (stateName === "cordings") {
        return ["Pre-made cording", "Welt cording (from fabric)"];
    }
    if (stateName === "flanges") {
        return ["Pre-made flange", "We will make flange from fabric"];
    }
    if (stateName === "trims") {
        return ["Pre-made Trim", "We will make trim from fabric"];
    }
    return [];
};

const AdditionalFormBlock = styled.div`
    background-color: #fff;
    width: 100%;
    border-radius: 0.7em;
    position: relative;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
    .CordingType {
        color: #545454;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-left: 21px;
   }
`;

const AdditionalFormHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    color: #3b97b1;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 50px;
    text-transform: uppercase;
    margin-bottom: 19px;
    background: #d6eaf0;
    padding-left: 21px;
`;

const SaveBtn = styled(Button)`
    box-shadow: none;
    border: 1px solid #c6c6c6;
    border-radius: 0.5em;
    display: block;
    text-transform: capitalize;
    margin-top: 15px;
`;

const DeleteBtn = styled(Button)`
    border-radius: 3px;
    border: 0;
    color: #fff;
    cursor: pointer;
    outline-color: #fff0;
    padding: 10px;
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 30px;
    width: 30px;
    height: 30px;
`;
const CalculatorFieldInputName = styled(CalculatorFieldInput)`
    width: 40%;
`;
const CalculatorFieldInputPrice = styled(CalculatorFieldInput)`
    @media (max-width: 1000px) {
        justify-content: space-between;
    }
`;

interface EmbellishmentCellProps {
    uiCenter?: boolean;
    noPaddingTop?: boolean;
    noPaddingBottom?: boolean;
}

const EmbellishmentCell = styled(VContainer)<EmbellishmentCellProps>`
    grid-template-rows: auto;
    column-gap: 0;
    word-break: break-word;
    align-items: center;
    padding-top: ${(props) => (props.noPaddingTop ? 0 : 10)}px;
    padding-bottom: ${(props) => (props.noPaddingBottom ? 0 : 19)}px;
    &:hover {
        background-color: inherit;
    }

    ${(props) =>
        props.uiCenter &&
        css`
            justify-content: center;
        `}
    > :first-child {
        border: none;
    }
`;

const LocationContainer = styled(EmbellishmentCell)`
    flex: 1.4;
    border-left: none;
    border-top: none;
    > div {
        display: flex;
    }

    .title {
        color: #3b97b1;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin-bottom: 30px;
    }
`;

const InsetContainer = styled(EmbellishmentCell)`
    flex: 1.7;
    border-top: none;

    .title {
        color: #3b97b1;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin-bottom: 30px;
    }
`;

const WidthContainer = styled(EmbellishmentCell)`
    flex: 1.5;
    border-top: none;

    > div {
        display: flex;

        .title {
            border: none;
        }
    }

    .title {
        color: #3b97b1;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin-bottom: 30px;
    }
`;

const TotalStripsContainer = styled(EmbellishmentCell)`
    flex: 2.2;
    border-top: none;
    border-right: none;
    transition: all 0.2s;
    > span {
        display: flex;
    }

    .title {
        color: #3b97b1;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin-bottom: 30px;
        padding-left: 30px;
    }
`;

const TotalStripsSpacer = styled(TotalStripsContainer)`
    margin-right: 0px;
`;

const TableContainer = styled(HContainer)`
    padding: 0;
    margin: 0;
`;

const AdditionalPrice = styled(HContainer)`
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 19px;
    align-items: flex-start;

    label {
        width: 100%;
        margin-right: 0;
        margin-bottom: 5px;
    }

    input {
        flex: 1;
    }
`;

interface Props {
    targetArray: string;
    targetArrayIndex: number;
    embellishment: DraperyOrderDesignEmbellishment;
    differentiate: number | boolean;
    disabled: boolean;
    highlightedSection: string | null | undefined;

    addEmbellishment(stateName: string): void;
    deleteEmbellishment(stateName: string, index: number): void;
    handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void;
    handleInputChangeForNumberPrice(e: ChangeEvent<HTMLInputElement>): void;

    handleInputChangeForString(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void;
    handleInputChangeForBoolean(e: ChangeEvent<HTMLInputElement>, arr?: string, index?: number): void;
    handleSelectListChange(value: string | number, name?: string): void;
    handleInputBlur?(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
    updateStateByName(
        name: string,
        value: string | number | boolean | number[] | undefined
    ): void;
}

class AdditionalForm extends Component<Props> {
    public constructor(props: Props) {
        super(props);

        this.state = {};
    }

    public render(): JSX.Element {
        const {
            targetArray,
            targetArrayIndex,
            embellishment,
            highlightedSection,
        } = this.props;

        const showWidthColumn =
            targetArray === "bandings" ||
            ((targetArray === "cordings" ||
                targetArray === "flanges" ||
                targetArray === "trims") &&
                embellishment.subType === 1);
        const title = embellishmentTitle(targetArray);
        const typeTitle = embellishmentTypeTitle(targetArray);

        return (
            <AdditionalFormBlock>
                <AdditionalFormHeader>
                    {title} Details {this.props.differentiate}
                    <DeleteBtn
                        uiColor="primary"
                        type="button"
                        onClick={this.handleDeleteButton}
                        disabled={this.props.disabled}
                        uiDisabled={this.props.disabled}
                        style={{ backgroundColor: "inherit", color: "#3B97B1" }}
                    >
                        &times;
                    </DeleteBtn>
                </AdditionalFormHeader>
                {typeTitle.length >= 2 && (
                    <HContainer
                        style={{ alignItems: "center", marginBottom: "26px" }}
                    >
                        <span className="CordingType">Type: </span>
                        <CalculatorRadioGroup
                            style={
                                highlightedSection ===
                                `${targetArray}.${targetArrayIndex}.subType`
                                    ? highlightStyle
                                    : {}
                            }
                        >
                            <CalculatorRadioWrapper>
                                <CalculatorRadio
                                    type="radio"
                                    id={`${targetArray}.${targetArrayIndex}.subType0`}
                                    name={`${targetArray}.${targetArrayIndex}.subType`}
                                    value={0}
                                    checked={embellishment.subType === 0}
                                    onChange={this.handleSubType}
                                    disabled={this.props.disabled}
                                    uiDisabled={this.props.disabled}
                                />
                                {typeTitle && typeTitle[0]}
                            </CalculatorRadioWrapper>
                            <CalculatorRadioWrapper>
                                <CalculatorRadio
                                    type="radio"
                                    id={`${targetArray}.${targetArrayIndex}.subType1`}
                                    name={`${targetArray}.${targetArrayIndex}.subType`}
                                    value={1}
                                    checked={embellishment.subType === 1}
                                    onChange={this.handleSubType}
                                    disabled={this.props.disabled}
                                    uiDisabled={this.props.disabled}
                                />
                                {typeTitle && typeTitle[1]}
                            </CalculatorRadioWrapper>
                        </CalculatorRadioGroup>
                    </HContainer>
                )}
                {targetArray === "flanges" && embellishment.subType === 1 && (
                    <StyleSelectContainer
                        style={
                            highlightedSection ===
                            `${targetArray}.${targetArrayIndex}.styleType`
                                ? highlightStyle
                                : {}
                        }
                    >
                        <SelectList
                            name={`${targetArray}.${targetArrayIndex}.styleType`}
                            label={"Style"}
                            selectList={["Flat"]}
                            handleClickSelect={
                                this.props.handleSelectListChange
                            }
                            valueSelect={embellishment.styleType}
                            flexDirection="row"
                            smallPadding
                            selectWidth={150}
                            disabled={this.props.disabled}
                            noClearBox
                            uiDropArrow
                            style={{ fontSize: "14px" }}
                        />
                    </StyleSelectContainer>
                )}
                {targetArray === "othersEmbellishments" && (
                    <HContainer>
                        <CalculatorFieldInputName
                            smallPadding
                            style={
                                highlightedSection ===
                                `${targetArray}.${targetArrayIndex}.name`
                                    ? highlightStyle
                                    : {}
                            }
                        >
                            <CalculatorFormLabel
                                style={{ paddingLeft: "19px" }}
                            >
                                Name
                            </CalculatorFormLabel>
                            <CalculatorInput
                                type="text"
                                className="name-input"
                                id={`${targetArray}.${targetArrayIndex}.name`}
                                name={`${targetArray}.${targetArrayIndex}.name`}
                                value={embellishment.name || ""}
                                onChange={this.props.handleInputChangeForString}
                                disabled={this.props.disabled}
                                uiDisabled={this.props.disabled}
                                autoComplete="off"
                            />
                        </CalculatorFieldInputName>

                        <AdditionalPrice>
                            <CalculatorFieldInputPrice
                                smallPadding
                                style={
                                    highlightedSection ===
                                    `${targetArray}.${targetArrayIndex}.price`
                                        ? highlightStyle
                                        : {}
                                }
                            >
                                <CalculatorFormLabel className="fabrication-price">
                                    Additional Fabrication Price $
                                </CalculatorFormLabel>
                                <CalculatorInput
                                    type="text"
                                    className="name-input price-input"
                                    id={`${targetArray}.${targetArrayIndex}.price`}
                                    name={`${targetArray}.${targetArrayIndex}.price`}
                                    value={embellishment.price || ""}
                                    onChange={
                                        this.props
                                            .handleInputChangeForNumberPrice
                                    }
                                    disabled={this.props.disabled}
                                    uiDisabled={this.props.disabled}
                                    autoComplete="off"
                                />
                            </CalculatorFieldInputPrice>
                        </AdditionalPrice>
                    </HContainer>
                )}
                {targetArray === "othersEmbellishments" && (
                    <HContainer>
                        <TextAreaContainer style={{ paddingLeft: "19px" }}>
                            <span>Details</span>
                            <TextArea
                                id={`${targetArray}.${targetArrayIndex}.details`}
                                name={`${targetArray}.${targetArrayIndex}.details`}
                                value={embellishment.details}
                                onChange={this.props.handleInputChangeForString}
                                disabled={this.props.disabled}
                                uiDisabled={this.props.disabled}
                            />
                        </TextAreaContainer>
                    </HContainer>
                )}
                {targetArray !== "othersEmbellishments" && (
                    <>
                        <TableContainer uiStretch>
                            <LocationContainer
                                style={{ border: "none" }}
                                uiCenter
                                noPaddingBottom
                                uiRightBorder
                                uiTopBorder
                                uiLeftBorder
                                uiPaddingLeft
                            >
                                <span className="title">Location</span>
                            </LocationContainer>
                            <InsetContainer
                                style={{ border: "none" }}
                                uiCenter
                                noPaddingBottom
                                uiRightBorder
                                uiTopBorder
                                uiPaddingLeft
                            >
                                <span className="title">Inset</span>
                            </InsetContainer>
                            {showWidthColumn && (
                                <WidthContainer
                                    style={{ border: "none" }}
                                    uiCenter
                                    noPaddingBottom
                                    uiRightBorder
                                    uiTopBorder
                                    uiPaddingLeft
                                >
                                    <span className="title">
                                        {targetArray === "bandings" && `Width`}
                                        {targetArray === "cordings" && `Size`}
                                        {targetArray === "flanges" &&
                                            `Flange Width`}
                                        {targetArray === "trims" &&
                                            `Trim Width`}
                                    </span>
                                </WidthContainer>
                            )}
                            <TotalStripsContainer
                                style={{ border: "none" }}
                                uiCenter
                                noPaddingBottom
                                uiRightBorder
                                uiTopBorder
                                uiPaddingLeft
                            >
                                <span className="title noCapitalize">
                                    Total # of Vertical Strips
                                    <TooltipPopup
                                        text={
                                            <>
                                                <br />
                                                <TooltipContainer>
                                                    <TooltipImgContainer>
                                                        <TooltipVertStrips />
                                                    </TooltipImgContainer>
                                                    <TooltipHeader>
                                                        TOTAL # OF VERTICAL
                                                        STRIPS
                                                    </TooltipHeader>
                                                    <TooltipText>
                                                        Enter the total number
                                                        of <b>vertical</b>{" "}
                                                        strips you need for the
                                                        entire panel
                                                        configuration. In the
                                                        example, the total
                                                        number of{" "}
                                                        <b>vertical</b> strips
                                                        needed is 4.
                                                    </TooltipText>
                                                    <TooltipHeader />
                                                    <TooltipText>
                                                        The total number of{" "}
                                                        <b>
                                                            horizontal strips
                                                            should NOT be
                                                            included
                                                        </b>{" "}
                                                        because the horizontal
                                                        yardage calculation is
                                                        based on the number of
                                                        widths which is already
                                                        in the system.
                                                    </TooltipText>
                                                </TooltipContainer>
                                            </>
                                        }
                                        position="top right"
                                        className="floatRight"
                                        size="25px"
                                    />
                                </span>
                            </TotalStripsContainer>
                        </TableContainer>
                        <TableContainer uiStretch>
                            <LocationContainer
                                uiCenter
                                noPaddingTop
                                uiRightBorder
                                uiBottomBorder
                                uiLeftBorder
                                uiPaddingLeft
                            >
                                <PrependAppendInputGroup
                                    uiSmall
                                    style={
                                        highlightedSection ===
                                        `${targetArray}.${targetArrayIndex}.leadReturnEdge.active`
                                            ? highlightStyle
                                            : {}
                                    }
                                >
                                    <CalculatorCheckbox
                                        id={`${targetArray}.${targetArrayIndex}.leadReturnEdge.active`}
                                        name={`${targetArray}.${targetArrayIndex}.leadReturnEdge.active`}
                                        checked={
                                            embellishment.leadReturnEdge.active
                                        }
                                        onChange={(e) => {
                                            this.props.handleInputChangeForBoolean(e, targetArray, targetArrayIndex);
                                            const { checked } = e.currentTarget;

                                            if (checked) {
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.totalStrips`,
                                                    ''
                                                );
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.leadReturnEdge.insetActive`,
                                                    false
                                                );
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.leadReturnEdge.insetSize`,
                                                    ''
                                                );
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.leadReturnEdge.size`,
                                                    undefined
                                                );
                                            } else {
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.totalStrips`,
                                                    undefined 
                                                );
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.leadReturnEdge.insetActive`,
                                                    false 
                                                );
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.leadReturnEdge.insetSize`,
                                                    undefined 
                                                );
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.leadReturnEdge.size`,
                                                    undefined 
                                                );
                                            }
                                        }}
                                        disabled={this.props.disabled}
                                        uiDisabled={this.props.disabled}
                                    />
                                    <span style={{ marginTop: "3px" }}>
                                        Lead / Return
                                    </span>
                                </PrependAppendInputGroup>
                            </LocationContainer>
                            <InsetContainer
                                noPaddingTop
                                uiRightBorder
                                uiBottomBorder
                                uiPaddingLeft
                            >
                                <PrependAppendInputGroup uiSmall>
                                    <CalculatorCheckbox
                                        id={`${targetArray}.${targetArrayIndex}.leadReturnEdge.insetActive`}
                                        name={`${targetArray}.${targetArrayIndex}.leadReturnEdge.insetActive`}
                                        checked={
                                            !!embellishment.leadReturnEdge
                                                .insetActive
                                        }
                                        onChange={(e) => {
                                            this.props.handleInputChangeForBoolean(e, targetArray, targetArrayIndex);
                                            const { checked } = e.currentTarget;
                                    
                                            if (!checked) {
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.leadReturnEdge.insetSize`,
                                                    '' 
                                                );
                                            }
                                        }}
                                        disabled={
                                            this.props.disabled || 
                                            !embellishment.leadReturnEdge.active 
                                        }
                                        uiDisabled={this.props.disabled}                                       
                                        />
                                    <PrependAppendInput
                                        type="text"
                                        style={
                                            highlightedSection ===
                                            `${targetArray}.${targetArrayIndex}.leadReturnEdge.insetSize`
                                                ? highlightStyle
                                                : {}
                                        }
                                        id={`${targetArray}.${targetArrayIndex}.leadReturnEdge.insetSize`}
                                        name={`${targetArray}.${targetArrayIndex}.leadReturnEdge.insetSize`}
                                        autoComplete="off"
                                        value={
                                            embellishment.leadReturnEdge
                                                .insetSize || ""
                                        }
                                        onChange={
                                            this.props
                                                .handleInputChangeForString
                                        }
                                        onBlur={this.props.handleInputBlur}
                                        disabled={
                                            this.props.disabled || 
                                            !embellishment.leadReturnEdge.active 
                                        } 
                                        uiDisabled={this.props.disabled}
                                    />
                                    <PrependAppendInputGroupLabel>
                                        in.
                                    </PrependAppendInputGroupLabel>
                                </PrependAppendInputGroup>
                            </InsetContainer>
                            {showWidthColumn && (
                                <WidthContainer
                                    noPaddingTop
                                    uiRightBorder
                                    uiBottomBorder
                                    uiPaddingLeft
                                >
                                    <PrependAppendInputGroup uiSmall>
                                        <PrependAppendInput
                                            style={
                                                highlightedSection ===
                                                `${targetArray}.${targetArrayIndex}.leadReturnEdge.size`
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            type={
                                                targetArray === "cordings"
                                                    ? "text"
                                                    : "number"
                                            }
                                            id={`${targetArray}.${targetArrayIndex}.leadReturnEdge.size`}
                                            name={`${targetArray}.${targetArrayIndex}.leadReturnEdge.size`}
                                            autoComplete="off"
                                            value={numberToString(
                                                embellishment.leadReturnEdge
                                                    .size
                                            )}
                                            onChange={
                                                targetArray === "cordings"
                                                    ? this.props
                                                          .handleInputChangeForString
                                                    : this.props
                                                          .handleInputChangeForNumber
                                            }
                                            disabled={
                                                this.props.disabled || 
                                                !embellishment.leadReturnEdge.active 
                                            }
                                            uiDisabled={this.props.disabled}
                                        />
                                        {targetArray !== "cordings" && (
                                            <PrependAppendInputGroupLabel
                                                style={{ marginTop: "8px" }}
                                            >
                                                in.
                                            </PrependAppendInputGroupLabel>
                                        )}
                                    </PrependAppendInputGroup>
                                </WidthContainer>
                            )}
                            <TotalStripsContainer
                                noPaddingTop
                                uiRightBorder
                                uiBottomBorder
                                uiPaddingLeft
                                style={
                                    highlightedSection ===
                                    `${targetArray}.${targetArrayIndex}.totalStrips`
                                        ? highlightStyle
                                        : {}
                                }
                            >
                                <PrependAppendInputGroup uiSmall>
                                    <PrependAppendInput
                                        type="number"
                                        id={`${targetArray}.${targetArrayIndex}.totalStrips`}
                                        name={`${targetArray}.${targetArrayIndex}.totalStrips`}
                                        autoComplete="off"
                                        value={numberToString(
                                            embellishment.totalStrips
                                        )}
                                        onChange={
                                            this.props
                                                .handleInputChangeForNumber
                                        }
                                        disabled={
                                            this.props.disabled || 
                                            !embellishment.leadReturnEdge.active 
                                        } 
                                        uiDisabled={this.props.disabled}
                                    />
                                </PrependAppendInputGroup>
                            </TotalStripsContainer>
                        </TableContainer>
                        <TableContainer uiStretch>
                            <LocationContainer
                                uiCenter
                                uiRightBorder
                                uiBottomBorder
                                uiLeftBorder
                                uiPaddingLeft
                            >
                                <PrependAppendInputGroup uiSmall
                                     style={
                                         highlightedSection ===
                                         `${targetArray}.${targetArrayIndex}.leadReturnEdge.active`
                                             ? highlightStyle
                                             : {}
                                     }
                                >
                                    <CalculatorCheckbox
                                        id={`${targetArray}.${targetArrayIndex}.topEdge.active`}
                                        name={`${targetArray}.${targetArrayIndex}.topEdge.active`}
                                        checked={embellishment.topEdge.active}
                                        onChange={(e) => {
                                            this.props.handleInputChangeForBoolean(e, targetArray, targetArrayIndex);
                                            const { checked } = e.currentTarget;

                                            if (checked) {
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.topEdge.insetActive`,
                                                    false
                                                );
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.topEdge.insetSize`,
                                                    ''
                                                );
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.topEdge.size`,
                                                    undefined
                                                );
                                            } else {
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.topEdge.insetActive`,
                                                    false
                                                );
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.topEdge.insetSize`,
                                                    undefined 
                                                );
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.topEdge.size`,
                                                    undefined 
                                                );
                                            }
                                        }} 
                                        disabled={this.props.disabled}
                                        uiDisabled={this.props.disabled}
                                    />
                                    <span style={{ marginTop: "3px" }}>
                                        Top
                                    </span>
                                </PrependAppendInputGroup>
                            </LocationContainer>
                            <InsetContainer
                                uiRightBorder
                                uiBottomBorder
                                uiPaddingLeft
                            >
                                <PrependAppendInputGroup uiSmall>
                                    <CalculatorCheckbox
                                        id={`${targetArray}.${targetArrayIndex}.topEdge.insetActive`}
                                        name={`${targetArray}.${targetArrayIndex}.topEdge.insetActive`}
                                        checked={
                                            !!embellishment.topEdge.insetActive
                                        }
                                        onChange={(e) => {
                                            this.props.handleInputChangeForBoolean(e, targetArray, targetArrayIndex);
                                            const { checked } = e.currentTarget;
                                    
                                            if (!checked) {
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.topEdge.insetSize`,
                                                    '' 
                                                );
                                            }
                                        }}
                                        disabled={
                                            this.props.disabled || 
                                            !embellishment.topEdge.active 
                                        }
                                        uiDisabled={this.props.disabled}
                                    />
                                    <PrependAppendInput
                                        style={
                                            highlightedSection ===
                                            `${targetArray}.${targetArrayIndex}.topEdge.insetSize`
                                                ? highlightStyle
                                                : {}
                                        }
                                        type="text"
                                        id={`${targetArray}.${targetArrayIndex}.topEdge.insetSize`}
                                        name={`${targetArray}.${targetArrayIndex}.topEdge.insetSize`}
                                        autoComplete="off"
                                        value={
                                            embellishment.topEdge.insetSize ||
                                            ""
                                        }
                                        onChange={
                                            this.props
                                                .handleInputChangeForString
                                        }
                                        onBlur={this.props.handleInputBlur}
                                        disabled={
                                            this.props.disabled || 
                                            !embellishment.topEdge.active 
                                        }
                                        uiDisabled={this.props.disabled}
                                    />
                                    <PrependAppendInputGroupLabel>
                                        in.
                                    </PrependAppendInputGroupLabel>
                                </PrependAppendInputGroup>
                            </InsetContainer>
                            {showWidthColumn && (
                                <WidthContainer
                                    uiRightBorder
                                    uiBottomBorder
                                    uiPaddingLeft
                                >
                                    <PrependAppendInputGroup uiSmall>
                                        <PrependAppendInput
                                            style={
                                                highlightedSection ===
                                                `${targetArray}.${targetArrayIndex}.topEdge.size`
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            type={
                                                targetArray === "cordings"
                                                    ? "text"
                                                    : "number"
                                            }
                                            id={`${targetArray}.${targetArrayIndex}.topEdge.size`}
                                            name={`${targetArray}.${targetArrayIndex}.topEdge.size`}
                                            autoComplete="off"
                                            value={numberToString(
                                                embellishment.topEdge.size
                                            )}
                                            onChange={
                                                targetArray === "cordings"
                                                    ? this.props
                                                          .handleInputChangeForString
                                                    : this.props
                                                          .handleInputChangeForNumber
                                            }
                                            disabled={
                                                this.props.disabled || 
                                                !embellishment.topEdge.active 
                                            }
                                            uiDisabled={this.props.disabled}
                                        />
                                        {targetArray !== "cordings" && (
                                            <PrependAppendInputGroupLabel
                                                style={{ marginTop: "8px" }}
                                            >
                                                in.
                                            </PrependAppendInputGroupLabel>
                                        )}
                                    </PrependAppendInputGroup>
                                </WidthContainer>
                            )}
                            <TotalStripsSpacer uiPaddingLeft />
                        </TableContainer>
                        <TableContainer uiStretch>
                            <LocationContainer
                                uiCenter
                                uiRightBorder
                                uiBottomBorder
                                uiLeftBorder
                                uiPaddingLeft
                            >
                                <PrependAppendInputGroup uiSmall
                                 style={
                                     highlightedSection ===
                                     `${targetArray}.${targetArrayIndex}.leadReturnEdge.active`
                                         ? highlightStyle
                                         : {}
                                 }
                                >
                                    <CalculatorCheckbox
                                        id={`${targetArray}.${targetArrayIndex}.bottomEdge.active`}
                                        name={`${targetArray}.${targetArrayIndex}.bottomEdge.active`}
                                        checked={
                                            embellishment.bottomEdge.active
                                        }
                                        onChange={(e) => {
                                            this.props.handleInputChangeForBoolean(e, targetArray, targetArrayIndex);
                                            const { checked } = e.currentTarget;

                                            if (checked) {
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.bottomEdge.insetActive`,
                                                    false
                                                );
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.bottomEdge.insetSize`,
                                                    ''
                                                );
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.bottomEdge.size`,
                                                    undefined
                                                );
                                            } else {
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.bottomEdge.insetActive`,
                                                    false
                                                );
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.bottomEdge.insetSize`,
                                                    undefined 
                                                );
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.bottomEdge.size`,
                                                    undefined 
                                                );
                                            }
                                        }} 
                                        disabled={this.props.disabled}
                                        uiDisabled={this.props.disabled}
                                    />
                                    <span style={{ marginTop: "3px" }}>
                                        Bottom
                                    </span>
                                </PrependAppendInputGroup>
                            </LocationContainer>
                            <InsetContainer
                                uiRightBorder
                                uiBottomBorder
                                uiPaddingLeft
                            >
                                <PrependAppendInputGroup uiSmall>
                                    <CalculatorCheckbox
                                        id={`${targetArray}.${targetArrayIndex}.bottomEdge.insetActive`}
                                        name={`${targetArray}.${targetArrayIndex}.bottomEdge.insetActive`}
                                        checked={
                                            !!embellishment.bottomEdge
                                                .insetActive
                                        }
                                        onChange={(e) => {
                                            this.props.handleInputChangeForBoolean(e, targetArray, targetArrayIndex);
                                            const { checked } = e.currentTarget;
                                    
                                            if (!checked) {
                                                this.props.updateStateByName(
                                                    `${targetArray}.${targetArrayIndex}.bottomEdge.insetSize`,
                                                    '' 
                                                );
                                            }
                                        }}
                                        disabled={
                                            this.props.disabled || 
                                            !embellishment.bottomEdge.active 
                                        }
                                        uiDisabled={this.props.disabled}
                                    />
                                    <PrependAppendInput
                                        style={
                                            highlightedSection ===
                                            `${targetArray}.${targetArrayIndex}.bottomEdge.insetSize`
                                                ? highlightStyle
                                                : {}
                                        }
                                        type="text"
                                        id={`${targetArray}.${targetArrayIndex}.bottomEdge.insetSize`}
                                        name={`${targetArray}.${targetArrayIndex}.bottomEdge.insetSize`}
                                        autoComplete="off"
                                        value={
                                            embellishment.bottomEdge
                                                .insetSize || ""
                                        }
                                        onChange={
                                            this.props
                                                .handleInputChangeForString
                                        }
                                        onBlur={this.props.handleInputBlur}
                                        disabled={
                                            this.props.disabled || 
                                            !embellishment.bottomEdge.active 
                                        }
                                        uiDisabled={this.props.disabled}
                                    />
                                    <PrependAppendInputGroupLabel
                                        style={{ marginTop: "8px" }}
                                    >
                                        in.
                                    </PrependAppendInputGroupLabel>
                                </PrependAppendInputGroup>
                            </InsetContainer>
                            {showWidthColumn && (
                                <WidthContainer
                                    uiRightBorder
                                    uiBottomBorder
                                    uiPaddingLeft
                                >
                                    <PrependAppendInputGroup uiSmall>
                                        <PrependAppendInput
                                            style={
                                                highlightedSection ===
                                                `${targetArray}.${targetArrayIndex}.bottomEdge.size`
                                                    ? highlightStyle
                                                    : {}
                                            }
                                            type={
                                                targetArray === "cordings"
                                                    ? "text"
                                                    : "number"
                                            }
                                            id={`${targetArray}.${targetArrayIndex}.bottomEdge.size`}
                                            name={`${targetArray}.${targetArrayIndex}.bottomEdge.size`}
                                            autoComplete="off"
                                            value={numberToString(
                                                embellishment.bottomEdge.size
                                            )}
                                            onChange={
                                                targetArray === "cordings"
                                                    ? this.props
                                                          .handleInputChangeForString
                                                    : this.props
                                                          .handleInputChangeForNumber
                                            }
                                            disabled={
                                                this.props.disabled || 
                                                !embellishment.bottomEdge.active 
                                            }
                                            uiDisabled={this.props.disabled}
                                        />
                                        {targetArray !== "cordings" && (
                                            <PrependAppendInputGroupLabel
                                                style={{ marginTop: "12px" }}
                                            >
                                                in.
                                            </PrependAppendInputGroupLabel>
                                        )}
                                    </PrependAppendInputGroup>
                                </WidthContainer>
                            )}
                            <TotalStripsSpacer uiPaddingLeft />
                        </TableContainer>
                    </>
                )}
                <SaveBtn
                    uiColor="primary"
                    type="button"
                    onClick={this.handleAddAnotherButton}
                    disabled={this.props.disabled}
                    uiDisabled={this.props.disabled}
                    style={{
                        backgroundColor: "#D6EAF0",
                        color: "#3B97B1",
                        marginBottom: "15px",
                        marginLeft: "15px",
                    }}
                >
                    Add Another{" "}
                    {title !== "Other Embellishments"
                        ? `${title}`
                        : "Embellishment"}
                </SaveBtn>
            </AdditionalFormBlock>
        );
    }

    private handleAddAnotherButton = async (): Promise<void> => {
        this.props.addEmbellishment(this.props.targetArray);
    };

    private handleDeleteButton = () => {
        this.props.deleteEmbellishment(
            this.props.targetArray,
            this.props.targetArrayIndex
        );
    };

    private handleSubType = (e: ChangeEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        const nValue = +value;
        if (this.props.embellishment.draperyOrderFabric) {
            const type = `${this.props.targetArray}.${this.props.targetArrayIndex}.draperyOrderFabric.type`;
            const name = `${this.props.targetArray}.${this.props.targetArrayIndex}.draperyOrderFabric.name`;
            if (this.props.targetArray === "cordings") {
                this.props.updateStateByName(type, nValue ? 3 : 4);
                this.props.updateStateByName(
                    name,
                    nValue ? "WELT CORDING FABRIC" : "CORDING (PRE-MADE)"
                );
            } else if (this.props.targetArray === "flanges") {
                this.props.updateStateByName(type, nValue ? 5 : 6);
                this.props.updateStateByName(
                    name,
                    nValue ? "FLANGE FABRIC" : "FLANGE (PRE-MADE)"
                );
            } else if (this.props.targetArray === "trims") {
                this.props.updateStateByName(type, nValue ? 7 : 8);
                this.props.updateStateByName(
                    name,
                    nValue ? "TRIM FABRIC" : "TRIM (PRE-MADE)"
                );
            }
        }
        this.props.handleInputChangeForNumber(e);
    };
}

export default AdditionalForm;
