export interface DescriptionOptionField {
    id: string;
    name: string;
    isEnabled: boolean;
}

// export interface PricingAddOns {
//     id: string;
//     name: string;
//     price: string;
// }

export interface PricingSimpleItem {
    id: string;
    newPleatId?: any;
    name: string;
    nameSubText?: string;
    price: string;
    image?: string;
    isActive?: boolean;
    isDeleted?: boolean;
}

export interface PricingGroupItem {
    group: {
        name: string;
        nameSubText?: string;
        items: PricingSimpleItem[];
    };
}

export type PricingGroupOrSimpleItem = PricingGroupItem | PricingSimpleItem;

export interface DraperyItemDetailsFields {
    id: number;
    pricingTypeId: number;
    pricingTypeName: string;
    isClientPricingActionOn: boolean;
    clientPricingAction: string;
    clientPricingValue: number | "";
    isPartialWidthRoundingOn: boolean;
    partialWidthRoundUpTypeId: number;
    partialWidthRoundUpTypeName: string;
    pleatTypesWithPricing: PricingSimpleItem[];
    liningAndInterliningWithPricing: PricingSimpleItem[];
    cafeCurtainsWidthInches: string;
    cafeCurtainsWidthPricing: string;
    extraLargePanelsLengthInches: string;
    extraLargePanelsLengthPricing: string;
    extraLargePanelsWidthInches: string;
    extraLargePanelsWidthPricing: string;
    draperyPinningWidthPricing: string;
    specialityFabricsMarkUpPercentage: string;
    embellishmentsWithPricing: PricingGroupOrSimpleItem[];
    addOnsWithPricing: PricingSimpleItem[];
    salesDescriptionSettings: DescriptionOptionField[];
    orderingDescriptionSettings: DescriptionOptionField[];
}

export type DraperyItemDetailsFieldsPartial = Partial<DraperyItemDetailsFields>;

export type DraperyItemDetailsFieldsAction =
    | {
          type: "updateFormFields";
          formFieldUpdates: DraperyItemDetailsFieldsPartial;
      }
    | { type: "resetFormFields" };

const pleatTypesWithPricingInitialState: PricingSimpleItem[] = [
    {
        id: "noneflat",
        name: "None (flat)",
        price: "",
    },
    {
        id: "euro2finger",
        name: "Euro (2 Finger)",
        price: "",
    },
    {
        id: "euro3finger",
        name: "Euro (3 Finger)",
        price: "",
    },
    {
        id: "goblet",
        name: "Goblet",
        price: "",
    },
    {
        id: "inverted",
        name: "Inverted",
        price: "",
    },
    {
        id: "pinch2finger",
        name: "Pinch (2 Finger)",
        price: "",
    },
    {
        id: "pinch3finger",
        name: "Pinch (3 Finger)",
        price: "",
    },
];

const liningAndInterliningWithPricingInitialState: PricingSimpleItem[] = [
    {
        id: "1stlayerlining",
        name: "1st Layer (Lining)",
        price: "",
    },
    {
        id: "2ndlayerinterlining",
        name: "2nd Layer (Interlining)",
        price: "",
    },
    {
        id: "3rdlayerandfollowing",
        name: "3rd Layer and Following",
        price: "",
    },
];

const addOnsWithPricingInitialState: PricingSimpleItem[] = [
    {
        id: "beadedChain",
        name: "Weight: Beaded Chain",
        price: ""
    },
    {
        id: "standardCorner",
        name: "Weight: Standard (Corner)",
        price: ""
    }
];

const embellishmentsWithPricingInitialState: PricingGroupOrSimpleItem[] = [
    {
        id: "bandingstraight",
        name: "Banding - Straight",
        price: "",
    },
    {
        group: {
            name: "Cording",
            items: [
                {
                    id: "cordingpremade",
                    name: "Pre-Made",
                    nameSubText: "(Apply)",
                    price: "",
                },
                {
                    id: "cordingwelt",
                    name: "Welt",
                    nameSubText: "(Make & Apply)",
                    price: "",
                },
            ],
        },
    },
    {
        group: {
            name: "Flange",
            items: [
                {
                    id: "flangepremade",
                    name: "Pre-Made",
                    nameSubText: "(Apply)",
                    price: "",
                },
                {
                    id: "flangefromfabric",
                    name: "From Fabric",
                    nameSubText: "(Make & Apply)",
                    price: "",
                },
            ],
        },
    },
    {
        group: {
            name: "Trim Pre-made",
            nameSubText: "(Apply)",
            items: [
                {
                    id: "trimpremadewithadhesive",
                    name: "With Adhesive",
                    price: "",
                },
                {
                    id: "trimpremademachinestitched",
                    name: "Machine Stitched",
                    price: "",
                },
                {
                    id: "trimpremadehandsewn",
                    name: "Hand Sewn",
                    price: "",
                },
            ],
        },
    },
    {
        group: {
            name: "Trim from fabric",
            nameSubText: "(Make & Apply)",
            items: [
                {
                    id: "trimfromfabricwithadhesive",
                    name: "With Adhesive",
                    price: "",
                },
                {
                    id: "trimfromfabricmachinestitched",
                    name: "Machine Stitched",
                    price: "",
                },
                {
                    id: "trimfromfabrichandsewn",
                    name: "Hand Sewn",
                    price: "",
                },
            ],
        },
    },
];

const descriptionSettings = [
    {
        name: "Link to Work Order",
        isEnabled: true,
    },
    {
        name: "Window ID",
        isEnabled: true,
    },
    {
        name: "Functionality and Style",
        isEnabled: true,
    },
    {
        name: "Finished Length",
        isEnabled: true,
    },
    {
        name: "Heading and Pleat Type",
        isEnabled: true,
    },
    {
        name: "Panel and Width Details",
        isEnabled: true,
    },
    {
        name: "Total Number of Panels and Widths",
        isEnabled: true,
    },
    {
        name: "If Lining and Interlining Selected",
        isEnabled: true,
    },
    {
        name: "Lining and Interlining Names",
        isEnabled: true,
    },
    {
        name: "Embellishments Selected",
        isEnabled: true,
    },
];

const getDescriptionOptions = (descriptionType: string) => {
    const modDescriptions = descriptionSettings.map((d) => {
        return {
            ...d,
            id: `${descriptionType}${d.name.replace(/\s/g, "").toLowerCase()}`,
        };
    });
    return modDescriptions;
};

export const draperyItemDetailsFieldsInitialState: DraperyItemDetailsFields = {
    id: 0,
    pricingTypeId: 1,
    pricingTypeName: "",
    isClientPricingActionOn: true,
    clientPricingAction: "",
    clientPricingValue: "",
    isPartialWidthRoundingOn: true,
    partialWidthRoundUpTypeId: 0,
    partialWidthRoundUpTypeName: "",
    pleatTypesWithPricing: pleatTypesWithPricingInitialState,
    liningAndInterliningWithPricing:
        liningAndInterliningWithPricingInitialState,
    cafeCurtainsWidthInches: "",
    cafeCurtainsWidthPricing: "",
    extraLargePanelsLengthInches: "",
    extraLargePanelsLengthPricing: "",
    extraLargePanelsWidthInches: "",
    extraLargePanelsWidthPricing: "",
    draperyPinningWidthPricing: "",
    specialityFabricsMarkUpPercentage: "",
    embellishmentsWithPricing: embellishmentsWithPricingInitialState,
    addOnsWithPricing: addOnsWithPricingInitialState,
    salesDescriptionSettings: getDescriptionOptions("sales"),
    orderingDescriptionSettings: getDescriptionOptions("ordering"),
};
export const draperyItemDetailsFieldsReducer = (
    state: DraperyItemDetailsFields,
    action: DraperyItemDetailsFieldsAction
): DraperyItemDetailsFields => {
    switch (action.type) {
        case "updateFormFields": {
            return {
                ...state,
                ...action.formFieldUpdates,
            };
        }
        case "resetFormFields": {
            return draperyItemDetailsFieldsInitialState;
        }
        default: {
            throw new Error("Invalid action type");
        }
    }
};
