import { ClassAttributes, HTMLAttributes } from "react";
import styled from "../theme/styled";

// tslint:disable-next-line:no-empty-interface
export interface GridTableBodyUIProps { }

export type GridTableBodyProps = HTMLAttributes<HTMLDivElement> & GridTableBodyUIProps;

/**
 *
 * Table body container
 */
const GridTableBody = styled.div<GridTableBodyProps & ClassAttributes<HTMLElement>> `
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    overflow-y: auto;
`;

export default GridTableBody;
