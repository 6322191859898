import React, { FC } from "react";

import GenericTooltip from "../content/GenericTooltip";
import LockIcon from "../../assets/icons/icons8-lock.svg";
import styled from "../../theme";

interface LockedLabelProps {
    text: string;
    toolTipText: string;
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LockedLabel: FC<LockedLabelProps> = ({ text, toolTipText }) => {
    return (
        <Container>
            <p className="m-0 mr-2">{text}</p>
            <div>
                <GenericTooltip
                    text={
                        <div className="pt-4 px-2">
                            <p>{toolTipText}</p>
                        </div>
                    }
                    position={"top right"}
                >
                    <LockIcon className="h-4" />
                </GenericTooltip>
            </div>
        </Container>
    );
};

export default LockedLabel;
