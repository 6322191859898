import { flagRoot } from "../../utils";

// export type HeaderIconType = "account" | "teams" | "chat" | "dashboard" | "faq" | "history" | "missed";

/**
 * Realtime status type
 */
export type RealtimeStatus = "connecting" | "connected" | "disconnected" | "paused";

/**
 * Application state
 */
export interface State {
    /**
     * Is application loaded
     */
    loaded: boolean;
    /**
     * Will be true for first login
     */
    // firstLogin: false;
    /**
     * Application Header text
     */
    // headerText: string;
    /**
     * Application Header icon
     */
    // headerIcon: HeaderIconType;
    /**
     * Application realtime status
     */
    realtimeStatus: RealtimeStatus;
}

/**
 * State default attributes
 */
export function defaults(): State {
    const st: State = {
        loaded: false,
        // firstLogin: false,
        // headerText: "",
        // headerIcon: "dashboard",
        realtimeStatus: "disconnected",
    };
    return flagRoot(st);
}
