import { BaseAction } from "@quipa/api";

import { Notification, NotificationType } from "./model";
/**
 * Notification actions
 */

export const ADD_NOTIFICATION = "frontend/notifications/ADD";
export const REMOVE_NOTIFICATION = "frontend/notifications/REMOVE";
export const ADD_NOTIFICATION_INTERNAL = "frontend/notification/ADD_INTERNAL";

/**
 * This is same as notification interface but without id. This will be processed by middleware so we don't need it
 */
export interface AddNotificationPayload {
    /**
     * Notification type
     */
    type: NotificationType;
    /**
     * Notification message
     */
    message: string;
     /**
     * Notification message
     */
     subMessage?: string;
    /**
     * Should the notification be automatically hidden after some time
     * @default true
     */
    autoDismissable?: boolean;
}

/**
 * Add notification internal payload
 */
// tslint:disable-next-line:no-empty-interface
export interface AddNotificationInternalPayload extends Notification {}

export type AddNotificationAction = BaseAction<typeof ADD_NOTIFICATION, AddNotificationPayload>;
/**
 * Add notification
 * @param notification
 */
export function addNotification(notification: AddNotificationPayload): AddNotificationAction {
    return {
        type: ADD_NOTIFICATION,
        payload: {
            type: notification.type,
            message: notification.message,
            subMessage: notification.subMessage,
        },
    };
}

export type AddNotificationInternalAction = BaseAction<
    typeof ADD_NOTIFICATION_INTERNAL,
    AddNotificationInternalPayload
>;

/**
 * This should not be used directly, it will be dispatched as response to addNotification() by notification middleware
 * @param notification
 */
export function addNotificationInternal(notification: AddNotificationInternalPayload): AddNotificationInternalAction {
    return {
        type: ADD_NOTIFICATION_INTERNAL,
        payload: notification,
    };
}

export type RemoveNotificationAction = BaseAction<typeof REMOVE_NOTIFICATION, number>;

/**
 * Remove notification by id
 * @param id
 * @returns
 */
export function removeNotification(id: number): RemoveNotificationAction {
    return {
        type: REMOVE_NOTIFICATION,
        payload: id,
    };
}

export interface NotificationsMap {
    [ADD_NOTIFICATION]: AddNotificationAction;
    [ADD_NOTIFICATION_INTERNAL]: AddNotificationInternalAction;
    [REMOVE_NOTIFICATION]: RemoveNotificationAction;
}
