import { Api } from "@quipa/api";

import { actionReducer, flagRoot } from "../../utils";

import { ADD_NOTIFICATION_INTERNAL, NotificationsMap, REMOVE_NOTIFICATION } from "./actions";
import { MAX_NOTIFICATIONS, notificationDefaults, notificationsStateDefaults, State } from "./model";

export default actionReducer<NotificationsMap & Api.RealtimeActionsMap, State>(
    notificationsStateDefaults(),
    {
        [ADD_NOTIFICATION_INTERNAL]: (draft, { payload }) => {
            if (draft.length === MAX_NOTIFICATIONS) {
                draft.shift();
            }
            draft.push({
                ...notificationDefaults,
                id: payload.id,
                message: payload.message,
                subMessage: payload.subMessage,
                type: payload.type,
            });
        },
        [REMOVE_NOTIFICATION]: (draft, { payload }) => {
            return flagRoot(draft.filter((n) => n.id !== payload));
        },
    }
);
