import Bind from "lodash-decorators/bind";
import React, { Component, ComponentClass, SFC } from "react";
import { forwardRef, WithForwardedRef } from "../utils/ref";
import { WithValidationProps } from "./with_validation";

interface State {
    error?: string;
    asyncStatus?: "pending" | "success" | "error";
}

export interface Options<T> {
    // uiAsyncValidationStatusProp?: TypePropertyNames<T, string>;
    uiAsyncValidationStatusProp?: keyof T;
    // uiErrorProp?: TypePropertyNames<T, boolean>;
    uiErrorProp?: keyof T;
}
export interface WithValidationErrorMessageProps {
    /**
     * Report pending async validation to wrapped component
     * @default false
     */
    uiReportAsyncValidationPending?: boolean;
    /**
     * Report success/error result of async validation to wrapped component
     * @default false
     */
    uiReportAsyncValidationResult?: boolean;
    /**
     * Clean success/error result after timeout
     * @default undefined
     */
    uiReportResultTimeout?: number;
}

/**
 * Wrap validation component to automatically display error message on validation error
 * @param WrappedComponent Component to wrap. Must implement validation props
 * @param options Wrapping options
 */
export function WithValidationErrorMessage<T extends WithValidationProps>(
    WrappedComponent: React.ComponentType<T>,
    options: Options<T> = {},
): SFC<T & WithValidationErrorMessageProps> {
    class WithErrorMessageComponent extends Component<WithValidationProps & WithValidationErrorMessageProps, State> {
        public static displayName = `WithErrorMessage(${WrappedComponent.displayName || WrappedComponent.name || "WrappedComponent"})`;

        public state: State = {
            error: undefined,
            asyncStatus: undefined,
        };

        private statusTimer?: number;

        public componentWillUnmount(): void {
            if (this.statusTimer) {
                clearTimeout(this.statusTimer);
            }
        }

        public render(): JSX.Element {
            const { asyncStatus } = this.state;
            const additionalProps = {};
            if (options.uiAsyncValidationStatusProp) {
                if (asyncStatus === "pending" && this.props.uiReportAsyncValidationPending) {
                    additionalProps[(options.uiAsyncValidationStatusProp as string)] = asyncStatus;
                } else if ((asyncStatus === "success" || asyncStatus === "error") && this.props.uiReportAsyncValidationResult) {
                    additionalProps[(options.uiAsyncValidationStatusProp as string)] = asyncStatus;
                }
            }
            if (options.uiErrorProp) {
                additionalProps[(options.uiErrorProp as any)] = !!this.state.error;
            }
            const {
                uiReportAsyncValidationPending, uiReportAsyncValidationResult, uiReportResultTimeout, forwardedRef, ...other
            } = this.props as WithForwardedRef<WithValidationErrorMessageProps & WithValidationProps>;
            const Comp = WrappedComponent as any as ComponentClass<WithValidationProps>;
            return (
                <>
                    <Comp
                        // additional reporting props can be overwritten explicitly
                        {...additionalProps}
                        {...other}
                        uiOnValid={this.onValid}
                        uiOnInvalid={this.onInvalid}
                        uiOnStartAsyncValidation={this.onStartAsyncValidation}
                        uiOnFinishAsyncValidation={this.onFinishAsyncValidation}
                        ref={forwardedRef}
                    />
                    {this.state.error && (
                        <div className="error-msg">{this.state.error}</div>
                    )}
                </>
            );
        }

        @Bind()
        protected onValid(): void {
            if (this.state.error) {
                this.setState({ error: undefined });
            }
        }

        @Bind()
        protected onInvalid(msg: string): void {
            if (!this.state.error || this.state.error !== msg) {
                this.setState({ error: msg });
            }
        }

        @Bind()
        protected onStartAsyncValidation(): void {
            if (this.statusTimer) {
                clearTimeout(this.statusTimer);
            }
            if (this.props.uiReportAsyncValidationPending) {
                this.setState({ asyncStatus: "pending" });
            }
        }

        @Bind()
        protected onFinishAsyncValidation(valid: boolean): void {
            const { uiReportAsyncValidationResult, uiReportAsyncValidationPending, uiReportResultTimeout } = this.props;
            // NOTE: No need to set error message here, WithValidation() will report error byself
            // and uiOnInvalid() will be triggered
            if (uiReportAsyncValidationPending || uiReportAsyncValidationResult) {
                this.setState({
                    asyncStatus: !uiReportAsyncValidationResult ? undefined : valid ? "success" : "error",
                });
            }
            if (uiReportResultTimeout && uiReportAsyncValidationResult) {
                this.statusTimer = setTimeout(() => {
                    this.statusTimer = undefined;
                    this.setState({ asyncStatus: undefined });
                }, uiReportResultTimeout) as any;
            }
        }
    }

    return forwardRef(WithErrorMessageComponent as any);
}

export default WithValidationErrorMessage;
