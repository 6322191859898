import Color from "color";
import React, { forwardRef, RefObject } from "react";
import { CSSTransition } from "react-transition-group";
import { CSSTransitionProps } from "react-transition-group/CSSTransition";
import styled from "../theme/styled";
import BaseModal, { BaseModalUIProps } from "./base_modal";
import ModalElement, { ModalElementProps } from "./modal_element";

const PortalStyledBaseModal = styled(BaseModal) `
    z-index: ${({ theme }) => theme.zIndex.portalModal};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background: rgba(128, 128, 128, 0.6);
    &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => Color(theme.colors.background).alpha(0.25).string()};
    }
`;

export interface ModalUIProps extends BaseModalUIProps {
    /**
     * Modal displayed/hidden
     */
    uiActive: boolean;
    /**
     * Modal transition timeouts
     */
    uiTransitionTimeout?: CSSTransitionProps["timeout"];
    /**
     * Modal Element reference
     */
    uiElementRef?: RefObject<HTMLElement>;
}

export type ModalProps = ModalElementProps & ModalUIProps;

const ModalComponent = forwardRef<BaseModal, ModalProps>((props, ref) => {
    const {
         uiActive, children, uiDefaultSizes, uiBasic, className, uiTransitionTimeout,
         uiPosition, uiOffsetLeft, uiOffsetTop, uiCloseOnEsc, uiCloseOnOutsideClick,
         uiOnRequestClose, uiOnOpen, uiOnClose, uiElementRef, ...other
    } = props;

    return (
        <CSSTransition
            in={uiActive}
            appear
            enter
            exit
            mountOnEnter
            unmountOnExit
            classNames="transition"
            timeout={uiTransitionTimeout!}
        >
            <PortalStyledBaseModal
                uiPosition={uiPosition}
                uiOffsetLeft={uiOffsetLeft}
                uiOffsetTop={uiOffsetTop}
                uiCloseOnEsc={uiCloseOnEsc}
                uiCloseOnOutsideClick={uiCloseOnOutsideClick}
                uiOnRequestClose={uiOnRequestClose}
                uiOnOpen={uiOnOpen}
                uiOnClose={uiOnClose}
                ref={ref}
            >
                <ModalElement
                    {...other}
                    className={className}
                    uiBasic={uiBasic}
                    uiDefaultSizes={uiDefaultSizes}
                    ref={uiElementRef}
                >
                    {children}
                </ModalElement>
            </PortalStyledBaseModal>
        </CSSTransition>
    );
});

ModalComponent.defaultProps = {
    uiActive: false,
    uiDefaultSizes: true,
    uiBasic: false,
    uiTransitionTimeout: 200,
};

/**
 * Standard modal
 */
const Modal = styled(ModalComponent)`
    &.transition-appear,
    &.transition-enter {
        opacity: 0.01;
        transform: scale(0.5);
        transform-origin: 50% 25%;
    }

    &.transition-appear-active,
    &.transition-enter-active {
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    }

    &.transition-exit {
        opacity: 1;
        transform: scale(1);
        transform-origin: 50% 25%;
    }

    &.transition-exit-active {
        opacity: 0;
        transform: scale(0.5);
        transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    }
`;

export default Modal;
