import React, { FC, memo } from "react";

import { Header } from "@ramble/ramble-ui";
import styled from "../../theme";

export interface PageHeaderProps {
    /**
     * Page title
     */
    title: string;
    classNameProps?: string;
}

const Container = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
`;

const StyledHeader = styled(Header)`
    margin: -10px 0 10px;
    font-family: "Poppins", serif;
    font-size: 24px;
    letter-spacing: 0px;
    color: #306f82;
    font-weight: 400;
`;

const Content = styled.div`
    align-self: flex-end;
`;

export const PageHeader: FC<PageHeaderProps> = memo((props) => (
    <Container>
        <StyledHeader className={props.classNameProps}>{props.title}</StyledHeader>
        <Content>{props.children}</Content>
    </Container>
));
