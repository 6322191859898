import React, { Component, createRef, MouseEvent as ReactMouseEvent, TouchEvent as ReactTouchEvent } from "react";
import { RGBColor } from "react-color";
import { confirmation } from "../../../../confirmation";
import { DraperyModule } from "../../../../redux";
import styled from "../../../../theme";
import { isTouchDevice } from "../../../../utils/is_touch_device";
import InputText from "./input";
import NewShape from "./new-shape";
import SelectedShape from "./selected-shape";
import Shapes from "./shapes";

export const viewBox = {
    x: 0,
    y: 0,
    width: 500,
    height: 300,
};

export const viewBoxStr = "0 0 500 300";

export const rgbaToString = (color: RGBColor) => color.a === 0 ? "none" : `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

export interface Rect {
    x: number;
    y: number;
    width: number;
    height: number;
}

export const toRect = (point1: DraperyModule.Point, point2: DraperyModule.Point): Rect => {
    const x1 = point1.x < point2.x ? point1.x : point2.x;
    const y1 = point1.y < point2.y ? point1.y : point2.y;
    const x2 = point1.x > point2.x ? point1.x : point2.x;
    const y2 = point1.y > point2.y ? point1.y : point2.y;
    return {
        x: x1,
        y: y1,
        width: x2 - x1,
        height: y2 - y1,
    };
};

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    touch-action: pinch-zoom;
`;

interface Props {
    tool: "line" | "pen" | "text" | "selection" | "rect" | "circle";
    arrow: boolean;
    lineWeight: number;
    lineType: "solid" | "dashed";
    filledColor: RGBColor;
    strokeColor: RGBColor;
    fontSize: number;

    category: number;
    panelType?: string;
    panelJson: DraperyModule.DraperyOrderDesignPanelJson;
    rodWidth?: number;
    finishedLengthOfPanels: number;
    shapes?: DraperyModule.Shape[];
    disabled: boolean;

    onShapeChanged(shapes: DraperyModule.Shape[]): void;
    onShapeSelected(selected: boolean): void;
}

interface State {
    status: "idle" | "drawing" | "inputingText" | "selected";
    shapes: DraperyModule.Shape[];
    currentShape?: DraperyModule.Shape;
    currentShapeIndex?: number;
}

class Board extends Component<Props, State> {
    public state: State = {
        status: "idle",
        shapes: this.props.shapes || [],
    };

    private drawAreaRef = createRef<HTMLDivElement>();
    private shapesRef = createRef<Shapes>();
    private newShapeRef = createRef<NewShape>();
    private selectedShapeRef = createRef<SelectedShape>();

    public componentDidMount(): void {
        if (this.drawAreaRef.current) {
            this.drawAreaRef.current.addEventListener("touchmove", this.preventTouchMove, { passive: false });
        }
    }

    public componentWillReceiveProps(nextProps: Props) {
        if (nextProps.shapes !== this.props.shapes) {
            this.setState({
                shapes: nextProps.shapes || [],
            });
        }
    }

    public componentWillUnmount(): void {
        if (this.drawAreaRef.current) {
            this.drawAreaRef.current.removeEventListener("touchmove", this.preventTouchMove);
        }
    }

    public render() {
        return(
            <Container
                ref={this.drawAreaRef}
                onMouseDown={!isTouchDevice() ? this.handleMouseDown : undefined}
                onMouseMove={!isTouchDevice() ? this.handleMouseEvent : undefined}
                onMouseUp={!isTouchDevice() ? this.handleMouseEvent : undefined}
                onTouchStart={this.handleMouseDown}
                onTouchMove={this.handleMouseEvent}
                onTouchEnd={this.handleMouseEvent}
            >
                <Shapes
                    tool={this.props.tool}
                    shapes={this.state.shapes}
                    selectedShape={this.state.currentShape}
                    ref={this.shapesRef}
                    onShapeMouseEvent={this.handleShapeMouseEvent}
                />
                {this.props.tool !== "selection" && this.state.currentShape && this.state.status === "inputingText" &&
                    <InputText
                        shape={this.state.currentShape}
                        onFinished={this.handleNewShape}
                        relativeCoordinatesForText={this.relativeCoordinatesForText}
                        relativeCoordinatesForEvent={this.relativeCoordinatesForEvent}
                    />
                }
                {this.props.tool !== "selection" &&  this.state.currentShape && this.state.status === "drawing" &&
                    <NewShape
                        ref={this.newShapeRef}
                        tool={this.props.tool}
                        newShape={this.state.currentShape}
                        newShapeIndex={this.state.currentShapeIndex!}
                        onFinished={this.handleNewShape}
                        relativeCoordinatesForEvent={this.relativeCoordinatesForEvent}
                    />
                }
                {this.props.tool === "selection" && this.state.currentShape && this.state.status === "inputingText" &&
                    <InputText
                        shape={this.state.currentShape}
                        onFinished={this.handleSelectedShape}
                        onDeleted={this.handleDeleteShape}
                        relativeCoordinatesForText={this.relativeCoordinatesForText}
                        relativeCoordinatesForEvent={this.relativeCoordinatesForEvent}
                    />
                }
                {this.props.tool === "selection" && this.state.currentShape && this.state.status === "selected" &&
                    <SelectedShape
                        ref={this.selectedShapeRef}
                        tool={this.props.tool}
                        selectedShape={this.state.currentShape}
                        selectedShapeIndex={this.state.currentShapeIndex!}
                        onFinished={this.handleSelectedShape}
                        onDeleted={this.handleDeleteShape}
                        relativeCoordinatesForEvent={this.relativeCoordinatesForEvent}
                    />
                }
            </Container>
        );
    }

    public createImageUrl = (): string | null => {
        this.updateParentShapes();

        if (this.shapesRef.current) {
            return this.shapesRef.current.createImageUrl();
        } else {
            return null;
        }
    }

    public handleClear = async () => {
        if (this.state.shapes.length > 0) {
            const allowed = await confirmation(<div>Are you sure to clear all drawing items?</div>, {
                uiHeader: "Clear",
                uiAcceptLabel: "Yes",
                uiDeclineLabel: "No",
            });
            if (allowed) {
                this.setState({
                    shapes: [],
                }, () => {
                    this.updateParentShapes();
                });
            }
        }
    }

    public handleColorForSelection = (filledColor: RGBColor, strokeColor: RGBColor) => {
        if (this.selectedShapeRef.current) {
            this.selectedShapeRef.current.handleColor(filledColor, strokeColor);
        }
    }

    public handleLineWeightForSelection = (lineWeight: number, lineType: "solid" | "dashed") => {
        if (this.selectedShapeRef.current) {
            this.selectedShapeRef.current.handleLineWeight(lineWeight, lineType);
        }
    }

    private preventTouchMove = (e: TouchEvent) => {
        e.preventDefault();
    }

    // selection
    private selectShape = (shape: DraperyModule.Shape, index: number) => {
        this.setState({
            status: shape.type === "text" ? "inputingText" : "selected",
            currentShape: shape,
            currentShapeIndex: index,
        });
        this.props.onShapeSelected(true);
    }

    private handleSelectedShape = (shape?: DraperyModule.Shape): void => {
        if (this.state.currentShapeIndex !== undefined && shape) {
            this.state.shapes.splice(this.state.currentShapeIndex, 1, shape);
        }
        this.setState({
            status: "idle",
            currentShape: undefined,
            currentShapeIndex: undefined,
        }, () => {
            this.updateParentShapes();
        });
        this.props.onShapeSelected(false);
    }

    private handleDeleteShape = (): void => {
        if (this.state.currentShapeIndex !== undefined) {
            this.state.shapes.splice(this.state.currentShapeIndex, 1);
            this.setState({
                status: "idle",
                currentShape: undefined,
                currentShapeIndex: undefined,
            }, () => {
                this.updateParentShapes();
            });
            this.props.onShapeSelected(false);
        }
    }

    // new shape
    private startNewShape = (point: DraperyModule.Point): void => {
        if (this.props.tool !== "selection") {
            const newShape: DraperyModule.Shape = {
                points: [{...point}],
                type: this.props.tool,
                arrow: this.props.arrow,
                lineWeight: this.props.lineWeight,
                lineType: this.props.lineType,
                text: "",
                filledColor: this.props.filledColor,
                strokeColor: this.props.strokeColor,
                fontSize: this.props.fontSize,
            };

            this.setState({
                status: "drawing",
                currentShape: newShape,
                currentShapeIndex: this.state.shapes.length,
            });
            this.props.onShapeSelected(false);
        }
    }

    private handleNewShape = (shape?: DraperyModule.Shape, textInputed?: boolean): void => {
        if (shape) {
            if (shape.type === "text" && !textInputed) {
                this.setState({
                    status: "inputingText",
                    currentShape: shape,
                });
                this.props.onShapeSelected(false);
                return;
            }
            this.state.shapes.push(shape);
        }
        this.setState({
            status: "idle",
            currentShape: undefined,
            currentShapeIndex: undefined,
        }, () => {
            this.updateParentShapes();
        });
        this.props.onShapeSelected(false);
    }

    // mouse event
    private handleMouseDown = (event: ReactMouseEvent | ReactTouchEvent) => {
        if (("button" in event && event.button !== 0) || this.props.disabled) {
            return;
        }
        if (this.state.status === "idle" && this.props.tool !== "selection") {
            const point = this.relativeCoordinatesForEvent(event);
            if (point) {
                this.startNewShape(point);
            }
        }
    }

    private handleMouseEvent = (event: ReactMouseEvent | ReactTouchEvent) => {
        if (this.state.status === "drawing") {
            if (this.newShapeRef.current) {
                this.newShapeRef.current.handleMouseEvent(event);
            }
        }
    }

    private handleShapeMouseEvent = (event: ReactMouseEvent | ReactTouchEvent, shape: DraperyModule.Shape, index: number): void => {
        if (("button" in event && event.button !== 0) || this.props.disabled) {
            return;
        }
        if (event.type === "mousedown" || event.type === "touchstart") {
            if (this.state.status === "idle" && this.props.tool === "selection") {
                this.selectShape(shape, index);
            }
        }
    }

    // calculate point
    private relativeCoordinatesForEvent = (event: ReactMouseEvent | ReactTouchEvent): DraperyModule.Point | undefined => {
        const node = this.drawAreaRef.current;
        if (node) {
            const boundingRect = node.getBoundingClientRect();
            const ratioX = viewBox.width / boundingRect.width;
            const ratioY = viewBox.height / boundingRect.height;
            const clientX = "clientX" in event ? event.clientX : event.type === "touchstart" || event.type === "touchend" ? event.changedTouches[0].clientX : event.targetTouches[0].clientX;
            const clientY = "clientY" in event ? event.clientY : event.type === "touchstart" || event.type === "touchend" ? event.changedTouches[0].clientY : event.targetTouches[0].clientY;
            const x = (clientX - boundingRect.left) * ratioX;
            const y = (clientY - boundingRect.top) * ratioY;
            return ({x, y});
        }
    }

    private relativeCoordinatesForText = (point: DraperyModule.Point): DraperyModule.Point | undefined => {
        const node = this.drawAreaRef.current;
        if (node) {
            const boundingRect = node.getBoundingClientRect();
            const ratioX = boundingRect.width / viewBox.width;
            const ratioY = boundingRect.height / viewBox.height;
            const x = point.x * ratioX;
            const y = point.y * ratioY;
            return ({x, y});
        }
    }

    // update parent shapes
    private updateParentShapes = () => {
        this.props.onShapeChanged(this.state.shapes);
    }
}
export default Board;
