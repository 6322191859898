import { ClassAttributes, HTMLAttributes } from "react";
import { breakpoints } from "../theme";
import styled, { css } from "../theme/styled";

// tslint:disable-next-line:no-empty-interface
export interface GridTableCellUIProps {
    uiGridArea?: string;
}

export type GridTableCellProps = HTMLAttributes<HTMLDivElement> & GridTableCellUIProps;

/**
 * Simple table cell with some padding
 */
const GridTableCell = styled.div<GridTableCellProps & ClassAttributes<HTMLDivElement>> `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    max-width: 100%;
    padding: 5px 10px;
    ${breakpoints.down("sm", css`
        padding: 3px 10px;
    `)};
    ${props => props.uiGridArea && `
        grid-area: ${props.uiGridArea};
    `};
`;

/** @component */
export default GridTableCell;
