import { Checkbox, GridTableCell, GridTableRow } from "@ramble/ramble-ui";
import moment from "moment";
import React, {ChangeEvent, PureComponent} from "react";
import { PlanInfoModal } from "../../modals/plan_info_modal";
import { AdminModule } from "../../redux";
import { UserSubscriptionInfo } from "../../redux/modules/admin";
import styled, { css, theme } from "../../theme";
import CopyToClipboard from "../UI/copy-to-clipboard";
import AdminWOHoverBox from "./admin_wo_hover_box";

interface CellProps {
    uiCenter?: boolean;
    uiClickable?: boolean;
}

const Row = styled(GridTableRow)`
    grid-template-columns: 70px 70px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    border-bottom: solid #ddd 1px;

    &:last-child {
        border: 0;
    }

    &:hover {
        background-color: #f8f5f8;
    }
`;

const Cell = styled(GridTableCell)<CellProps>`
    text-align: left;
    display: flex;
    align-items: center;
    ${props => props.uiCenter && css`
        justify-content: center;
    `};
    ${props => props.uiClickable && css`
        color: ${theme.colors.primary};
    `};
`;

export interface AdminRowProps {
    user: AdminModule.UserInfoForAdmin;
    currentUserId: number;
    onUserRoleChanged(userId: number, role: "admin" | "office_worker"): void;
    onGetUserSubscriptionHistory(accountId: number): Promise<UserSubscriptionInfo[]>;
}

interface State {
    isPlanInfoModalOpen: boolean;
    planHistory: UserSubscriptionInfo[];
}

export class AdminRow extends PureComponent<AdminRowProps> {
    public state: State = {
        isPlanInfoModalOpen: false,
        planHistory: [],
    };

    public render(): JSX.Element {
        const { user, currentUserId } = this.props;
        const { planHistory } = this.state;
        return (
            <Row>
            <Cell uiCenter>
                <Checkbox checked={user.role === "admin"} onChange={this.onUserRoleChanged} uiDisabled={user.id === currentUserId}/>
            </Cell>

            <Cell>
                {user.accountId}
            </Cell>

            <Cell>
                {user.companyName}
            </Cell>

            <Cell>
                {user.email && (
                    <CopyToClipboard title={user.email} copy={user.email} tooltipMessage="Email Copied" />
                )}
            </Cell>

            <Cell onClick={this.handleOpenModal} uiClickable>
                {user.planName}
            </Cell>

            <Cell>
                <AdminWOHoverBox
                    monthlyCount={user.currentMonthWorkOrders}
                    planLimit={user.workOrdersLimit}
                    lifetimeCount={user.totalWorkOrders}
                />
            </Cell>

            <Cell>
                {user.totalClients}
            </Cell>

            <Cell>
                {user.totalSuppliers}
            </Cell>

            <Cell>
                {user.lastLogin ? moment(user.lastLogin).format("YYYY-MM-DD hh:mm:ss") : ""}
            </Cell>
            <PlanInfoModal
                active={this.state.isPlanInfoModalOpen}
                onRequestClose={this.handleCloseModal}
                planHistory={planHistory}
            />
        </Row>
        );
    }

    private onUserRoleChanged = (e: ChangeEvent<HTMLInputElement>) => {
        this.props.onUserRoleChanged(this.props.user.id, e.target.checked ? "admin" : "office_worker");
    }

    private handleCloseModal = () => {
        this.setState({ isPlanInfoModalOpen: false });
    }

    private handleOpenModal = async () => {
        const planHistory = await this.props.onGetUserSubscriptionHistory(this.props.user.accountId);
        this.setState({ planHistory, isPlanInfoModalOpen: true });
    }
}
