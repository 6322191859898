import Color from "color";
import { ClassAttributes, HTMLAttributes } from "react";
import styled from "../theme/styled";

// tslint:disable-next-line:no-empty-interface
export interface ModalActionsUIProps {}

export type ModalActionsProps = HTMLAttributes<HTMLElement> &
    ModalActionsUIProps;

const ModalActions = styled.section<
    ModalActionsProps & ClassAttributes<HTMLElement>
>`
    // background: ${({ theme }) =>
        Color(theme.colors.background).darken(0.08).alpha(0.8).string()};
    padding: 1rem;
    border-top: 1px solid ${({ theme }) => theme.colors.divider};
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > * {
        font-weight: 400 !important;
    }

    > *:not(:last-child) {
        margin-right: 0.4em;
    }
`;
export default ModalActions;
