import { Api, BACKEND_REALTIME_EVENT, CLIENT_REALTIME_EVENT } from "@quipa/api";

import io from "socket.io-client";

/**
 * Realtime api client
 */
export class RealtimeClient {
    /**
     * Number of current connection/reconnection attempts
     */
    public connectionAttempts = 0;

    /**
     * Client socket
     */
    private socket: SocketIOClient.Socket;

    /**
     * Pending actions map. Each action represented by corresponding promise
     */
    private pendingActions: Map<
        Promise<any>,
        { reject: (e: Error) => void; resolve: (e: any) => void }
    >;

    /**
     * Flag indicating what we had success connection initially.
     */
    private initiallyConnected = false;

    /**
     * Connection promise
     */
    private connectionPromise: Promise<any>;

    // Connection promise handlers
    private connectionPromiseResolve: () => void;
    /**
     * Paused flag
     */
    private paused: boolean = false;

    /**
     * @constructor
     */
    public constructor(realtimeUrl: string, deviceId: string) {
        this.pendingActions = new Map();
        // Delay connecting until call connect()
        this.socket = io(realtimeUrl + "/api", {
            autoConnect: false,
            reconnection: true,
            query: {
                apiVersion: Api.API_VERSION,
                deviceId,
            },
        });

        this.socket.on("connect", this.onConnect.bind(this));
        this.socket.on("connect_error", this.onConnectError.bind(this));
        this.socket.on("error", this.onSocketError.bind(this));
        this.socket.on("disconnect", this.onDisconnect.bind(this));
        this.socket.on("reconnecting", this.onReconnecting.bind(this));

        this.socket.on(BACKEND_REALTIME_EVENT, this.onRealtimeEvent.bind(this));
    }

    /**
     * Callback called when attempting to connect
     */
    public onConnecting?(): void;
    /**
     * Callback called when connected
     * @param reconnect True if reconnected
     */
    public onConnected?(reconnect: boolean): void;
    /**
     * Callback called when disconnected/failed to connect
     * @param initiallyConnected True if it was disconnected, false when it was failed to connect initially
     * @param paused True if connection was paused by explicit request
     */
    public onDisconnected?(initiallyConnected: boolean, paused: boolean): void;
    /**
     * Callback called when receiving the server action from the server
     */
    public onServerAction?(event: any): void;
    /**
     * Initiate socket connection
     */
    public connect() {
        // this.store = store;
        // inform app that we're trying to connect
        if (this.onConnecting) {
            this.onConnecting();
        }
        // Update connection attempts
        this.connectionAttempts++;

        // create connection promise
        this.connectionPromise = new Promise<void>((resolve, reject) => {
            this.connectionPromiseResolve = resolve;
        });
        this.socket.connect();
    }

    /**
     * Disconnect from realtime backend
     */
    public disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }

    /**
     * Send action to realtime backend
     */
    public sendAction(action: any): Promise<any> {
        // using function () since need this to point to promise
        // tslint:disable-next-line:no-this-assignment
        const self = this;
        return new Promise<any>(async function (
            this: Promise<any>,
            resolve: (a: any) => void,
            reject: (e: Error) => void
        ) {
            try {
                // for active connection we should have always resolved connectionPromise
                if (!self.connectionPromise) {
                    throw new Error("Socket is not connected");
                }
                self.pendingActions.set(this, { resolve, reject });
                await self.connectionPromise;
                self.socket.emit(
                    CLIENT_REALTIME_EVENT,
                    action,
                    (response: any) => {
                        self.pendingActions.delete(this);
                        resolve(response);
                    }
                );
            } catch (e) {
                if (self.pendingActions.has(this)) {
                    self.pendingActions.delete(this);
                }
                reject(e);
            }
        });
    }

    /**
     * Pause application
     */
    public pause(): void {
        // disable reconnection
        this.paused = true;
        this.connectionPromise = new Promise<void>((resolve, reject) => {
            this.connectionPromiseResolve = resolve;
        });

        // disconnect socket
        this.socket.disconnect();
        // Inform app that we've paused
        if (this.onDisconnected) {
            this.onDisconnected(this.initiallyConnected, true);
        }
    }

    /**
     * Resume application and resync
     */
    public resume(): void {
        // reset connection attempts
        this.connectionAttempts = 0;
        this.paused = false;
        this.socket.connect();
    }

    /**
     * Return if connected
     */
    public isConnected(): boolean {
        return this.socket.connected;
    }

    /**
     * Successful connection handler
     */
    protected onConnect() {
        // reset connection attempts
        this.connectionAttempts = 0;
        // resolve connection promise
        if (this.connectionPromise) {
            this.connectionPromiseResolve();
        }
        // indicate that we're connected
        if (this.onConnected) {
            this.onConnected(this.initiallyConnected);
        }
        // set initial connection flag
        this.initiallyConnected = true;
    }

    /**
     * Socket errors handler
     */
    protected onSocketError(error: Error) {
        // let appError = createFromObject(error);
        // this.socket.off("connect");
        // this.store.dispatch(applicationLoadFailed(appError));
    }

    /**
     * Socket trying to reconnecting
     */
    protected onReconnecting() {
        // We're indicating status only for >3 reconnection
        if (this.connectionAttempts > 3) {
            // this causing flashing
            // if (this.onConnecting) {
            //     this.onConnecting();
            // }
        }
        this.connectionAttempts++;
    }

    protected onConnectError() {
        // Indicate that we've lost the connection after third failed connection attempt
        if (this.connectionAttempts > 3) {
            if (this.onDisconnected) {
                this.onDisconnected(this.initiallyConnected, false);
            }
            this.resetPendingActions();
            this.connectionAttempts = 0;
        }
    }

    /**
     * Disconnect handler
     */
    protected onDisconnect() {
        // pause() sets own promise
        if (!this.paused) {
            // We're not indicating app what we're disconnected, since it could restore connection almost immediately.
            // We'll indicate app about losing connection only after third failed reconnection attempt
            // instead create new connectionPromise
            this.connectionPromise = new Promise<void>((resolve, reject) => {
                this.connectionPromiseResolve = resolve;
            });
        }
    }

    protected resetPendingActions() {
        this.pendingActions.forEach((promiseControl) => {
            promiseControl.reject(new Error("Realtime connection was lost"));
        });

        this.pendingActions.clear();
    }

    /**
     * Incoming realtime event handler
     */
    protected onRealtimeEvent(action: any) {
        if (this.onServerAction) {
            this.onServerAction(action);
        }
    }
}
