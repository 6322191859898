import Color from "color";
import Bind from "lodash-decorators/bind";
import React, { FormEvent, PureComponent, ReactElement } from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { DimmerProps } from "../dimmer";
import Dimmer from "../dimmer/dimmer";
import Form, { FormProps } from "../form/form";
import { Loader } from "../loader";
import styled from "../theme/styled";
import { forwardRef, WithForwardedRef } from "../utils/ref";

/**
 * Field name -> errors mapping
 */
export interface FormValidationErrorMap {
    [key: string]: string;
}

export interface FormValidatorErrorMap {
    [key: string]: string | false | undefined;
}

/**
 * Represent server validation errors
 */
export class FormValidationError extends Error {
    /**
     * Convert Validation error to FormServerValidationError
     * @param error
     * @param keyReplacer
     */
    public static fromErrorDictionary(
        error: { [key: string]: string },
        keyReplacer?: { [key: string]: string }
    ): FormValidationError {
        const newErrors = {};
        for (const key of Object.keys(error)) {
            newErrors[
                keyReplacer && keyReplacer[key] ? keyReplacer[key] : key
            ] = error[key];
        }
        return new FormValidationError(newErrors);
    }
    /**
     * Errors
     */
    public readonly errors: FormValidationErrorMap;

    /**
     * @constructor
     * @param errors
     */
    public constructor(errors: FormValidationErrorMap) {
        super("Validation error");
        Object.setPrototypeOf(this, FormValidationError.prototype);
        this.errors = errors;
    }
}

export interface ValidationFormProps extends FormProps {
    /**
     * Form dimmer color
     */
    uiDimmerColor?: DimmerProps["uiColor"];
    /**
     * Dimmer blurring
     * @default true
     */
    uiDimmerBlurring?: boolean;
    /**
     * Dim form and render loader when submitting
     */
    uiUseDimmer?: boolean;
    /**
     * Render error icon for error message
     * When true, renders default error icon
     * When ReactElement given renders it instead
     */
    uiSubmitFailedIcon?: true | ReactElement<any>;
    /**
     * General submit failed message
     */
    uiSubmitFailedMessage?: string;
    /**
     * If true when uses submit failed message from Error object
     */
    uiUseSubmitFailedMessageFromError?: boolean;
    /**
     * Error reporting mode:
     * * firstInvalid - will report error only for first invalid field. Preferred mode if you're displaying errors in popup
     * * allInvalid - will report error for all invalid fields
     * @default "allInvalid"
     */
    uiReportValidityMode?: "firstInvalid" | "allInvalid";
    /**
     * Custom validator run before submit. Note: only run if standard elements are already valid
     * @param e Form element
     */
    uiFormValidator?(e: HTMLFormElement): FormValidatorErrorMap;
    /**
     * Submit callback
     * @param e Form event
     */
    uiOnSubmit(e: FormEvent<HTMLFormElement>): Promise<void>;
    /**
     * Called for form element which has failed validation with "firstInvalid" mode
     * @param e HTMLelement with failed validation
     */
    uiOnInvalidElem?(e: HTMLElement): void;
    /**
     * Called when failed form validation (or any form element validation)
     * @param e HTML form element
     */
    uiOnInvalidForm?(e: HTMLFormElement): void;
}

interface State {
    errorMsg?: string;
    /**
     * Submitting flag
     */
    submitting: boolean;
}

class ValidationFormComponent extends PureComponent<
    ValidationFormProps,
    State
> {
    public static defaultProps: Partial<ValidationFormProps> = {
        uiDimmerColor: "transparent",
        uiDimmerBlurring: false,
        uiSubmitFailedMessage:
            "There was an error. If the problem persists, please contact support.",
        uiUseDimmer: true,
        uiReportValidityMode: "allInvalid",
    };

    public state: State = {
        errorMsg: undefined,
        submitting: false,
    };

    public render(): JSX.Element {
        const {
            children,
            uiDimmerColor,
            uiDimmerBlurring,
            uiSubmitFailedMessage,
            uiUseSubmitFailedMessageFromError,
            uiOnSubmit,
            forwardedRef,
            uiUseDimmer,
            uiSubmitFailedIcon,
            uiReportValidityMode,
            ...other
        } = this.props as WithForwardedRef<ValidationFormProps>;
        const { errorMsg, submitting } = this.state;
        const errorIcon =
            typeof uiSubmitFailedIcon === "boolean" ? (
                <FaExclamationCircle />
            ) : (
                uiSubmitFailedIcon || null
            );

        return (
            <Form
                {...other}
                // we use own submit validation logic
                noValidate
                onSubmit={this.handleSubmit}
                ref={forwardedRef}
            >
                <Dimmer
                    uiBlurring={uiDimmerBlurring}
                    uiColor={uiDimmerColor}
                    uiActive={submitting}
                >
                    <Loader />
                </Dimmer>
                {children}
                {errorMsg && !submitting && (
                    <div className="error-msg">
                        {errorIcon}
                        {uiUseSubmitFailedMessageFromError
                            ? uiSubmitFailedMessage
                            : errorMsg ||
                              "There was an error submitting this form. If the problem persists, please contact support."}
                    </div>
                )}
            </Form>
        );
    }

    @Bind()
    private async handleSubmit(
        e: React.FormEvent<HTMLFormElement>
    ): Promise<void> {
        const {
            uiOnSubmit,
            uiUseDimmer,
            uiFormValidator,
            uiReportValidityMode,
            uiOnInvalidElem,
            uiOnInvalidForm,
        } = this.props;
        e.stopPropagation();
        e.preventDefault();
        const form = e.currentTarget;
        // note: form.checkValidity() will display error messages for all fields

        // check/report validity
        if (uiReportValidityMode === "allInvalid") {
            // for allInvalid mode just report validity for whole form and bail if non valid
            if (!form.reportValidity()) {
                if (uiOnInvalidForm) {
                    uiOnInvalidForm(form);
                }
                return;
            }
        } else {
            let hasInvalid = false;
            for (let i = 0; i < form.elements.length; i++) {
                const elem = form.elements.item(i);
                if (elem && "checkValidity" && "reportValidity" in elem) {
                    const elemValid = (
                        elem as HTMLInputElement
                    ).checkValidity();
                    if (!elemValid) {
                        hasInvalid = true;
                        (elem as HTMLInputElement).reportValidity();
                        if (uiOnInvalidElem) {
                            uiOnInvalidElem(elem);
                        }
                        break;
                    }
                }
            }
            if (hasInvalid) {
                if (uiOnInvalidForm) {
                    uiOnInvalidForm(form);
                }
                return;
            }
        }

        if (uiFormValidator) {
            const validity = uiFormValidator(form);
            if (validity && Object.entries(validity).find(([, val]) => !!val)) {
                for (const [field, val] of Object.entries(validity)) {
                    if (!val) {
                        continue;
                    }
                    const elem = form.elements[field];
                    if (elem && "setCustomValidity" in elem) {
                        (elem as HTMLInputElement).setCustomValidity(val);
                        if (uiReportValidityMode === "firstInvalid") {
                            (elem as HTMLInputElement).reportValidity();
                            if (uiOnInvalidElem) {
                                uiOnInvalidElem(elem);
                            }
                            break;
                        }
                    }
                }
                if (uiReportValidityMode === "allInvalid") {
                    form.reportValidity();
                }
                if (uiOnInvalidForm) {
                    uiOnInvalidForm(form);
                }
                return;
            }
        }

        try {
            if (uiUseDimmer) {
                this.setState({ submitting: true });
            }
            await uiOnSubmit(e);
        } catch (err) {
            if (err instanceof FormValidationError) {
                if (!Object.keys(err.errors).length) {
                    this.setState({ errorMsg: err.message });
                    return;
                }
                for (const key of Object.keys(err.errors)) {
                    const elem = form.elements[key];
                    if (
                        elem &&
                        "setCustomValidity" in elem &&
                        err.errors[key]
                    ) {
                        elem.setCustomValidity(err.errors[key]);
                        if (uiOnInvalidElem) {
                            uiOnInvalidElem(elem);
                        }
                    }
                }
                form.reportValidity();
                if (uiOnInvalidForm) {
                    uiOnInvalidForm(form);
                }
            } else {
                const message = err.message.split(":")[1];
                this.setState({ errorMsg: message });
            }
        } finally {
            if (uiUseDimmer) {
                this.setState({ submitting: false });
            }
        }
    }
}

const ForwardRefForm = forwardRef<HTMLFormElement, ValidationFormProps>(
    ValidationFormComponent
);
const ValidationForm = styled(ForwardRefForm)`
    > .error-msg {
        margin-top: 1em;
        font-size: 17px;
        background: transparent;
        display: none !important;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        padding: 1em 1.5em 1em;
        gap: 5px;
        color: ${({ theme }) =>
            Color(theme.colors.danger).lighten(0.2).string()};
        min
        /* border-top: 1px solid #9f3a38; */
    }
    
`;
export default ValidationForm;
