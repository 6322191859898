import { BotInfo, BrowserInfo, NodeInfo } from "detect-browser";
import {
    FabricsOrLiningsItemDetailsFields,
    FabricsOrLiningsItemDetailsFieldsAction,
} from "../../../redux/modules/my-shop/fabrics-or-linings-item-details-fields-reducer";
import {
    FormInput,
    FormLabel,
    RadioInput,
    RadioLabel,
    SubFormHeader,
    TooltipCell,
    TooltipContainer,
    TooltipExtraWidePopup,
    TooltipHeader,
    TooltipImgContainer,
    TooltipPopup,
    TooltipRow,
    TooltipText,
} from "../styles";
import React, { ChangeEvent, Dispatch, FC } from "react";

import { Checkbox } from "@ramble/ramble-ui";
import TooltipMatchRepeat from "../../../assets/images/tooltip/match_repeat.png";
import TooltipNoMatch from "../../../assets/images/tooltip/no_match.png";
import TooltipNoPleatToPattern from "../../../assets/images/tooltip/no_pleat_to_pattern.png";
import TooltipPleatToPattern from "../../../assets/images/tooltip/pleat_to_pattern.png";
import TooltipRailroadE from "../../../assets/icons/tooltip/railroad.svg";
import TooltipUpTheRollE from "../../../assets/icons/tooltip/up_the_roll.svg";
import styled from "../../../theme";
import { validatePricingInput } from "../helpers";

export const TooltipUpTheRoll = styled(TooltipUpTheRollE)`
    margin: 20px 0;
    width: 200px;
`;

export const TooltipRailroad = styled(TooltipRailroadE)`
    margin: 20px 0;
    width: 200px;
`;

interface SpecificationsProps {
    browser: BrowserInfo | BotInfo | NodeInfo | null;
    fabricsOrLiningsItemDetailsFields: FabricsOrLiningsItemDetailsFields;
    dispatch: Dispatch<FabricsOrLiningsItemDetailsFieldsAction>;
}

const Specifications: FC<SpecificationsProps> = ({
    browser,
    fabricsOrLiningsItemDetailsFields,
    dispatch,
}) => {
    const {
        fabricWidthInches,
        verticalRepeatInches,
        horizontalRepeatInches,
        isDropRepeatOn,
        isMatchRepeatAtSeamsOn,
        isPleatToPatternOn,
        directionOfUseTypeId,
        isSpecialtyFabricHandlingOn,
    } = fabricsOrLiningsItemDetailsFields;

    const inputContainerClassName =
        "max-w-[80%] py-2 flex flex-row items-center justify-between";

    const autoCompleteOffSetting =
        browser && browser.name === "chrome" ? "new-password" : "off";

    const checkIfDropRepeatDisabled = () => {
        const { verticalRepeatInches } = fabricsOrLiningsItemDetailsFields;
        if (!verticalRepeatInches || verticalRepeatInches === "0") {
            return true;
        }
        return false;
    };

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement>,
        keyParam?: string,
        type: string = "inches"
    ) => {
        const updates = validatePricingInput({
            event: e,
            keyParam,
            type,
        });
        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updates,
        });
    };

    const toggleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const fieldName = e.currentTarget.name;
        const value = !fabricsOrLiningsItemDetailsFields[fieldName];

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: {
                [fieldName]: value,
            },
        });
    };

    const handleFieldChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        let key = e.currentTarget.id;
        let value: string | number = e.currentTarget.value;

        if (["douUpRoll", "douRailroad"].includes(key)) {
            key = "directionOfUseTypeId";
            value = +value;
        }

        const updatedForm = {
            [key]: value,
        };

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updatedForm,
        });
    };

    const isDropRepeatDisabled = checkIfDropRepeatDisabled();

    return (
        <div className="flex flex-wrap w-full items-start">
            <div className="w-full lg:w-3/4 mb-6 pr-6">
                <div className="flex items-center font-bold tracking-wider place-content-between mt-4 mb-4 text-twBlue1">
                    SPECIFICATIONS
                </div>
                <div className="flex flex-col lg:flex-row justify-between w-full">
                    <div className="w-full lg:w-2/4 max-w-[400px]">
                        <SubFormHeader>Dimensions</SubFormHeader>
                        <div className={inputContainerClassName}>
                            <FormLabel htmlFor="fabricWidthInches">
                                Fabric Width
                            </FormLabel>
                            <div>
                                <FormInput
                                    id="fabricWidthInches"
                                    name="fabricWidthInches"
                                    className="p-2 ml-2 max-w-[80px]"
                                    type="text"
                                    value={fabricWidthInches}
                                    onChange={handleInputChange}
                                    uiReportOnBlur={false}
                                    disabled={false}
                                    uiDisabled={false}
                                    list="autocompleteOff"
                                    autoComplete={autoCompleteOffSetting}
                                />
                                &nbsp;inches
                            </div>
                        </div>
                        <div className={inputContainerClassName}>
                            <FormLabel htmlFor="verticalRepeatInches">
                                Vertical Repeat
                            </FormLabel>
                            <div>
                                <FormInput
                                    id="verticalRepeatInches"
                                    name="verticalRepeatInches"
                                    className="p-2 ml-2 max-w-[80px]"
                                    type="text"
                                    value={verticalRepeatInches}
                                    onChange={handleInputChange}
                                    uiReportOnBlur={false}
                                    disabled={false}
                                    uiDisabled={false}
                                    list="autocompleteOff"
                                    autoComplete={autoCompleteOffSetting}
                                />
                                &nbsp;inches
                            </div>
                        </div>
                        <div className={inputContainerClassName}>
                            <FormLabel htmlFor="horizontalRepeatInches">
                                Horizontal Repeat
                            </FormLabel>
                            <div>
                                <FormInput
                                    id="horizontalRepeatInches"
                                    name="horizontalRepeatInches"
                                    className="p-2 ml-2 max-w-[80px]"
                                    type="text"
                                    value={horizontalRepeatInches}
                                    onChange={handleInputChange}
                                    uiReportOnBlur={false}
                                    disabled={false}
                                    uiDisabled={false}
                                    list="autocompleteOff"
                                    autoComplete={autoCompleteOffSetting}
                                />
                                &nbsp;inches
                            </div>
                        </div>
                        <div className="mt-2 mb-4">
                            <Checkbox
                                name="isDropRepeatOn"
                                checked={
                                    isDropRepeatDisabled
                                        ? false
                                        : isDropRepeatOn
                                }
                                onChange={toggleCheckbox}
                                disabled={isDropRepeatDisabled}
                            />
                            <span>Drop Repeat</span>
                        </div>
                    </div>
                    <div className="flex flex-col w-2/4">
                        <div className="mb-6">
                            <SubFormHeader>
                                Pattern Matching{" "}
                                <TooltipExtraWidePopup
                                    text={
                                        <>
                                            <br />
                                            <TooltipContainer>
                                                <TooltipRow>
                                                    <TooltipCell>
                                                        <TooltipImgContainer className="h-[150px]">
                                                            <img
                                                                src={
                                                                    TooltipPleatToPattern
                                                                }
                                                            />
                                                        </TooltipImgContainer>
                                                    </TooltipCell>
                                                    <TooltipCell>
                                                        <TooltipImgContainer className="h-[150px]">
                                                            <img
                                                                src={
                                                                    TooltipNoPleatToPattern
                                                                }
                                                            />
                                                        </TooltipImgContainer>
                                                    </TooltipCell>
                                                </TooltipRow>
                                                <TooltipRow>
                                                    <TooltipCell>
                                                        <TooltipHeader>
                                                            PLEAT TO PATTERN
                                                        </TooltipHeader>
                                                        <TooltipText>
                                                            Positioning the
                                                            pleats on the
                                                            drapery in alignment
                                                            with a particular
                                                            feature of the
                                                            fabric pattern. This
                                                            typically increases
                                                            the yardage required
                                                            both horizontally
                                                            and vertically.
                                                        </TooltipText>
                                                    </TooltipCell>
                                                    <TooltipCell>
                                                        <TooltipHeader>
                                                            STANDARD PLEATING
                                                        </TooltipHeader>
                                                        <TooltipText>
                                                            Positioning the
                                                            pleats based on a
                                                            mathematical
                                                            calculation without
                                                            regard to the fabric
                                                            pattern.
                                                        </TooltipText>
                                                    </TooltipCell>
                                                </TooltipRow>
                                                <TooltipRow>
                                                    <TooltipCell>
                                                        <TooltipImgContainer className="h-[150px]">
                                                            <img
                                                                src={
                                                                    TooltipMatchRepeat
                                                                }
                                                            />
                                                        </TooltipImgContainer>
                                                    </TooltipCell>
                                                    <TooltipCell>
                                                        <TooltipImgContainer className="h-[150px]">
                                                            <img
                                                                src={
                                                                    TooltipNoMatch
                                                                }
                                                            />
                                                        </TooltipImgContainer>
                                                    </TooltipCell>
                                                </TooltipRow>
                                                <TooltipRow>
                                                    <TooltipCell>
                                                        <TooltipHeader>
                                                            MATCH REPEAT
                                                        </TooltipHeader>
                                                        <TooltipText>
                                                            Sewing two pieces of
                                                            fabric together so
                                                            the pattern aligns
                                                            and continues across
                                                            the seam.
                                                        </TooltipText>
                                                    </TooltipCell>
                                                    <TooltipCell>
                                                        <TooltipHeader>
                                                            NO MATCH REPEAT
                                                        </TooltipHeader>
                                                        <TooltipText>
                                                            Where matching the
                                                            pattern at the seams
                                                            is not necessary or
                                                            undesired in order
                                                            to use less yardage.
                                                        </TooltipText>
                                                    </TooltipCell>
                                                </TooltipRow>
                                            </TooltipContainer>
                                        </>
                                    }
                                    position="left center"
                                    className="floatRight"
                                    size="200"
                                />
                            </SubFormHeader>
                            <div className="mt-2 mb-4">
                                <Checkbox
                                    name="isMatchRepeatAtSeamsOn"
                                    checked={isMatchRepeatAtSeamsOn}
                                    onChange={toggleCheckbox}
                                    disabled={false}
                                />
                                <span>Match Repeat at Seams/Edges</span>
                                
                            </div>
                            <div className="mt-2 mb-4">
                                <Checkbox
                                    name="isPleatToPatternOn"
                                    checked={isPleatToPatternOn}
                                    onChange={toggleCheckbox}
                                    disabled={false}
                                />
                                <span>Pleat to Pattern</span>
                            </div>
                        </div>
                        <div>
                            <SubFormHeader>
                                Direction of Use
                                <TooltipPopup
                                    text={
                                        <>
                                            <br />
                                            <TooltipContainer>
                                                <TooltipImgContainer className="h-[150px]">
                                                    <TooltipUpTheRoll />
                                                </TooltipImgContainer>
                                                <TooltipHeader>
                                                    UP THE ROLL
                                                </TooltipHeader>
                                                <TooltipText>
                                                    Using the fabric up the roll
                                                    where the selvage edge runs
                                                    vertically on the
                                                </TooltipText>
                                                <TooltipImgContainer className="h-[150px]">
                                                    <TooltipRailroad />
                                                </TooltipImgContainer>
                                                <TooltipHeader>
                                                    RAILROAD
                                                </TooltipHeader>
                                                <TooltipText>
                                                    Using the fabric across the
                                                    roll where the selvage edge
                                                    runs horizontally across the
                                                    treatment.
                                                </TooltipText>
                                            </TooltipContainer>
                                        </>
                                    }
                                    position="top right"
                                    className="floatRight"
                                    size="250"
                                />
                            </SubFormHeader>
                            <div className="mt-2">
                                <RadioLabel className="mb-2">
                                    <RadioInput
                                        id="douUpRoll"
                                        name="directionOfUse"
                                        type="radio"
                                        checked={directionOfUseTypeId === 1}
                                        value={1}
                                        onChange={handleFieldChange}
                                        uiReportOnBlur={false}
                                        disabled={false}
                                    />
                                    Up the roll
                                </RadioLabel>
                                <RadioLabel className="mb-2">
                                    <RadioInput
                                        id="douRailroad"
                                        name="directionOfUse"
                                        type="radio"
                                        checked={directionOfUseTypeId === 2}
                                        value={2}
                                        onChange={handleFieldChange}
                                        uiReportOnBlur={false}
                                        disabled={false}
                                    />
                                    Railroad
                                </RadioLabel>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-8">
                    <Checkbox
                        name="isSpecialtyFabricHandlingOn"
                        checked={isSpecialtyFabricHandlingOn}
                        onChange={toggleCheckbox}
                        disabled={false}
                    />
                    <span>Specialty fabric handling</span>
                </div>
            </div>
        </div>
    );
};

export default Specifications;
