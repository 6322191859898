import React, { ClassAttributes, HTMLAttributes } from "react";
import styled from "../theme/styled";

export interface LoaderTextUIProps {
    children: React.ReactChild;
}

export type LoaderTextProps = HTMLAttributes<HTMLDivElement> & LoaderTextUIProps;

const LoaderText = styled.div<LoaderTextProps & ClassAttributes<HTMLDivElement>>`
    margin-top: 0.4rem;
    color: inherit;
    font-size: 0.5em;
`;

/**
 * Loader text is variation of header used below the loader icon
 */
export default LoaderText;
