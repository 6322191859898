import {
    Button,
    FormField,
    PageDimmer,
    ValidationForm,
    ValidationPopupInput,
    ValidationPopupStateInput,
    ValidityMessagesMap,
} from "@ramble/ramble-ui";
import Bind from "lodash-decorators/bind";
import React, { FormEvent, PureComponent } from "react";
import { connect, DispatchProp } from "react-redux";
import { ContentSection } from "../components/content/content_section";
import { PageContent } from "../components/content/page_content";
import { PageHeader } from "../components/content/page_header";
import { UpdatePasswordModal } from "../modals/update_password_modal";
import { RootState, UserModule } from "../redux";
import styled from "../theme";
import { updateCrispEmail, getCrispProfile } from "../api/crisp";
import { NotificationsModule } from "../redux";
import { Crisp } from "crisp-sdk-web";
export interface UserSettingsProps {
    email: string;
    firstName: string;
    lastName: string;
    accountId: number;
}

const UserForm = styled(ValidationForm)`
    max-width: 500px;
`;

const UpdatePasswordButton = styled(Button)`
    margin-top: 1em;
    color: white;
`;

interface State {
    updatePasswordActive: boolean;
}

export class UserSettings extends PureComponent<
    UserSettingsProps & DispatchProp,
    State
> {
    public state: State = {
        updatePasswordActive: false,
    };

    private validationMessages: ValidityMessagesMap<"username" | "email"> = {
        username: {
            nonUnique: "An account with that Username already exists",
            patternMismatch: "Only alphanum characters are allowed",
        },
        email: {
            nonUnique: "An account with that Email already exists",
        },
    };

    public render(): JSX.Element {
        const { email, firstName, lastName } = this.props;
        return (
            <PageContent>
                <PageHeader title="User Settings" />
                <ContentSection>
                    <UserForm
                        uiPadding={false}
                        uiSubmitFailedIcon
                        uiReportValidityMode="firstInvalid"
                        uiOnSubmit={this.submit}
                        uiDimmerBlurring={false}
                    >
                        <FormField
                            uiRequired
                            uiLabel="E-Mail"
                            uiLabelFor="email"
                        >
                            <ValidationPopupStateInput
                                id="email"
                                name="email"
                                type="email"
                                required
                                tabIndex={2}
                                maxLength={255}
                                pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                                defaultValue={email}
                                uiCustomAsyncValidator={
                                    this.uniquenessValidator
                                }
                                uiValidityMessages={
                                    this.validationMessages.email
                                }
                                uiReportAsyncValidationPending
                                uiReportResultTimeout={3000}
                                uiPopupPosition="bottom right"
                            />
                        </FormField>
                        <FormField
                            uiRequired
                            uiLabel="First Name"
                            uiLabelFor="firstname"
                        >
                            <ValidationPopupInput
                                id="firstname"
                                name="firstname"
                                required
                                tabIndex={3}
                                maxLength={255}
                                defaultValue={firstName}
                                uiPopupPosition="bottom right"
                            />
                        </FormField>
                        <FormField
                            uiRequired
                            uiLabel="Last Name"
                            uiLabelFor="lastname"
                        >
                            <ValidationPopupInput
                                id="lastname"
                                name="lastname"
                                required
                                tabIndex={3}
                                maxLength={255}
                                defaultValue={lastName}
                                uiPopupPosition="bottom right"
                            />
                        </FormField>
                        <Button type="submit" uiColor="primary">
                            Update
                        </Button>
                        <UpdatePasswordButton
                            type="button"
                            uiColor="secondary"
                            onClick={this.openPasswordUpdateModal}
                        >
                            Update Password
                        </UpdatePasswordButton>
                    </UserForm>
                </ContentSection>
                <PageDimmer uiActive={this.state.updatePasswordActive} />
                <UpdatePasswordModal
                    active={this.state.updatePasswordActive}
                    onRequestClose={this.closePasswordUpdateModal}
                    onUpdatePassword={this.updatePassword}
                />
            </PageContent>
        );
    }

    @Bind()
    private async uniquenessValidator(
        val: string,
        elem: HTMLInputElement
    ): Promise<{ [key: string]: boolean } | undefined> {
        const { dispatch, email } = this.props;
        val = val.trim();
        if (elem.id === "email" && email !== val) {
            const res = await dispatch(UserModule.validateEmail(val));
            if (res.error) {
                return {
                    nonUnique: true,
                };
            }
        }
    }

    @Bind()
    private async submit(e: FormEvent<HTMLFormElement>): Promise<void> {
        const { dispatch, firstName, lastName, email, accountId } = this.props;
        /* tslint:disable:no-string-literal */
        const newEmail = (
            e.currentTarget.elements["email"] as HTMLInputElement
        ).value.trim();
        const newFirstName = (
            e.currentTarget.elements["firstname"] as HTMLInputElement
        ).value.trim();
        const newLastName = (
            e.currentTarget.elements["lastname"] as HTMLInputElement
        ).value.trim();
        const userSettingsPayload = {
            email: email !== newEmail ? newEmail : undefined,
            firstName: firstName !== newFirstName ? newFirstName : undefined,
            lastName: lastName !== newLastName ? newLastName : undefined,
        };

        const res = await dispatch(UserModule.updateUser(userSettingsPayload));
        const accountPayload = {
            email: email !== newEmail ? newEmail : email,
            firstName: firstName !== newFirstName ? newFirstName : firstName,
            lastName: lastName !== newLastName ? newLastName : lastName,
            prevEmail: email,
        };
        if (res.error) {
            console.log("error:", res.payload.message);
            return;
        }
        const responseCrisp = await updateCrispEmail(accountId, accountPayload);
        if (responseCrisp.status === 200) {
            const notification: NotificationsModule.Notification = {
                id: 0,
                message: "Success!",
                subMessage: "User settings updated.",
                type: "info",
            };
            dispatch(NotificationsModule.addNotification(notification));
            const profile = await getCrispProfile(accountId);
            if (profile) {
                Crisp.user.setEmail(profile.email);
                Crisp.user.setNickname(`${profile.person.nickname}`);
            }
        }
    }

    @Bind()
    private openPasswordUpdateModal(): void {
        this.setState({
            updatePasswordActive: true,
        });
    }

    @Bind()
    private closePasswordUpdateModal(): void {
        this.setState({
            updatePasswordActive: false,
        });
    }

    @Bind()
    private async updatePassword(
        oldPass: string,
        newPass: string
    ): Promise<void> {
        const { dispatch } = this.props;
        const res = await dispatch(
            UserModule.updateUserPassword(oldPass, newPass)
        );
        if (res.error) {
            throw new Error(res.payload.message);
        }
    }
}

function mapStateToProps(state: RootState): UserSettingsProps {
    return {
        email: state.user.email,
        firstName: state.user.firstName,
        lastName: state.user.lastName,
        accountId: state.account.id,
    };
}

export const UserSettingsContainer = connect(mapStateToProps)(UserSettings);
