import { ClassAttributes, SVGAttributes } from "react";
import CloseIcon from "../icons/close_icon";
import styled from "../theme/styled";

export type ModalCloseIconProps = SVGAttributes<SVGSVGElement>;

const ModalCloseIcon = styled(CloseIcon)<ModalCloseIconProps & ClassAttributes<SVGSVGElement>> `
    cursor: pointer;
    opacity: 0.8;
    font-size: 1em;
    margin-left: auto;
    fill: #3B97B1; 
    width: 11.702px;
    height: 11.702px;
    flex-shrink: 0;
`;

export default ModalCloseIcon;
