import { ClassAttributes, HTMLAttributes } from "react";
import styled, { css } from "../theme/styled";

export interface GridTableRowUIProps {
    /**
     * Selectable row
     * @default true
     */
    uiSelectable?: boolean;
    /**
     * Header row. Setting it to true will make it unselectable
     * @default false
     */
    uiHeader?: boolean;
    /**
     * True if even rows should have different color
     * @default false
     */
    uiEven?: boolean;
    /**
     * Display border
     */
    uiBorder?: boolean;
}

export type GridTableRowProps = HTMLAttributes<HTMLDivElement> &
    GridTableRowUIProps;

const GridTableRow = styled.div<
    GridTableRowProps & ClassAttributes<HTMLDivElement>
>`
    display: grid;
    width: 100%;
    position: relative;
    font-weight: normal;
    padding: 6px 0;
    background: ${(props) => props.theme.colors.background};
    -webkit-tap-highlight-color: transparent;
    transition: background, color 1.5s linear;
    column-gap: 10px;
    justify-items: stretch;
    align-items: stretch;
    justify-content: stretch;
    align-content: start;
    flex: 0 0 auto;
    ${(props) =>
        (props.uiBorder || props.uiHeader) &&
        css`
            &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.07);
            }
        `};
    ${(props) =>
        props.uiSelectable &&
        !props.uiHeader &&
        css`
        cursor: pointer;

        &:hover #eee;
        }
    `};
    ${(props) =>
        props.uiEven &&
        !props.uiHeader &&
        css`
            :nth-child(even) {
                background: rgb(245, 245, 245);
            }
        `};
    ${(props) =>
        props.uiHeader &&
        css`
            border-left: 0;
            border-right: 0;
            border-top: 0;
            background-color: rgb(249, 250, 251);
            color: ${(props) => props.theme.colors.primaryText};
            font-weight: 600;
            padding: 4px 0;
            border-bottom: 1px solid ${(props) => props.theme.colors.divider};
        `};
`;

GridTableRow.defaultProps = {
    uiSelectable: true,
    uiEven: false,
};

/** @component */
export default GridTableRow;
