import React from "react";
// import Fabrication from "../../../assets/images/fabrication.png";
// import Fabrics from "../../../assets/images/fabrics.png";

// const images =[Fabrication,Fabrics]

interface TitleInterface {
    title: string;
    isActive?: boolean;
    index: number;
}
const rowHeight = 14;

const TitleSection = ({ title, isActive, index }: TitleInterface) => {
    return (
        <div
            className={`grid grid-cols-12 h-${rowHeight}  bg-[#F6F6F6] border border-solid border-[#e2e2e2] border-t-0 ${
                !isActive && "text-twGrey italic"
            }`}
        >
            <div className="col-span-1 flex"></div>
            <div className="self-center items-center col-span-6 flex h-full">
                {/* <img src={images[index]} alt="" /> */}
                <p
                    className="self-center pl-3 text-lg text-[#545454] text-[18px] font-medium"
                    style={{ fontWeight: "600" }}
                >
                    {title || ""} {!isActive && "(inactive)"}
                </p>
            </div>
        </div>
    );
};

export default TitleSection;
