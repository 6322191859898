import React from "react";
import { breakpoints } from "../theme";
import styled, { css } from "../theme/styled";
import { cleanUiProps } from "../utils/withStyledProps";

/**
 * Expanded mergeOn configuration
 */
export interface MergeConfig {
    /**
     * Breakpoint to merge columns
     */
    uiValue: number;
    /**
     * Overwrite flex-grow
     */
    uiGrow?: number;
    /**
     * Overwrite width in percents
     */
    uiWidth?: number;
    /**
     * Overwrite flex-shrink
     */
    uiShrink?: number;
    /**
     * Overwrite alignment
     */
    uiAlignment?: "start" | "center" | "end";
    /**
     * Overwrite flex direction
     */
    uiDirection?: "column" | "row";
}

/**
 * Wrapper column configuration
 */
export interface WrapperColumnConfig {
    /**
     * Actual column flag
     */
    uiActualColumn: false;
    /**
     * Breakpoint to hide this column & all descendants completely
     */
    uiHideOn?: false | number;
    /**
     * Breakpoint to merge descendants columns into one
     */
    uiMergeOn?: false | number | MergeConfig | MergeConfig[];
    /**
     * Inner columns configuration
     */
    uiInnerColumns?: ColumnConfig[] | ColumnConfig;
    /**
     * Column width, in percent
     */
    uiWidth?: number;
    /**
     * Flex-grow
     */
    uiGrow?: number;
    /**
     * Flex-shrink
     */
    uiShrink?: number;
    /**
     * Alignment
     * @default start
     */
    uiAlignment?: "start" | "center" | "end";
}

export interface ActualColumnConfig {
    /**
     * Pass true to indicate that corresponding child should be rendered instead
     * of wrapper column
     */
    uiActualColumn: true;
}

/**
 * Column configuration
 */
export type ColumnConfig = WrapperColumnConfig | ActualColumnConfig;

interface ColumnWrapperUIProps {
    uiWidth?: number;
    uiGrow?: number;
    uiShrink?: number;
    uiMergeOn?: false | number | MergeConfig | MergeConfig[];
    uiHideOn?: false | number;
    uiAlignment?: "start" | "center" | "end";
}

/**
 * Process mergeOn and return necessary @media
 */
function processMerge(props: ColumnWrapperUIProps): any {
    if (!props.uiMergeOn) {
        return "";
    }
    if (typeof props.uiMergeOn === "number") {
        return breakpoints.down(props.uiMergeOn, css`
            flex-flow: column nowrap;

            & > div {
                width: 100%;
            }
        `);
    }
    const mergeArr = Array.isArray(props.uiMergeOn) ? props.uiMergeOn : [props.uiMergeOn];
    return mergeArr.map(m => breakpoints.down(m.uiValue, css`
        flex-flow: ${m.uiDirection != null ? m.uiDirection : "column"} nowrap;
        ${m.uiWidth != null ? `width: ${m.uiWidth}%` : ""};
        ${m.uiGrow != null ? `flex-grow: ${m.uiGrow}` : ""};
        ${m.uiShrink != null ? `flex-shrink: ${m.uiShrink}` : ""};

        & > div {
            width: 100%;
            ${m.uiAlignment != null ? `justify-content: ${m.uiAlignment === "start" ? "flex-start" : m.uiAlignment === "end" ? "flex-end" : "center"}` : ""};
        }
    `));
}

/**
 * Flexible column wrapper. Internal element
 */
const OldColumnWrapper = styled.div<ColumnWrapperUIProps> `
    display: flex;
    flex-flow: row nowrap;
    min-width: 0;
    width: ${props => props.uiWidth != null ? props.uiWidth + "%" : "auto"};
    flex-grow: ${props => props.uiGrow || "initial"};
    flex-shrink: ${props => props.uiShrink || "initial"};
    justify-content: ${props => props.uiAlignment === "end" ? "flex-end" : props.uiAlignment === "center" ? "center" : "flex-start"};
    ${props => processMerge(props)};
    ${props => props.uiHideOn && breakpoints.down(props.uiHideOn, css`
        display: none;
    `)};
`;

OldColumnWrapper.defaultProps = {
    uiShrink: 0,
    uiGrow: 1,
    uiAlignment: "start",
};

/**
 * Process configuration, render either column wrappers or original childs
 */
function processConfig(config: ColumnConfig, origChildren: React.ReactNode[], state: { childIndex: number }, index?: number): React.ReactNode | null {
    if (config.uiActualColumn) {
        return origChildren[state.childIndex++] || null;
    }
    const innerColumns = !config.uiInnerColumns ? [] : Array.isArray(config.uiInnerColumns) ? config.uiInnerColumns : [config.uiInnerColumns];
    return (
        <OldColumnWrapper
            key={index}
            uiMergeOn={config.uiMergeOn}
            uiGrow={config.uiGrow}
            uiShrink={config.uiShrink}
            uiWidth={config.uiWidth}
            uiHideOn={config.uiHideOn}
            uiAlignment={config.uiAlignment}
        >
            {innerColumns.map((c, i) => processConfig(c, origChildren, state, i))}
        </OldColumnWrapper>
    );
}

export interface TableHeadUIProps {
    /**
     * Column definition
     */
    uiColumns?: ColumnConfig;
}

export type TableHeadProps = React.HTMLAttributes<HTMLElement> & TableHeadUIProps;

const TableHeadComponent: React.SFC<TableHeadProps> = props => {
    const { children, uiColumns, ...other } = props;
    const childArray = React.Children.toArray(children);

    cleanUiProps(other);
    const state = { childIndex: 0 };
    return (
        <div {...other}>
            {processConfig(uiColumns!, childArray, state)}
        </div>
    );
};

const TableHeadStyled = styled(TableHeadComponent) `
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    border: 1px solid ${props => props.theme.colors.divider};
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-collapse: collapse;
    background-color: rgb(249, 250, 251);
    color: ${props => props.theme.colors.primaryText};
    font-weight: 600;
    padding: 4px 0;
    flex-shrink: 0;
`;

TableHeadStyled.defaultProps = {
    uiColumns: {
        uiActualColumn: true,
    },
};

/**
 * Table head row
 */
export const TableHead = TableHeadStyled;

export interface TableRowUIProps extends TableHeadUIProps {
    /**
     * True if row is selectable
     * @default true
     */
    uiSelectable?: boolean;
    /**
     * True if even rows should have different color
     * @default false
     */
    uiEven?: boolean;
    /**
     * Display border
     */
    uiBorder?: boolean;
}

export type TableRowProps = React.HTMLAttributes<HTMLElement> & TableRowUIProps;

const TableRowComponent: React.SFC<TableRowProps> = props => {
    const { children, uiColumns, uiSelectable, uiEven, className, uiBorder, ...other } = props;
    const childArray = React.Children.toArray(children);

    cleanUiProps(other);
    const state = { childIndex: 0 };
    return (
        <div
            {...other}
            className={className}
        >
            {processConfig(uiColumns!, childArray, state)}
        </div>
    );
};

const TableRowStyled = styled(TableRowComponent) `
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    position: relative;
    font-weight: normal;
    padding: 6px 0;
    background: ${props => props.theme.colors.background};
    -webkit-tap-highlight-color: transparent;
    transition: background, color 1.5s linear;
    ${props => props.uiBorder && css`
        &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.07);
            border-collapse: collapse;
        }
    `};
    ${props => props.uiSelectable && css`
        cursor: pointer;

        &:hover {
            background: rgba(0, 40, 100, 0.05);
        }
    `};
    ${props => props.uiEven && css`
        :nth-child(even) {
            background: rgb(245, 245, 245);
        }
    `};
`;

TableRowStyled.defaultProps = {
    uiSelectable: true,
    uiEven: false,
    uiColumns: {
        uiActualColumn: true,
    },
};

/**
 * Table row
 */
export const TableRow = TableRowStyled;

// tslint:disable-next-line:no-empty-interface
export interface TableUIProps {
    /**
     * Bordered table
     * @default true
     */
    uiBorder?: boolean;
}

export type TableProps = React.HTMLAttributes<HTMLElement> & TableUIProps;

/**
 * Main table container
 */
export const Table = styled.div<TableUIProps>`
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    ${({ uiBorder, theme }) => uiBorder && css`
        border: 1px solid ${theme.colors.divider};
        border-collapse: collapse;
        border-radius: 0.28rem;
    `};
`;
Table.defaultProps = {
    uiBorder: true,
};

// tslint:disable-next-line:no-empty-interface
export interface TableBodyUIProps { }

export type TableBodyProps = React.HTMLAttributes<HTMLElement> & TableBodyUIProps;

/**
 *
 * Table body container
 */
export const TableBody = styled.div<TableBodyProps>`
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    flex-shrink: 0;
`;

// tslint:disable-next-line:no-empty-interface
export interface NoItemRowUIProps { }

export type NoItemRowProps = React.HTMLAttributes<HTMLElement> & NoItemRowUIProps;

/**
 * Table row for no items
 */
export const NoItemRow = styled.div<NoItemRowProps> `
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    padding: 15px;
    background: repeating-linear-gradient(-45deg, transparent, transparent 5px, whitesmoke 5px, whitesmoke 6px);
`;

// tslint:disable-next-line:no-empty-interface
export interface TableCellUIProps {}

export type TableCellProps = React.HTMLAttributes<HTMLElement> & TableCellUIProps;

/**
 * Simple table cell with some padding
 */
export const TableCell = styled.div<TableCellProps> `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    max-width: 100%;
    padding: 5px 10px;
    ${breakpoints.down("sm", css`
        padding: 3px 10px;
    `)};
`;
